<template>
  <div class="dynamic-department-component">
    <div class="selector-container">
      <el-select
        ref="selectIns"
        :disabled="disabled"
        class="department-selector"
        v-model="displayValue"
        :multiple="multiple"
        placeholder="请选择"
        @remove-tag="staffRemove"
        @focus="attendanceStaffFocus"
      >
      </el-select>
      <img
        class="input-icon"
        src="@/assets/images/workflow/department_icon.png"
        alt=""
      />
    </div>
    <DepartmentSelection
      v-if="departmentDialog.dialogVisible"
      :dataset="departmentDialog"
      :multiple="multiple"
      @close="dialogCloseHandler"
      @submit="departmentSubmit"
    />
  </div>
</template>

<script>
import { labelRender, updateSource } from "../../../detail/utils.js";
import DepartmentSelection from "@/components/DepartmentSelection";

export default {
  name: "dynamic-department-component",
  components: {
    DepartmentSelection,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        this.value = labelRender(datas, "departmentDefault", []);
        this.multiple = labelRender(datas, "multiple", false);
        this.code = labelRender(datas, "code", null);
        this.updateHandler();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    displayValue: {
      get() {
        return this.value.map((item) => item.deptName);
      },
      set() {},
    },
  },
  data() {
    return {
      value: [],
      multiple: false,
      code: null,
      departmentDialog: {
        selectList: [],
        dialogVisible: false,
        mainTitle: "选择部门",
      },
    };
  },
  methods: {
    dialogCloseHandler() {
      this.departmentDialog.dialogVisible = false;
    },
    departmentSubmit(userList) {
      this.value = userList;
      this.departmentDialog.dialogVisible = false;
      this.updateHandler();
    },
    staffRemove(value) {
      this.value = this.value.filter((item) => item.deptName !== value);
      this.updateHandler();
    },
    attendanceStaffFocus() {
      this.departmentDialog.selectList = this.value;
      this.departmentDialog.dialogVisible = true;
      this.$nextTick(() => {
        const domIns = this.$refs.selectIns.blur();
        console.log("domIns", domIns);
      });
    },
    updateHandler() {
      updateSource(this.dataset, 'departmentDefault', this.value)
      this.$emit("update", {
        key: this.code,
        value: this.multiple
          ? this.value.map((item) => item.deptId)
          : this.value.length
          ? this.value[0].deptId
          : undefined,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic-department-component {
  .selector-container {
    position: relative;
    .input-icon {
      position: absolute;
      left: 12px;
      top: 10px;
    }
    .department-selector {
      width: 100%;
      position: relative;
      ::v-deep .el-input__inner {
        padding-left: 40px;
      }
    }
  }
}
</style>