var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rx-input-panel", on: { click: _vm.handleOutsideClick } },
    [
      _c(
        "div",
        { ref: "inputWrapRef", staticClass: "input" },
        [
          _c(
            "el-input",
            _vm._g(
              _vm._b(
                {
                  ref: "inputRef",
                  on: { focus: _vm.handleFocus, input: _vm.handleInput },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "suffix",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { on: { click: _vm.suffixClick } },
                              [_vm._t("suffix")],
                              2
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                "el-input",
                [_vm.$props, _vm.$attrs],
                false
              ),
              _vm.$listeners
            )
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          ref: "popupRef",
          staticClass: "popup",
          class: _vm.popupClass,
          style: _vm.style,
          on: { click: _vm.handleClickPopup },
        },
        [
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.visible
                ? [
                    _vm._t("default", function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "panel" },
                          [
                            _vm._t(
                              "panel-list",
                              function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "input-panel-wrapper" },
                                    _vm._l(
                                      _vm.suggestions,
                                      function (groupItem) {
                                        return _c(
                                          "ul",
                                          {
                                            key: groupItem.value,
                                            staticClass: "panel-list",
                                          },
                                          [
                                            groupItem.children.length
                                              ? [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "panel-list",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "group-item-title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              groupItem.label
                                                            ) +
                                                              "（" +
                                                              _vm._s(
                                                                groupItem
                                                                  .children
                                                                  .length
                                                              ) +
                                                              "）"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._l(
                                                        groupItem.children,
                                                        function (item) {
                                                          return _c(
                                                            "ul",
                                                            {
                                                              key: item.value,
                                                              staticClass:
                                                                "panel-list",
                                                            },
                                                            [
                                                              _c(
                                                                "li",
                                                                {
                                                                  staticClass:
                                                                    "option",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleClickPanelItem(
                                                                          item.data
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src:
                                                                        item
                                                                          .data
                                                                          .nodeType ===
                                                                        "category"
                                                                          ? _vm.folderUrl
                                                                          : _vm.fileUrl,
                                                                    },
                                                                  }),
                                                                  _c("div", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "option-title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.label
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "option-path",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.label
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              },
                              { data: _vm.suggestions }
                            ),
                          ],
                          2
                        ),
                      ]
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }