<template>
  <div class="props-component-default">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <!-- onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9_-]/g, '')" -->
      <el-input-number
        v-if="['numberInput'].includes(itemType)"
        v-model="dataset.value"
        class="number-input-dom"
        :controls="false"
        :min="minValue"
        :max="maxValue"
      ></el-input-number>
      <el-input
        v-else
        v-model="dataset.value"
        :placeholder="dataset.value || '请输入'"
        :maxlength="dataset.key === 'default' ? 999 : 20"
        :show-word-limit="dataset.key !== 'default'"
      ></el-input>
    </el-form-item>
  </div>
</template>

<script>
import { labelRender } from "../utils.js";
import { cloneDeep } from "lodash";

export default {
  name: "props-component-default",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    propsList: {
      type: Array,
      default() {
        return [];
      },
    },
    itemType: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      minValue: Number.MIN_SAFE_INTEGER,
      maxValue: Number.MAX_SAFE_INTEGER,
    };
  },
  watch: {
    dataset: {
      handler() {
        switch (this.itemType) {
          case "numberInput":
            this.numberInputHandler();
            break;
        }
      },
      deep: true,
    },
  },
  methods: {
    // 数字输入框的默认值需要处理min和max
    numberInputHandler() {
      const propsList = cloneDeep(this.propsList);
      const minValueObj = labelRender(
        { propsList },
        "minValue",
        Number.MIN_SAFE_INTEGER
      );
      const { value, noLimit } = minValueObj;
      this.minValue = noLimit === false ? value : Number.MIN_SAFE_INTEGER;
      const maxValueObj = labelRender(
        { propsList },
        "maxValue",
        Number.MAX_SAFE_INTEGER
      );
      const { value: value2, noLimit: nolimit2 } = maxValueObj;
      this.maxValue = nolimit2 === false ? value2 : Number.MAX_SAFE_INTEGER;
      // 数字输入框的value如果为null时，显示的为0，需要处理成undefined
      const currentValue = labelRender({ propsList }, "default", null);
      if (currentValue === null) {
        this.dataset.value = undefined;
      }
    },
  },
};
</script>