var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "props-component-timeDefault" },
    [
      _c(
        "el-form-item",
        {
          class: ["single-form-item", `single-form-${_vm.dataset.key}`],
          attrs: { label: _vm.dataset.label, prop: _vm.dataset.key },
        },
        [
          _vm.pickerShow
            ? _c("el-time-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "is-range": _vm.isRange,
                  "range-separator": "-",
                  "value-format": "HH:mm:ss",
                  "start-placeholder": "开始",
                  "end-placeholder": "结束",
                  placeholder: "请选择",
                },
                model: {
                  value: _vm.dataset.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataset, "value", $$v)
                  },
                  expression: "dataset.value",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }