var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "page",
      style: {
        width: _vm.sidebar.opened
          ? "calc(100vw - 330px)"
          : "calc(100vw - 140px)",
      },
    },
    [
      _c("ProcessTable", {
        ref: "processTableRef",
        staticClass: "table-area",
        attrs: {
          queryType: "management",
          tabList: _vm.tabList,
          fetchList: _vm.fetchList,
        },
        scopedSlots: _vm._u([
          {
            key: "operate",
            fn: function (scope) {
              return [
                [1].includes(scope.row.processStatus)
                  ? _c(
                      "el-button",
                      {
                        staticClass: "view-btn",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.openTransformDialog(scope.row)
                          },
                        },
                      },
                      [_vm._v("转办")]
                    )
                  : _vm._e(),
                [1, 2, 3].includes(scope.row.processStatus)
                  ? _c(
                      "el-button",
                      {
                        staticClass: "view-btn danger-btn",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.openCloseDialog(scope.row)
                          },
                        },
                      },
                      [_vm._v("关单")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "FlowModal",
        {
          attrs: {
            width: "600px",
            title: "关单",
            visible: _vm.closeDialogConfig.visible,
            loading: _vm.loading,
          },
          on: { onOk: _vm.onOkCloseDialog, onCancel: _vm.onCancelCloseModal },
        },
        [
          _vm.closeDialogConfig.visible
            ? _c("CloseTransformFormModal", {
                ref: "closeFormModalRef",
                attrs: {
                  definitionId: _vm.closeDialogConfig.data.definitionId,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "FlowModal",
        {
          attrs: {
            width: "600px",
            title: "转办",
            visible: _vm.transformDDialogConfig.visible,
            loading: _vm.loading,
          },
          on: {
            onOk: _vm.onOkTransformDialog,
            onCancel: _vm.onCancelTransformModal,
          },
        },
        [
          _vm.transformDDialogConfig.visible
            ? _c("CloseTransformFormModal", {
                ref: "transformFormModalRef",
                attrs: {
                  definitionId: _vm.transformDDialogConfig.data.definitionId,
                  "show-transferred-field": true,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }