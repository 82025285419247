var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "props-component-activeValue" },
    [
      _c(
        "el-form-item",
        {
          class: ["single-form-item", `single-form-${_vm.dataset.key}`],
          attrs: { label: _vm.dataset.label, prop: _vm.dataset.key },
        },
        [
          _c("el-input", {
            attrs: { placeholder: _vm.dataset.placeHolder },
            model: {
              value: _vm.dataset.value,
              callback: function ($$v) {
                _vm.$set(_vm.dataset, "value", $$v)
              },
              expression: "dataset.value",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }