var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "props-component-maxValue" },
    [
      _c(
        "el-form-item",
        {
          class: ["single-form-item", `single-form-${_vm.dataset.key}`],
          attrs: { label: _vm.dataset.label, prop: _vm.dataset.key },
        },
        [
          _c(
            "div",
            { staticClass: "maxValue-line" },
            [
              _c("el-input-number", {
                staticClass: "number-input",
                attrs: { disabled: _vm.dataset.value.noLimit },
                model: {
                  value: _vm.dataset.value.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataset.value, "value", $$v)
                  },
                  expression: "dataset.value.value",
                },
              }),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.dataset.value.noLimit,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataset.value, "noLimit", $$v)
                    },
                    expression: "dataset.value.noLimit",
                  },
                },
                [_vm._v("不限")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }