<template>
  <div class="graph-component-input single-graph-component">
    <div :class="['item-label', datas.require && 'item-label-required']">
      {{ datas.label }}
    </div>
    <!-- el-select在只读状态下点击事件没办法修改，点击到组件无法激活当前卡片，所以用input实现 -->
    <el-input
      :suffix-icon="
        datas.clearable ? 'el-icon-circle-close' : 'el-icon-arrow-down'
      "
      :readonly="readOnly"
      :placeholder="datas.placeHolder"
    >
    </el-input>
  </div>
</template>
<script>
import { labelRender } from "../utils.js";
export default {
  name: "graph-component-input",
  props: {
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(item) {
        this.datas.label = labelRender(item, "label", "未命名");
        this.datas.clearable = labelRender(item, "clearable", false);
        this.datas.placeHolder = labelRender(item, "placeHolder2", "请输入");
        console.log("this.datas.placeHolder", this.datas.placeHolder);
        this.datas.require = labelRender(item, "require", false);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      labelRender,
      datas: {
        require: false,
        label: null,
        clearable: false,
        placeHolder: "请输入",
      },
    };
  },
};
</script>
<style lang="less" scoped>
.graph-component-input {
}
</style>