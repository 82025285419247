var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "single-form-item" },
    [
      _c(_vm.propsCompRequire(_vm.dataset), {
        key: _vm.dataset.id,
        tag: "component",
        attrs: { dataset: _vm.dataset, readOnly: _vm.readOnly },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }