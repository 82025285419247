export const processTableTabs = [
  { key: '-1', label: "全部" },
  { key: '0', label: "草稿" },
  { key: '1', label: "待审" },
  { key: '2', label: "驳回" },
  { key: '3', label: "撤回" },
  { key: '4', label: "结束" },
];

export const statisticTabs = [
  {
    label: "全部流程",
    icon: "kbIcon1",
    key: "allCount",
    routerName: "approvalProcessAll",
  },
  {
    label: "待我审的",
    icon: "kbIcon4",
    key: "pendingProcessCount",
    routerName: "approvalProcessPendingReview",
  },
  {
    label: "我起草的",
    icon: "kbIcon2",
    key: "draftCount",
    routerName: "approvalProcessDraft",
  },
  {
    label: "我已审的",
    icon: "kbIcon3",
    key: "completedProcessCount",
    routerName: "approvalProcessMyReviewed",
  },
];
export const showSearchField = [
  { key: "modeName", order: 1, label: "流程名称" },
  { key: "applicant", order: 2, label: "发起人" },
  { key: "createDate", order: 3, label: "创建时间" },
  { key: "processStatus", order: 4, label: "状态" },
  { key: "currentReviewer", order: 5, label: "当前处理人" },
];
