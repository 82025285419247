
<template>
 <el-tooltip placement="top-start" :offset="100" v-bind="$attrs" v-tooltip-auto-show :content="value" >
    <div class="text-overflow" :class="contentClass">{{value}}</div>
 </el-tooltip>
</template>

<script>
export default {
    name: "TooltipText",
    props: {
        value: {
            type: String,
            default: ''
        },
        contentClass: {
          type: String,
          default: ''
        }
    }
}
</script>

<style scoped> 
.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>