import { cloneDeep } from 'lodash';

export const componentsList = [
  {
    key: 'formComponent',
    label: '表单组件',
    active: true,
    icon: require('../../../../assets/images/dynamicForm/component_icon_1.png'),
    list: [
      {
        key: 'input',
        label: '单行输入',
        icon: require('../../../../assets/images/dynamicForm/component_icon_input.png'),
      },
      {
        key: 'textarea',
        label: '多行输入',
        icon: require('../../../../assets/images/dynamicForm/component_icon_textarea.png'),
      },
      {
        key: 'selector',
        label: '下拉选择器',
        icon: require('../../../../assets/images/dynamicForm/component_icon_selector.png'),
      },
      {
        key: 'numberInput',
        label: '数字输入框',
        icon: require('../../../../assets/images/dynamicForm/component_icon_numberInput.png'),
      },
      {
        key: 'timePicker',
        label: '时间选择',
        icon: require('../../../../assets/images/dynamicForm/component_icon_timePicker.png'),
      },
      {
        key: 'calendar',
        label: '日历选择',
        icon: require('../../../../assets/images/dynamicForm/component_icon_calendar.png'),
      },
      {
        key: 'switch',
        label: '开关按钮',
        icon: require('../../../../assets/images/dynamicForm/component_icon_switch.png'),
      },
      {
        key: 'phoneNumber',
        label: '手机号',
        icon: require('../../../../assets/images/dynamicForm/component_icon_phoneNumber.png'),
      },
      {
        key: 'radio',
        label: '单选按钮',
        icon: require('../../../../assets/images/dynamicForm/component_icon_radio.png'),
      },
      {
        key: 'checkbox',
        label: '多选按钮',
        icon: require('../../../../assets/images/dynamicForm/component_icon_checkbox.png'),
      },
      {
        key: 'editor',
        label: '编辑器',
        icon: require('../../../../assets/images/dynamicForm/component_icon_editor.png'),
      },
      {
        key: 'imgUploader',
        label: '图片上传',
        icon: require('../../../../assets/images/dynamicForm/component_icon_imgUploader.png'),
      },
      {
        key: 'fileUploader',
        label: '文件上传',
        icon: require('../../../../assets/images/dynamicForm/component_icon_fileUploader.png'),
      },
      {
        key: 'email',
        label: '邮箱',
        icon: require('../../../../assets/images/dynamicForm/component_icon_email.png'),
      },
    ],
  },
  {
    key: 'businessComponent',
    label: '业务组件',
    active: true,
    icon: require('../../../../assets/images/dynamicForm/component_icon_1.png'),
    list: [
      {
        key: 'user',
        label: '用户组件',
        icon: require('../../../../assets/images/dynamicForm/component_icon_user.png'),
      },
      {
        key: 'department',
        label: '部门组件',
        icon: require('../../../../assets/images/dynamicForm/component_icon_department.png'),
      },
    ],
  },
];
/**
 * 标题：label
 * 编码：code
 * 占位内容：placeHolder
 * 默认内容：default
 * 最大字数：maxLength
 * 布局：layout
 *    - half / all
 * 必填：require
 *    - true / false
 * 占位：occupy
 *  {
 *    value: true | false,
 *    clearable: true | false,
 *    placeHolder: '请选择'
 *  }
 */
const configMaps = {
  input: [
    'label',
    'code',
    'placeHolder',
    'default',
    'maxLength',
    'layout',
    'require',
  ],
  textarea: [
    'label',
    'code',
    'placeHolder',
    'default',
    'maxLength',
    'layout',
    'require',
  ],
  selector: [
    'label',
    'code',
    'placeHolder2',
    'options',
    'default',
    'layout',
    'clearable',
    'multiple',
    'filterable',
    'require',
  ],
  numberInput: [
    'label',
    'code',
    'placeHolder',
    'default',
    'unit',
    'accuracy',
    'maxValue',
    'minValue',
    'layout',
    'require',
  ],
  timePicker: [
    'label',
    'code',
    'isRange',
    'startPlaceHolder',
    'endPlaceHolder',
    'placeHolder2',
    'timeDefault',
    'layout',
    'editable',
    'clearable',
    'require',
  ],
  companySelector: [
    'companyLabel',
    'code',
    'placeHolder2',
    'companyOptions',
    'default',
    'layout',
    'clearable',
    'multiple',
    'filterable',
    'require',
  ],
  calendar: [
    'label',
    'code',
    'placeHolder2',
    'calendarDefault',
    'layout',
    'editable',
    'clearable',
    'require',
  ],
  switch: [
    'label',
    'code',
    'switchDefault',
    'activeValue',
    'inactiveValue',
    'layout',
  ],
  phoneNumber: [
    'label',
    'code',
    'phoneDefault',
    'placeHolder',
    'layout',
    'require',
  ],
  radio: ['label', 'code', 'direction', 'options', 'layout', 'require', 'hideValue',],
  checkbox: ['label', 'code', 'direction', 'options', 'layout', 'require', 'hideValue',],
  editor: ['label', 'code', 'placeHolder', 'default', 'layout', 'require'],
  imgUploader: [
    'label',
    'code',
    'limit',
    'layout',
    'uploadMultiple',
    'require',
    'hideValue',
  ],
  fileUploader: [
    'label',
    'code',
    'limit',
    'layout',
    'uploadMultiple',
    'require',
    'hideValue',
  ],
  email: ['label', 'code', 'placeHolder', 'emailDefault', 'layout', 'require'],
  user: ['label', 'code', 'layout', 'userDefault', 'multiple', 'require'],
  department: [
    'label',
    'code',
    'layout',
    'departmentDefault',
    'multiple',
    'require',
  ],
};
const singleConfigMaps = {
  hideValue: {
    key: 'hideValue',
    value: null,
    require: false,
  },
  userDefault: {
    key: 'userDefault',
    label: '默认值',
    value: [], //提交参数：[userId1, userId2]
    // displayValue: null, // 给selector回显用['name1', 'name2']
    // valueResource: [], // value原始数据，给弹框回显用
    require: false,
  },
  departmentDefault: {
    key: 'departmentDefault',
    label: '默认值',
    value: [],
    require: false,
  },
  limit: {
    key: 'limit',
    label: '最大上传数量',
    value: 1,
    require: false,
  },
  direction: {
    key: 'direction',
    label: '排列方式',
    value: 'row', // row | column
    require: false,
  },
  calendarDefault: {
    key: 'calendarDefault',
    label: '默认值',
    value: null,
    valueType: 'date', // date | datetime
    placeHolder: '请选择',
    require: false,
  },
  switchDefault: {
    key: 'switchDefault',
    label: '默认开/关',
    value: true, // true | false
    require: false,
  },
  activeValue: {
    key: 'activeValue',
    label: '开启时的值',
    value: 'true',
    placeHolder: '请输入',
    require: false,
  },
  inactiveValue: {
    key: 'inactiveValue',
    label: '关闭时的值',
    value: 'false',
    placeHolder: '请输入',
    require: false,
  },
  label: {
    key: 'label',
    label: '标题',
    value: '未命名',
    placeHolder: '',
    require: true,
  },
  companyLabel: {
    key: 'label',
    label: '标题',
    value: '项目',
    placeHolder: '',
    require: true,
  },
  code: {
    key: 'code',
    label: '编码',
    value: null,
    placeHolder: '请输入',
    require: true,
  },
  placeHolder: {
    key: 'placeHolder',
    label: '占位内容',
    value: '请输入',
    placeHolder: '请输入',
    require: false,
  },
  placeHolder2: {
    key: 'placeHolder2',
    label: '占位内容',
    value: '请选择',
    placeHolder: '请输入',
    require: false,
  },
  default: {
    key: 'default',
    label: '默认值',
    value: null,
    placeHolder: '请输入',
    require: false,
  },
  maxLength: {
    key: 'maxLength',
    label: '最大字数',
    value: 20,
    require: false,
  },
  layout: {
    key: 'layout',
    label: '布局',
    value: 'half',
    require: false,
  },
  require: {
    key: 'require',
    label: '必填',
    value: false,
    require: false,
  },
  occupy: {
    key: 'occupy',
    label: '占位',
    value: {
      value: true,
      clearable: true,
      placeHolder: '请选择',
    },
    require: false,
  },
  multiple: {
    key: 'multiple',
    label: '是否多选',
    value: false,
    require: false,
  },
  uploadMultiple: {
    key: 'uploadMultiple',
    label: '允许批量上传',
    value: false,
    require: false,
  },
  filterable: {
    key: 'filterable',
    label: '是否可搜索',
    value: true,
    require: false,
  },
  clearable: {
    key: 'clearable',
    label: '显示清除按钮',
    value: true,
    require: false,
  },
  options: {
    key: 'options',
    label: '选项',
    require: false,
    config: {
      type: 'static', // static | inner | classify
      optionList: [
        {
          value: null,
          label: null,
          selected: true,
        },
        {
          value: null,
          label: null,
          selected: false,
        },
      ],
      serviceUrl: null,
      labelKey: null,
      valueKey: null,
      classifyCode: null,
    },
  },
  companyOptions: {
    key: 'companyOptions',
    label: '选项',
    require: false,
    config: {
      type: 'inner',
      optionList: [],
      serviceUrl: '/saascloud/saas/base/tenant/project/list',
      labelKey: 'projectName',
      valueKey: 'projectId',
    },
  },
  unit: {
    key: 'unit',
    label: '单位',
    unitType: 'prepend', // prepend | append
    unitString: '',
    require: false,
  },
  accuracy: {
    key: 'accuracy',
    label: '精度',
    value: 0,
    require: false,
  },
  maxValue: {
    key: 'maxValue',
    label: '最大值',
    value: {
      value: 999,
      noLimit: true,
    },
    require: false,
  },
  minValue: {
    key: 'minValue',
    label: '最小值',
    value: {
      value: 0,
      noLimit: true,
    },
    require: false,
  },
  isRange: {
    key: 'isRange',
    label: '是否为范围选择',
    value: false,
    require: false,
  },
  startPlaceHolder: {
    key: 'startPlaceHolder',
    label: '开始时间占位内容',
    value: '请选择',
    require: false,
  },
  endPlaceHolder: {
    key: 'endPlaceHolder',
    label: '结束时间占位内容',
    value: '请选择',
    require: false,
  },
  editable: {
    key: 'editable',
    label: '文本框可输入',
    value: true,
    require: false,
  },
  timeDefault: {
    key: 'timeDefault',
    label: '默认值',
    value: null,
    require: false,
  },
  phoneDefault: {
    key: 'phoneDefault',
    label: '默认值',
    value: null,
    require: false,
  },
  emailDefault: {
    key: 'emailDefault',
    label: '默认值',
    value: null,
    require: false,
  },
};
export const getPropsByType = (type) => {
  const configList = configMaps[type];
  return configList.map((item) => cloneDeep(singleConfigMaps[item]));
};
