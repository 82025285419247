<template>
  <div class="dynamic-fileUploader-component">
    <div class="upload-card card-container">
      <el-upload
        ref="upload"
        accept=".xls, .xlsx, .pdf, .doc, .docx"
        :limit="limit"
        :multiple="multiple"
        :disabled="disabled"
        :file-list="fileList"
        :auto-upload="true"
        :show-file-list="false"
        :on-change="handleChange"
        :on-success="successHandler"
        :on-error="errorHandler"
        :on-exceed="exceedHandler"
        :before-upload="beforeUpload"
        drag
        :action="uploadPathBuild()"
      >
        <img
          src="@/assets/images/import_folder_icon.png"
          style="margin-bottom: 8px"
          alt=""
        />
        <div class="el-upload__text">
          将文件拖到此处，或<em style="color: #2a61ff">点击选择文件</em>
        </div>
      </el-upload>
      <div
        v-for="(item, index) in fileList"
        :key="item.uid"
        class="file-list card-container"
      >
        <div class="top-line">
          <span class="name-span">
            <img
              :src="
                require(`@/assets/images/workflow/fileTypeIcon/${iconRender(
                  item
                )}.png`)
              "
              alt=""
            />
            <!-- <img src="@/assets/images/excel_icon.png" alt="" /> -->
            <div class="size-part">
              <div class="name-line">
                {{ item.name }}
              </div>
              <div class="size-line">
                {{ sizeRender(item.size) }}
              </div>
            </div>
          </span>
          <i class="el-icon-close" v-if="!disabled" @click="deleteHandler(item)"></i>
        </div>
        <div class="progress-line">
          <div class="outer">
            <div
              class="inner"
              :style="{
                width:
                  index === fileList.length - 1 ? `${progressRate}%` : '100%',
              }"
            ></div>
          </div>
          <div class="rate-part">
            {{ index === fileList.length - 1 ? progressRate : "100" }}%
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { labelRender, updateSource } from "../../../detail/utils.js";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "dynamic-fileUploader-component",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        this.limit = labelRender(datas, "limit", 1);
        this.code = labelRender(datas, "code", null);
        this.multiple = labelRender(datas, "uploadMultiple", false);
        const hideValue = labelRender(datas, "hideValue", null);
        this.fileList = hideValue || [];
        this.updateHandler()
      },
      deep: true,
      immediate: true,
    },
    fileList: {
      handler() {
        this.updateHandler();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      uploadLoading: false,
      fileList: [],
      limit: 1,
      multiple: false,
      progressRate: 100,
      code: null,
    };
  },
  methods: {
    iconRender(item) {
      const lowerCaseFileName = item.name.toLowerCase();
      const fileExtension = lowerCaseFileName.split(".").pop();
      switch (fileExtension) {
        // case 'jpg':
        // case 'jpeg':
        //   return 'image/jpeg';
        // case 'png':
        //   return 'image/png';
        // case 'gif':
        // return 'image/gif';
        case "pdf":
          return "pdf";
        // case 'txt':
        //   return 'text/plain';
        case "doc":
        case "docx":
          return "word";
        case "xls":
        case "xlsx":
          return "excel";
        case "ppt":
        case "pptx":
          return "ppt";
        // case 'mp3':
        //   return 'audio/mpeg';
        // case 'mp4':
        //   return 'video/mp4';
        // case 'zip':
        //   return 'application/zip';
        case "rar":
          return "rar";
        default:
          return "unknow";
      }
    },
    uploadPathBuild() {
      const rootPath = envInfo.bgApp.archivePath;
      return `${rootPath}/param/archive/upload?ulType=DefaultUpload&scopeType=ALL`;
    },
    updateHandler() {
      updateSource(this.dataset, 'hideValue', this.fileList)
      this.$emit("update", {
        key: this.code,
        value: this.fileList.map((item) => item.response[0].fileId),
      });
    },
    handleChange(file, fileList) {},
    exceedHandler(files, fileList) {
      this.$message.warning(`当前限制选择 ${this.limit} 个文件`);
    },
    deleteHandler({ uid }) {
      this.fileList = this.fileList.filter((item) => item.uid !== uid);
    },
    progressAnimation() {
      this.progressRate = 0;
      setTimeout(() => {
        this.progressRate = 100;
      }, 200);
    },
    successHandler(response, file, fileList) {
      this.uploadLoading = false;
      this.fileList = fileList.slice(-this.limit);
      this.progressAnimation();
    },
    errorHandler() {
      this.uploadLoading = false;
      this.resetDatas();
    },
    resetDatas() {
      this.fileList = [];
      this.progressRate = 0;
    },
    beforeUpload(file) {
      console.log("xxx1", file);
      this.uploadLoading = true;
      const isLt2M = file.size / 1024 / 1024 <= 10;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
        this.uploadLoading = false;
        return false;
      }
      return true;
    },
    sizeRender(size) {
      if (size > 1024 * 1024) {
        return `${(size / 1024 / 2014).toFixed()}MB`;
      }
      return `${(size / 1024).toFixed()}KB`;
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic-fileUploader-component {
  ::v-deep .el-upload-dragger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .file-list {
    margin-top: 12px;
    .top-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name-span {
        display: flex;
        align-items: center;
        .size-part {
          display: flex;
          flex-direction: column;
          margin-left: 8px;
          line-height: 14px;
          .name-line {
            color: #252d3d;
          }
          .size-line {
            color: #a7b4c7;
            margin-top: 4px;
          }
        }
      }
      .el-icon-close {
        position: relative;
        top: -7px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .progress-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .outer {
        flex: 1;
        background: #e6eef5;
        border-radius: 10px;
        position: relative;
        height: 8px;
        .inner {
          transition: all 0.7s ease-in-out;
          background: #2a61ff;
          border-radius: 10px;
          position: absolute;
          left: 0;
          top: 0;
          height: 8px;
        }
      }
      .rate-part {
        margin-left: 12px;
      }
    }
  }
}
</style>