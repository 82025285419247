var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ckPeopleSet" },
    [
      _c("TForm", {
        ref: "refFrom1",
        attrs: {
          lableRow: "",
          autoCheck: true,
          model: _vm.htmlDatas,
          formlist: _vm.formlist,
          rowGutter: 16,
          labelPosition: "left",
        },
        scopedSlots: _vm._u([
          {
            key: "copyTarget",
            fn: function (scope) {
              return [
                _c("div", { staticClass: "diyNdItems" }, [
                  _c(
                    "div",
                    { staticClass: "cpNodeAdd" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btnAdd",
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.openAddModal = true
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "btntxt" }, [
                            _vm._v("添加处理用户"),
                          ]),
                          _c("img", {
                            staticClass: "iconAdd",
                            attrs: {
                              src: require("@/assets/images/addicon2x.png"),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm._l(
                        _vm.htmlDatas[scope.current.keys],
                        function (it, dex) {
                          return _c("div", { key: dex, staticClass: "itdiv" }, [
                            _vm._v("\n\t\t\t\t\t\t" + _vm._s(it.name) + " "),
                            _c("img", {
                              staticClass: "delIcon",
                              attrs: {
                                src: require("@/assets/images/delx.png"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.delCopytoItms(dex)
                                },
                              },
                            }),
                          ])
                        }
                      ),
                      _vm.htmlDatas[scope.current.keys].length < 1
                        ? _c("div", { staticClass: "nullDiv" }, [
                            _c("div", [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/tbnull.png"),
                                },
                              }),
                            ]),
                            _vm._v("\n\t\t\t\t\t\t暂无数据\n\t\t\t\t\t"),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm.openAddModal
        ? _c("userTaskAuditor", {
            attrs: { isOpen: _vm.openAddModal },
            on: { close: _vm.closeDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }