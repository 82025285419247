var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.treeData.name
    ? _c("table", [
        _c("tr", [
          _c(
            "td",
            {
              class: {
                parentLevel:
                  Array.isArray(_vm.treeData.children) &&
                  _vm.treeData.children.length,
                extend:
                  Array.isArray(_vm.treeData.children) &&
                  _vm.treeData.children.length &&
                  _vm.treeData.extend,
              },
              attrs: {
                colspan: Array.isArray(_vm.treeData.children)
                  ? _vm.treeData.children.length * 2
                  : 1,
              },
            },
            [
              _c(
                "div",
                { class: { node: true, hasMate: _vm.treeData.mate } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "person",
                      class: Array.isArray(_vm.treeData.class)
                        ? _vm.treeData.class
                        : [],
                      on: {
                        click: function ($event) {
                          return _vm.$emit("click-node", _vm.treeData)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "avat" }, [
                        _vm.treeData.imageUrl
                          ? _c("img", { attrs: { src: _vm.treeData.imageUrl } })
                          : _vm._e(),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.treeData.name)),
                        ]),
                      ]),
                    ]
                  ),
                  Array.isArray(_vm.treeData.mate) && _vm.treeData.mate.length
                    ? _vm._l(_vm.treeData.mate, function (mate, mateIndex) {
                        return _c(
                          "div",
                          {
                            key: _vm.treeData.name + mateIndex,
                            staticClass: "person",
                            class: Array.isArray(mate.class) ? mate.class : [],
                            on: {
                              click: function ($event) {
                                return _vm.$emit("click-node", mate)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "avat" }, [
                              mate.imageUrl
                                ? _c("img", { attrs: { src: mate.imageUrl } })
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(mate.name)),
                            ]),
                          ]
                        )
                      })
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
        ]),
        Array.isArray(_vm.treeData.children) &&
        _vm.treeData.children.length &&
        _vm.treeData.extend
          ? _c(
              "tr",
              _vm._l(_vm.treeData.children, function (children, index) {
                return _c(
                  "td",
                  {
                    key: index,
                    staticClass: "childLevel",
                    attrs: { colspan: "2" },
                  },
                  [
                    _c("TreeChart", {
                      attrs: { json: children },
                      on: {
                        "click-node": function ($event) {
                          return _vm.$emit("click-node", $event)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }