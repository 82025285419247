<template>
  <div class="props-component-timeDefault">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <el-time-picker
        v-if="pickerShow"
        v-model="dataset.value"
        style="width: 100%"
        :is-range="isRange"
        range-separator="-"
        value-format="HH:mm:ss"
        start-placeholder="开始"
        end-placeholder="结束"
        placeholder="请选择"
      >
      </el-time-picker>
    </el-form-item>
  </div>
</template>

<script>
import { labelRender } from "../utils.js";

export default {
  name: "props-component-timeDefault",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    propsList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      pickerShow: true,
      isRange: false,
    };
  },
  watch: {
    propsList: {
      handler(list) {
        // 动态切换isRange会导致popover漂移至屏幕左上角，所以用pickerShow让组件重新加载一遍
        const current = labelRender({ propsList: list }, "isRange", false);
        if (current !== this.isRange) {
          this.pickerShow = false;
          setTimeout(() => {
            this.isRange = current;
            this.dataset.value = null;
            this.pickerShow = true;
          }, 100);
        }
      },
      deep: true,
    },
  },
};
</script>