<template>
	<!--线节点的表达式-->
	<div class="lineExpreHtml">
		<div v-if="!isAllowExp"> 不允许添加表达式</div>
		<TForm v-if='isAllowExp' ref="refFrom1" lableRow  :autoCheck='true' :model="htmlDatas" :formlist="formlist" :rowGutter='16' labelPosition='left'>
			<template slot="logicalSelect" slot-scope="s">
				<div class="diyNdItems">
					<div class="cpNodeAdd">
						<el-button class='btnAdd' type="text" @click='toAddConditItems'> 
							<span class="btntxt">添加条件</span><img src="@/assets/images/addicon2x.png" class="iconAdd" />
						</el-button>
					</div>
					<el-select v-model="htmlDatas[s.current.keys]"  placeholder="请选择">
                        <el-option v-for="(item,dex2) in formlist[0].options" :key="dex2" :label="item.name" :value="item.valCode?item.valCode:item.code"></el-option>
                    </el-select>
				</div>
			</template>
			<template slot="conditionlist" slot-scope="s">
				<div class="condWarp">
					<div class="condIts" v-for="(it,dex) in htmlDatas[s.current.keys]" :key='dex'>
						<div class="itCdName">
							条件{{dex+1}}
							<span class="delTx" @click="delCondition(dex)">删除</span>
						</div>
						<div class="itMes">
							<div class="tpRow">
								<div class="blodtx">选择关系</div>
								<el-select v-model="it.type"  placeholder="请选择">
			                        <el-option v-for="(item,dex2) in belongClass" :key="dex2" :label="item.name" :value="item.valCode?item.valCode:item.code"></el-option>
			                    </el-select>
							</div>
							<div>
								<div class="blodtx">关系运算符
									<el-button class='relationAdd' type="text" @click='toAddConditFilters(it)'> + </el-button>
								</div>
								<el-row :gutter="10" v-for='(_ic,_d) in it.filters' :key='_d' class='itfters'>
								  	<el-col :span="10">
								  		<el-select v-model="_ic.operator" placeholder="请选择">
										    <el-option v-for="item in calculateArrs" :key="item.code" :label="item.name" :value="item.code" />
									  	</el-select>
								  	</el-col>
								  	<el-col :span="12">
								  		<el-input v-model="_ic.value" />
									  	<!--<el-input v-model="_ic.value"  oninput="value=value.replace(/[^\d^\.]+/g,'')" />-->
								  	</el-col>
							  		<el-col :span="1">
									  <el-button :disabled='_d<1' class='delx' type="text" @click='toDelConditFilters(it,d)'> × </el-button>
								  	</el-col>
								</el-row>
							</div>
						</div>
						
						
						
					</div>
					<div class="nullDiv" v-if="htmlDatas[s.current.keys].length<1">
						<div ><img src="@/assets/images/tbnull.png" /></div>
						暂无数据
					</div>
				</div>
			</template>
		</TForm>
		
	
		
		

	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		components: { TForm},
		props: {
			formInfo:{
				type: Object,
				default:()=>{
					return {}
				}
			},
		},
		watch:{
			type: {
				deep: true, // 深度监听
				handler(val, oval) {}
			},
			htmlDatas: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$nextTick(()=>{
						this.$emit('upvals',this.htmlDatas)
					})
					
				}
			},
		},
		computed: {},
		data() {
			return {
				htmlDatas: {},
				formlist: [
					{name: '逻辑关系',keys: 'logic',value: 'or',type: 'slot',slotName:"logicalSelect",options:[
						{name:'与',code:'and'},
						{name:'或',code:'or'},
					]},
	  				{name: '条件列表',keys: 'conditions',value:[],type:'slot',slotName:"conditionlist",labelHide:true,options:[
						{name:'与',code:'1'},
						{name:'或',code:'2'},
					]},
				],
				openAddModal:false,
				calculateArrs:[//运算符(>,<,=,>=,<=)
					{name:'等于',code:'=='},
					{name:'大于',code:'>'},
					{name:'小于',code:'<'},
					{name:'大于等于',code:'>='},
					{name:'小于等于',code:'<='},
					{name:'包含',code:'isInclude'},
					{name:'不包含',code:'isNotInclude'},
				],
				belongClass:[],
				belongDfClass:[//所属类型 （项目：project、公司：company、部门:dept、岗位:duty、表单字段:xxx）
//					{name:'请选择',code:''},
					{name:'项目',code:'project'},
					{name:'公司',code:'company'},
					{name:'部门',code:'dept'},
					{name:'岗位',code:'duty'},
				],
				isAllowExp:false,
			};
		},
		created() {
			console.log('线表达式-formInfo',this.formInfo)
			this.resetTFrom();
		},
		mounted() {
			this.initHtml();
		},
		methods: {
			async initHtml(){
				//获取到所有节点  判断不是网关出线  不是的话 不允许配置表达式
				let res =await this.$parent.getNowNodes();
				let formConfis=this.$parent.getDefaultFormDatas();
				let formsArr=[];
				if(formConfis){
					formsArr=JSON.parse(formConfis);
					formsArr.map(it=>{
						const [findLabel]=it.propsList.filter(_its=>{
							return (_its.key=='label')
						})
						const [findCode]=it.propsList.filter(_its=>{
							return (_its.key=='code')
						})
						if(findLabel){
							it.name=findLabel.value;
						}
						if(findCode){
							it.code=findCode.value;
						}
					})
				}
				console.log('formConfis',JSON.parse(formConfis))
				this.belongClass=JSON.parse(JSON.stringify(this.belongDfClass)).concat(formsArr)
				
				if(res){
					const {lines,nodes}=res;
					this.isAllowExp=false;
					nodes.map(it=>{
						if(it.nodeType=='exclusiveGateway'){//仅限条件网关， 并行网关赞不需要||it.nodeType=='parallelGateway'
							let isMore=Array.isArray(it.outgoing);//判断节点是否有多根线
//							console.log(6666666666,typeof(it.outgoing),isMore)
							if(isMore){
								if(it.outgoing.indexOf(this.formInfo.id)>-1){
									this.isAllowExp=true;
								}
							}else{
								if(this.formInfo.id==it.outgoing){
									this.isAllowExp=true;
//									console.log('----------------isFind',this.formInfo.id,it.outgoing)
								}
							}
							
						}
					})
					
					
					console.log('线表达式-当前节点222',this.isAllowExp,res)
					
				}
//				
				
			},
			delCondition(i){
				this.htmlDatas.conditions.splice(i,1);
			},
			
			toAddConditItems(){
				//新增一个条件下
				let itInfo={
					type:'',//条件下的 选择关系  所属类型  （项目：project、公司：company、部门:dept、岗位:duty、表单字段:xxx）
					filters:[//关系运算符
						{
							"operator": "", // 操作符(>,<,=,>=,<=)
							"value": "" // 条件值
						}
					],//运算符
				}
				this.htmlDatas.conditions.push(itInfo)
			},
			toAddConditFilters(data){
				//新增一个条件下的运算关系符
				let items={
					"operator": "", // 操作符(>,<,=,>=,<=)
					"value": "" // 条件值
				}
				data.filters.push(items);
			},
			toDelConditFilters(data,i){
				//删除一个条件下的运算关系符
				data.filters.splice(i,1);
			},
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
	  				let fval=this.formInfo[item.keys]
	  				if(fval) item.value=fval;
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
	  		closeDialog(val){
	  			this.openAddModal=false;
	  			console.log('sdsd',val)
	  			
	  			if(val){
	  				this.htmlDatas.copyItems.push(val)
	  			}
	  		},
	  		delCopytoItms(i){
	  			this.htmlDatas.copyItems.splice(i,1);
	  		},
		}
		
	};
</script>

<style scoped>
	.cpNodeAdd{
		.nametx{
			font-weight: bold;
			font-size: 14px;
			color: #252D3D;
		}
		.btnAdd{
			position: absolute;
			top: 8px;
			padding: 0;
			right: 0;
			.btntxt{
				color: #2A61FF;
				margin-right: 4px;
			}
			.iconAdd{
				height: 14px;
				width: 14px;
			    vertical-align: top;
			}
		}
	}
	.condWarp{
		
		.condIts{
			margin-bottom: 10px;
			color: #252D3D;
		}
		.itCdName{
			
			font-weight: bold;
			position: relative;
			.delTx{
				position: absolute;
				right: 0;
				color: #F35555;
				cursor: pointer;
			}
		}
		.itMes{
			padding: 10px;
			border-radius: 10px;
			border: 1px solid #E3E8EE;
			.tpRow{
				margin-bottom: 12px;
			}
			.blodtx{
				font-size: 13px;
				font-weight: bold;
				position: relative;
				.relationAdd{
					position: absolute;
					top: 6px;
					right: 0;
					font-size: 20px;
					color: #2A61FF;
					padding: 0;
				} 
			}
			
			.itfters{
				margin: 4px 0;
				.delx{
					color: #F35555;
					font-size: 20px;
	                position: relative;
                	top: -4px;
				}
				.is-disabled.delx{
					color: #ffbaba;
				}
			}
		}
		
	}
	.nullDiv{
		text-align: center;
		color: #ccc;
		font-size: 12px;
		img{
			width: 84px;
		}
	}
</style>
<style type="text/css">
	.lineExpreHtml .el-input__inner{
		color: #252D3D;
		font-weight: bold;
	}
</style>