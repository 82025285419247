const workflow = {
  state: {
    flowVariable: null, //流程变量 {procInstId:xx, businessKey: xx, sourceApp: xx, porcDefKey: xx}
    statisticCounts: {
      allCount: 0, //所有计数
      draftCount: 0, //草稿计数
      completedProcessCount: 0, //已完成流程计数
      pendingProcessCount: 0, //待处理流程计数
    },
  },
  mutations: {
    SET_FLOW_VARIABLE: (state, variable) => {
      state.flowVariable = variable;
    },
    UPDATE_STATISTIC_COUNTS(state, counts) {
      state.statisticCounts = { ...state.statisticCounts, ...counts };
    },
  },
  actions: {
    SetFlowVariable({ commit }, variable) {
      commit("SET_FLOW_VARIABLE", variable);
    },
    updateStatisticCounts({ commit }, counts) {
      commit("UPDATE_STATISTIC_COUNTS", counts);
    },
  },
};

export default workflow;
