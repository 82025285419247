<template>
	<div class="rtConfig">
		<div class="tabs_warp">
	  		<div class="it_tap" v-for="(it,dex) in tabsArr" :key='dex' :class="{'actived':activeTabs==it.val}" v-if="!it.hide">
	  			<div  class="it_n" @click='setTabChange(it)'>{{ it.name }} <div class="xbtm"></div></div>
	  		</div>
	  	</div>	
		<div class="formWarp" ref="propertyPanel">
			<div v-if="!isClickNode">
				<!--没有选中节点的时候  是流程配置-->
				<div v-if="activeTabs==1">
					<!--<component :is="formName" :formInfo='webForms' @upvals='fromValsChange'></component>-->
					
					<flowWhole :model='flowCofigs.basics'></flowWhole>
				</div>
				<div v-else>
					<listenSet :formInfo='flowCofigs.listen'  @upvals='fromValsChange'></listenSet>
				</div>
			</div>
			<div v-if="isClickNode" :class="getNodeYype">
				<div v-if="activeTabs==1">
					<el-form :model="webForms" label-width="0" size="small" label-position='left' >
						<el-form-item label="" style='margin-right: 0;'>
							<div class="itFormlabel">
								<!--<span class="diyXx">*</span>--> 
								节点名称</div>
							<el-input v-model="webForms.name" @input="nameChange" style="width: 100%;" :maxlength='nameMaxNum'></el-input>
						</el-form-item>
						<!--<el-form-item label="" style='margin-right: 0;'>
							<div class="itFormlabel"><span class="diyXx">*</span> 节点key1</div>
							<el-input v-model="webForms.webkey" style="width: 100%;"></el-input>
						</el-form-item>-->
						<!--<el-form-item label="节点颜色">
							<el-color-picker v-model="webForms.color" @active-change="colorChange"></el-color-picker>
						</el-form-item>-->
					</el-form>
					
					<div :key='webForms.id'>
						<component ref='classNodes' :is="formName" :formInfo='webForms' @upvals='fromValsChange'></component>
					</div>
						
					</div>
					<div v-if="activeTabs==2">
						<listenSet :formInfo='webForms'  @upvals='fromValsChange'></listenSet>
					</div>
					<div v-if="activeTabs==3">
						<userTaskExamine :formInfo='webForms' @upvals='fromValsChange' />
					</div>
					<div v-if="activeTabs==4">
						<lineExpression :formInfo='webForms' @upvals='fromValsChange' />
					</div>
			</div>
		</div>
	</div>
</template>

<script>
	import listenSet from './fromTypes/listenSet.vue';//监听设置
	import flowWhole from './fromTypes/flowWhole.vue';//流程配置
	import copyNode from './fromTypes/copyNode.vue';//抄送节点
	import startNode from './fromTypes/startNode.vue';//开始节点
	import endNode from './fromTypes/endNode.vue';//结束节点
	import userTaskNode from './fromTypes/userTaskNode.vue';//审核人设置
	import userTaskExamine from './fromTypes/userTaskExamine.vue';//审核节点
	import gatewayNode from './fromTypes/gatewayNode.vue';//网关节点(条件网关+并行网关)
	import timingNode from './fromTypes/timingNode.vue';//审核节点
	import lineNode from './fromTypes/lineNode.vue';//线节点
	import lineExpression from './fromTypes/lineExpression.vue';//线节点-表达式
	export default {
		components: { listenSet,flowWhole,copyNode,startNode,endNode,userTaskNode,userTaskExamine,timingNode,lineNode,lineExpression,gatewayNode},
		props: {
			modeler: {
				type: Object,
				required: true
			}
		},
		computed: {
			userTask() {
				if(!this.element) {
					return;
				}
				return this.element.type === "bpmn:UserTask";
			},
			sequenceFlow() {
				if(!this.element) {
					return;
				}
				return this.element.type === "bpmn:SequenceFlow";
			},
			getNodeYype(){
				if(!this.element) {
					return;
				}
				const typeStr=this.element.type.replace('bpmn:','');
				this.formName=this.bpmnArrs[typeStr];
				this.judgeExpress();
//				console.log('----------ffffname----------',typeStr,this.formName)
				return typeStr;
			},
		},
		data() {
			return {
				tabsArr:[],
				tabsDefault:[
					{name:'基本设置',val:'1'},
					{name:'监听设置',val:'2',hide:false},
					{name:'审核人设置',val:'3',hide:true},
					{name:'表达式',val:'4',hide:true},
				],
				activeTabs:'1',
				formName:'flowWhole',
				nameMaxNum:15,
				bpmnArrs:{
					'ReceiveTask':'copyNode',//抄送节点
					'StartEvent':'startNode',//开始节点
					'EndEvent':'endNode',//结束节点
					'Task':'userTaskNode',//审核节点
					'UserTask':'userTaskNode',//审核节点
					'IntermediateCatchEvent':'timingNode',
					'IntermediateThrowEvent':'timingNode',
					'SequenceFlow':'lineNode',//线节点
					'ExclusiveGateway':'gatewayNode',//条件网关
					'ParallelGateway':'gatewayNode',//并行网关
				},
				isClickNode: false, //是否有选中节点
				flowCofigs :{
					basics:{},//基本设置
					listen:{},//监听设置
				},
				webForms: {
					id: "",
					name: "",
					color: null,
					key1: 'hellow',
				},
				element: {},
			};
		},
		mounted() {
			this.initTabsData();
			this.handleModeler();
			
			
//			let flowStr = localStorage.getItem('flowDatas');
//			if(flowStr){
//				const { flowCofigs} =JSON.parse(flowStr);
//				console.log('flowCofigs',flowCofigs)
//				this.flowCofigs=flowCofigs
//			}
//			this.initDataById();
			this.$nextTick(()=>{
				setTimeout(()=>{
					this.hideNodeLabels();
					this.listenNameChange();
				},300)
			})
		},
		watch:{
			formName: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.initTabsData();
				}
			},
			
		},
		methods: {
			listenNameChange(){
				const maxlen=this.nameMaxNum;
				console.log('执行了listenNameChange');
				$('.lfBox').delegate(".djs-direct-editing-content","input", function(){
		               	let txval=$(this).text()
					    if(txval.length>maxlen){
					    	$(this).text(txval.slice(0,maxlen))
					    }
		            });
				// 假设你想要监听一个输入框的值变化
//				$('.djs-direct-editing-content').on('input', function() {
//				    console.log('输入框的值变化了，当前值为：', $(this).text());
//				    let txlen=$(this).text().length;
//				    if(txlen>10){
//				    	$(this).text('hellow word')
//				    	
//				    }
//				});
//				
////				
//				$('.djs-direct-editing-content').on('DOMNodeInserted',function(){
//			        var txt = $(this).text();
//			        console.log('777777djs-direct-editing-content',txt);
//			    })
				
//				// 创建一个观察器实例并传入回调函数
//				var observer = new MutationObserver(function(mutations) {
//				    mutations.forEach(function(mutation) {
//				        console.log('内容变化了，新的内容为：', mutation.target.innerHTML);
//				    });
//				});
//				 
//				// 选择目标节点
//				var target = document.querySelector('.djs-direct-editing-content');
//				var target2 = document.querySelector('.lfBox');
//				console.log('target',target)
//				console.log('target2',target2)
//				 
//				// 配置观察选项:
//				var config = { attributes: true, childList: true, characterData: true, subtree: true };
//				 
//				// 传入目标节点和观察选项开始观察
//				observer.observe(target, config);
				 
				// 之后，你可以在需要的时候停止观察：
				// observer.disconnect();
				
				
				
			},
			
			initHtmlCompent(flowDatas){
				//初始化渲染  获取数据  加载渲染到页面及对应的组件上
				this.activeTabs='1';//节点发生变化  右侧视图切回基本设置
				this.webForms={};
				this.formName='flowWhole';
				this.isClickNode = false;
				if(flowDatas){
					const {flowCofigs,xmlDatas}=flowDatas;
//					console.log('+++++++++++++',flowDatas)
			      	this.flowCofigs=flowCofigs
				}else{
					this.flowCofigs={
						basics:{},//基本设置
						listen:{},//监听设置
					};
				}
				
			},
			setTabChange(it){
				this.activeTabs=it.val;
//				this.$emit('saveWebData')
			},
			async allowGetAllNodes(){
				//判断是否允许获取全部节点
				const {_elements} = this.modeler.get('elementRegistry');
				console.log('所有的节点ele',JSON.parse(JSON.stringify(_elements)))
				let nodes=[],lines=[];
				let startNums=0,endNums=0;
				for (let keys in _elements){
					const {element}=_elements[keys];//每一个节点
					const ndType=element.type;
					if(ndType!=='label'&&ndType!=='bpmn:Process'){
						let  nodeMes=element.businessObject;
						let itemNode={
							...nodeMes,
							...nodeMes.$attrs
						}
						if(ndType=='bpmn:SequenceFlow'){
							lines.push({
//								'-id':itemNode.id,
								'lineId':itemNode.id,
								...itemNode,
							})
						}else{
							if(ndType=='bpmn:StartEvent') startNums++;//获取开始的节点
							if(ndType=='bpmn:EndEvent') endNums++;//获取结束的节点
							nodes.push({
//								'-id':itemNode.id,
//								'-name':itemNode.name,
//								'lineId':itemNode.id,
//								'-webDatas':itemNode.webDatas,
								'nodeId':itemNode.nodeId,
								...itemNode,
							})
						}
					}
				}
				if(startNums>1){
					this.$message.warning('存在多个开始节点！');
					return false;
				}
				if(endNums>1){
					this.$message.warning('存在多个结束节点！');
					return false;
				}
				let resObj={nodes,lines};
//				console.log('所有的节点',JSON.parse(JSON.stringify(resObj)))
				
				let nodeArrs= await this.getNowNodes();
				console.log('所有的节点nodeArrs',JSON.parse(JSON.stringify(resObj)))
				return nodeArrs;
			},
			
			async getNowNodes(){
				let nodeArrs=await this.$parent.$parent.nowBpmnNodeToDatas();
//				console.log('xxxx---nodeArrs-----xxxxx',JSON.parse(JSON.stringify(nodeArrs)))
				return nodeArrs;
			},
			getDefaultFormDatas(){
				return this.$parent.$parent.dfFormConfig;
			},
			updateFormConfig(val){
				//更新全局的
				this.$parent.$parent.updateDfConfig(val); 
			},
			fromValsChange(val){
				console.log('--xxxxxxxxxxx--from-is-Changed',this.isClickNode,JSON.parse(JSON.stringify(val)))
				//监听对应的节点配置变更时候  更新flow节点信息。
				if(this.isClickNode){
					//如果有选中节点的话 代表节点监听设置  更新数据
					this.webForms={
						...this.webForms,
						...JSON.parse(JSON.stringify(val)),
					};
					const modeling = this.modeler.get("modeling");
					let valStr=JSON.stringify(this.webForms);
					modeling.updateProperties(this.element,{webDatas:valStr});
					if(val.nodeName){
						modeling.updateLabel(this.element, val.nodeName);
					}
					
					
//				const {type}=this.element;
//				const allowLabel=['bpmn:UserTask','bpmn:ReceiveTask','bpmn:SequenceFlow'];
//				const findIndex=allowLabel.indexOf(allowLabel);
//				if(findIndex>-1){}
					
				}else{
					//没有的话 代表的是流程监听设置
					this.flowCofigs.listen={
						...this.webForms,
						...JSON.parse(JSON.stringify(val)),
					};
					
//					console.log('----流程监听设置',JSON.parse(JSON.stringify(this.flowCofigs)))
				}
				
				
			},
			
			
			
			async hideNodeLabels(){
//				let t1=new Date().getTime();
				const {nodes,lines} =await this.getNowNodes();
//				const allNodesId=[];
//				nodes.map(it=>allNodesId.push(it.nodeId));
//				lines.map(it=>allNodesId.push(it.lineId));
				console.log('--+++++++++--allNodes',lines)
//				const allowType=['startNode','endNode','']
				$('.djs-shape').each(function() {
					const nowEle=$(this);
				    let elId = nowEle.attr('data-element-id'); // 获取当前元素的ID属性
//				    console.log('elId',elId)
				    if(elId.indexOf('_label')>-1){//判断找出如果是label的话 则处理
				    	let getId=elId.replace('_label');
				    	let toHide=true;
				    	if(lines){
				    		let isarr=Array.isArray(lines);
				    		if(isarr){
				    			const [isline]=lines.filter(it=>{//找出是不是线的label
						    		return elId.indexOf(it.lineId)>-1
						    	})
						    	if(isline){//如果是线的话  判断是否含有条件网关的出线  既带表达式的线  如果是的话 则不需要隐藏label
						    		if(isline['-webDatas']){
					      				let lineInfo=JSON.parse(isline['-webDatas']);
					      				if(lineInfo.isExpreline){
					      					toHide=false;//进行判断 如果是带表达式的线 则不添加隐藏的class  
					      					if(!nowEle.hasClass('notDrop')) nowEle.addClass('notDrop');//并且添加不允许拖拽的class
					      				}
		//			      				console.log('---------lineInfo --',lineInfo);
					      			}
						    	}
				    		}
				    	}
				    	
				    	
				    	if(toHide){
//				    		console.log('isline',isline)
				    	 	let isHide=nowEle.hasClass('noNodelabel');
				    	 	if(!isHide){
				    	 		nowEle.addClass('noNodelabel');
				    	 	}
				    	}
				    }
				});
//				let t2=new Date().getTime();
//				console.log('性能消耗',t2-t1)
			},
			
			
			
			
			
			async judgeExpress(){
				if(this.formName=='lineNode'){
					let isAllowExp=false;
					let res =await this.getNowNodes();
					if(res){
						const {lines,nodes}=res;
						nodes.map(it=>{
							if(it.nodeType=='exclusiveGateway'){//仅限条件网关， 并行网关赞不需要||it.nodeType=='parallelGateway'
								let isMore=Array.isArray(it.outgoing);//判断节点是否有多根线
	//							console.log(6666666666,typeof(it.outgoing),isMore)
								if(isMore){
									if(it.outgoing.indexOf(this.webForms.id)>-1){
										isAllowExp=true;
									}
								}else{
									if(this.webForms.id==it.outgoing){
										isAllowExp=true;
									}
								}
							}
						})
					}
					this.tabsArr.map(it=>{
						if(it.val=='4'){
							if(isAllowExp){
								it.hide=false
								this.fromValsChange({...this.webForms,'isExpreline':true});
							}else{
								it.hide=true
							}
						}
					})
				}
//				console.log(666666666666,isAllowExp)
			},
			async initTabsData(){
				let Arrs=JSON.parse(JSON.stringify(this.tabsDefault));
				if(this.isClickNode){
					if(this.formName=='lineNode'){
						Arrs=[
							{name:'基本设置',val:'1'},
							{name:'表达式',val:'4',hide:false},
						]
					}
					if(this.formName=='userTaskNode'){
						Arrs=[
							{name:'基本设置',val:'1'},
							{name:'审核人设置',val:'3',hide:false},
							{name:'监听设置',val:'2',hide:false},
						]
					}
					if(this.formName=='timingNode'){
						Arrs=[
							{name:'基本设置',val:'1'},
						]
					}
				}
				console.log('*************tabsArr',this.formName,JSON.parse(JSON.stringify(Arrs)))
				this.tabsArr=Arrs;
				
			},
			toNodeFromValidate(element){
				this.nodeClickEvent(element);
				this.$nextTick(()=>{
					setTimeout(()=>{
						this.$refs.classNodes.$refs.refFrom1.validate();
					},500)
				})
			},
			nodeClickEvent(el){
				//处理节点点击一下  渲染右侧配置表单内容事件
				const element = el;
				this.element = element;
				this.activeTabs='1';//节点发生变化  右侧视图切回基本设置
				this.webForms={};
				console.log('1111111节点选择变化',this.element);
				if(!element) {
					this.formName='flowWhole';
					this.isClickNode = false;
					return;
				} else {
					if(el.type=='label'){
						this.isClickNode=false;
						return false;
					}
					let  nodeMes=element.businessObject;
					if(el.type.indexOf('bpmn:')>-1){
						
						if(!nodeMes.name){
							const addNameArrs={
								'StartEvent':'开始节点',
								'EndEvent':'结束节点',
								'IntermediateCatchEvent':'定时节点',
								'IntermediateThrowEvent':'定时节点',
								'ExclusiveGateway':'条件网关',
								'ParallelGateway':'并行网关',
							}
							const typeStr=el.type.replace('bpmn:','');
							const createName=addNameArrs[typeStr];
//							const modeling = this.modeler.get("modeling");
//							modeling.updateLabel(this.element, createName);
							nodeMes.name=createName;
						}
					}
					
					
					this.webForms = {
						...nodeMes,
						...nodeMes.$attrs
					};
					if(!this.webForms.nodeCode){
						let tNum=new Date().getTime();
						let _key='webk'+tNum.toString();//类型+时间戳
//						let _key=element.type.replace('bpmn:','')+tNum.toString();//类型+时间戳
//						this.webForms={...this.webForms,...{webkey:_key,nodeCode:'hellow'}}
						this.webForms={...this.webForms,...{nodeCode:_key}}
					}
//					console.log('222222element.webForms',JSON.parse(JSON.stringify(this.webForms)))
					const webDatas=element.businessObject.$attrs.webDatas;
					if(webDatas){
						this.webForms={
							...this.webForms,
							...JSON.parse(webDatas),//取出保存扩展的数据[字符串]，转成对象解构赋值，用于回显对应的表单信息
						}
					}
					
					
					
					
					if(this.webForms.userType === "candidateUsers") {
						this.webForms["candidateUsers"] = this.webForms["candidateUsers"].split(",") || [];
					}
					this.isClickNode = true;
//					console.log('222222element.changed',JSON.parse(JSON.stringify(this.webForms)))
				}
			},
			handleModeler() {
				// 监听节点选择变化
				this.modeler.on("selection.changed", e => {
					const element = e.newSelection[0];
					this.nodeClickEvent(element);//执行节点点击事件，处理出局渲染右侧视图内容
				});
				
				this.modeler.on("commandStack.changed", e => {
					//发生任意可撤销/恢复操作时触发，可用来实时更新xml  用来触发隐藏指定的节点label
					setTimeout(()=>{
						this.hideNodeLabels();
					},30)
				});
				

//				//  监听节点属性变化
				this.modeler.on("element.changed", e => {
					const {
						element
					} = e;
					if(!element) return;
					//  新增节点需要更新回属性面板
					if(element.id === this.webForms.id) {
						
						this.webForms.name = element.businessObject.name;
						this.webForms = { ...this.webForms
						};
//						console.log('333333333element.changed',JSON.parse(JSON.stringify(this.webForms)))
					}
				});
				
				const selection = this.modeler.get('selection');
				if(selection && selection.get().length > 0) {
					// 有选中的节点
					console.log('当前有选中的节点');
				} else {
					// 没有选中的节点
					console.log('当前没有选中的节点');
				}
				if(this.webForms.name === '') {
					//              this.$message.warning('请选择流程节点！');
					return
				}
			},
			// 属性面板名称，更新回流程节点
			nameChange(name) {
				const modeling = this.modeler.get("modeling");
				modeling.updateLabel(this.element, name);
//				const {type}=this.element;
//				const allowLabel=['bpmn:UserTask','bpmn:ReceiveTask','bpmn:SequenceFlow'];
//				const findIndex=allowLabel.indexOf(allowLabel);
//				if(findIndex>-1){}
			},
			setNodeColorByStatus(nowel,status=0){
//				const colorArrs=['#FFFFFF','#CDE4FF','#D6D6D6'];
				const colorArrs={
					0:'#FFFFFF',//未进行
					1:'#eef6ff',//进行中
					2:'#ededed',//已完成
					'errTip':'red',//警示
					'default':'#fff',//默认颜色
				}
				const color=colorArrs[status]
//				console.log('====================',nowel,color)
				const modeling = this.modeler.get("modeling");
				if(status=='errTip'){
					modeling.setColor(nowel, {
						fill: null,
						stroke: color
					});
				}else{
					modeling.setColor(nowel, {
						fill: color,
						stroke: null
					});
					
				}
//				modeling.updateProperties(nowel, {
//					color: color
//				});
			},
			// 属性面板颜色，更新回流程节点
			colorChange(color) {
				const modeling = this.modeler.get("modeling");
				modeling.setColor(this.element, {
					fill: null,
					stroke: color
				});
				modeling.updateProperties(this.element, {
					color: color
				});
			},
			// 任务节点配置人员
			addUser(properties) {
				this.updateProperties(
					Object.assign(properties, {
						userType: Object.keys(properties)[0]
					})
				);
			},
			// 切换人员类型
			typeChange() {
				const types = ["assignee", "candidateUsers", "candidateGroups"];
				types.forEach(type => {
					delete this.element.businessObject.$attrs[type];
					delete this.webForms[type];
				});
			},
			setPropertiesKey(keys) {
				
				let info={};
				info[keys]=this.webForms[keys];
				const modeling = this.modeler.get("modeling");
				modeling.updateProperties(this.element,info);
			},

			// 在这里我们封装一个通用的更新节点属性的方法
			updateProperties(properties) {
				const modeling = this.modeler.get("modeling");
				modeling.updateProperties(this.element, properties);
			}
		}
	};
</script>

<style scoped>
	.rtConfig{
		height: 100%;
	}
	.formWarp {
		/*border-left: 1px solid #cccccc;*/
		padding:16px 18px;
		width: 100%;
		height: calc(100% - 50px);
		position: relative;
		top: 0;
		overflow-y: auto;
	}
	.itFormlabel{
		color: #252D3D;
		font-size: 14px;
		font-weight: bold;
		.diyXx{
			color: #F56C6C;
		}
	}
	.tabs_warp{
		line-height: 48px;
		display: flex;
		border-bottom: 1px solid #eee;
		.it_tap{
			padding:0px 10px;
			line-height: 48px;
			color:#5D687C;
			cursor: pointer;
			flex: 8;
			max-width: 100px;
			text-align: center;
	        .it_n{
	        	font-size: 14px;
	        	font-weight: bold;
	        	position: relative;
	        	.xbtm{
	        		height: 2px;
	        		width: 20px;
	        		position: absolute;
	        		bottom: -1px;
	        		left: 50%;
	        		margin-left: -10px;
	        		
	        	}
	        }
		}
		.it_tap:hover{
			color: #2a61ff !important;
		}
		.actived.it_tap{
			color: #252D3D;
			.xbtm{
				background: #1A4CEC;
			}
			
		}
	}
</style>
<style type="text/css">
	.rtConfig .formWarp{
		.el-textarea__inner,.el-input__count{
    	    background-color: #F7F8FA;
    	    border: none;
	    }
	}
	.notDrop.djs-element > .djs-hit-all{
		pointer-events: none !important;
	}
	.noNodelabel.djs-element.djs-shape{
		display: none !important;
	}
	/*.djs-direct-editing-parent{
		display: none !important;
	}*/
</style>