<template>
  <div class="take-back-person" v-loading="loading">
    <el-select v-model="value" multiple="" placeholder="请选择收回人" style="width: 100%" @change="updateHandler">
      <el-option
        v-for="item in userList"
        :value="item.userId"
        :label="item.userName"
        :key="item.userId"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { getWithdrawUserId } from "@/api/ruge/workflow/detail";

export default {
  name: "take-back-person",
  props: {
    flowDetails: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      loading: false,
      value: null,
      userList: [],
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    initDatas() {
      this.loading = true;
      const { taskId } = this.flowDetails;
      getWithdrawUserId({
        taskId
      }).then((res) => {
        this.userList = res
      }).finally(() => {
        this.loading = false;
      })
    },
    updateHandler() {
      this.$emit('update', this.value)
    },
  },
};
</script>

<style lang="less" scoped>
.take-back-person {
}
</style>