import request from "@/utils/request";
import { envInfo } from "@/constants/envInfo";

//获取流程分页列表
export function getProcDefList(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/procdef/page/list",
    method: "get",
    params,
  });
}

//挂起流程
export function suspendProcDef(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/procdef/suspend",
    method: "post",
    data: params,
  });
}

//激活流程
export function activeProdDef(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/procdef/active",
    method: "post",
    data: params,
  });
}

//激活流程
export function deleteProcDef(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/procdef/delete",
    method: "post",
    data: params,
  });
}

//待办
export function getTodoTaskList(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/todo/page/list",
    method: "get",
    params,
  });
}

//工作台 - 个人待办事项
export function getPersonalTodoList(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/examine/findExaminePage",
    method: "get",
    params,
  });
}

/**
 * 流程管理接口 - 获取流程分类树(预发布)
 * @returns {Promise<Array>} 返回节点数组
 * @returns {Array} array[].nodeType - 节点类型(mode:流程模型 category:文件夹(分类))
 * @returns {string} array[].nodeId - 节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
 * @returns {string} array[].parentNodeId - 父节点ID
 * @returns {string} array[].nodeName - 节点名称
 */
export function apiGetPreNodeTree() {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/preNodeTree",
    method: "get",
  });
}
export function apiGetNodeTree() {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/nodeTree",
    method: "get",
  });
}

/**
 * 流程管理接口 - 获取流程分类树(预发布)
 * @returns {Promise<Array>} 返回节点数组
 * @returns {Array} array[].nodeType - 节点类型(mode:流程模型 category:文件夹(分类))
 * @returns {string} array[].nodeId - 节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
 * @returns {string} array[].parentNodeId - 父节点ID
 * @returns {string} array[].nodeName - 节点名称
 */
export function getPublishedNodeTree() {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/nodeTree",
    method: "get",
  });
}

/**
 * 流程管理接口 - 保存文件夹节点
 */
function postCreateUpdateNode(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/saveNode",
    method: "post",
    data: params,
  });
}
/**
 * 流程管理接口 - 保存文件夹节点
 * @param {Object} params - 请求参数
 * @param {string} params.nodeType - 节点类型(category:文件夹(分类))
 * @param {Object} [params.categoryVO] - 文件夹分类对象(nodeType为category时使用)
 * @param {string} [params.categoryVO.categoryId] - 文件夹(分类)ID，新增时不传
 * @param {string} params.categoryVO.categoryName - 文件夹(分类)名称
 * @param {string} params.categoryVO.parentId - 父文件夹(分类)ID
 * @param {string} params.categoryVO.categoryCode - 文件夹code
 * @returns {Promise<void>} 无返回值
 */
export function apiPostSaveFolderNode(params) {
  return postCreateUpdateNode(params);
}
/**
 * 流程管理接口 - 保存分类节点
 * @param {Object} params - 请求参数
 * @param {string} params.nodeType - 节点类型(mode:流程模型)
 * @param {Object} [params.modeVO] - 流程模型对象(nodeType为mode时使用)
 * @param {string} [params.modeVO.modeId] - 流程模型ID，新增时不传
 * @param {string} params.modeVO.processName - 流程名称
 * @param {string} params.modeVO.processCode - 流程编码
 * @param {string} params.modeVO.categoryId - 文件夹(分类)ID
 * @param {('ENABLE'|'DISABLE')} params.modeVO.enableStatus - 启用状态
 * @param {('PUBLISH'|'DRAFT')} params.modeVO.modeStatus - 模型状态
 * @returns {Promise<void>} 无返回值
 */
export function apiPostSaveFileNode(params) {
  return postCreateUpdateNode(params);
}
/**
 * 流程管理接口 - 删除分类节点
 * @param {Object} params - 请求参数
 * @param {string} params.nodeId - 节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
 * @param {string} params.nodeType - 节点类型(mode:流程模型 category:文件夹(分类))
 * @returns {Promise<void>} 无返回值
 */
export function apiDeleteNode(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/deleteNode",
    method: "post",
    data: params,
  });
}
/**
 * 流程管理接口 - 分类节点重命名
 * @param {Object} params - 请求参数
 * @param {string} params.nodeId - 节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
 * @param {string} params.nodeType - 节点类型(mode:流程模型 category:文件夹(分类))
 * @param {string} params.nodeName - 节点名称
 * @returns {Promise<void>} 无返回值
 */
export function apiNodeRename(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/nodeRename",
    method: "post",
    data: params,
  });
}
/**
 * 流程管理接口 - 重命名保存并发布接口
 * @param {Object} params - 请求参数
 * @param {string} params.modeId - 节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
 * @param {string} params.modeStatus - 节点类型(mode:流程模型 category:文件夹(分类))
 * @param {string} params.processName - 节点名称
 * @returns {Promise<void>} 无返回值
 */
export function apiNodeSaveRename(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/processRename",
    method: "post",
    data: params,
  });
}
/**
 * 流程管理接口 - 移动分类节点
 * @param {Object} params - 请求参数
 * @param {string} params.nodeId - 节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
 * @param {string} params.parentNodeId - 父节点ID
 * @param {string} params.nodeType - 节点类型(mode:流程模型 category:文件夹(分类))
 * @returns {Promise<void>} 无返回值
 */
export function apiMoveNode(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/moveNode",
    method: "post",
    data: params,
  });
}

/**
 * 流程管理接口 - 复制流程模型
 * @param {Object} params - 请求参数
 * @param {string} params.modeId - 流程模型ID
 * @param {string} params.processCode - 流程编码
 * @param {string} params.processName - 流程名称
 * @returns {Promise<void>} 无返回值
 */
export function apiCopyMode(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/copyMode",
    method: "post",
    data: params,
  });
}
/**
 * 流程管理接口 - 查询发布记录
 * @param {Object} params - 请求参数
 * @param {string} params.modeId - 流程模型ID
 * @returns {Array} array[].versionId - 版本ID
 * @returns {string} array[].modeId - 流程模型ID
 * @returns {string} array[].processName - 流程名称
 * @returns {string} array[].processCode - 流程编码
 * @returns {('ENABLE'|'DISABLE')} array[].enableStatus - 启用状态
 * @returns {('PUBLISH'|'DRAFT')} array[].modeStatus - 模型状态
 * @returns {string} array[].publishDescription - 发布备注
 */
export function apiPublishRecord(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/publishRecord",
    method: "get",
    params: { modeId: params.modeId },
  });
}
/**
 * 流程管理接口 - 查询发布历史版本详情
 * @param {Object} params - 请求参数
 * @param {string} params.modeId - 流程模型ID
 * @param {string} params.versionId - 版本ID
 * @returns {Promise<Object>} 返回发布历史版本详情对象
 */
export function apiPublishRecordDetail(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/recordVersion",
    method: "get",
    params,
  });
}

/**
 * 流程管理接口 - 发布流程
 * @param {Object} params - 请求参数
 * @param {string} [params.modeId] - 流程模型ID，新增时不传
 * @param {('PUBLISH'|'DRAFT')} params.modeStatus - 模型状态
 * @param {string} params.processName - 流程名称
 * @param {string} params.publishDescription - 发布备注
 * @returns {Promise<void>} 无返回值
 */
export function apiPublish(params) {
  // https://sit.rlinkiot.com/saascloud/saas/workflow/mode/publishMode

      return  request({
        url: envInfo.bgApp.workflowPath + "/mode/publishMode",
        method: "post",
        data: params,
      });
}
/**
 * 流程管理接口 - 禁用/启用
 * @param {Object} params - 请求参数
 * @param {string} params.nodeType - 节点类型(mode:流程模型)
 * @param {Object} [params.modeVO] - 流程模型对象(nodeType为mode时使用)
 * @param {string} [params.modeVO.modeId] - 流程模型ID，新增时不传
 * @param {('ENABLE'|'DISABLE')} params.modeVO.enableStatus - 启用状态
 * @returns {Promise<void>} 无返回值
 */
export function apiEnableDisable(params) {
  return postCreateUpdateNode(params);
}
/**
 * 流程管理接口 - 查询发布记录列表
 * @param {Object} params - 请求参数
 * @param {string} params.modeId - 流程模型ID
 * @returns {Promise<Object>} 返回发布记录列表对象
 */
export function apiPublishRecordList(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/publishRecordList",
    method: "get",
    params,
  });
}

/**
 * 流程管理接口 - 查询流程分类下的节点列表(预发布)
 * @param {Object} params - 请求参数
 * @param {string} params.categoryId - 文件夹(分类)ID
 * @returns {Promise<Array>} 返回节点列表数组
 * @returns {string} array[].nodeId - 节点ID(mode类型为modeId，category类型为categoryId)
 * @returns {string} array[].parentNodeId - 父节点ID
 * @returns {string} array[].nodeName - 节点名称
 * @returns {('mode'|'category')} array[].nodeType - 节点类型(mode:流程模型 category:文件夹)
 * @returns {('ENABLE'|'DISABLE')} array[].enableStatus - 启用状态(仅mode类型)
 * @returns {('PUBLISH'|'DRAFT')} array[].modeStatus - 模型状态(仅mode类型)
 * @returns {('Y'|'N')} array[].isPublished - 是否已发布(仅mode类型)
 * @returns {string} array[].description - 节点描述
 * @returns {string} array[].updateUserName - 最后更新人
 * @returns {string} array[].lastUpdateDate - 最后更新时间
 * @returns {('1'|'0')} array[].isProcess - 是否可以配流程(1:是 0:否)
 */
export function apiPreCategoryNodeList(params, source) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/preCategoryNodeList",
    method: "get",
    params,
    cancelToken: source && source.token
  });
}
export function apiGetCategoryNodeList(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/categoryNodeList",
    method: "get",
    params,
  });
}

export function apiCategoryNodeList(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/categoryNodeList",
    method: "get",
    params,
  });
}
/**
 * 创建副本
 * params:modeId versionId processCode processName
 * @param {Object} params - 请求参数
 * @param {string} params.modeId - 流程模型ID
 * @param {string} params.versionId - 版本ID
 * @param {string} params.processCode - 流程编码
 * @param {string} params.processName - 流程名称
 * @returns {Promise<void>} 无返回值
 */
export function apiCreateCopy(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/createCopy",
    method: "post",
    data: params,
  });
}
/**
 * 还原流程
 * params:modeId versionId
 * @param {Object} params - 请求参数
 * @param {string} params.modeId - 流程模型ID
 * @param {string} params.versionId - 版本ID
 * @returns {Promise<void>} 无返回值
 */
export function apiRestore(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/restoreMode",
    method: "post",
    data: params,
  });
}
