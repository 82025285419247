var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "flowFormRef",
      staticClass: "flow-form",
      attrs: {
        "label-position": "top",
        "label-width": "80px",
        model: _vm.form,
        rules: _vm.rules,
      },
    },
    [
      _c(
        "el-form-item",
        {
          attrs: { prop: "code" },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("div", { staticClass: "flow-form-item" }, [
                    _c("span", { staticClass: "flow-form-item-label" }, [
                      _vm._v("编码"),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "code" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入编码", disabled: _vm.readonly },
                model: {
                  value: _vm.form.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "code", $$v)
                  },
                  expression: "form.code",
                },
              }),
              _c(
                "el-button",
                {
                  staticClass: "copy-btn",
                  attrs: { type: "text" },
                  on: { click: _vm.copy },
                },
                [_vm._v("复制")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }