<template>
  <div class="guide-form-comp">
    <el-form :model="ruleForm" :rules="rules" label-position="top" ref="ruleForm" label-width="100px"
      class="demo-ruleForm">
      <el-row>
        <el-col>
          <el-form-item label="编码名称" prop="codeName">
            <el-input maxlength="50" placeholder="请输入编码名称" v-model="ruleForm.codeName" :maxlength="50" show-word-limit></el-input></el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="编码" prop="code">
            <el-input maxlength="50" placeholder="请输入编码" v-model="ruleForm.code" :maxlength="50" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="自定义固定长度" prop="codeLenth">
            <el-input-number v-model="ruleForm.codeLenth" @change="handleChange" :min="1" :max="10"
              label="描述文字"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item align="right">
          <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick>确定</el-button>
          <el-button @click="resetForm">取消</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {
  addEncod, updateEncod
} from "@/api/ruge/ams/basicConfig/codeConfig";
export default {
  props: {
    codeData: {
      type: Object,
      default:()=>({})
    },
  },
  data() {
    return {
      editId: "",
      ruleForm: {
        codeName: null,
        code: null,
        codeLenth: 1,
      },
      rules: {
        codeName: [
          { required: true, message: "编码名称为必填", trigger: "blur" },
        ],
        code: [
          { required: true, message: "编码为必填", trigger: "blur" },
        ],
        codeLenth: [
          { required: true, message: "自定义固定长度必填", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    if (this.codeData) {
      this.editId = this.codeData.id
      this.ruleForm.codeName = this.codeData.codeName
      this.ruleForm.code = this.codeData.code
      this.ruleForm.codeLenth = this.codeData.codeLenth
    }
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitHandler();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitHandler() {
      if (this.editId) {
        updateEncod({
          id: this.editId,
          codeName: this.ruleForm.codeName,
          code: this.ruleForm.code,
          codeLenth: this.ruleForm.codeLenth,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("编辑成功！");
            this.$emit("close", true);
          } else {
            this.$message.warning("编辑失败！");
          }
        });
      } else {
        addEncod({
          codeName: this.ruleForm.codeName,
          code: this.ruleForm.code,
          codeLenth: this.ruleForm.codeLenth,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("新增成功！");
            this.$emit("close", true);
          } else {
            this.$message.warning("新增失败！");
          }
        });
      }

    },
    resetForm() {
      this.$emit("close", false);
    },

  },
};
</script>

<style scoped lang="less"></style>