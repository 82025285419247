var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "department-selection" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset.dialogVisible,
            "before-close": _vm.dialogCloseHandler,
            width: "500px",
            "destroy-on-close": true,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "dialogVisible", $event)
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.dataset.mainTitle))]),
            _c(
              "span",
              {
                staticClass: "second-title",
                staticStyle: { "font-size": "12px", color: "#666" },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.dataset.secondTitle) + "\n      "
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "department-choose-part" }, [
            _c("div", { staticClass: "operation-line" }, [
              _c("span", [
                _vm._v(" 已选：" + _vm._s(_vm.selectList.length) + "个 "),
              ]),
              _c(
                "span",
                { staticClass: "clear-span", on: { click: _vm.clearAll } },
                [_vm._v(" 全部清除 ")]
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.treeLoading,
                    expression: "treeLoading",
                  },
                ],
                staticClass: "detail-container",
              },
              [
                _c("el-tree", {
                  ref: "organizationTree",
                  attrs: {
                    data: _vm.organizationDatas,
                    "node-key": "id",
                    "auto-expand-parent": true,
                    "highlight-current": true,
                    "show-checkbox": "",
                    "check-strictly": !_vm.multiple,
                    "default-expand-all": "",
                  },
                  on: {
                    "check-change": _vm.handleCheckChange,
                    check: _vm.handlerNodeClick,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "department-footer-container" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close", false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitHandler },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }