var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.innerLoading || _vm.loading,
          expression: "innerLoading || loading",
        },
      ],
      ref: "flowFormRef",
      staticClass: "flow-form",
      attrs: {
        "label-position": "top",
        "label-width": "80px",
        model: _vm.form,
      },
    },
    [
      _vm.showTransferredField
        ? [
            _c(
              "el-form-item",
              {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "flow-form-item" }, [
                            _c(
                              "span",
                              { staticClass: "flow-form-item-label" },
                              [_vm._v("转办节点")]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2711568430
                ),
              },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "select",
                    attrs: { placeholder: "请选择节点" },
                    model: {
                      value: _vm.form.transferredNode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "transferredNode", $$v)
                      },
                      expression: "form.transferredNode",
                    },
                  },
                  _vm._l(_vm.transferredNodeList, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "flow-form-item" }, [
                            _c(
                              "span",
                              { staticClass: "flow-form-item-label" },
                              [_vm._v("转办人员")]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2072960983
                ),
              },
              [
                _c("el-cascader", {
                  staticClass: "select",
                  attrs: {
                    filterable: "",
                    placeholder: "请选择转办人员",
                    options: _vm.transferredPersonnelList,
                  },
                  model: {
                    value: _vm.form.transferredPersonnel,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "transferredPersonnel", $$v)
                    },
                    expression: "form.transferredPersonnel",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "el-form-item",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("div", { staticClass: "flow-form-item" }, [
                    _c("span", { staticClass: "flow-form-item-label" }, [
                      _vm._v("通知紧急程度"),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "radio-group-border",
              model: {
                value: _vm.form.status,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "status", $$v)
                },
                expression: "form.status",
              },
            },
            _vm._l(_vm.radioList, function (item) {
              return _c(
                "el-radio",
                {
                  key: item.key,
                  staticClass: "radio-border",
                  class: { "radio-checked": _vm.form.status === item.key },
                  attrs: { label: item.key, border: "" },
                },
                [
                  _c("div", { staticClass: "radio-item" }, [
                    _c("img", { attrs: { src: item.imgSrc, alt: item.label } }),
                    _c("span", [_vm._v(_vm._s(item.label))]),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm.showTransferredField
        ? _c(
            "el-form-item",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "flow-form-item" }, [
                          _c("span", { staticClass: "flow-form-item-label" }, [
                            _vm._v("通知选项"),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3766984712
              ),
            },
            [
              _c(
                "el-checkbox",
                {
                  staticClass: "checkbox",
                  model: {
                    value: _vm.form.isEndNotify,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isEndNotify", $$v)
                    },
                    expression: "form.isEndNotify",
                  },
                },
                [_vm._v("流程结束后通知我")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("div", { staticClass: "flow-form-item" }, [
                    _c("span", { staticClass: "flow-form-item-label" }, [
                      _vm._v("当前处理人"),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "text-user" }, [
            _vm._v(_vm._s(_vm.form.currentUser)),
          ]),
        ]
      ),
      _c(
        "el-form-item",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("div", { staticClass: "flow-form-item" }, [
                    _c("span", { staticClass: "flow-form-item-label" }, [
                      _vm._v("已经处理人"),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "text-user" }, [
            _vm._v(_vm._s(_vm.form.prevUsers)),
          ]),
        ]
      ),
      _c(
        "el-form-item",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("div", { staticClass: "flow-form-item" }, [
                    _c("span", { staticClass: "flow-form-item-label" }, [
                      _vm._v("处理意见"),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-input", {
            staticClass: "textarea",
            attrs: { type: "textarea", placeholder: "请输入内容" },
            model: {
              value: _vm.form.auditComment,
              callback: function ($$v) {
                _vm.$set(_vm.form, "auditComment", $$v)
              },
              expression: "form.auditComment",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }