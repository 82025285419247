var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "process-radio-component" }, [
    _c(
      "div",
      { staticClass: "radio-container" },
      _vm._l(_vm.dataset, function (item) {
        return _c(
          "div",
          {
            key: item.key,
            class: ["single-radio", item.active && "single-radio-active"],
            on: {
              click: function ($event) {
                return _vm.radioChange(item.key)
              },
            },
          },
          [
            _c("div", { staticClass: "left-part" }, [
              item.icon
                ? _c("img", {
                    staticClass: "item-icon",
                    attrs: { src: item.icon, alt: "" },
                  })
                : _vm._e(),
              _c("span", { staticClass: "label-span" }, [
                _vm._v("\n          " + _vm._s(item.label) + "\n        "),
              ]),
            ]),
            _c("div", { staticClass: "right-part" }, [
              !item.active
                ? _c("div", { staticClass: "no-check" })
                : _c("img", {
                    staticClass: "checked-icon",
                    attrs: {
                      src: require("@/assets/images/workflow/detail/checked-icon.png"),
                      alt: "",
                    },
                  }),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }