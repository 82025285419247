<template>
  <div class="dynamic-numberInput-component">
    <div class="input-container">
      <el-input-number
        :class="['number-input', unitString && `number-input-${unitType}`]"
        style="text-align: left"
        :disabled="disabled"
        :placeholder="placeHolder"
        v-model="value"
        :controls="false"
        :min="minValue"
        :max="maxValue"
        :precision="accuracy"
        @input="updateHandler"
      >
        <!-- <template v-if="unitString" :slot="unitType">{{ unitString }}</template> -->
      </el-input-number>
      <div v-if="unitString" :class="['unit-slot', `unit-slot-${unitType}`]">
        {{ unitString }}
      </div>
    </div>
  </div>
</template>

<script>
import { labelRender, updateSource } from "../../../detail/utils.js";

export default {
  name: "dynamic-numberInput-component",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        const currentValue = labelRender(datas, "default", null);
        this.value = currentValue === null ? undefined : currentValue;
        this.placeHolder = labelRender(datas, "placeHolder", "请输入");
        this.code = labelRender(datas, "code", null);
        this.accuracy = labelRender(datas, "accuracy", 0);
        if (
          this.accuracy !== null &&
          this.value !== undefined &&
          currentValue !== null
        ) {
          this.value = Number(this.value).toFixed(this.accuracy);
        }
        this.unitType = labelRender(datas, "unit", "prepend", "unitType");
        this.unitString = labelRender(datas, "unit", null, "unitString");
        this.numberInputHandler(datas);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      value: null,
      placeHolder: "请输入",
      code: null,
      accuracy: 0,
      unitType: null,
      unitString: null,
      minValue: Number.MIN_SAFE_INTEGER,
      maxValue: Number.MAX_SAFE_INTEGER,
    };
  },
  methods: {
    numberInputHandler(datas) {
      const minValueObj = labelRender(
        datas,
        "minValue",
        Number.MIN_SAFE_INTEGER
      );
      const { value, noLimit } = minValueObj;
      this.minValue = noLimit === false ? value : Number.MIN_SAFE_INTEGER;
      const maxValueObj = labelRender(
        datas,
        "maxValue",
        Number.MAX_SAFE_INTEGER
      );
      const { value: value2, noLimit: nolimit2 } = maxValueObj;
      this.maxValue = nolimit2 === false ? value2 : Number.MAX_SAFE_INTEGER;
      // 数字输入框的value如果为null时，显示的为0，需要处理成undefined
      const currentValue = labelRender(datas, "default", null);
      if (currentValue === null) {
        this.dataset.value = undefined;
      }
    },
    updateHandler() {
      updateSource(this.dataset, 'default', this.value)
      this.$emit("update", {
        key: this.code,
        value: this.value,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic-numberInput-component {
  .input-container {
    width: 100%;
    position: relative;
    .number-input {
      width: 100%;
      ::v-deep .el-input__inner {
        text-align: left;
      }
    }
    .number-input-prepend {
      ::v-deep .el-input__inner {
        padding-left: 30px;
      }
    }
    .number-input-append {
      ::v-deep .el-input__inner {
        padding-right: 30px;
      }
    }
    .unit-slot {
      position: absolute;
      top: 0;
    }
    .unit-slot-prepend {
      left: 10px;
    }
    .unit-slot-append {
      right: 10px;
    }
  }
}
</style>