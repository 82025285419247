var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "flowFormRef",
      staticClass: "flow-form",
      attrs: {
        "label-position": "top",
        "label-width": "80px",
        model: _vm.form,
        rules: _vm.rules,
      },
    },
    [
      _c(
        "el-form-item",
        {
          attrs: { prop: "name" },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("div", { staticClass: "flow-form-item" }, [
                    _c("span", { staticClass: "flow-form-item-label" }, [
                      _vm._v("审批流名称"),
                    ]),
                    _c(
                      "span",
                      { staticClass: "flow-form-item-path" },
                      [
                        _c("span", [_vm._v("位置：")]),
                        _c("TooltipText", {
                          attrs: { contentClass: "text", value: _vm.path },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-input", {
            model: {
              value: _vm.form.name,
              callback: function ($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: { prop: "remark" },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("div", { staticClass: "flow-form-item" }, [
                    _c("span", { staticClass: "flow-form-item-label" }, [
                      _vm._v("备注"),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-input", {
            attrs: { type: "textarea", rows: 2, placeholder: "请输入内容" },
            model: {
              value: _vm.form.remark,
              callback: function ($$v) {
                _vm.$set(_vm.form, "remark", $$v)
              },
              expression: "form.remark",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }