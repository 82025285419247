var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dynamic-textarea-component" },
    [
      _c("el-input", {
        attrs: {
          type: "textarea",
          disabled: _vm.disabled,
          rows: 3,
          placeholder: _vm.placeHolder,
          maxlength: _vm.maxlength,
          "show-word-limit": "",
        },
        on: { input: _vm.updateHandler },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }