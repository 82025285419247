<script>
import kbIcon1 from "@/assets/images/approval/kb1.svg";
import kbIcon2 from "@/assets/images/approval/kb2.svg";
import kbIcon3 from "@/assets/images/approval/kb3.svg";
import kbIcon4 from "@/assets/images/approval/kb4.svg";
import { statisticTabs } from "../constant";
import { apiAuditInspectorCount } from "@/api/ruge/workflow/approvalProcess";
export default {
  name: "Statistic",
  data() {
    return {

      iconMap: {
        kbIcon1: kbIcon1,
        kbIcon2: kbIcon2,
        kbIcon3: kbIcon3,
        kbIcon4: kbIcon4,
      },
    };
  },
  computed: {
    statisticCounts() {
      return this.$store.getters.statisticCounts;
    },
  },
  props: {
    tabList: {
      type: Array,
      default: () => statisticTabs,
    },
  },
  methods: {
    handleLink(link) {
      if (!link) return;
      this.$router.push({
        name: link,
      });
    },
  },
};
</script>

<template>
  <div class="statistic">
    <div
      class="box"
      v-for="item of tabList"
      :key="item.key"
      @click="() => handleLink(item.routerName)"
    >
      <img :src="iconMap[item.icon]" class="icon" :alt="item.icon" />
      <div>
        <div class="name">{{ item.label }}</div>
        <div class="count">{{ statisticCounts[item.key] }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.statistic {
  display: flex;
  column-gap: 13px;
  .box {
    display: flex;
    padding: 22px 18px;
    background: #ffffff;
    border-radius: 20px;
    min-width: 268px;
    flex-basis: 25%;
    cursor: pointer;
    transform: translateX(0); /* 默认状态下，元素位置 */
    transition: transform 0.3s ease; /* 平移动画 */
    &:hover {
      transform: translateY(-10px);
    }
    .icon {
      width: 56px;
      height: 56px;
      margin-right: 18px;
    }
    .name {
      font-weight: 400;
      font-size: 14px;
      color: #747e9c;
      line-height: 14px;
      margin-bottom: 6px;
    }
    .count {
      font-family: Helvetica, Helvetica, serif;
      font-weight: bold;
      font-size: 24px;
      color: #252d3d;
      line-height: 28px;
    }
  }
}
</style>
