var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "statistic" },
    _vm._l(_vm.tabList, function (item) {
      return _c(
        "div",
        {
          key: item.key,
          staticClass: "box",
          on: { click: () => _vm.handleLink(item.routerName) },
        },
        [
          _c("img", {
            staticClass: "icon",
            attrs: { src: _vm.iconMap[item.icon], alt: item.icon },
          }),
          _c("div", [
            _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.label))]),
            _c("div", { staticClass: "count" }, [
              _vm._v(_vm._s(_vm.statisticCounts[item.key])),
            ]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }