<script>
import { mapGetters } from "vuex";

import ProcessTable from "../components/process-table.vue";
import Statistic from "../components/statistic.vue";
import {statisticTabs, showSearchField, processTableTabs} from "../constant";
const showSearchFieldList = showSearchField.filter((item) =>
  ["modeName", "applicant", "createDate"].includes(item.key)
);
const tabs = statisticTabs.map((item) => {
  if (item.key === "allCount") {
    return {
      ...item,
      routerName: "",
    };
  }
  return { ...item };
});
export default {
  components: {
    Statistic,
    ProcessTable,
  },
  data() {
    return {
      tabs,
      tableTabList:processTableTabs ,
      showSearchFieldList,
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
};
</script>

<template>
  <div
    class="page"
    :style="{
      width: sidebar.opened ? 'calc(100vw - 330px)' : 'calc(100vw - 140px)',
    }"
  >
    <statistic :tabList="tabs" />
    <ProcessTable query-type="all" class="table-area" :showSearchField="showSearchFieldList" :tab-list="tableTabList" :show-tabs="true" />
  </div>
</template>

<style scoped lang="less">
.page {
  display: flex;
  width: 100%;
  height: calc(100vh - 90px);
  box-sizing: border-box;
  //padding: 20px;
  border-radius: 10px;
  margin: 0 40px 20px;
  padding-top: 10px;
  font-size: 14px;
  font-family: "PingFang SC", "Microsoft YaHei", sans-serif;
  overflow: auto;
  flex-direction: column;
.table-area{
  margin-top: 20px;
}
  div {
    box-sizing: border-box;
  }
}
</style>
