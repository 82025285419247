<template>
  <div class="process-detail-title">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'process-detail-title',
  props: {
    title: {
      type: String,
      default() {
        return ''
      },
    }
  },
}
</script>

<style lang="less" scoped>
.process-detail-title {
  font-weight: 600;
  font-size: 14px;
  color: #252D3D;
  position: relative;
  padding-left: 7px;
  padding-bottom: 20px;
  &::before {
    content: '';
    display: inline-block;
    width: 3px;
    height: 12px;
    background: #2A61FF;
    border-radius: 7px 7px 7px 7px;
    position: absolute;
    top: 1px;
    left: 0;
  }
}
</style>