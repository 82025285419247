<template>
  <div class="graph-component-imgUploader single-graph-component">
    <div :class="['item-label', datas.require && 'item-label-required']">
      {{ datas.label }}
    </div>
    <div class="uploader-example">
      <i class="el-icon-plus"></i>
    </div>
  </div>
</template>
<script>
import { labelRender } from "../utils.js";
export default {
  name: "graph-component-imgUploader",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(item) {
        this.datas.label = labelRender(item, "label", "未命名");
        this.datas.require = labelRender(item, "require", false);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      labelRender,
      datas: {
        label: null,
        require: false,
      },
    };
  },
};
</script>
<style lang="less" scoped>
.graph-component-imgUploader {
  .uploader-example {
    width: 120px;
    height: 120px;
    background: #fcfcfc;
    border-radius: 4px;
    border: 1px solid #d1d5dd;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    .el-icon-plus {
      font-size: 20px;
      color: #545f73;
    }
  }
}
</style>