<template>
  <div class="workflow-detail-page">
    <div class="header-line">
      <div class="header-left">
        <div class="back-div" @click="routeBack">
          <img src="@/assets/images/go_back_icon.png" alt="" />
          <span>返回</span>
        </div>
        <div class="border-line"></div>
        <div class="name-div">
          {{ workFlowTitle }}
        </div>
      </div>
      <div class="header-right">
        <r-button @click="testHandler"> 表单测试 </r-button>
        <r-button @click="testHandler2"> 表单测试2 </r-button>
        <div class="look-json" @click="jsonViewHander">
          <img src="@/assets/images/dynamicForm/look_json.png" alt="" />
          <div class="label-line">查看json代码</div>
        </div>
        <r-button @click="goFlowConfig"> 配置流程 </r-button>
        <r-button
          @click="publishHandler"
          :disabled="enableStatus === 'DISABLE'"
        >
          发布
        </r-button>
        <r-button plain @click="saveHandler" :loading="submitloading"
          >保存</r-button
        >
      </div>
    </div>
    <div class="main-container">
      <!-- 左侧组件列表 -->
      <div class="component-part">
        <div
          class="single-catelog"
          v-for="item in componentsList"
          :key="item.key"
        >
          <div class="catelog-title" @click="item.active = !item.active">
            <div class="label-part">
              <img :src="item.icon" alt="" />
              <span class="label-span">
                {{ item.label }}
              </span>
            </div>
            <div class="icon-part">
              <i class="el-icon-caret-right" v-if="!item.active"></i>
              <i class="el-icon-caret-bottom" v-else></i>
            </div>
          </div>
          <div
            :style="{
              height: item.active ? 'auto' : '0',
              overflow: 'hidden',
            }"
          >
            <VueDraggable
              v-model="item.list"
              animation="150"
              ghostClass="left-ghost"
              class="container component-list"
              :sort="false"
              :group="{ name: 'components', pull: 'clone', put: false }"
              filter=".ignore"
              :clone="cloneHandler"
            >
              <div
                class="single-component"
                :data-type="single.key"
                v-for="single in item.list"
                :key="single.key"
              >
                <img class="component-image" :src="single.icon" alt="" />
                <div class="component-label">
                  {{ single.label }}
                </div>
              </div>
            </VueDraggable>
          </div>
        </div>
      </div>
      <!-- 中间画布 -->
      <div class="graph-part" @click.stop="setItemActive" v-loading="loading">
        <div class="no-card" v-if="formItemList.length === 0">
          <img src="@/assets/images/customer_add_icon.png" alt="" />
          选择组件长按鼠标左键拖动到此区域
        </div>
        <VueDraggable
          v-model="formItemList"
          animation="150"
          :ghostClass="dynamicGhost"
          class="container"
          group="components"
          filter=".ignore"
          @add="addHandler"
        >
          <div
            :class="[
              `single-item-${item.key}`,
              `single-item`,
              item.active && 'single-item-active',
            ]"
            :id="item.id"
            v-for="item in formItemList"
            :key="item.id"
            :style="{
              width: cardWidthReander(item),
            }"
            @click.stop="setItemActive(item)"
          >
            <DynamicGraphItem :dataset="item" :readOnly="true" />
            <div
              class="delete-container"
              v-if="item.active"
              @click="deleteItem(item)"
            >
              <img src="@/assets/images/dynamicForm/delete_icon.png" alt="" />
            </div>
          </div>
        </VueDraggable>
      </div>
      <!-- 右侧属性列表 -->
      <div class="props-part">
        <DynamicProps
          ref="propsRef"
          :dataset="currentSelect"
          @update="updateHandler"
        />
      </div>
    </div>
    <!-- 查看json代码 -->
    <JSONViewDialog :dataset="jsonView" />
    <!-- 表单测试 -->
    <el-dialog
      :visible.sync="test.show"
      :before-close="
        () => {
          test.show = false;
        }
      "
      width="800px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <DynamicForm
        v-if="test.show"
        ref="dynamicFormIns"
        :dataset="test.dataset"
        :editState="test.editState"
        :flowNodeId="test.flowNodeId"
      />
      <r-button @click="getTestDatas">更新数据</r-button>
    </el-dialog>

    <flow-modal
      width="600px"
      title="发布"
      :visible="publishModalConfig.visible"
      @onOk="onOkPublishModal"
      @onCancel="onCancelPublishModal"
    >
      <publish-modal
        v-if="publishModalConfig.visible"
        ref="publishModalRef"
        :path="publisNodeNamePaths"
        :default-form="publishModalConfig.data.form"
      />
    </flow-modal>
  </div>
</template>

<script>
import JSONViewDialog from "../../vlink/dynamicForm/components/JSONViewDialog.vue";
import { componentsList, getPropsByType } from "./componentsConfig.js";
import DynamicProps from "./components/dynamicProps.vue";
import DynamicGraphItem from "./components/dynamicGraphItem.vue";
import { GenNonDuplicateID } from "@/utils/utils.js";
import { cloneDeep } from "lodash";
import { VueDraggable } from "vue-draggable-plus";
import {
  getWorkFlowDetails,
  processSaveForm,
  publishMode,
} from "../../../../api/ruge/workflow/detail";
import DynamicForm from "../components/DynamicForm";
import FlowModal from "@/views/ruge/workflow/list/components/flow-modal.vue"; //用于发布按钮 弹窗-罗鑫写的
import PublishModal from "@/views/ruge/workflow/list/components/publish-modal.vue"; //用于发布按钮 弹窗-罗鑫写的
export default {
  name: "workflow-detail-page",
  components: {
    DynamicProps,
    VueDraggable,
    DynamicGraphItem,
    JSONViewDialog,
    DynamicForm,
    PublishModal,
    FlowModal,
  },
  data() {
    return {
      componentsList,
      workFlowTitle: "审批流管理",
      loading: false,
      submitloading: false,
      currentSelect: {
        propsList: [],
        productInfo: this.getProductInfo(),
        id: null,
        type: null,
      },
      formItemList: [],
      dynamicGhost: "ghost-half",
      resource: {}, //备份原始数据
      jsonView: {
        show: false,
        json: null,
      },
      test: {
        show: false,
        flowNodeId: "xxxx1",
        dataset: null,
        editState: "edit",
      },
      processName: null,
      publisNodeNamePaths: "", //发布弹窗右侧的位置 string
      publishModalConfig: {
        visible: false,
        /**
         * Modal data containing:
         * @property {string} path - Current path in workflow tree
         * @property {object} form - object.name
         * @property {string} nodeId - ID of node being published
         * @property {string} nodeType - Type of node being published
         */
        data: {},
      },
      enableStatus: "ENABLE",
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    async getTestDatas() {
      // const formConfig = this.$refs.dynamicFormIns.getFormConfiguredDatas();
      // processSaveForm({
      //   nodeType: "mode",
      //   modeVO: {
      //     modeId: this.$route.query.nodeId, // 模型ID
      //     formConfig, // 表单配置
      //   },
      // }).then((res) => {
      //   this.$message.success("更新成功");
      //   this.initDatas();
      // });
      // console.log("xxx1", datas);
      const formDatas = await this.$refs.dynamicFormIns.checkFormValidate();
      console.log("xxx1", formDatas);
    },
    testHandler() {
      this.test.dataset = JSON.stringify(this.formItemList);
      this.test.flowNodeId = "xxx1";
      this.test.show = true;
    },
    testHandler2() {
      this.test.dataset = JSON.stringify(this.formItemList);
      this.test.flowNodeId = "xxx2";
      this.test.show = true;
    },
    async onOkPublishModal() {
      //发布弹窗确认按钮事件
      const data = await this.$refs.publishModalRef.onOk();
      if (!data) return;
      const { name, remark } = data;
      const { modeVO } = this.buildSaveParams("PUBLISH", remark);
      this.submitloading = true;
      publishMode({ ...modeVO, ...{ processName: name } })
        .then((res) => {
          const { processVerify } = res;
          if (processVerify && processVerify.length) {
            this.$message.warning("流程配置有误，请先修改流程配置后再发布");
            return;
          }
          this.$message.success("保存成功");
          this.onCancelPublishModal();
        })
        .finally(() => {
          this.submitloading = false;
        });
    },
    saveFormAsync(noMessage, state, remark) {
      this.submitloading = true;
      return new Promise((resolve) => {
        processSaveForm(this.buildSaveParams(state, remark)).then((res) => {
          resolve(res);
          this.submitloading = false;
          !noMessage && this.$message.success("保存成功");
        });
      });
    },
    onCancelPublishModal() {
      //发布弹窗关闭取消
      this.publishModalConfig = {
        visible: false,
        data: {},
      };
    },
    async goFlowConfig() {
      await this.saveFormAsync(true);
      this.$router.push({
        path: "/workflow/flowBpnm",
        query: this.$route.query,
      });
    },
    // 拖拽添加，激活item
    addHandler(card) {
      this.setItemActive(card.clonedData);
    },
    jsonViewHander() {
      this.jsonView.show = true;
      this.jsonView.json = JSON.stringify(this.formItemList, null, "\t");
    },
    // 构建simple，在form表单显示的时候用
    simpleBuild(list) {
      return list.map((item) => {
        return {
          label: item.propsList.filter((single) => single.key === "label")[0]
            .value,
          value: item.propsList.filter((single) => single.key === "default")[0]
            .value,
          code: item.propsList.filter((single) => single.key === "code")[0]
            .value,
          require: item.propsList.filter(
            (single) => single.key === "require"
          )[0].value,
        };
      });
    },
    publishHandler() {
      if (!this.checkFormValid()) return;
      const { nodeId } = this.$route.query;
      this.publishModalConfig = {
        visible: true,
        data: {
          form: {
            name: this.processName,
          },
          path: null,
          nodeId,
          nodeType: "mode",
        },
      };
    },
    saveHandler() {
      // if (!this.checkFormValid()) return;
      this.saveFormAsync();
    },
    buildSaveParams(state, remark) {
      const { nodeId } = this.$route.query;
      return {
        nodeType: "mode",
        modeVO: {
          modeStatus: state || "DRAFT",
          modeId: nodeId, // 模型ID
          publishDescription: remark || null,
          formConfig: JSON.stringify(this.formItemList), // 表单配置
        },
      };
    },
    initDatas() {
      const { nodeId } = this.$route.query;
      if (!nodeId) return;
      this.loading = true;
      getWorkFlowDetails({ modeId: nodeId })
        .then((res) => {
          this.enableStatus = res.enableStatus;
          this.currentSelect.productInfo = this.getProductInfo(res);
          this.workFlowTitle += ` - ${res.processName}`;
          this.processName = res.processName;
          this.publisNodeNamePaths = res.nodeNamePaths
            ? res.nodeNamePaths.join(">")
            : "";
          try {
            this.formItemList = JSON.parse(res.formConfig).map((item) => {
              item.active = false;
              return item;
            });
          } catch (error) {
            console.log("解析form表单失败：", error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * form表单校验
     *  1. 检查必填项code
     *  2. 检查default的长度是否超过maxLength
     *  3. 检查重复code
     * @returns {Boolean}
     *  true - 校验通过
     *  false - 校验不通过
     */
    checkFormValid() {
      const repeatCodeMap = {};
      for (let item of this.formItemList) {
        for (let single of item.propsList) {
          const { require, value, key } = single;
          // 检查必填项code
          if (require && !value) {
            this.currentSelect.propsList = item.propsList;
            this.setItemActive(item);
            this.$nextTick(() => {
              this.setRepeatNodeError([item.id]);
              this.$refs.propsRef.checkForm();
            });
            return false;
          }
          // 检查default的长度是否超过maxLength
          if (key === "default") {
            const defaultLength = value ? value.length : 0;
            const maxLengthItem = item.propsList.filter(
              (item) => item.key === "maxLength"
            )[0];
            if (maxLengthItem) {
              const maxLength = maxLengthItem.value;
              if (defaultLength > maxLength) {
                this.setItemActive(item);
                this.$nextTick(() => {
                  this.setRepeatNodeError([item.id]);
                  this.$refs.propsRef.checkForm();
                });
                return false;
              }
            }
          }
          // 检查重复code
          if (key === "code") {
            if (!repeatCodeMap[value]) {
              repeatCodeMap[value] = item.id;
            } else {
              this.setRepeatNodeError([repeatCodeMap[value], item.id]);
              this.$message.warning("编码重复，请修改后再保存！");
              return false;
            }
          }
        }
      }
      return true;
    },
    // 重复code的item，根据id把dom节点标红
    setRepeatNodeError(list) {
      try {
        list.forEach((id) => {
          $(`#${id}`).addClass("error-node");
        });
        setTimeout(() => {
          list.forEach((id) => {
            $(`#${id}`).removeClass("error-node");
          });
        }, 3000);
      } catch (error) {}
    },
    cardWidthReander(item) {
      let defaultValue = "half";
      const { propsList } = item || {};
      if (propsList && propsList.length) {
        propsList.forEach((item) => {
          if (item.key === "layout") {
            defaultValue = item.value;
          }
        });
      }
      return defaultValue === "all" ? "100%" : `calc(50% - 15px)`;
    },
    updateHandler({ value, key }) {
      this.currentSelect.propsList.forEach((item) => {
        if (item.key === key) {
          item.value = value;
        }
      });
    },
    async deleteItem({ id }) {
      await this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      );
      for (let index = 0; index < this.formItemList.length; index++) {
        if (this.formItemList[index].id === id) {
          this.formItemList.splice(index, 1);
          this.currentSelect.propsList = [];
          break;
        }
      }
    },
    // 激活item
    setItemActive({ id, key }) {
      this.currentSelect.propsList = [];
      this.currentSelect.id = null;
      this.currentSelect.type = key;
      this.formItemList.forEach((item) => {
        if (item.id === id) {
          item.active = true;
          this.currentSelect.propsList = item.propsList;
          this.currentSelect.id = id;
        } else {
          item.active = false;
        }
      });
      this.$forceUpdate();
    },
    cloneHandler(element) {
      const cloneObj = cloneDeep(element);
      delete cloneObj.icon;
      cloneObj.id = GenNonDuplicateID();
      cloneObj.active = false;
      cloneObj.propsList = getPropsByType(element.key);
      cloneObj.propsList = this.setItemDefaultName(
        cloneObj.propsList,
        cloneObj.label,
        element.key
      );
      console.log("cloneObj", cloneObj);
      return cloneObj;
    },
    /**
     * 1. 每个组件添加默认标题
     * 2. 【多行输入】【编辑器】【图片上传】【文件上传】这四个只能整行输入
     */
    setItemDefaultName(propsList, label, elementKey) {
      return propsList.map((item) => {
        if (item.key === "label") {
          item.value = label;
        }
        if (
          ["textarea", "editor", "imgUploader", "fileUploader"].includes(
            elementKey
          ) &&
          item.key === "layout"
        ) {
          item.value = "all";
        }
        return item;
      });
    },
    routeBack() {
      const query = this.$route.query;
      this.$router.push({
        path: "/workflow/list",
        query: { folderNodeId: query.folderNodeId },
      });
    },
    // 构建产品详情数据
    getProductInfo(productInfo) {
      const { processName } = productInfo || {};
      return [
        {
          label: "审批流名称",
          value: processName || "-",
          infoKey: "processName",
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.workflow-detail-page {
  background: #f7f8fa;
  .header-line {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e3e8ee;
    background: #ffffff;
    padding: 0 40px;
    .header-left {
      display: flex;
      align-items: cneter;
      .back-div {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        color: #2a61ff;
        img {
          margin-right: 4px;
          position: relative;
          top: -1px;
        }
        span {
          font-weight: 600;
        }
      }
      .border-line {
        width: 1px;
        height: 17px;
        background: #cbdbe9;
        margin: 0 10px;
      }
      .name-div {
        font-weight: 600;
        font-size: 18px;
        color: #252d3d;
      }
    }
    .header-right {
      display: flex;
      align-items: center;
      .look-json {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin-right: 32px;
        .label-line {
          font-weight: 500;
          font-size: 12px;
          color: #252d3d;
          margin-top: 6px;
        }
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .main-container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 70px);
    background: #ffffff;
    .component-part {
      width: 280px;
      height: 100%;
      border-right: 1px solid #e3e8ee;
      background: #ffffff;
      padding: 0 20px 20px;
      overflow: auto;
      .single-catelog {
        margin-top: 20px;
        .catelog-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #e3e8ee;
          padding-bottom: 12px;
          cursor: pointer;
          .label-part {
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
              position: relative;
              top: -1px;
            }
            .label-span {
              font-weight: 600;
              font-size: 16px;
              color: #252d3d;
            }
          }
          .icon-part {
            color: #a0aac0;
          }
        }
        .component-list {
          overflow: hidden;
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 12px;
          column-gap: 20px;
          margin-top: 12px;
          .single-component {
            width: 110px;
            height: 100px;
            background: #f7f8fa;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: default;
            &:hover {
              background: #f2f3f5;
            }
            &:active {
              background: #eeeff0;
            }
            .component-label {
              font-weight: 400;
              font-size: 12px;
              color: #252d3d;
              margin-top: 5px;
            }
          }
        }
      }
    }
    .graph-part {
      position: relative;
      margin: 20px 0;
      width: calc(100vw - 560px);
      overflow-y: auto;
      padding: 0px 40px;
      background: #fff;
      .no-card {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 14px;
        color: #b5bece;
        img {
          margin-bottom: 20px;
        }
      }
      .container {
        height: 100%;
        width: 100%;
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        align-content: flex-start;
        .single-item {
          border: 2px solid #00000000;
          position: relative;
          min-height: 68px;
          .delete-container {
            width: 24px;
            height: 20px;
            position: absolute;
            top: 0;
            right: 0;
            background: #2a61ff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          &:hover {
            border: 2px solid #2a61ff50;
          }
        }
        .error-node {
          border: 2px solid #ff0000 !important;
        }
        .single-item-active {
          border: 2px solid #2a61ff;
          &:hover {
            border: 2px solid #2a61ff;
          }
        }
        .single-item-input {
          height: 68px;
        }
        .single-item-selector {
          height: 68px;
        }
        .single-item-companySelector {
          height: 68px;
        }
        .single-item-textarea {
          height: 107px;
        }
        .ghost-half {
          width: calc(50% - 15px);
          background: rgba(42, 97, 255, 0.1);
          border: 1px dotted #2a61ff;
          .component-image {
            opacity: 0;
          }
          .component-label {
            opacity: 0;
          }
        }
      }
    }
    .props-part {
      width: 280px;
      height: 100%;
      border-left: 1px solid #e3e8ee;
      background: #ffffff;
    }
  }
  .rlink-button-component + .rlink-button-component {
    margin-left: 16px;
  }
}
</style>