var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dynamic-fileUploader-component" }, [
    _c(
      "div",
      { staticClass: "upload-card card-container" },
      [
        _c(
          "el-upload",
          {
            ref: "upload",
            attrs: {
              accept: ".xls, .xlsx, .pdf, .doc, .docx",
              limit: _vm.limit,
              multiple: _vm.multiple,
              disabled: _vm.disabled,
              "file-list": _vm.fileList,
              "auto-upload": true,
              "show-file-list": false,
              "on-change": _vm.handleChange,
              "on-success": _vm.successHandler,
              "on-error": _vm.errorHandler,
              "on-exceed": _vm.exceedHandler,
              "before-upload": _vm.beforeUpload,
              drag: "",
              action: _vm.uploadPathBuild(),
            },
          },
          [
            _c("img", {
              staticStyle: { "margin-bottom": "8px" },
              attrs: {
                src: require("@/assets/images/import_folder_icon.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "el-upload__text" }, [
              _vm._v("\n        将文件拖到此处，或"),
              _c("em", { staticStyle: { color: "#2a61ff" } }, [
                _vm._v("点击选择文件"),
              ]),
            ]),
          ]
        ),
        _vm._l(_vm.fileList, function (item, index) {
          return _c(
            "div",
            { key: item.uid, staticClass: "file-list card-container" },
            [
              _c("div", { staticClass: "top-line" }, [
                _c("span", { staticClass: "name-span" }, [
                  _c("img", {
                    attrs: {
                      src: require(`@/assets/images/workflow/fileTypeIcon/${_vm.iconRender(
                        item
                      )}.png`),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "size-part" }, [
                    _c("div", { staticClass: "name-line" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.name) +
                          "\n            "
                      ),
                    ]),
                    _c("div", { staticClass: "size-line" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.sizeRender(item.size)) +
                          "\n            "
                      ),
                    ]),
                  ]),
                ]),
                !_vm.disabled
                  ? _c("i", {
                      staticClass: "el-icon-close",
                      on: {
                        click: function ($event) {
                          return _vm.deleteHandler(item)
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "progress-line" }, [
                _c("div", { staticClass: "outer" }, [
                  _c("div", {
                    staticClass: "inner",
                    style: {
                      width:
                        index === _vm.fileList.length - 1
                          ? `${_vm.progressRate}%`
                          : "100%",
                    },
                  }),
                ]),
                _c("div", { staticClass: "rate-part" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        index === _vm.fileList.length - 1
                          ? _vm.progressRate
                          : "100"
                      ) +
                      "%\n        "
                  ),
                ]),
              ]),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }