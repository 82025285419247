// 选项默认值
export const ItemRenderDefault = 1;

export const FormDisplayOption = {
  1: '可见不可编辑',
  2: '可编辑',
  3: '不可见',
};

export const getFormDisplayOptions = () => {
  let list = [];
  for (let key in FormDisplayOption) {
    list.push({
      value: key,
      label: FormDisplayOption[key],
    });
  }
  return list;
};
