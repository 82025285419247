var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "workflow-list-page",
      style: {
        width: _vm.sidebar.opened
          ? "calc(100vw - 330px)"
          : "calc(100vw - 140px)",
      },
    },
    [
      _c(
        "div",
        { staticClass: "tree-box" },
        [
          _c(
            "InputPanel",
            {
              staticClass: "tree-box-search",
              attrs: {
                "popup-class": "workflow-popup",
                fetchSuggestions: _vm.querySearch,
              },
              on: { change: _vm.handleInputPanelChange },
              scopedSlots: _vm._u([
                {
                  key: "panel-list",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "input-panel-wrapper" },
                        _vm._l(scope.data, function (groupItem) {
                          return _c(
                            "ul",
                            { key: groupItem.value, staticClass: "panel-list" },
                            [
                              groupItem.children.length
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "panel-list" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "group-item-title" },
                                          [
                                            _vm._v(
                                              _vm._s(groupItem.label) +
                                                "（" +
                                                _vm._s(
                                                  groupItem.children.length
                                                ) +
                                                "）"
                                            ),
                                          ]
                                        ),
                                        _vm._l(
                                          groupItem.children,
                                          function (item) {
                                            return _c(
                                              "ul",
                                              {
                                                key: item.value,
                                                staticClass: "panel-list",
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass: "option",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleSelect(
                                                          item.data
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src:
                                                          item.data.nodeType ===
                                                          "category"
                                                            ? _vm.folderUrl
                                                            : _vm.fileUrl,
                                                      },
                                                    }),
                                                    _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "option-title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.label)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "option-path",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.path)
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.searchValue,
                callback: function ($$v) {
                  _vm.searchValue = $$v
                },
                expression: "searchValue",
              },
            },
            [
              _c("template", { slot: "suffix" }, [
                _c("img", {
                  attrs: { src: _vm.inputSearchUrl, alt: "inputSearchUrl" },
                }),
              ]),
            ],
            2
          ),
          _c("flow-tree", {
            staticClass: "tree",
            attrs: {
              "selected-node": _vm.selectedNode,
              data: _vm.treeData,
              "default-expanded-keys": _vm.expandedKeys,
            },
            on: { "node-click": _vm.nodeClick },
            scopedSlots: _vm._u([
              {
                key: "suffix",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "more",
                        class: { show: _vm.treeShowDropdown === data.data.id },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return (() => {}).apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "more-content" }, [
                          _c(
                            "div",
                            { staticClass: "dropdown-part" },
                            [
                              _c(
                                "el-dropdown",
                                {
                                  on: {
                                    "visible-change": (v) =>
                                      _vm.treeDropdownVisibleChange(
                                        v,
                                        data.data
                                      ),
                                    command: (key) =>
                                      _vm.handleCommandTree(key, data.data),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "ellipsis-btn-wrap" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "ellipsis-btn" },
                                        [
                                          _c("div", { staticClass: "dot" }),
                                          _c("div", { staticClass: "dot" }),
                                          _c("div", { staticClass: "dot" }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      staticClass: "dropdown-menu shadow",
                                      attrs: {
                                        slot: "dropdown",
                                        trigger: "click",
                                        "hide-on-click": false,
                                      },
                                      slot: "dropdown",
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "createFolder" } },
                                        [_vm._v("新建文件夹")]
                                      ),
                                      data.data.type !== "ROOT"
                                        ? [
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  command: "createFile",
                                                },
                                              },
                                              [_vm._v("新建审批流")]
                                            ),
                                            _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "rename" } },
                                              [_vm._v("重命名")]
                                            ),
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: { command: "showCode" },
                                              },
                                              [_vm._v("查看编码")]
                                            ),
                                            _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "move" } },
                                              [
                                                _vm._v(
                                                  "\n                      移动至文件夹\n                    "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "delete" } },
                                              [_vm._v(" 删除 ")]
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { ref: "cardWrapperRef", staticClass: "right" },
        [
          _c(
            "div",
            { staticClass: "right-top" },
            [
              _c(
                "create-button",
                {
                  staticClass: "list-create-btn",
                  attrs: { "icon-url": _vm.folderUrl },
                  on: { click: _vm.createFolderClick },
                },
                [_c("div", [_vm._v("新建文件夹")])]
              ),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    "popper-class": "workflow-tips",
                    placement: "top",
                    content: "根节点下不能新建审批流",
                    effect: "light",
                    disabled:
                      _vm.selectedNode && _vm.selectedNode.id !== _vm.RootType,
                  },
                },
                [
                  _c(
                    "create-button",
                    {
                      staticClass: "list-create-btn",
                      attrs: {
                        disabled:
                          _vm.selectedNode &&
                          _vm.selectedNode.id === _vm.RootType,
                        "icon-url": _vm.fileUrl,
                      },
                      on: { click: _vm.createFileClick },
                    },
                    [_c("div", [_vm._v("新建审批流")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-box" },
            [
              _c(
                "FilterSelect",
                {
                  staticClass: "list-filter-select",
                  attrs: {
                    options: _vm.filterFileTypeList,
                    placeholder: "请选择",
                  },
                  model: {
                    value: _vm.filterFileTypeValue,
                    callback: function ($$v) {
                      _vm.filterFileTypeValue = $$v
                    },
                    expression: "filterFileTypeValue",
                  },
                },
                _vm._l(_vm.filterFileTypeList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _c(
                "FilterSelect",
                {
                  staticClass: "list-filter-select-sort",
                  attrs: {
                    placeholder: "请选择",
                    options: _vm.sortOptionsList,
                  },
                  on: { change: _vm.handleSortChange },
                  model: {
                    value: _vm.filterSortValue,
                    callback: function ($$v) {
                      _vm.filterSortValue = $$v
                    },
                    expression: "filterSortValue",
                  },
                },
                _vm._l(_vm.sortOptionsList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          !_vm.workflowListFilter.length
            ? _c("div", { staticClass: "empty-area" }, [
                _c("div", { staticClass: "empty-box" }, [
                  _c("img", { attrs: { src: _vm.emptyUrl, alt: "empty" } }),
                  _c("div", { staticClass: "empty-text" }, [
                    _vm._v("暂无内容"),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "workflow-list" },
            _vm._l(_vm.workflowListFilter, function (item) {
              return _c(
                "div",
                { key: item.nodeId, staticClass: "workflow-card" },
                [
                  _c("div", { staticClass: "workflow-card-content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "dropdown-part",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return (() => {}).apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "el-dropdown",
                          {
                            on: {
                              command: (key) => _vm.handleCommand(key, item),
                            },
                          },
                          [
                            _c("div", { staticClass: "ellipsis-btn-wrap" }, [
                              _c("div", { staticClass: "ellipsis-btn" }, [
                                _c("div", { staticClass: "dot" }),
                                _c("div", { staticClass: "dot" }),
                                _c("div", { staticClass: "dot" }),
                              ]),
                            ]),
                            _c(
                              "el-dropdown-menu",
                              {
                                staticClass: "dropdown-menu shadow",
                                attrs: {
                                  slot: "dropdown",
                                  trigger: "click",
                                  "hide-on-click": false,
                                },
                                slot: "dropdown",
                              },
                              [
                                item.type === _vm.FileType
                                  ? [
                                      item.enableStatus === "ENABLE"
                                        ? _c(
                                            "el-dropdown-item",
                                            { attrs: { command: "publish" } },
                                            [_vm._v("发布")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "formConfig" } },
                                        [_vm._v("表单配置")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            disabled: item.isProcess !== "1",
                                            command: "processConfiguration",
                                          },
                                        },
                                        [_vm._v("流程配置")]
                                      ),
                                      item.enableStatus === "ENABLE"
                                        ? _c(
                                            "el-dropdown-item",
                                            { attrs: { command: "disable" } },
                                            [_vm._v("禁用")]
                                          )
                                        : _vm._e(),
                                      item.enableStatus === "DISABLE"
                                        ? _c(
                                            "el-dropdown-item",
                                            { attrs: { command: "enable" } },
                                            [_vm._v("取消禁用")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "copy" } },
                                        [_vm._v("复制审批流")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "publishRecord" } },
                                        [_vm._v("发布记录")]
                                      ),
                                      item.type === _vm.FolderType
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: {
                                                command: "createFolder",
                                              },
                                            },
                                            [_vm._v("新建文件夹")]
                                          )
                                        : _vm._e(),
                                      item.type === _vm.FolderType
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              attrs: { command: "createFile" },
                                            },
                                            [_vm._v("新建审批流")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "rename" } },
                                        [_vm._v("重命名")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "showCode" } },
                                        [_vm._v("查看编码")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "move" } },
                                        [
                                          _vm._v(
                                            "\n                    移动至文件夹\n                  "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "delete" } },
                                        [
                                          _vm._v(
                                            "\n                    删除\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                item.type === _vm.FolderType
                                  ? [
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "createFolder" } },
                                        [_vm._v("新建文件夹")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "createFile" } },
                                        [_vm._v("新建审批流")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "rename" } },
                                        [_vm._v("重命名")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "showCode" } },
                                        [_vm._v("查看编码")]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "move" } },
                                        [
                                          _vm._v(
                                            "\n                    移动至文件夹\n                  "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-item",
                                        { attrs: { command: "delete" } },
                                        [_vm._v(" 删除 ")]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "workflow-icon-box",
                        on: {
                          click: function ($event) {
                            return _vm.handleSelectWorkflow(item)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "workflow-icon",
                          attrs: {
                            src:
                              item.type === _vm.FileType
                                ? _vm.fileUrl
                                : _vm.folderUrl,
                            alt: "folder",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "workflow-card-footer" }, [
                    _c(
                      "div",
                      { staticClass: "workflow-card-name" },
                      [
                        _c("img", {
                          staticClass: "name-icon",
                          attrs: {
                            src:
                              item.type === _vm.FileType
                                ? _vm.fileUrl
                                : _vm.folderUrl,
                            alt: "folder",
                          },
                        }),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "tooltip-auto-show",
                                rawName: "v-tooltip-auto-show",
                              },
                            ],
                            staticClass: "text-overflow tooltip",
                            attrs: {
                              "open-delay": 500,
                              effect: "dark",
                              content: item.nodeName,
                              placement: "top-start",
                            },
                          },
                          [
                            _c("div", { staticClass: "text-overflow h-20" }, [
                              _vm._v(_vm._s(item.nodeName)),
                            ]),
                          ]
                        ),
                        item.type === _vm.FileType
                          ? _c("div", { staticClass: "workflow-tag-box" }, [
                              item.modeStatus === "PUBLISH"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "workflow-tag workflow-tag-publish",
                                    },
                                    [_vm._v("已发布")]
                                  )
                                : _vm._e(),
                              item.modeStatus === "DRAFT"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "workflow-tag workflow-tag-draft",
                                    },
                                    [_vm._v("草稿")]
                                  )
                                : _vm._e(),
                              item.enableStatus === "DISABLE"
                                ? _c(
                                    "span",
                                    { staticClass: "workflow-tag-disabled" },
                                    [_vm._v("禁用")]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "workflow-name-date" }, [
                      _c("span", [_vm._v(_vm._s(item.updateUserName))]),
                      _c("span", [
                        _vm._v("修改于：" + _vm._s(item.lastUpdateDate)),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
          _c(
            "flow-modal",
            {
              attrs: {
                width: "600px",
                title: "新建文件夹",
                visible: _vm.createFolderModalConfig.visible,
                loading: _vm.btnLoading,
              },
              on: {
                onOk: _vm.onOkCreateFolder,
                onCancel: _vm.handleCancelCreateFolder,
              },
            },
            [
              _vm.createFolderModalConfig.visible
                ? _c("create-folder-modal", {
                    ref: "createFolderModalRef",
                    attrs: { path: _vm.createFolderModalConfig.data.path },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "flow-modal",
            {
              attrs: {
                width: "600px",
                title: "新建审批流",
                visible: _vm.createFileModalConfig.visible,
                loading: _vm.btnLoading,
              },
              on: {
                onOk: _vm.onOkCreateFile,
                onCancel: _vm.handleCancelCreateFile,
              },
            },
            [
              _vm.createFileModalConfig.visible
                ? _c("create-file-modal", {
                    ref: "createFileModalRef",
                    attrs: { path: _vm.createFileModalConfig.data.path },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "flow-modal",
            {
              attrs: {
                width: "400px",
                title: "重命名",
                visible: _vm.renameModalConfig.visible,
                data: _vm.renameModalConfig.data,
                loading: _vm.btnLoading,
              },
              on: { onOk: _vm.onOkRename, onCancel: _vm.handleCancelRename },
              scopedSlots: _vm._u(
                [
                  _vm.renameModalConfig.data.nodeType === "mode" &&
                  _vm.renameModalConfig.data.enableStatus === "ENABLE"
                    ? {
                        key: "footer-middle",
                        fn: function () {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  "border-radius": "10px",
                                  padding: "9px 25px",
                                },
                                attrs: { type: "primary", plain: "" },
                                on: {
                                  click: () => _vm.onOkSaveRename("PUBLISH"),
                                },
                              },
                              [_vm._v("保存并发布")]
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            [
              _vm.renameModalConfig.visible
                ? _c("rename-modal", {
                    ref: "renameModalRef",
                    attrs: { "old-name": _vm.renameModalConfig.data.oldName },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "flow-modal",
            {
              attrs: {
                width: "600px",
                title: "复制审批流",
                visible: _vm.copyModalConfig.visible,
                loading: _vm.btnLoading,
              },
              on: { onOk: _vm.onOkCopyModal, onCancel: _vm.onCancelCopyModal },
            },
            [
              _vm.copyModalConfig.visible
                ? _c("create-file-modal", {
                    ref: "copyModalRef",
                    attrs: {
                      "auto-code": true,
                      path: _vm.copyModalConfig.data.path,
                      defaultForm: _vm.copyModalConfig.data.form,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "flow-modal",
            {
              attrs: {
                width: "720px",
                title: "移动至文件夹",
                visible: _vm.moveFileModalConfig.visible,
                loading: _vm.btnLoading,
              },
              on: { onOk: _vm.onOkMoveFile, onCancel: _vm.onCancelMoveFile },
            },
            [
              _vm.moveFileModalConfig.visible
                ? _c("move-file-modal", {
                    ref: "moveFileModalRef",
                    attrs: { "node-id": _vm.moveFileModalConfig.data.nodeId },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "flow-modal",
            {
              attrs: {
                width: "600px",
                title: "发布",
                visible: _vm.publishModalConfig.visible,
                loading: _vm.btnLoading,
              },
              on: {
                onOk: _vm.onOkPublishModal,
                onCancel: _vm.onCancelPublishModal,
              },
            },
            [
              _vm.publishModalConfig.visible
                ? _c("publish-modal", {
                    ref: "publishModalRef",
                    attrs: {
                      path: _vm.publishModalConfig.data.path,
                      "default-form": _vm.publishModalConfig.data.form,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "flow-modal",
            {
              attrs: {
                width: "600px",
                title: "查看编码",
                visible: _vm.showCodeModalConfig.visible,
              },
              on: { onCancel: _vm.onCancelShowCodeModal },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "flow-button flow-button-primary",
                          attrs: { type: "primary" },
                          on: { click: _vm.onCancelShowCodeModal },
                        },
                        [_vm._v("关闭")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm.showCodeModalConfig.visible
                ? _c("ShowCodeModal", {
                    attrs: {
                      "default-form": _vm.showCodeModalConfig.data,
                      readonly: true,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }