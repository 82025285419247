import { render, staticRenderFns } from "./fromWordsSet.vue?vue&type=template&id=34b6c06e&scoped=true"
import script from "./fromWordsSet.vue?vue&type=script&lang=js"
export * from "./fromWordsSet.vue?vue&type=script&lang=js"
import style0 from "./fromWordsSet.vue?vue&type=style&index=0&id=34b6c06e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34b6c06e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\ruge-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('34b6c06e')) {
      api.createRecord('34b6c06e', component.options)
    } else {
      api.reload('34b6c06e', component.options)
    }
    module.hot.accept("./fromWordsSet.vue?vue&type=template&id=34b6c06e&scoped=true", function () {
      api.rerender('34b6c06e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ruge/workflow/FlowBpmn/fromTypes/fromWordsSet.vue"
export default component.exports