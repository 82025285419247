var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "submit-role-selector" },
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: { placeholder: "" },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        _vm._l(_vm.roleList, function (item, index) {
          return _c("el-option", {
            key: index,
            attrs: { value: item.value, label: item.label },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }