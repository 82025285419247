import request from "@/utils/request";
import { envInfo } from "@/constants/envInfo";

/**
 *  分页查询
 * @param params
 */
export function getDepreciation(params) {
  return request({
    url: envInfo.bgApp.amsPath + "/depreciation/Page",
    method: "get",
    params,
  });
}

/**
 *  新增/编辑
 * @param params
 */
export function addDepreciation(params) {
  return request({
    url: envInfo.bgApp.amsPath + "/depreciation/add",
    method: "post",
    data: params,
  });
}

/**
 *  固定资产品类列表
 * @param params
 */
export function getPropertyList(params) {
  return request({
    url: envInfo.bgApp.amsPath + "/category/asset/list",
    method: "get",
    params,
  });
}
