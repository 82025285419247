var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-basic-info" },
    [
      _c(
        "el-row",
        _vm._l(_vm.colList, function (item) {
          return _c("el-col", { key: item.value, attrs: { span: 12 } }, [
            _c("div", { staticClass: "single-item" }, [
              _c("span", { staticClass: "label-span" }, [
                _vm._v("\n          " + _vm._s(item.label) + "\n        "),
              ]),
              _vm._v("\n        :\n        "),
              _c("span", { staticClass: "value-span" }, [
                _vm._v("\n          " + _vm._s(item.value) + "\n        "),
              ]),
            ]),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }