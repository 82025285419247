var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dynamic-numberInput-component" }, [
    _c(
      "div",
      { staticClass: "input-container" },
      [
        _c("el-input-number", {
          class: [
            "number-input",
            _vm.unitString && `number-input-${_vm.unitType}`,
          ],
          staticStyle: { "text-align": "left" },
          attrs: {
            disabled: _vm.disabled,
            placeholder: _vm.placeHolder,
            controls: false,
            min: _vm.minValue,
            max: _vm.maxValue,
            precision: _vm.accuracy,
          },
          on: { input: _vm.updateHandler },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        }),
        _vm.unitString
          ? _c("div", { class: ["unit-slot", `unit-slot-${_vm.unitType}`] }, [
              _vm._v("\n      " + _vm._s(_vm.unitString) + "\n    "),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }