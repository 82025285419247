import request from "@/utils/request";
import { envInfo } from "@/constants/envInfo";
import source from "echarts/src/data/Source";
/**
 * 流程管理接口 - 获取流程实例列表
 * @returns {Promise<Object>} 接口响应
 * @returns {number} response.total - 数据的总条数
 * @returns {number} response.totalPages - 总页数
 * @returns {Array<Object>} response.rows - 流程实例列表
 * @returns {string} response.rows.definitionId - 流程实例ID
 * @returns {number} response.rows.tenantId - 租户ID
 * @returns {number} response.rows.projectId - 项目ID
 * @returns {string} response.rows.processCode - 申请编号
 * @returns {number} response.rows.processDeptId - 申请部门ID
 * @returns {string} response.rows.processDeptName - 申请部门名称（多层用“/”分割）
 * @returns {string} response.rows.processName - 流程名称
 * @returns {string} response.rows.modeName - 模板名称
 * @returns {number} response.rows.processInitiator - 流程发起人ID
 * @returns {string} response.rows.processInitiatorName - 流程发起人名称
 * @returns {Date} response.rows.startTime - 流程开始时间
 * @returns {Date} response.rows.endTime - 流程结束时间
 * @returns {number} response.rows.elapsedTime - 流程耗时（毫秒）
 * @returns {Date} response.rows.creationDate - 创建时间
 * @returns {string} response.rows.processDescription - 流程描述
 * @returns {number} response.rows.processStatus - 流程状态
 * @returns {string} response.rows.processStatusStr - 流程状态描述
 * @returns {string} response.rows.currentNode - 当前节点名称
 * @returns {string} response.rows.currentReviewer - 当前审核人
 */

export function apiGetWorkflowList(params, source) {
  const cancelToken = source && source.cancelToken && source.cancelToken.token
  return request({
    url: envInfo.bgApp.workflowPath + "/auditInspector/queryWorkflowList",
    method: "get",
    params,
    cancelToken
  });
}
export function apiGetQueryAdminWorkflowList(params, source) {
  return request({
    url: envInfo.bgApp.workflowPath + "/auditInspector/queryAdminWorkflowList",
    method: "get",
    params,
    cancelToken:  source.cancelToken
        .token
  });
}
export function apiAuditInspectorCount(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/auditInspector/count",
    method: "get",
    params,
  });
}

/**
 * 关单
 * @param {Object} params
 * @param {String} params.definitionId - 流程实例ID
 * @param {String} params.urgencyLevel - 通知参数 紧急程度，0：一般，1：急，2：紧急
 * @param {String} params.auditComment - 审核意见
 * @returns {Promise<Object>} 接口响应
 */
export function apiPostCloseOrder(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/definition/closeOrder",
    method: "post",
    data: params,
  });
}

/**
 * 转派
 * @param {Object} params
 * @param {String} params.definitionId - 流程实例ID
 * @param {String} params.taskId - 转派节点任务ID
 * @param {String} params.recipient - 转派接收人
 * @param {String} params.urgencyLevel - 通知参数 紧急程度，0：一般，1：急，2：紧急
 * @param {String} params.isEndNotify - 流程结束后是否通知我（N：否，Y：是）
 * @param {String} params.auditComment - 审核意见
 * @returns {Promise<Object>} 接口响应
 */
export function apiPostTransferOrder(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/definition/transferOrder",
    method: "post",
    data: params,
  });
}

/**
 * 获取转派节点选项
 * @param {Object} params
 * @param {String} params.definitionId - 流程实例ID
 * @returns {Promise<Object>} 接口响应
 */
export function apiGetTransferNodeList(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/definition/transferNodeList",
    method: "get",
    params,
  });
}

/**
 * 获取关单、转派详情
 * @param {Object} params
 * @param {String} params.definitionId - 流程实例ID
 * @returns {Promise<Object>} 接口响应
 */
export function apiGetDefinitionBackData(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/definition/getDefinitionBackData",
    method: "get",
    params,
  });
}

/**
 * 加载人员
 * @param params
 */
export function apiGetEmployeeTreeAll(params) {
  return request({
    url: envInfo.bgSuffixPath.iorganization + "/tenant/employee/getEmployeeTreeAll",
    method: 'get',
    params,
  });
}
