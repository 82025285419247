var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "graph-component-input single-graph-component" },
    [
      _c(
        "div",
        { class: ["item-label", _vm.datas.require && "item-label-required"] },
        [_vm._v("\n    " + _vm._s(_vm.datas.label) + "\n  ")]
      ),
      _c("el-input", {
        attrs: {
          "suffix-icon": _vm.datas.clearable
            ? "el-icon-circle-close"
            : "el-icon-arrow-down",
          readonly: _vm.readOnly,
          placeholder: _vm.datas.placeHolder,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }