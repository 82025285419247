var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "graph-component-user single-graph-component" },
    [
      _c(
        "div",
        { class: ["item-label", _vm.datas.require && "item-label-required"] },
        [_vm._v("\n    " + _vm._s(_vm.datas.label) + "\n  ")]
      ),
      _c(
        "div",
        { staticClass: "tags-container" },
        [
          _vm._l(_vm.datas.value, function (item) {
            return _c("span", { key: item.value, staticClass: "single-tag" }, [
              _vm._v("\n      " + _vm._s(item.userName) + "\n      "),
              _c("i", { staticClass: "el-icon-error" }),
            ])
          }),
          _vm._m(0),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "add-tag single-tag" }, [
      _c("i", { staticClass: "el-icon-plus" }),
      _vm._v("\n      添加\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }