var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "process-table-component" }, [
    _c(
      "div",
      { staticClass: "table-header" },
      _vm._l(_vm.tableHeader, function (item) {
        return _c(
          "span",
          {
            key: item.key,
            class: ["single-header", `table-flex-${item.flex}`],
          },
          [_vm._v("\n      " + _vm._s(item.label) + "\n    ")]
        )
      }),
      0
    ),
    _c("div", { staticClass: "table-container" }, [
      !_vm.tableData.length
        ? _c("div", { staticClass: "empty-container" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/workflow/empty.svg"),
                alt: "empty",
              },
            }),
          ])
        : _c(
            "div",
            _vm._l(_vm.tableData, function (item, index) {
              return _c(
                "div",
                {
                  key: item.key,
                  staticClass: "single-line",
                  style: {
                    background: index % 2 == 1 ? "#FAFBFE" : "#ffffff",
                  },
                },
                _vm._l(item, function (single) {
                  return _c(
                    "span",
                    {
                      key: single.key,
                      class: [
                        "single-value",
                        `table-flex-${single.flex}`,
                        single.key === "attachmentVOList" && "file-container",
                      ],
                    },
                    [
                      single.key === "attachmentVOList"
                        ? [
                            single.value && single.value.length
                              ? _vm._l(single.value, function (files) {
                                  return _c(
                                    "div",
                                    {
                                      key: files.fileId,
                                      staticClass: "single-file",
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFile(files)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(files.attachmentName || "-") +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                })
                              : [_vm._v(" 无 ")],
                          ]
                        : [
                            _vm._v(
                              "\n            " +
                                _vm._s(single.value) +
                                "\n          "
                            ),
                          ],
                    ],
                    2
                  )
                }),
                0
              )
            }),
            0
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }