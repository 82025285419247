var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("page", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "tree-box" },
      [
        _c("flow-tree", {
          attrs: {
            "selected-node": _vm.selectedNode,
            data: _vm.treeData,
            "default-expanded-keys": _vm.expandedKeys,
          },
          on: { "node-click": _vm.nodeClick },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "right" }, [
      _c(
        "div",
        { staticClass: "right-header" },
        [_c("PathTooltip", { attrs: { value: _vm.path } })],
        1
      ),
      !_vm.workflowList.length
        ? _c("div", { staticClass: "empty-box" }, [
            _c("img", { attrs: { src: _vm.emptyUrl, alt: "empty" } }),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "cards" },
        _vm._l(_vm.workflowList, function (item) {
          return _c("card-file", {
            key: item.id,
            staticClass: "card-item",
            attrs: { data: item },
            on: {
              click: function ($event) {
                return _vm.handleSelectWorkflow(item)
              },
            },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }