<template>
  <div class="props-component-userDefault">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <el-select
        v-model="displayValue"
        multiple
        filterable
        remote
        reserve-keyword
        @remove-tag="staffRemove"
        placeholder="请选择"
        @focus="attendanceStaffFocus"
        style="width: 100%; cursor: pointer"
        class="attendanceStaff-selector"
      >
      </el-select>
    </el-form-item>
    <EmployeeSelection
      v-if="employeeDialog.dialogVisible"
      :dataset="employeeDialog"
      :noCheck="true"
      :multiple="multiple"
      @close="employeeClose"
      @submit="employeeSubmit"
    />
  </div>
</template>

<script>
import EmployeeSelection from "@/components/EmployeeSelection";
import { labelRender } from "../utils.js";

export default {
  name: "props-component-userDefault",
  components: {
    EmployeeSelection,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    propsList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    propsList: {
      handler(item) {
        const curMultiple = labelRender({ propsList: item }, "multiple", false);
        this.multipleChange(curMultiple);
      },
      deep: true,
    },
  },
  data() {
    return {
      multiple: undefined,
      employeeDialog: {
        dialogVisible: false,
        mainTitle: "选择用户",
        secondTitle: "",
        selectList: [],
      },
    };
  },
  computed: {
    displayValue() {
      return this.dataset.value ? this.dataset.value.map((item) => item.userName) : [];
    },
  },
  methods: {
    multipleChange(curMultiple) {
      if (this.multiple === curMultiple) return;
      this.multiple = curMultiple;
      this.dataset.value = [];
    },
    staffRemove(value) {
      this.dataset.value = this.dataset.value.filter(
        (item) => item.userName !== value
      );
    },
    attendanceStaffFocus() {
      this.employeeDialog.selectList = this.dataset.value;
      this.employeeDialog.dialogVisible = true;
    },
    employeeClose() {
      this.employeeDialog.dialogVisible = false;
    },
    employeeSubmit(userList) {
      this.dataset.value = userList.map((item) => {
        const { userName, userId, deptId, deptName, companyName } = item;
        return {
          userName,
          userId,
          deptId,
          deptName,
          companyName,
        };
      });
      this.employeeDialog.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang="less">
.props-component-userDefault {
  .unit-line {
    display: flex;
    gap: 4px;
    .unit-selector {
      min-width: 88px;
    }
  }
}
</style>