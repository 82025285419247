<template>
  <div class="process-table-component">
    <div class="table-header">
      <span
        :class="['single-header', `table-flex-${item.flex}`]"
        v-for="item in tableHeader"
        :key="item.key"
      >
        {{ item.label }}
      </span>
    </div>
    <div class="table-container">
      <div class="empty-container" v-if="!tableData.length">
        <img src="@/assets/images/workflow/empty.svg" alt="empty" />
      </div>
      <div v-else>
        <div
          class="single-line"
          :style="{
            background: index % 2 == 1 ? '#FAFBFE' : '#ffffff',
          }"
          v-for="(item, index) in tableData"
          :key="item.key"
        >
          <span
            v-for="single in item"
            :key="single.key"
            :class="[
              'single-value',
              `table-flex-${single.flex}`,
              single.key === 'attachmentVOList' && 'file-container',
            ]"
          >
            <!-- 附件 single.value = attachmentVOList-->
            <template v-if="single.key === 'attachmentVOList'">
              <template v-if="single.value && single.value.length">
                <div
                  class="single-file"
                  v-for="files in single.value"
                  :key="files.fileId"
                  @click="downloadFile(files)"
                >
                  {{ files.attachmentName || "-" }}
                </div>
              </template>
              <template v-else> 无 </template>
            </template>
            <!-- 其他 -->
            <template v-else>
              {{ single.value }}
            </template>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";

export default {
  name: "process-table-component",
  props: {
    tableHeader: {
      type: Array,
      default() {
        return [];
      },
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    downloadFile({ fileId, attachmentName }) {
      if (!fileId) return;
      let fileUrl = `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${fileId}`;
      const a = document.createElement("a");
      a.href = fileUrl;
      a.download = attachmentName;
      a.click();
    },
  },
};
</script>

<style lang="less" scoped>
.process-table-component {
  .table-flex-1 {
    flex: 1;
  }
  .table-flex-2 {
    flex: 2;
  }
  .table-flex-3 {
    flex: 3;
  }
  .table-flex-4 {
    flex: 4;
  }
  .table-header {
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    background: #f0f4fa;
    border-radius: 4px;
    .single-header {
      font-weight: 600;
      font-size: 14px;
      color: #5d687c;
      text-align: center;
    }
  }
  .table-container {
    .single-line {
      display: flex;
      align-items: center;
      min-height: 60px;
      border-radius: 4px;
      .single-value {
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #252d3d;
      }
      .file-container {
        display: flex;
        flex-direction: column;
        min-height: 60px;
        justify-content: space-evenly;
      }
    }
    .empty-container {
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 104px;
        width: 120px;
      }
    }
  }
  .single-file {
    font-weight: 400;
    font-size: 14px;
    color: #2a61ff;
    cursor: pointer;
  }
}
</style>