var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "flowFormRef",
      attrs: {
        "label-position": "top",
        "label-width": "80px",
        model: _vm.form,
        rules: _vm.rules,
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { prop: "name" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入新名称" },
            model: {
              value: _vm.form.name,
              callback: function ($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }