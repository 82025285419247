<template>
  <div class="transfer-user-selector" v-loading="loading">
    <el-cascader
      v-model="value"
      :show-all-levels="false"
      filterable
      @change="updateHandler"
      :options="$store.state.app.employesLvAll"
      :props="{
        multiple: false,
        value: 'userId',
        label: 'userName',
        children: 'children',
        emitPath: false,
      }"
      style="width: 100%"
      :collapse-tags="true"
    >
      <template slot-scope="{ node, data }">
        <span v-if="!node.isLeaf">
          {{ data.deptName ? data.deptName : data.companyName }}
        </span>
        <span v-else>
          {{ data.userName }}
        </span>
      </template>
    </el-cascader>
  </div>
</template>

<script>
export default {
  name: "transfer-user-selector",
  data() {
    return {
      value: [],
      userList: [],
      loading: false,
    };
  },
  mounted() {
    this.$store.dispatch('getEmployeelvAll');//获取级联人员数据
  },
  methods: {
    updateHandler() {
      console.log('this.value',this.value)
      this.$emit("update", this.value);
    },
  },
};
</script>

<style lang="less" scoped>
.transfer-user-selector {
}
</style>