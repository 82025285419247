<template>
  <div class="dynamic-checkbox-component">
    <el-checkbox-group
      v-model="value"
      :disabled="disabled"
      @change="updateHandler"
      :class="[direction === 'column' && 'radio-group-column']"
    >
      <el-checkbox
        v-for="item in optionList"
        :key="item.value"
        :label="item.value"
      >
        {{ item.label }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
import { labelRender, updateSource } from "../../../detail/utils.js";
import request from "@/utils/request";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "dynamic-checkbox-component",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        this.direction = labelRender(datas, "direction", "row");
        this.code = labelRender(datas, "code", null);
        const hideValue = labelRender(datas, "hideValue", null);
        this.optionsRender(datas, hideValue);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      value: null,
      code: null,
      direction: "row",
      optionList: [],
    };
  },
  methods: {
    updateHandler() {
      updateSource(this.dataset, 'hideValue', this.value)
      this.$emit("update", {
        key: this.code,
        value: this.value,
      });
    },
    optionsRender(item, hideValue) {
      this.optionList = [];
      const { propsList } = item;
      const optionsItem = propsList.filter((item) => item.key === "options")[0];
      // static | inner | classify
      const { type, optionList, serviceUrl, valueKey, labelKey, classifyCode } =
        optionsItem.config;
      if (type === "static") {
        this.optionList = optionList;
        this.value = hideValue ? hideValue : optionList.map((single) => {
          if (single.selected) {
            return single.value;
          }
        });
        this.updateHandler();
      } else if (type === "inner" && serviceUrl && valueKey && labelKey) {
        request({
          url: serviceUrl,
          method: "get",
        }).then((res) => {
          this.optionList = res.map((singleItem, singleIndex) => {
            if (hideValue) {
              this.value = hideValue
            } else {
              if (singleIndex === 0) {
                this.value = [singleItem[valueKey]];
              }
            }
            return {
              value: singleItem[valueKey],
              label: singleItem[labelKey],
            };
          });
        });
      } else if (type === "classify" && classifyCode) {
        request({
          url:
            envInfo.bgApp.lookupPath +
            `/tenant/lookup/item/find/list?classifyCode=${classifyCode}`,
          method: "get",
        }).then((res) => {
          this.optionList = res.map((singleItem, singleIndex) => {
            if (hideValue) {
              this.value = hideValue
            } else { 
              if (singleIndex === 0) {
                this.value = [singleItem["itemCode"]];
              }
            }
            return {
              value: singleItem["itemCode"],
              label: singleItem["itemName"],
            };
          });
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic-checkbox-component {
  .radio-group-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
}
</style>