<template>
  <div class="workflow-dynamic-form">
    <el-form
      ref="dynamicFormIns"
      :model="dynamicForm"
      :rules="dynamicFormRules"
      label-width="120px"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col
          v-for="item in activeItemList"
          :key="item.id"
          :span="colCalc(item)"
        >
          <el-form-item
            :label="labelRender(item, 'label', '未命名')"
            :prop="labelRender(item, 'code', null)"
            class="single-item"
            :ref="labelRender(item, 'code', null)"
          >
            <div class="component-container">
              <!-- 不可见遮罩层 -->
              <div
                class="mask-container"
                v-if="
                  editState === 'flowConfig' &&
                  item.itemRenderMap &&
                  item.itemRenderMap[flowNodeId] &&
                  item.itemRenderMap[flowNodeId] === 3
                "
              ></div>
              <component
                :is="propsCompRequire(item.key)"
                :key="item.id"
                :dataset="item"
                :disabled="disableRender(item)"
                @update="updateHandler"
              ></component>
            </div>
            <el-radio-group
              v-if="editState === 'flowConfig'"
              v-model="item.itemRenderMap[flowNodeId]"
            >
              <el-radio :label="1">可见不可编辑</el-radio>
              <el-radio :label="2">可编辑</el-radio>
              <el-radio :label="3">不可见</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { FormDisplayOption, ItemRenderDefault } from "./constants.js";
import { labelRender } from "../../detail/utils.js";
import { computed } from 'vue';

export default {
  name: "workflow-dynamic-form",
  props: {
    // 全量表单数据
    dataset: {
      type: String,
      default() {
        return "";
      },
    },
    // 当前审批节点的key
    flowNodeId: {
      type: String,
      default() {
        return "";
      },
    },
    /**
     * default | readOnly | flowConfig | hide
     * default: 表单正常编辑&提交
     * readOnly: 表单只读
     * flowConfig: 配置表单控件在工作流中的display状态
     * process: 表单审批时的状态
     */
    editState: {
      type: String,
      default() {
        return "default";
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        console.log('xxx-dataset', datas)
        console.log('xxx-flowNodeId', this.flowNodeId)
        this.buildFormAndRules(datas);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    activeItemList() {
      if (this.editState !== "process" || !this.flowNodeId) {
        return this.itemList;
      }
      return this.itemList.filter((item) => {
        // 如果item.itemRenderMap存在，3为不可见； 如果item.itemRenderMap不存在，默认展示
        return item.itemRenderMap
          ? item.itemRenderMap[this.flowNodeId] !== 3
          : true;
      });
    },
  },
  data() {
    return {
      labelRender,
      dynamicForm: {},
      dynamicFormRules: {},
      itemList: [],
    };
  },
  methods: {
    checkFormValidate() {
      return new Promise((resolve) => {
        this.$refs.dynamicFormIns.validate((valid) => {
          if (valid) {
            resolve(this.dynamicForm);
          }
        });
      });
    },
    /**
     * return formData/formDataJson
     */
    checkFormAndGetParams(noCheck) {
      return new Promise((resolve) => {
        if (noCheck) {
          resolve({
            formData: JSON.stringify(this.itemList),
            formDataJson: JSON.stringify(this.dynamicForm),
          });
        } else {
          this.$refs.dynamicFormIns.validate((valid, object) => {
            if (valid) {
              resolve({
                formData: JSON.stringify(this.itemList),
                formDataJson: JSON.stringify(this.dynamicForm),
              });
            } else {
              this.itemScrollIntoView(object)
              resolve(false)
            }
          });
        }
      });
    },
    itemScrollIntoView(object) {
      for (let i in object) {
        let dom = this.$refs[i];
        if (Object.prototype.toString.call(dom) !== "[object Object]") {
          //这里是针对遍历的情况（多个输入框），取值为数组
          dom = dom[0];
        }
        //第一种方法（包含动画效果）
        dom.$el.scrollIntoView({
          //滚动到指定节点
          block: "center", //值有start,center,end，nearest，当前显示在视图区域中间
          behavior: "smooth", //值有auto、instant,smooth，缓动动画（当前是慢速的）
        });

        //第二种方法
        // let top = dom.$el.getBoundingClientRect().top; //获取当前节点的偏移值
        // console.log(top,'top');
        // let scrollTop = document.documentElement.scrollTop; //获取视图滚动值
        // let diff = top + scrollTop;
        // document.documentElement.scrollTop = diff - 276; //276是第一个输入框节点距离顶部的偏移值，也可以在初始化提前保存
        //window.scrollTo(0,diff- 276) //同上
        break; //因为我们只需要检测一项,所以就可以跳出循环了
      }
    },
    disableRender(item) {
      if (["flowConfig", "process"].includes(this.editState)) {
        // itemRenderMap[this.flowNodeId]为1/3时禁用
        // 如果itemRenderMap没配置，默认就禁用
        return item.itemRenderMap && item.itemRenderMap[this.flowNodeId]
          ? [1, 3].includes(item.itemRenderMap[this.flowNodeId])
          : true;
      }
      if (this.editState === "readOnly") {
        return true;
      }
      return false;
    },
    // flowConfig状态下，获取拼装了itemRenderMap的所有数据
    getFormConfiguredDatas() {
      /**
       * formItem中添加renderMap
       */
      return JSON.stringify(this.itemList);
    },
    /**
     * flowConfig状态下, 重置当前节点或者覆盖
     * 1. id没有的时候就是恢复默认
     * 2. 有id的时候就拿对应id的数据覆盖当前节点
     */
    setCurrentItemById(nodeId) {
      this.itemList.forEach((item) => {
        if (item.itemRenderMap) {
          // 如果有传nodeId，并且nodeId是配置过的
          if (nodeId && item.itemRenderMap[nodeId]) {
            item.itemRenderMap[this.flowNodeId] = item.itemRenderMap[nodeId];
          } else {
            item.itemRenderMap[this.flowNodeId] = 1;
          }
        } else {
          // 如果没有itemRenderMap，说明没配置过,不管有没有nodeId，都设置为1
          this.$set(item, "itemRenderMap", {
            [this.flowNodeId]: 1,
          });
        }
      });
    },
    colCalc(datas) {
      const Layout = labelRender(datas, "layout", "half");
      return Layout === "all" ? 24 : 12;
    },
    propsCompRequire(compName) {
      return require(`./singleFormItem/${compName}.vue`).default;
    },
    requireMsgRender(type) {
      switch (type) {
        case "fileUploader":
        case "imgUploader":
          return "请上传";
        case "selector":
          return "请选择";
        default:
          return "请输入";
      }
    },
    buildFormAndRules(datas) {
      if (!datas) return;
      const parsedData = JSON.parse(datas);
      parsedData.forEach((data) => {
        const isRequire = labelRender(data, "require", false);
        const itemCode = labelRender(data, "code", "");
        const itemDefault = labelRender(data, "default", "");
        if (isRequire) {
          this.$set(this.dynamicFormRules, itemCode, [
            {
              required: true,
              message: this.requireMsgRender(data.key),
              trigger: ["blur"],
            },
          ]);
        }
        this.$set(this.dynamicForm, itemCode, itemDefault);
        if (this.editState !== "flowConfig") return;
        // 如果没有记录itemRenderMap，初始化下
        // if (!data.itemRenderMap || !data.itemRenderMap[this.flowNodeId]) {
        //   this.$set(data, "itemRenderMap", {
        //     [this.flowNodeId]: ItemRenderDefault,
        //   });
        // }
        if (!data.itemRenderMap) {
          this.$set(data, "itemRenderMap", {});
        }
        if (!data.itemRenderMap[this.flowNodeId]) {
          this.$set(data.itemRenderMap, this.flowNodeId, ItemRenderDefault);
        }
      });
      this.itemList = parsedData;
    },
    updateHandler({ key, value }) {
      this.dynamicForm[key] = value;
      console.log("this.dynamicForm", this.dynamicForm);
      console.log("this.itemList", this.itemList);
    },
  },
};
</script>

<style lang="less" scoped>
.workflow-dynamic-form {
  .component-container {
    position: relative;
    .mask-container {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      background-image: repeating-linear-gradient(
        110deg,
        transparent,
        transparent 3px,
        rgba(0, 0, 0, 0.1) 3px,
        rgba(0, 0, 0, 0.1) 7px
      );
    }
  }
}
</style>