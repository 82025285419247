var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "graph-component-textarea single-graph-component" },
    [
      _c(
        "div",
        { class: ["item-label", _vm.datas.require && "item-label-required"] },
        [_vm._v("\n    " + _vm._s(_vm.datas.label) + "\n  ")]
      ),
      _c("el-input", {
        attrs: {
          readonly: _vm.readOnly,
          type: "textarea",
          resize: "none",
          rows: 3,
          placeholder: _vm.datas.placeHolder,
          maxlength: _vm.datas.maxlength,
        },
        model: {
          value: _vm.datas.value,
          callback: function ($$v) {
            _vm.$set(_vm.datas, "value", $$v)
          },
          expression: "datas.value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }