<template>
    <div class="app-container mini-evaluation-management">
        <div class="button-line">
            <r-button plain @click="addNewConfig">新增</r-button>
            <r-button @click="initDatas">刷新</r-button>
        </div>
        <div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler" />
        </div>
        <el-dialog :visible.sync="detailDialogShow" width="728px" :destroy-on-close="true"
            :close-on-click-modal="false">
            <template slot="title">
                <span>新增</span>
            </template>
            <codeConfig :codeData="codeObj" v-if="detailDialogShow" @close="closeDialog" />
        </el-dialog>
    </div>
</template>
<script>
import finalTable from "@/components/FinalTable";
import codeConfig from "./codeConfig.vue";
import {
    getCodeConfigList,
    deleteEncode,
} from "@/api/ruge/ams/basicConfig/codeConfig";
//   import NewTemplateDialog from "./components/newTemplateDialog.vue";

export default {
    name: "ams-codeConfig",
    components: {
        finalTable,
        codeConfig
    },
    data() {
        return {
            loadingFlag: false,
            detailDialogShow: false,
            codeObj: {},
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                selection: {
                    need: false,
                },
                header: [
                    { prop: "codeName", label: "编码名称", width: "" },
                    { prop: "code", label: "编码", width: "" },
                    { prop: "codeLenth", label: "自定义固定长度", width: "180" },
                    { prop: "operation", label: "操作", width: "150" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {
                    codeName: {
                        type: "input",
                        label: "编码名称",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入编码名称",
                        prefixIcon: "el-icon-search",
                    },
                    code: {
                        type: "input",
                        label: "编码",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入编码",
                        prefixIcon: "el-icon-search",
                    },
                },
                // 表格内容配置
                detailConfig: {
                    //   creationDate: {
                    //     type: "dateFormat",
                    //   },
                    operation: {
                        type: "icon",
                        iconList: [
                            {
                                actionType: "iconClick",
                                eventName: "edit",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-edit",
                                tooltips: "编辑",
                            },
                            {
                                actionType: "iconClick",
                                eventName: "delete",
                                fontSize: "14px",
                                color: "#E47470",
                                cursorPointer: "pointer",
                                iconName: "el-icon-delete",
                                tooltips: "删除",
                            },

                        ],
                    },
                },
                // 高级搜索配置
                advanceFilterConfig: {
                    //   creationDate: {
                    //     type: "dateRange",
                    //     pickerType: "date",
                    //     label: "申请时间",
                    //     value: [],
                    //   },
                    codeName: {
                        inline: true,
                        value: "",
                    },
                    code: {
                        inline: true,
                        value: "",
                    },

                },
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            filterObj: {
                current: 1,
                rowCount: 10,
                codeName: "",
                code: ""
            },
        };
    },
    watch: {
        loadingFlag: {
            handler(val) {
                this.$refs.finalTableRef.loadingToggle(val);
            },
        },
    },
    created() {
        this.initDatas();
    },
    methods: {
        closeDialog() {
            this.detailDialogShow = false;
            this.initDatas();
        },
        addNewConfig() {
            this.codeObj = {}
            this.detailDialogShow = true;
        },
        initDatas() {
            this.loadingFlag = true;
            getCodeConfigList(this.filterObj)
                .then((res) => {
                    this.dataset.tableData = res.data.rows;
                    this.dataset.pageVO.total = res.data.totalPages;
                    console.log("this.dataset.tableData", this.dataset.tableData)

                })
                .finally(() => {
                    this.loadingFlag = false;
                });
        },
        tableEventHandler(datas) {
            if (datas.type === "goSearch") {
                this.filterObj.current = 1;
                this.filterObj = { ...this.filterObj, ...datas.params };
                this.initDatas();
            } else if (datas.type === "paginationChange") {
                this.filterObj.current = datas.params.current.page;
                this.filterObj.rowCount = datas.params.current.limit;
                this.initDatas();
            } else if (datas.type === "iconClick") {
                switch (datas.eventName) {
                    case "delete":
                        this.deleteConfig(datas.row);
                        break;
                    case "edit":
                        this.editConfig(datas.row);
                        break;
                }
            }
        },
        editConfig(rowDatas) {
            this.detailDialogShow = true
            this.codeObj={
                id:rowDatas.id,
                code:rowDatas.code,
                codeName:rowDatas.codeName,
                codeLenth:rowDatas.codeLenth,
            }
        },
        async deleteConfig(rowDatas) {
            await this.$confirm(`是否确认删除`, this.$t("commons.warning"), {
                confirmButtonText: this.$t("commons.confirm"),
                cancelButtonText: this.$t("commons.cancel"),
                type: "warning",
            });
            deleteEncode({
                id: rowDatas.id
            }).then(() => {
                this.$message.success("删除成功！");
                this.initDatas();
            });
        },
    },
};
</script>

<style lang="less" scoped>
.mini-evaluation-management {
    .button-line {
        margin-bottom: 10px;
    }
}
</style>