import { render, staticRenderFns } from "./transferUserSelector.vue?vue&type=template&id=ba35a912&scoped=true"
import script from "./transferUserSelector.vue?vue&type=script&lang=js"
export * from "./transferUserSelector.vue?vue&type=script&lang=js"
import style0 from "./transferUserSelector.vue?vue&type=style&index=0&id=ba35a912&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba35a912",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\ruge-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ba35a912')) {
      api.createRecord('ba35a912', component.options)
    } else {
      api.reload('ba35a912', component.options)
    }
    module.hot.accept("./transferUserSelector.vue?vue&type=template&id=ba35a912&scoped=true", function () {
      api.rerender('ba35a912', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ruge/approvalProcess/detail/components/transferUserSelector.vue"
export default component.exports