var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "process-template-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset.show,
            "before-close": _vm.dialogCloseHandler,
            width: "1140px",
            title: "选择模板",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "dialog-main",
            },
            [
              _c(
                "div",
                { staticClass: "tree-box" },
                [
                  _c("FlowTree", {
                    attrs: {
                      "selected-node": _vm.selectedNode,
                      data: _vm.treeData,
                      "default-expanded-keys": _vm.expandedKeys,
                    },
                    on: { "node-click": _vm.nodeClick },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "right-part" }, [
                _c("div", { staticClass: "right-bread" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.breadCrumb) + "\n        "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "workflow-list" },
                  [
                    !_vm.workflowList.length
                      ? _c("div", { staticClass: "empty-box" }, [
                          _c("img", {
                            attrs: { src: _vm.emptyUrl, alt: "empty" },
                          }),
                        ])
                      : _vm._e(),
                    _vm._l(_vm.workflowList, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.nodeId,
                          class: [
                            "workflow-card",
                            item.nodeId === _vm.activeNodeId &&
                              "workflow-card-active",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.handleSelectWorkflow(item)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "workflow-icon",
                            attrs: {
                              src:
                                item.type === _vm.FileType
                                  ? _vm.fileUrl
                                  : _vm.folderUrl,
                              alt: "folder",
                            },
                          }),
                          _c(
                            "el-tooltip",
                            {
                              directives: [
                                {
                                  name: "tooltip-auto-show",
                                  rawName: "v-tooltip-auto-show",
                                },
                              ],
                              staticClass: "text-overflow tooltip",
                              attrs: {
                                offset: 50,
                                effect: "dark",
                                content: item.nodeName,
                                placement: "top-start",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "card-name text-overflow" },
                                [_vm._v(" " + _vm._s(item.nodeName))]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "button-line" },
                  [
                    _c("r-button", { on: { click: _vm.dialogCloseHandler } }, [
                      _vm._v("取消"),
                    ]),
                    _c(
                      "r-button",
                      {
                        attrs: { plain: "", disabled: !_vm.activeNodeId },
                        on: { click: _vm.submitHandler },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }