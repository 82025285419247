var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tree",
    _vm._b(
      {
        staticClass: "tree-container",
        attrs: {
          "node-key": "id",
          "default-checked-keys": _vm.checkedKeys,
          "icon-class": "icon-class",
          data: _vm.treeData,
          props: _vm.defaultProps,
        },
        on: { "node-click": _vm.handleNodeClick },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function ({ node, data }) {
                return _c(
                  "div",
                  {
                    staticClass: "custom-tree-node-wrapper",
                    class: { "node-selected": _vm.getSelected(data) },
                    style: {
                      paddingLeft: _vm.getPaddingLeft(data.level, node.isLeaf),
                    },
                  },
                  [
                    _c("div", { staticClass: "custom-tree-node" }, [
                      _c(
                        "div",
                        { staticClass: "node-wrapper" },
                        [
                          !node.isLeaf
                            ? _c("div", { staticClass: "icon-expanded" }, [
                                _c("img", {
                                  attrs: {
                                    src: node.expanded
                                      ? _vm.foldUrl
                                      : _vm.expandedUrl,
                                  },
                                  on: {
                                    click: () => _vm.toggleExpanded(node, data),
                                  },
                                }),
                              ])
                            : _vm._e(),
                          [
                            !data.status
                              ? _c("img", {
                                  attrs: {
                                    src:
                                      data.type === _vm.FolderType ||
                                      data.id === "ROOT"
                                        ? _vm.folderUrl
                                        : _vm.fileUrl,
                                  },
                                })
                              : _c("img", {
                                  attrs: { src: _vm.fileDisabledUrl },
                                }),
                          ],
                          _c(
                            "span",
                            {
                              staticClass: "tree-label",
                              class: { "tree-label-disabled": data.disabled },
                            },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  directives: [
                                    {
                                      name: "tooltip-auto-show",
                                      rawName: "v-tooltip-auto-show",
                                    },
                                  ],
                                  staticClass: "label-text",
                                  attrs: {
                                    content: node.label,
                                    placement: "top",
                                    "open-delay": 500,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "label-text",
                                      class: _vm.labelTextClass,
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(node.label) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.showCheckbox
                            ? _c("el-checkbox", {
                                staticClass: "checkbox",
                                on: {
                                  change: (event) =>
                                    _vm.change(event, { node, data }),
                                },
                                model: {
                                  value:
                                    _vm.currentCheckedKeys[
                                      data[_vm.defaultProps.id]
                                    ],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentCheckedKeys,
                                      data[_vm.defaultProps.id],
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentCheckedKeys[data[defaultProps.id]]",
                                },
                              })
                            : _vm._e(),
                          data.disabled
                            ? _c("span", { staticClass: "tree-tag-disabled" }, [
                                _vm._v("禁用"),
                              ])
                            : _vm._e(),
                          _vm._t("suffix", null, { data: data }),
                        ],
                        2
                      ),
                    ]),
                  ]
                )
              },
            },
          ],
          null,
          true
        ),
      },
      "el-tree",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }