var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "props-component-userDefault" },
    [
      _c(
        "el-form-item",
        {
          class: ["single-form-item", `single-form-${_vm.dataset.key}`],
          attrs: { label: _vm.dataset.label, prop: _vm.dataset.key },
        },
        [
          _c("el-select", {
            staticClass: "attendanceStaff-selector",
            staticStyle: { width: "100%", cursor: "pointer" },
            attrs: {
              multiple: "",
              filterable: "",
              remote: "",
              "reserve-keyword": "",
              placeholder: "请选择",
            },
            on: {
              "remove-tag": _vm.staffRemove,
              focus: _vm.attendanceStaffFocus,
            },
            model: {
              value: _vm.displayValue,
              callback: function ($$v) {
                _vm.displayValue = $$v
              },
              expression: "displayValue",
            },
          }),
        ],
        1
      ),
      _vm.employeeDialog.dialogVisible
        ? _c("EmployeeSelection", {
            attrs: {
              dataset: _vm.employeeDialog,
              noCheck: true,
              multiple: _vm.multiple,
            },
            on: { close: _vm.employeeClose, submit: _vm.employeeSubmit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }