var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "graph-component-input single-graph-component" },
    [
      _c(
        "div",
        { class: ["item-label", _vm.datas.require && "item-label-required"] },
        [_vm._v("\n    " + _vm._s(_vm.datas.label) + "\n  ")]
      ),
      _c(
        "div",
        { staticStyle: { border: "1px solid #ccc" } },
        [
          _c("Toolbar", {
            ref: "toolBar",
            staticStyle: { "border-bottom": "1px solid #ccc" },
            attrs: { editor: _vm.editor, defaultConfig: _vm.toolbarConfig },
          }),
          _c("Editor", {
            staticStyle: { height: "200px", "overflow-y": "hidden" },
            attrs: { defaultConfig: _vm.editorConfig },
            on: { onCreated: _vm.onCreated },
            model: {
              value: _vm.editorValue,
              callback: function ($$v) {
                _vm.editorValue = $$v
              },
              expression: "editorValue",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }