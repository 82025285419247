<template>
  <div class="graph-component-input single-graph-component">
    <div :class="['item-label', datas.require && 'item-label-required']">
      {{ datas.label }}
    </div>
    <el-input
      :readonly="readOnly"
      :placeholder="datas.placeHolder"
      :maxlength="datas.maxlength"
      v-model="datas.value"
    ></el-input>
  </div>
</template>
<script>
import { labelRender } from "../utils.js";
export default {
  name: "graph-component-input",
  props: {
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(item) {
        this.datas.label = labelRender(item, "label", "未命名");
        this.datas.value = labelRender(item, "default", "");
        this.datas.placeHolder = labelRender(item, "placeHolder", "请输入");
        this.datas.maxlength = labelRender(item, "maxLength", 20);
        this.datas.require = labelRender(item, "require", false);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      labelRender,
      datas: {
        label: null,
        value: null,
        placeHolder: "请输入",
        maxlength: 20,
        require: false,
      },
    };
  },
};
</script>
<style lang="less" scoped>
.graph-component-input {
}
</style>