<template>
  <div class="dynamic-user-component">
    <div class="tags-container">
      <span class="single-tag" v-for="item in value" :key="item.value">
        {{ item.userName }}
        <i
          class="el-icon-error"
          v-if="!disabled"
          @click="staffRemove(item)"
        ></i>
      </span>
      <span
        class="add-tag single-tag"
        v-if="!disabled"
        @click="attendanceStaffFocus"
      >
        <i class="el-icon-plus"></i>
        添加
      </span>
    </div>
    <EmployeeSelection
      v-if="employeeDialog.dialogVisible"
      :dataset="employeeDialog"
      :noCheck="true"
      :multiple="multiple"
      @close="employeeClose"
      @submit="employeeSubmit"
    />
  </div>
</template>

<script>
import EmployeeSelection from "@/components/EmployeeSelection";
import { labelRender, updateSource } from "../../../detail/utils.js";

export default {
  name: "dynamic-user-component",
  components: {
    EmployeeSelection,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        this.value = labelRender(datas, "userDefault", []);
        this.code = labelRender(datas, "code", []);
        this.multiple = labelRender(datas, "multiple", false);
        this.updateHandler();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      value: [],
      code: null,
      multiple: false,
      employeeDialog: {
        dialogVisible: false,
        mainTitle: "选择用户",
        secondTitle: "",
        selectList: [],
      },
    };
  },
  methods: {
    employeeClose() {
      this.employeeDialog.dialogVisible = false;
    },
    employeeSubmit(userList) {
      this.value = userList;
      this.employeeDialog.dialogVisible = false;
      this.updateHandler();
    },
    attendanceStaffFocus() {
      this.employeeDialog.selectList = this.value;
      this.employeeDialog.dialogVisible = true;
    },
    staffRemove({ userId }) {
      this.value = this.value.filter((item) => item.userId !== userId);
      this.updateHandler();
    },
    updateHandler() {
      updateSource(this.dataset, "userDefault", this.value)
      this.$emit("update", {
        key: this.code,
        value: this.multiple
          ? this.value.map((item) => item.userId)
          : this.value.length
          ? this.value[0].userId
          : undefined,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic-user-component {
  .tags-container {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    .single-tag {
      padding: 0 8px;
      height: 40px;
      display: flex;
      align-items: center;
      background: #f7f8fa;
      border-radius: 10px;
      color: #5d687c;
      .el-icon-error {
        cursor: pointer;
        color: #7d8799;
        margin-left: 4px;
      }
    }
    .add-tag {
      width: 76px;
      border: 1px dotted #7fa0ff;
      font-weight: 600;
      font-size: 14px;
      color: #2a61ff;
      cursor: pointer;
      .el-icon-plus {
        margin-right: 4px;
      }
    }
  }
}
</style>