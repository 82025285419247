<template>
  <el-tree
    v-bind="$attrs"
    class="tree-container"
    node-key="id"
    :default-checked-keys="checkedKeys"
    icon-class="icon-class"
    @node-click="handleNodeClick"
    :data="treeData"
    :props="defaultProps"
  >
    <div
      slot-scope="{ node, data }"
      class="custom-tree-node-wrapper"
      :class="{ 'node-selected': getSelected(data) }"
      :style="{ paddingLeft: getPaddingLeft(data.level, node.isLeaf) }"
    >
      <div class="custom-tree-node">
        <div class="node-wrapper">
          <div class="icon-expanded" v-if="!node.isLeaf">
            <img
              :src="node.expanded ? foldUrl : expandedUrl"
              @click="() => toggleExpanded(node, data)"
            />
          </div>
          <template>
            <img
              v-if="!data.status"
              :src="
                data.type === FolderType || data.id === 'ROOT'
                  ? folderUrl
                  : fileUrl
              "
            />
            <img v-else :src="fileDisabledUrl" />
          </template>

          <span
            class="tree-label"
            :class="{ 'tree-label-disabled': data.disabled }"
          >
            <el-tooltip
              class="label-text"
              :content="node.label"
              placement="top"
              :open-delay="500"
              v-tooltip-auto-show
            >
              <div class="label-text" :class="labelTextClass">
                {{ node.label }}
              </div>
            </el-tooltip>
          </span>
          <el-checkbox
            v-if="showCheckbox"
            class="checkbox"
            v-model="currentCheckedKeys[data[defaultProps.id]]"
            @change="(event) => change(event, { node, data })"
          >
          </el-checkbox>
          <span class="tree-tag-disabled" v-if="data.disabled">禁用</span>
          <slot name="suffix" :data="data"></slot>
        </div>
      </div>
    </div>
  </el-tree>
</template>
<script>
import fileDisabledUrl from "@/assets/images/workflow/file-disabled.svg";
import folderUrl from "@/assets/images/workflow/folder.svg";
import foldUrl from "@/assets/images/workflow/fold.svg";
import expandedUrl from "@/assets/images/workflow/expanded.svg";
import fileUrl from "@/assets/images/workflow/file-icon.svg";
import { FolderType, FileType, formatTreeData } from "../utils";
export default {
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    props: {
      type: Object,
      default: () => ({ id: "id", label: "label", children: "children" }),
    },
    selectedNode: {
      type: Object,
      default: null,
    },
    checkedKeys: {
      type: Array,
      default: () => [],
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    labelTextClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      FolderType,
      FileType,
      folderUrl,
      fileUrl,
      foldUrl,
      fileDisabledUrl,
      expandedUrl,
      treeData: [],
      currentCheckedKeys: {},
      defaultProps: {
        id: "id",
        label: "label",
        children: "children",
      },
    };
  },
  methods: {
    toggleExpanded(node, data) {
      this.$emit("toggle-expanded", node, data);
    },
    change(event, data) {
      console.log("data", data);
      const key = data.data[this.defaultProps.id];
      this.currentCheckedKeys[key] = event;
      this.$emit("checkbox-change", {
        checkedKeys: Object.keys(this.currentCheckedKeys),
        currentKey: event ? key : "",
      });
    },
    handleNodeClick(data) {
      this.$emit("node-click", data);
    },
    getSelected(data) {
      const selectedNode = this.selectedNode;
      if (!selectedNode) {
        return false;
      }
      const selectedNodeParentId =
        selectedNode.level === 0
          ? selectedNode[this.defaultProps.id]
          : selectedNode.parentIds.at(0);
      const nodeId = selectedNode.parentIds.at(
        selectedNode.parentIds.length - 1
      );

      return (
        selectedNodeParentId === data[this.defaultProps.id] ||
        nodeId === data[this.defaultProps.id]
      );
    },
    getPaddingLeft(level, isLeaf) {
      if (!level) return "8px";
      let pl = 20 * level;
      if (isLeaf) {
        pl = pl + 10;
      }
      pl = pl + 8;
      return pl + "px";
    },
  },
  watch: {
    data: {
      handler(val) {
        this.treeData = val;
      },
      immediate: true,
    },
    props: {
      handler(val) {
        this.defaultProps = { ...this.defaultProps, ...val };
      },
      immediate: true,
    },
    checkedKeys: {
      handler(val) {
        const currentCheckedKeys = { ...this.currentCheckedKeys };
        for (const item of Object.keys(this.currentCheckedKeys)) {
          currentCheckedKeys[item] = val.includes(item);
        }

        this.currentCheckedKeys = currentCheckedKeys;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style scoped lang="less">
.tree-container {
  height: 100%;
  background: #fcfcfc;
}

::v-deep {
  .icon-class {
    display: none;
  }
  .custom-tree-node-wrapper {
    position: relative;
    width: 100%;
    height: 34px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    box-sizing: content-box;
    padding-right: 8px;
    &:hover {
      background-color: #f4f4f5;
    }
    &.node-selected {
      background: #eaf0ff;
    }
  }
  .custom-tree-node {
    display: flex;

    border-radius: 6px;
    align-items: center;

    // padding-left: 8px;

    // &.node-selected {
    //   background: #eaf0ff;
    // }

    // &:hover {
    //   background-color: #f4f4f5;
    // }
    .icon-expanded {
      width: 6px;
      cursor: pointer;
      padding: 8px 0;
      display: flex;
      justify-items: center;
      align-items: center;
    }

    .node-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      column-gap: 4px;
    }

    .tree-label {
      display: flex;
      justify-content: space-between;
      flex: auto;
      font-weight: 400;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .label-text {
        width: 145px;
        font-size: 14px;
        color: #252d3d;
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .tree-label-disabled {
      color: #5d687c;
    }

    .tree-tag-disabled {
      background: #ffefef;
      color: #f35555;
      border-radius: 2px 4px 2px 4px;
      padding: 2px 4px;
      font-size: 10px;
      margin-left: 14px;
    }
    .checkbox {
      position: absolute;
      right: 20px;

      .el-checkbox__input {
        line-height: 0;
      }
    }
  }

  .el-tree-node__content > .el-tree-node__expand-icon {
    padding: 0 !important;
    border-radius: 6px;
  }

  .el-tree-node {
    margin-bottom: 0px !important;
  }

  .el-tree-node__content:hover {
    background-color: initial !important;
  }

  .el-tree-node:focus > .el-tree-node__content {
    background-color: initial !important;
  }

  .el-tree-node__content {
    height: 100%;
    padding-left: 0 !important;
  }
  .el-tree-node > .el-tree-node__children {
    overflow: inherit;
  }
}
</style>
