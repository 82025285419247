<template>
  <div class="props-component-email">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <el-input
        prefix-icon="el-icon-message"
        clearable
        v-model="dataset.value"
        @blur="emailValid"
      >
      </el-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "props-component-email",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    emailValid() {
      if (!this.dataset.value) return;
      const emailReg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (emailReg.test(this.dataset.value)) return;
      this.$message.warning("请输入正确的邮箱");
      this.dataset.value = null;
    },
  },
};
</script>