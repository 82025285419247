var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "drafter-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset.show,
            "before-close": _vm.dialogCloseHandler,
            width: "600px",
            title: "以起草人身份操作",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "flowFormRef",
              staticClass: "flow-form",
              attrs: {
                "label-position": "top",
                "label-width": "80px",
                model: _vm.form,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "flow-form-item" }, [
                            _c(
                              "span",
                              { staticClass: "flow-form-item-label" },
                              [_vm._v("操作")]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "radio-group-border",
                      model: {
                        value: _vm.form.auditOperation,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "auditOperation", $$v)
                        },
                        expression: "form.auditOperation",
                      },
                    },
                    _vm._l(_vm.operationListActive, function (item) {
                      return _c(
                        "el-radio",
                        {
                          key: item.key,
                          staticClass: "radio-border",
                          class: {
                            "radio-checked":
                              _vm.form.auditOperation === item.key,
                          },
                          attrs: { label: item.key, border: "" },
                        },
                        [
                          _c("div", { staticClass: "radio-item" }, [
                            _c("span", [_vm._v(_vm._s(item.label))]),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.auditOperation === 8
                ? [
                    _c(
                      "el-form-item",
                      {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "flow-form-item" }, [
                                    _c(
                                      "span",
                                      { staticClass: "flow-form-item-label" },
                                      [_vm._v("通知紧急程度")]
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3022740183
                        ),
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "radio-group-border",
                            model: {
                              value: _vm.form.urgencyLevel,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "urgencyLevel", $$v)
                              },
                              expression: "form.urgencyLevel",
                            },
                          },
                          _vm._l(_vm.radioList, function (item) {
                            return _c(
                              "el-radio",
                              {
                                key: item.key,
                                staticClass: "radio-border",
                                class: {
                                  "radio-checked":
                                    _vm.form.urgencyLevel === item.key,
                                },
                                attrs: { label: item.key, border: "" },
                              },
                              [
                                _c("div", { staticClass: "radio-item" }, [
                                  _c("img", {
                                    attrs: {
                                      src: item.imgSrc,
                                      alt: item.label,
                                    },
                                  }),
                                  _c("span", [_vm._v(_vm._s(item.label))]),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "flow-form-item" }, [
                                    _c(
                                      "span",
                                      { staticClass: "flow-form-item-label" },
                                      [_vm._v("通知选项")]
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3766984712
                        ),
                      },
                      [
                        _c(
                          "el-checkbox",
                          {
                            staticClass: "checkbox",
                            model: {
                              value: _vm.form.isEndNotify,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isEndNotify", $$v)
                              },
                              expression: "form.isEndNotify",
                            },
                          },
                          [_vm._v("流程结束后通知我")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "flow-form-item" }, [
                            _c(
                              "span",
                              { staticClass: "flow-form-item-label" },
                              [_vm._v("当前处理人")]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("div", { staticClass: "text-user" }, [
                    _vm._v(_vm._s(_vm.form.currentUser || "-")),
                  ]),
                ]
              ),
              _c(
                "el-form-item",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "flow-form-item" }, [
                            _c(
                              "span",
                              { staticClass: "flow-form-item-label" },
                              [_vm._v("已经处理人")]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("div", { staticClass: "text-user" }, [
                    _vm._v(_vm._s(_vm.form.prevUsers || "-")),
                  ]),
                ]
              ),
              _c(
                "el-form-item",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "flow-form-item" }, [
                            _c(
                              "span",
                              { staticClass: "flow-form-item-label" },
                              [_vm._v("处理意见")]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("el-input", {
                    staticClass: "textarea",
                    attrs: { type: "textarea", placeholder: "请输入内容" },
                    model: {
                      value: _vm.form.auditComment,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "auditComment", $$v)
                      },
                      expression: "form.auditComment",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "buttons-line" },
            [
              _c("r-button", { on: { click: _vm.dialogCloseHandler } }, [
                _vm._v("取消"),
              ]),
              _c(
                "r-button",
                {
                  attrs: { plain: "", loading: _vm.submitLoading },
                  on: { click: _vm.submitHandler },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }