var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "props-component-occupy" },
    [
      _c(
        "el-form-item",
        {
          class: ["single-form-item", `single-form-${_vm.dataset.key}`],
          attrs: { label: _vm.dataset.label, prop: _vm.dataset.key },
        },
        [
          _c(
            "div",
            [
              _c("el-switch", {
                staticClass: "require-switch",
                attrs: {
                  "active-color": "#2A61FF",
                  "inactive-color": "#ccc",
                  "active-value": true,
                  "inactive-value": false,
                },
                model: {
                  value: _vm.dataset.value.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataset.value, "value", $$v)
                  },
                  expression: "dataset.value.value",
                },
              }),
            ],
            1
          ),
          _vm.dataset.value.value
            ? [
                _c(
                  "div",
                  { staticClass: "occupy-line" },
                  [
                    _c("span", { staticClass: "occupy-label" }, [
                      _vm._v(" 显示清除按钮 "),
                    ]),
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#2A61FF",
                        "inactive-color": "#ccc",
                        "active-value": true,
                        "inactive-value": false,
                      },
                      model: {
                        value: _vm.dataset.value.clearable,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataset.value, "clearable", $$v)
                        },
                        expression: "dataset.value.clearable",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "occupy-label" }, [
                      _vm._v("占位内容"),
                    ]),
                    _c("el-input", {
                      model: {
                        value: _vm.dataset.value.placeHolder,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataset.value, "placeHolder", $$v)
                        },
                        expression: "dataset.value.placeHolder",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }