var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wholeHtml" },
    [
      _c("TForm", {
        ref: "refFrom1",
        attrs: {
          lableRow: "",
          autoCheck: true,
          model: _vm.model,
          formlist: _vm.formlist,
          rowGutter: 16,
          labelPosition: "left",
        },
        scopedSlots: _vm._u([
          {
            key: "timeoutSet",
            fn: function (scope) {
              return [
                _c("el-input", {
                  attrs: {
                    placeholder: "请输入",
                    onkeyup: "value=value.replace(/\\D+/g, '')",
                  },
                  model: {
                    value: _vm.model[scope.current.keys],
                    callback: function ($$v) {
                      _vm.$set(_vm.model, scope.current.keys, _vm._n($$v))
                    },
                    expression: "model[scope.current.keys]",
                  },
                }),
                _c("span", { staticClass: "tipTx" }, [_vm._v("分钟")]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }