<template>
  <div class="props-component-startPlaceHolder">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <el-input v-model="dataset.value"> </el-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "props-component-startPlaceHolder",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>