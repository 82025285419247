// 通过key或者value
export function labelRender(datas, prop, defaultValue, label = 'value') {
  const { propsList } = datas;
  if (propsList && propsList.length) {
    for (let key of propsList) {
      if (key.key === prop) {
        return key[label] !== undefined ? key[label] : defaultValue;
      }
    }
  }
}


export function updateSource(datas, prop, value, otherProp = 'value') {
  const { propsList } = datas;
  if (propsList && propsList.length) {
    for (let key of propsList) {
      if (key.key === prop) {
        key[otherProp] = value;
      }
    }
  }
}
