<template>
  <div class="process-detail-page">
    <div class="process-header">
      <span class="logo-and-title">
        <img src="@/assets/images/login/small_logo.png" alt="" />
        <span class="header-title">
          {{ this.processBasicInfo[0] }}
        </span>
      </span>
    </div>
    <div class="process-detail-container">
      <div class="process-name">
        {{ processName }}
      </div>
      <div class="card-container">
        <DetailTitle title="基本信息" />
        <DetailBasicInfo
          v-loading="initLoading"
          :dataset="processBasicInfo"
          ref="basicInfoIns"
        />
      </div>
      <div class="card-container">
        <DetailTitle title="审批内容" />
        <div class="form-container">
          <DynamicForm
            v-loading="initLoading"
            ref="dynamicFormIns"
            :editState="state === 'readOnly' ? 'readOnly' : 'process'"
            :flowNodeId="flowDetails.frontendNodeId"
            :dataset="formConfig"
          />
        </div>
      </div>
      <div class="card-container">
        <DetailTitle title="流程处理" />
        <div class="tabs-and-button">
          <div class="tabs-line">
            <span
              :class="['single-tab', item.active && 'single-tab-active']"
              v-for="item in filteredTabs"
              :key="item.key"
              @click="setTabActive(item.key)"
            >
              {{ item.label }}
            </span>
          </div>
          <div class="special-buttons">
            <span class="special-buton" v-if="specialButtonRender('admin')">
              以管理员身份操作
            </span>
            <span
              class="special-buton"
              v-if="specialButtonRender('start')"
              @click="drafterHandler"
            >
              以起草人身份操作
            </span>
          </div>
        </div>
        <keep-alive>
          <components
            :is="propsCompRequire(activeTab)"
            ref="processHandlerIns"
            :processInfo="processInfo"
            :nodeId="graphNodeId"
            :queryNodeParams="queryNodeParams"
            :flowDetails="flowDetails"
            :buttonList="buttonList"
            :diaplayDatas="diaplayDatas"
            :state="state"
            :processStatus="processStatus"
          />
        </keep-alive>
      </div>
    </div>
    <div class="process-buttons" v-loading="initLoading">
      <r-button type="danger" @click="closePage">关闭</r-button>
      <r-button v-if="buttonRender('copy')" @click="copyHandler"
        >复制流程</r-button
      >
      <r-button v-if="buttonRender('draft')" @click="saveAsDraft"
        >暂存</r-button
      >
      <r-button
        type="danger"
        v-if="buttonRender('delete')"
        @click="deleteHandler"
        >删除</r-button
      >
      <r-button
        v-if="buttonRender('submit')"
        plain
        @click="submitHandler"
        :loading="submitloading"
        >提交</r-button
      >
    </div>
    <DrafterDialog :dataset="drafterDialog" @close="drafterDialogClose" />
  </div>
</template>

<script>
import {
  getDetailsByNodeId,
  createDefinition,
  auditDefinition,
  withdrawSign,
  rejectSign,
  temporarilyDefinition,
  getDetailsByDefinitionId,
  getUserAndDeptName,
  deleteDefinition,
  copyDefinition,
} from "@/api/ruge/workflow/detail";
import DetailTitle from "./components/detailTitle";
import DetailBasicInfo from "./components/detailBasicInfo.vue";
import DynamicForm from "@/views/ruge/workflow/components/DynamicForm";
import DrafterDialog from "./components/drafterDialog";
import { mapGetters } from "vuex";
import { dateFormat } from "@/filters/index.js";

export default {
  name: "process-detail-page",
  components: {
    DetailTitle,
    DetailBasicInfo,
    DynamicForm,
    DrafterDialog,
  },
  data() {
    return {
      graphNodeId: null,
      initLoading: false,
      submitloading: false,
      processName: null,
      formConfig: null,
      tabs: [
        {
          label: "流程处理",
          key: "processHanding",
          active: true,
        },
        {
          label: "流程图",
          key: "processGraph",
          active: false,
        },
        {
          label: "流程日志",
          key: "processLog",
          active: false,
        },
      ],
      processInfo: {},
      flowDetails: {},
      /**
       * state: null | process | readOnly
       *  - null: 新增时没有state
       *  - process: 当rowData中的taskId存在，并且taskStatus为1的时候，审批状态
       *  - readOnly: 当rowData中的taskId不存在或者taskStatus不为1
       *  - draft:  当rowData中的processStatus为4的时候
       */
      state: null,
      currentUserInfo: {},
      processBasicInfo: [],
      drafterDialog: {
        show: false,
        datas: {},
      },
      // 草稿状态url没有versionId，遂存一个
      draftVersionId: null,
      processStatus: null,
      queryNodeParams: {
        definitionId: null, //"流程实例ID",
        nodeId: null, //"当前节点ID",
        modeId: null, //"模型ID",
        versionId: null, //"流程版本ID",
        formData: null, //"form表单数据",
      },
      // 控制操作选项，默认1,2,3,4
      buttonList: [1, 2, 3, 4],
      diaplayDatas: {},
    };
  },
  computed: {
    activeTab() {
      return this.tabs.filter((item) => item.active)[0].key;
    },
    filteredTabs() {
      return !this.state
        ? this.tabs.filter((item) => item.key !== "processLog")
        : this.tabs;
    },
    ...mapGetters(["havePermissions"]),
  },
  created() {
    this.initDatasNew();
  },
  methods: {
    copyHandler() {
      const { definitionId } = this.$route.query;
      copyDefinition({ definitionId }).then((res) => {
        const { modeId, versionId, temporarilyVO } = res;
        let url = `${location.origin}${location.pathname}#/approval-process/detail?nodeId=${modeId}&versionId=${versionId}&copyId=${temporarilyVO.definitionId}`;
        window.open(url, "_blank");
      });
    },
    async deleteHandler() {
      await this.$confirm(`确认删除该审批流吗？`, this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: "再想想",
        type: "warning",
      });
      const { definitionId } = this.$route.query;
      deleteDefinition({ definitionId }).then(() => {
        this.$message.success("删除成功");
        this.pageCloseHandler();
      });
    },
    buttonRender(type) {
      switch (type) {
        case "copy":
          return this.state === "process" || this.processStatus === 4;
        case "draft":
          return this.state !== "readOnly";
        case "delete":
          return this.state === "draft" && this.processStatus === 0;
        case "submit":
          return this.state !== "readOnly";
      }
    },
    drafterDialogClose(closeFlag) {
      this.drafterDialog.show = false;
      closeFlag && this.pageCloseHandler();
    },
    drafterHandler() {
      console.log("this.processInfo", this.processInfo);
      const { currentProcessor, handleProcessor } = this.processInfo;
      const { definitionId } = this.$route.query;
      this.drafterDialog.show = true;
      this.drafterDialog.datas = {
        currentUser: currentProcessor,
        prevUsers: handleProcessor,
        definitionId,
        processStatus: this.processStatus,
      };
    },
    specialButtonRender(type) {
      /**
      * admin  以管理员身份操作
        start  以起草人身份操作
      */
      //  流程已结束，不展示
      if (this.processStatus === 4) return false;
      // 提交流程时，不展示
      if (!this.state) return false;
      if (type === "admin") {
        // 判断是否有权限点
        return this.havePermissions.includes("adminManageWorkflow");
      } else if (type === "start") {
        return (
          this.currentUserInfo.userId === this.flowDetails.processInitiator
        );
      }
    },
    async closePage() {
      if (this.state !== "readOnly") {
        await this.$confirm(`是否确认关闭页面？`, this.$t("commons.warning"), {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        });
        window.close();
      } else {
        window.close();
      }
    },
    async saveAsDraft() {
      const { definitionId } = this.$route.query;
      // processDeptId/processDeptName/processInitiatorName/processNamePath
      const basicInfo = this.getSubmitInfo();
      // versionId/auditComment/urgencyLevel/notificationTypeDetailList
      const proveParams = this.$refs.processHandlerIns.getSubmitParams("draft");
      // proveParams为false表示必填项未通过校验
      if (proveParams === false) {
        this.submitloading = false;
        return;
      }
      // formData/formDataJson
      const formParams = await this.$refs.dynamicFormIns.checkFormAndGetParams(
        true
      );
      const params = {
        ...basicInfo,
        ...proveParams,
        ...formParams,
        ...{
          definitionId,
        },
      };
      temporarilyDefinition(params).then(() => {
        this.$message.success("保存成功");
        this.pageCloseHandler();
      });
    },
    async submitHandler() {
      this.submitloading = true;
      // 审批
      if (this.state === "process") {
        // taskId/nodeId/dataId - this.flowDetails
        // formData/formDataJson
        const formParams =
          await this.$refs.dynamicFormIns.checkFormAndGetParams();
        if (formParams === false) {
          this.submitloading = false;
          return;
        }
        // auditOperation/definitionId/auditComment/urgencyLevel/notificationTypeDetailList/workflowAttachmentVOList
        const proveParams = this.$refs.processHandlerIns.getSubmitParams(
          this.state
        );
        console.log("proveParams", proveParams);
        // proveParams为false表示必填项未通过校验
        if (proveParams === false) {
          this.submitloading = false;
          return;
        }
        const params = { ...this.flowDetails, ...formParams, ...proveParams };
        console.log("params.auditOperation", params.auditOperation);
        switch (params.auditOperation) {
          case 5:
            // 退回
            this.rejectSignProcessSubmit(params);
            break;
          case 6:
            // 收回
            this.withdrawSignProcessSubmit(params);
            break;
          default:
            this.normaoProcessSubmit(params);
        }
      } else {
        // 提交
        const { definitionId } = this.$route.query;
        // processDeptId/processDeptName/processInitiatorName/processNamePath
        const basicInfo = this.getSubmitInfo();
        // formData/formDataJson
        const formParams =
          await this.$refs.dynamicFormIns.checkFormAndGetParams();
        if (formParams === false) {
          this.submitloading = false;
          return;
        }
        // versionId/auditComment/urgencyLevel/notificationTypeDetailList
        const proveParams = this.$refs.processHandlerIns.getSubmitParams(
          this.state
        );
        // proveParams为false表示必填项未通过校验
        if (proveParams === false) {
          this.submitloading = false;
          return;
        }
        const params = {
          ...basicInfo,
          ...formParams,
          ...proveParams,
          ...{
            definitionId,
          },
        };
        if (this.state === "draft") {
          params.versionId = this.draftVersionId;
        }
        console.log("提交参数：", params);
        createDefinition(params)
          .then((res) => {
            if (res) {
              this.$message.success("提交成功");
              this.pageCloseHandler();
            }
          })
          .finally(() => {
            this.submitloading = false;
          });
      }
    },
    pageCloseHandler() {
      // setTimeout(() => {
      //   window.close()
      // }, 2000);
    },
    // 加签收回
    withdrawSignProcessSubmit(params) {
      withdrawSign(params)
        .then((res) => {
          if (res) {
            this.$message.success("操作成功");
            this.pageCloseHandler();
          }
        })
        .finally(() => {
          this.submitloading = false;
        });
    },
    // 退回加签
    rejectSignProcessSubmit(params) {
      rejectSign(params)
        .then((res) => {
          if (res) {
            this.$message.success("操作成功");
            this.pageCloseHandler();
          }
        })
        .finally(() => {
          this.submitloading = false;
        });
    },
    normaoProcessSubmit(params) {
      auditDefinition(params)
        .then((res) => {
          if (res) {
            this.$message.success("操作成功");
            this.pageCloseHandler();
          }
        })
        .finally(() => {
          this.submitloading = false;
        });
    },
    getSubmitInfo() {
      const { userName, deptId, deptNames } = this.currentUserInfo;
      return {
        processNamePath: this.nodeNamePaths,
        processDeptId: deptId,
        processDeptName: deptNames,
        processInitiatorName: userName,
      };
    },
    propsCompRequire(compName) {
      return require(`./components/${compName}.vue`).default;
    },
    setTabActive(key) {
      this.tabs.forEach((item) => {
        item.active = item.key === key;
      });
    },
    async initDatasNew() {
      /**
       * state: null | process | readOnly | draft
       *  - null: 新增时没有state
       *  - process: taskId存在，并且taskStatus为1的时候，审批状态
       *  - readOnly: taskId不存在或者taskStatus不为1
       *  - draft:  processStatus为0或者2时，提交人为登录人
       */
      /**
       * processStatus: (0:草稿: 1:待审:2:驳回: 3:撤回 4 :结束)
       */
      this.initLoading = true;
      this.currentUserInfo = await getUserAndDeptName();
      /**
       *  创建入口：nodeId、versionId
          copy入口：nodeId、versionId、copyId
          审批入口：definitionId、taskId（当前不审批则无）
       */
      const { nodeId, definitionId, versionId, copyId, taskId } =
        this.$route.query;
      // 创建/copy
      if (nodeId && versionId) {
        this.state = null;
        getDetailsByNodeId({
          modeId: nodeId,
        })
          .then((res) => {
            this.processName = res.processName;
            this.formConfig = res.formConfig;
            this.flowDetails.nodeId = nodeId;
            this.flowDetails.frontendNodeId = res.startFrontendNodeId;
            this.graphNodeId = nodeId;
            this.nodeNamePaths = res.nodeNamePaths.join("/");
            this.processBasicInfo = [
              this.nodeNamePaths,
              dateFormat(new Date(), "YYYY-MM-DD HH:mm:ss"),
              this.currentUserInfo.userName,
              this.currentUserInfo.deptNames,
            ];
            this.queryNodeParams = {
              definitionId: null, //"流程实例ID",
              nodeId: null, //"当前节点ID",
              modeId: nodeId, //"模型ID",
              versionId: versionId, //"流程版本ID",
              formData: res.formConfig, //"form表单数据",
            };
            // 如果有copyId说明是复制跳转过来的，需要查询提交节点保存的数据
            this.getCopyDatas(copyId);
          })
          .finally(() => {
            console.log("xxxxxxxxxxxxxxxxxxxxx-this.state", this.state);
            this.initLoading = false;
          });
      } else {
        // 审批
        this.state = "readOnly";
        const params = {
          definitionId,
        };
        if (taskId) {
          params.taskId = taskId;
        }
        getDetailsByDefinitionId(params)
          .then((res) => {
            const {
              workflowDefinitionVO,
              workflowFormDataVO,
              workflowTaskVO,
              currentProcessor,
              handleProcessor,
              nextProcessor,
              temporarilyVO,
              frontendNodeIdList,
              modeId,
            } = res;
            if (workflowTaskVO && workflowTaskVO.taskStatus === 1) {
              this.state = "process";
              // 控制操作选项的渲染
              this.buttonList = res.buttonList;
            }
            if (
              [0, 2, 3].includes(workflowDefinitionVO.processStatus) &&
              this.currentUserInfo.userId ===
                workflowDefinitionVO.processInitiator
            ) {
              this.state = "draft";
              this.nodeNamePaths = workflowDefinitionVO.processNamePath;
              this.draftVersionId = workflowDefinitionVO.versionId;
              this.diaplayDatas = temporarilyVO;
            }
            this.processName = workflowDefinitionVO.processName;
            this.processStatus = workflowDefinitionVO.processStatus;
            this.formConfig = workflowFormDataVO.formData;
            this.graphNodeId = modeId;
            this.flowDetails = {
              taskId,
              nodeId: res.nodeId,
              dataId: workflowFormDataVO.dataId,
              frontendNodeId: frontendNodeIdList[0] || null,
              createTime: workflowFormDataVO.creationDate,
              processInitiator: workflowDefinitionVO.processInitiator,
            };
            this.processBasicInfo = [
              workflowDefinitionVO.processNamePath,
              this.state === "draft"
                ? dateFormat(new Date(), "YYYY-MM-DD HH:mm:ss")
                : dateFormat(
                    workflowFormDataVO.creationDate,
                    "YYYY-MM-DD HH:mm:ss"
                  ),
              workflowDefinitionVO.processInitiatorName,
              workflowDefinitionVO.processDeptName,
            ];
            this.queryNodeParams = {
              definitionId: definitionId, //"流程实例ID",
              nodeId: res.nodeId, //"当前节点ID",
              modeId: modeId, //"模型ID",
              versionId: workflowDefinitionVO.versionId, //"流程版本ID",
              formData: workflowFormDataVO.formData, //"form表单数据",
            };
            this.processInfo = {
              currentProcessor,
              handleProcessor,
              nextProcessor,
            };
          })
          .finally(() => {
            console.log("xxxxxxxxxxxxxxxxxxxxx-this.state", this.state);
            this.initLoading = false;
          });
      }
    },
    async initDatas() {
      this.initLoading = true;
      this.currentUserInfo = await getUserAndDeptName();
      console.log("this.currentUserInfo", this.currentUserInfo);
      const { nodeId, definitionId, taskId, state, versionId, copyId } =
        this.$route.query;
      console.log("taskId", taskId);
      this.state = state;
      const params = {
        definitionId,
      };
      if (taskId !== "undefined") {
        params.taskId = taskId;
      }
      // 审批和阅读状态，通过definitionId & taskId初始化
      if (["process", "readOnly"].includes(state)) {
        getDetailsByDefinitionId(params)
          .then((res) => {
            const {
              workflowDefinitionVO,
              workflowFormDataVO,
              currentProcessor,
              handleProcessor,
              nextProcessor,
              frontendNodeIdList,
              modeId,
            } = res;
            // this.processName = workflowDefinitionVO.processName;
            // this.processStatus = workflowDefinitionVO.processStatus;
            // this.formConfig = workflowFormDataVO.formData;
            // this.flowDetails = {
            //   taskId,
            //   nodeId: res.nodeId,
            //   dataId: workflowFormDataVO.dataId,
            //   frontendNodeId: frontendNodeIdList[0] || null,
            //   createTime: workflowFormDataVO.creationDate,
            //   processInitiator: workflowDefinitionVO.processInitiator,
            // };
            // this.graphNodeId = modeId;
            // this.processInfo = {
            //   currentProcessor,
            //   handleProcessor,
            //   nextProcessor,
            // };
            // this.processBasicInfo = [
            //   workflowDefinitionVO.processNamePath,
            //   dateFormat(
            //     workflowFormDataVO.creationDate,
            //     "YYYY-MM-DD HH:mm:ss"
            //   ),
            //   workflowDefinitionVO.processInitiatorName,
            //   workflowDefinitionVO.processDeptName,
            // ];
            // this.queryNodeParams = {
            //   definitionId: definitionId, //"流程实例ID",
            //   nodeId: res.nodeId, //"当前节点ID",
            //   modeId: modeId, //"模型ID",
            //   versionId: workflowDefinitionVO.versionId, //"流程版本ID",
            //   formData: workflowFormDataVO.formData, //"form表单数据",
            // };
            // // 控制操作选项的渲染
            // this.buttonList = res.buttonList;
          })
          .finally(() => {
            this.initLoading = false;
          });
      } else if (state === "draft") {
        getDetailsByDefinitionId(params)
          .then((res) => {
            const {
              workflowDefinitionVO,
              workflowFormDataVO,
              frontendNodeIdList,
              temporarilyVO,
              modeId,
            } = res;
            // this.processName = workflowDefinitionVO.processName;
            // this.formConfig = workflowFormDataVO.formData;
            // this.flowDetails.nodeId = modeId;
            // this.flowDetails.frontendNodeId = frontendNodeIdList[0] || null;
            // this.flowDetails.processInitiator =
            //   workflowDefinitionVO.processInitiator;
            // this.processStatus = workflowDefinitionVO.processStatus;
            // this.graphNodeId = modeId;
            // this.nodeNamePaths = workflowDefinitionVO.processNamePath;
            // this.draftVersionId = workflowDefinitionVO.versionId;
            // this.processBasicInfo = [
            //   workflowDefinitionVO.processNamePath,
            //   dateFormat(new Date(), "YYYY-MM-DD HH:mm:ss"),
            //   workflowDefinitionVO.processInitiatorName,
            //   workflowDefinitionVO.processDeptName,
            // ];
            // this.queryNodeParams = {
            //   definitionId: definitionId, //"流程实例ID",
            //   nodeId: res.nodeId, //"当前节点ID",
            //   modeId: modeId, //"模型ID",
            //   versionId: workflowDefinitionVO.versionId, //"流程版本ID",
            //   formData: workflowFormDataVO.formData, //"form表单数据",
            // };
            // this.diaplayDatas = temporarilyVO;
          })
          .finally(() => {
            this.initLoading = false;
          });
      } else {
        // 新增通过nodeId初始化
        getDetailsByNodeId({
          modeId: nodeId,
        })
          .then((res) => {
            this.processName = res.processName;
            this.formConfig = res.formConfig;
            this.flowDetails.nodeId = nodeId;
            this.flowDetails.frontendNodeId = res.startFrontendNodeId;
            this.graphNodeId = nodeId;
            this.nodeNamePaths = res.nodeNamePaths.join("/");
            this.processBasicInfo = [
              this.nodeNamePaths,
              dateFormat(new Date(), "YYYY-MM-DD HH:mm:ss"),
              this.currentUserInfo.userName,
              this.currentUserInfo.deptNames,
            ];
            this.queryNodeParams = {
              definitionId: null, //"流程实例ID",
              nodeId: null, //"当前节点ID",
              modeId: nodeId, //"模型ID",
              versionId: versionId, //"流程版本ID",
              formData: res.formConfig, //"form表单数据",
            };
          })
          .finally(() => {
            this.initLoading = false;
            this.getCopyDatas(copyId);
          });
      }
    },
    getCopyDatas(copyId) {
      if (!copyId) return;
      copyDefinition({ definitionId: copyId }).then((res) => {
        const { formData, temporarilyVO } = res;
        this.diaplayDatas = temporarilyVO;
        this.formConfig = formData;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.process-detail-page {
  background: #f7f8fa;
  .process-header {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    border-bottom: 1px solid #e3e8ee;
    background: #ffffff;
    .logo-and-title {
      display: flex;
      align-items: center;
      .header-title {
        font-weight: 400;
        font-size: 16px;
        color: #252d3d;
        margin-left: 15px;
      }
    }
  }
  .process-detail-container {
    padding: 0 80px 55px;
    height: calc(100vh - 151px);
    overflow: auto;
    .card-container {
      margin: 20px 0;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #eaebed;
      padding: 30px 40px 10px 40px;
    }
    .process-name {
      margin: 40px 0 36px;
      font-weight: 600;
      font-size: 18px;
      color: #252d3d;
      text-align: center;
    }
    .form-container {
      margin-bottom: 20px;
    }
    .tabs-and-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tabs-line {
        padding: 0 10px;
        height: 40px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        border-radius: 10px;
        border: 1px solid #e9eff4;
        .single-tab {
          font-weight: 600;
          font-size: 14px;
          color: #5d687c;
          padding: 6px 12px;
          background: #ffffff;
          cursor: pointer;
        }
        .single-tab-active {
          color: #ffffff;

          background: #2a61ff;
          border-radius: 8px;
        }
      }
      .special-buttons {
        font-weight: 400;
        font-size: 14px;
        color: #2a61ff;
        display: flex;
        gap: 20px;
        .special-buton {
          cursor: pointer;
        }
      }
    }
  }
  .process-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 80px;
    background: #ffffff;
    box-shadow: 0px -2px 10px 0px rgba(129, 139, 152, 0.1);
    height: 80px;
  }
}
</style>
