<template>
  <div class="process-log-component">
    <ProcessTable
      v-loading="loading"
      :tableHeader="tableHeader"
      :tableData="tableData"
    />
  </div>
</template>

<script>
import ProcessTable from "./processTable";
import { getOperationLogByDefinitionId } from "@/api/ruge/workflow/detail";
import { dateFormat } from "@/filters/index.js";

export default {
  name: "process-log-component",
  components: {
    ProcessTable,
  },
  data() {
    return {
      loading: false,
      tableHeader: [
        {
          label: "节点名称",
          key: "nodeName",
          flex: 1,
        },
        {
          label: "操作者",
          key: "reviewerName",
          flex: 1,
        },
        {
          label: "接收时间",
          key: "taskStartTime",
          flex: 2,
        },
        {
          label: "操作时间",
          key: "taskEndTime",
          flex: 2,
        },
        {
          label: "操作状态",
          key: "systemOperate",
          flex: 2,
        },
      ],
      tableData: [],
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    initDatas() {
      this.loading = true;
      const { definitionId } = this.$route.query;
      getOperationLogByDefinitionId({
        definitionId,
      })
        .then((res) => {
          this.buildTableData(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildTableData(res) {
      this.tableData = [];
      res.forEach((item) => {
        let lineData = [];
        this.tableHeader.forEach((single) => {
          lineData.push({
            ...single,
            ...{
              value: ["taskStartTime", "taskEndTime"].includes(single.key)
                ? dateFormat(item[single.key], "YYYY-MM-DD HH:mm:ss")
                : item[single.key] || "-",
            },
          });
        });
        this.tableData.push(lineData);
      });
      console.log("this.tableData", this.tableData);
    },
  },
};
</script>

<style lang="less" scoped>
.process-log-component {
  margin-bottom: 20px;
}
</style>