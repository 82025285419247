<script>
import {mapGetters} from "vuex";

export default {
  name: 'Page',
  computed: {
    ...mapGetters(["sidebar"]),
  },
}
</script>

<template>
  <div class="page"   :style="{
      width: sidebar.opened ? 'calc(100vw - 330px)' : 'calc(100vw - 140px)',
    }">
    <slot></slot>
  </div>
</template>

<style scoped lang="less">
.page {
  display: flex;
  width: 100%;
  height: calc(100vh - 90px);
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0 20px 20px 40px;
  font-size: 14px;
  font-family: "PingFang SC", "Microsoft YaHei", sans-serif;
  overflow: auto;
  flex-direction: column;

  div {
    box-sizing: border-box;
  }
}
</style>