var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "props-component-unit" },
    [
      _c(
        "el-form-item",
        {
          class: ["single-form-item", `single-form-${_vm.dataset.key}`],
          attrs: { label: _vm.dataset.label, prop: _vm.dataset.key },
        },
        [
          _c(
            "div",
            { staticClass: "unit-line" },
            [
              _c(
                "el-select",
                {
                  staticClass: "unit-selector",
                  model: {
                    value: _vm.dataset.unitType,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataset, "unitType", $$v)
                    },
                    expression: "dataset.unitType",
                  },
                },
                _vm._l(_vm.unitList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { value: item.value, label: item.label },
                  })
                }),
                1
              ),
              _c("el-input", {
                model: {
                  value: _vm.dataset.unitString,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataset, "unitString", $$v)
                  },
                  expression: "dataset.unitString",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }