var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "props-component-layout" },
    [
      _c(
        "el-form-item",
        {
          class: ["single-form-item", `single-form-${_vm.dataset.key}`],
          attrs: { label: _vm.dataset.label, prop: _vm.dataset.key },
        },
        [
          _c("div", { staticClass: "layout-container" }, [
            _vm.showHalf
              ? _c(
                  "div",
                  {
                    class: [
                      "layout-item layout-half",
                      _vm.dataset.value === "half" && "layout-active",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.itemClickHandler("half")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "inner-item" }),
                    _c("div", { staticClass: "inner-item" }),
                    _vm.dataset.value === "half"
                      ? _c("img", {
                          staticClass: "active-corner",
                          attrs: {
                            src: require("@/assets/images/dynamicForm/layout-active-corner.png"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                class: [
                  "layout-item layout-all",
                  _vm.dataset.value === "all" && "layout-active",
                ],
                on: {
                  click: function ($event) {
                    return _vm.itemClickHandler("all")
                  },
                },
              },
              [
                _c("div", { staticClass: "inner-item" }),
                _vm.dataset.value === "all"
                  ? _c("img", {
                      staticClass: "active-corner",
                      attrs: {
                        src: require("@/assets/images/dynamicForm/layout-active-corner.png"),
                        alt: "",
                      },
                    })
                  : _vm._e(),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }