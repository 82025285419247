<template>
	<div class="flowHtml" :class="{lookHmtl:isLookBpmn}">
		<div class="header-line" v-if='!isCompent'>
			<div class="header-left" >
				<div class="back-div" @click="goBack">
					<img src="@/assets/images/go_back_icon.png" alt="" /><span>返回</span>
				</div>
				<div class="border-line"></div>
				<div class="name-div">流程配置 - {{publisModalName}}</div>
			</div>
			<div class="header-right">
				<!--<div class="look-json" >
					<img src="@/assets/images/dynamicForm/look_json.png" alt="" />
					<div class="label-line">查看json代码</div>
				</div>-->
				<!--<r-button size='small' @click="clearBpmnAll">清空</r-button>-->
				<!--<r-button size='small' @click="toDsetroyBpmn">销毁</r-button>-->
				<r-button size='small' @click="toFormSetHtml">表单配置</r-button>
				<r-button size='small' @click="tohandlePublish"  :disabled="enableStatus === 'DISABLE'">发布</r-button>
				<r-button plain @click="saveBpmn">保存</r-button>
			</div>
		</div>
		
		<mainBox class='Y-bpmn' :class='{"compBox":isCompent}' :style='difWidthHeight()'>
			<div class="lfBox">
				<div class="containers" ref="content">
					<div class="bpmnCanvas" ref="canvas" id="canvas"></div>
					<!-- 工具栏显示的地方 -->
					<!--<div id="js-properties-panel" class="panel" ></div>-->
					<div class="notMoveBox" v-if="bpmnNotMove" ></div>
				</div>
			</div>
			<div class="rtBox">
				<div class="disableBox" v-if="isLookBpmn"></div>
				<flowConfig v-if="bpmnModeler" ref='rtPanel' :modeler="bpmnModeler"  @saveWebData='saveBpmn'/>
			</div>
		</mainBox>
		
		
		 <flow-modal
	        width="600px"
	        title="发布"
	        :visible="publishModalConfig.visible"
	        :loading="btnLoading"
	        @onOk="onOkPublishModal"
	        @onCancel="onCancelPublishModal"
	      >
	        <publish-modal
	          v-if="publishModalConfig.visible"
	          ref="publishModalRef"
	          :path="publisNodeNamePaths"
	          :default-form="publishModalConfig.data.form"
	        />
	      </flow-modal>
		<div v-if="showNodeTip" ref='diyTooltip' class="diyTooltip" :style="difElementXy">
			<!--节点名称：{{toolMesTip.data.nodeName}}-->
			<div v-if="toolMesTip.data.reviewer&&toolMesTip.data.reviewer.length>0" >
				<div>节点处理人:</div>
				<div v-for="(_tx,dex) in toolMesTip.data.reviewer" :key='dex'>
					<div class="_peoMes">
						<span class="_round"></span>
						<span v-if="_tx.deptName&&_tx.userName">{{_tx.deptName}} - {{_tx.userName}}</span>
						<span v-else>{{_tx.userName || _tx.deptName}}</span>;
						
					</div>
				</div>
			</div>
			<div v-else> <i class="el-icon-loading" v-if="countloading" /><span v-else>暂无法计算处理人</span></div>
			
			
		</div>
		
	</div>

</template>

<script>
	import BpmnModeler from 'bpmn-js/lib/Modeler'
	import defaultXmlStr from './xmlStr.js'
	import customTranslate from './customTranslate.js';
	import TreeChart from "./treeChart.vue";
	const customTranslateModule = {// 自定义汉化模块
		translate: ['value', customTranslate],
	}
	import flowConfig from "./right-flowConfig.vue";// 右侧自定义属性面板
	import customModule from './customPalette';//自定义左侧节点
	import XmlToJson from './jsonXml.js';
	import userTaskImg from '../FlowBpmn/images/backg.png'
	import CustomContextPad from './context-pad'
	
	import FlowModal from "@/views/ruge/workflow/list/components/flow-modal.vue";//用于发布按钮 弹窗-罗鑫写的
	import PublishModal from "@/views/ruge/workflow/list/components/publish-modal.vue";//用于发布按钮 弹窗-罗鑫写的
	export default {
		props: {
			
			versionId:{
				type: String|Number,//根据versionId版本id+nodeId从后台获取数据 优先级最高 渲染流程图
				default: '',
			},
			bpmnId:{
				type: String|Number,//根据Id从后台获取数据 渲染流程图
				default: '',
			},
			allowEdit:{//是否允许编辑流程图  默认可以
				type:Boolean,
        		default:true,
			},
			bWidth:{//宽度
				type:String|Number,
        		default:'',
			},
			bHeight:{//高度
				type:String|Number,
        		default:'',
			},
			isCompent:{//是否组件级
				type:Boolean,
        		default:false,
			},
			definitionId:{//该ID 判断是否预览流程图审核动态  
				type:String|Number,
        		default:'',
//      		default:'fc1a3994cc834bceafb0b4c917ff5167',
			},
			versionIdSpecial:{// 与definitionId一样的作 用 2个ID都有的话都传
				type:String|Number,
        		default:'',
//      		default:'fc1a3994cc834bceafb0b4c917ff5167',
			},
			bpmnNotMove:{//是否允许拖动缩放bmpn
				type:Boolean,
        		default:false,
			},
			bpmnInitData:{
				//如果这个参数传了的话  流程图直接渲染这个数据
//					params={
//						formConfig:[], 
//						processName:'', 
//						nodeNamePaths:'', 
//						enableStatus:'DISABLE',
//						processConfig:'',
//					}
				type:Object|null,
				default:null,
			},
		},
		watch:{
			bpmnId:{
				deep: true,
				handler(n,o){
					console.log('--------xxxxxx----bpmnId-发生变化',n)
					this.initHtml();
				},
			},
			versionId:{
				deep: true,
				handler(n,o){
					this.initHtml();
					console.log('-------xxxxxx--versionId-发生变化',n)
				},
			},
			bpmnInitData:{
				deep: true,
				handler(n,o){
					console.log('-------xxxxxx--bpmnInitData-接收的数据',n)
					this.initHtml();
				},
			},
		},
		components: {flowConfig,TreeChart,FlowModal,PublishModal},
		data() {
			return {
				bpmnModeler: null,
				container: null,
				canvas: null,
				uploadBpmnFileList: [],
				scale: 1,
            	uuId: '',
				currentElement: null,
				bpmnXmlId:null,
				isLookBpmn:false,
				dfFormConfig:[],//通过id获取的初始表单配置
				httpAllNodes:[],//记录后台返回的节点数据
				showNodeTip:false,
				toolMesTip:{
					x:0,
					y:0,
					data:{}
				},
				
				//罗鑫 发布按钮相关start
				publisModalName:'',//发布弹窗里的审批流名称
				publisModalRemark:'',//发布弹窗里的备注
				publisNodeNamePaths:'',//发布弹窗右侧的位置 string
				publishModalConfig: {
			        visible: false,
			        /**
			         * Modal data containing:
			         * @property {string} path - Current path in workflow tree
			         * @property {object} form - object.name
			         * @property {string} nodeId - ID of node being published
			         * @property {string} nodeType - Type of node being published
			         */
			        data: {},
		      	},
		      	btnLoading:false,
		      	enableStatus:'DISABLE',
		      	//发布按钮相关end
		      	flowTrackDatas:[],//记录存储流程图审批动态数据，后续鼠标浮框要用
		      	countloading:false,
			}
		},
		created() {},
		computed: {
			difElementXy(){
//				console.log('------88888888-----',this.toolMesTip.x,this.toolMesTip.y)
				return {
					'position': 'absolute',
					'top': this.toolMesTip.y+'px',
					'left': this.toolMesTip.x+'px',
				}
				
			}
		},
		mounted() {
			this.$store.dispatch('getDeptslvAll');//获取级联部门数据
			this.$store.dispatch('getEmployeelvAll');//获取级联人员数据
			const {dataId,islook,nodeId} = this.$route.query;
			if(islook) this.isLookBpmn=true;
			if(nodeId) this.bpmnXmlId=nodeId
//			this.bpmnXmlId='007499371dcb46d492f316545810bc92';

			setTimeout(()=>{
				this.initHtml()
			},200)
		},
		methods: {/**初始化流程设计器对象*/
			difWidthHeight(){
				let diyCss={};
				if(this.bWidth) diyCss.width=this.bWidth+'px';
				if(this.bHeight) diyCss.height=this.bHeight+'px';
				return diyCss;
			},
			async initHtml() {
				this.isLookBpmn=!this.allowEdit;
				if(this.bpmnId) this.bpmnXmlId=String(this.bpmnId);//组件传值
				this.$nextTick(() => {
					this.initBpmn();
					this.initDataById();
					this.getBpmnStatuDatas();
				})
			},
			
			async initBpmn() {
				// 创建BpmnModeler
				let looOrEditBpmn={};
				if(this.isLookBpmn){
					looOrEditBpmn={
						labelEditingProvider: ["value", ''], //禁用节点编辑
	                 	contextPadProvider: ["value", ''], //禁用图形菜单
	                 	bendpoints: ["value", {}], //禁用连线拖动
						move: ['value', ''], //禁用单个图形拖动
						zoomScroll:["value", ''],//禁用滚动
						moveCanvas: ['value', ''],//禁用拖动整个流程图
					}
				}
				this.bpmnModeler = new BpmnModeler({
					container: this.$refs.canvas,
					bpmnRenderer:{
				        defaultStrokeColor:"#000",//线条 文字颜色
        				defaultFillColor:"#FFF"//图形填充颜色
				   	},
					propertiesPanel: {
						parent: '#js-properties-panel',// 加入工具栏支持
					},
					additionalModules: [
//						propertiesPanelModule,// 右边的工具栏 开启的时候 左侧工具栏必须要开启否则报错
//						propertiesProviderModule,// 左边工具栏以及节点
						customTranslateModule,// 汉化
						customModule,
						looOrEditBpmn,
					 	CustomContextPad,
					],
//					moddleExtensions: {
//					    //如果要在属性面板中维护camunda：XXX属性，则需要此 
//				    	camunda: camundaModdleDescriptor,
////				    	bpmn: bpmnModdleDescriptor,
//				  	},
				})
//				await this.createNewDiagram();
				
			},
			toDsetroyBpmn(){
				//销毁Bpmn 流程图
				const bpmnCanvas = this.bpmnModeler.get('canvas');
				bpmnCanvas._destroy()
			},
			updateDfConfig(val){
				if(val&&val.formConfigs){
					console.log('更新最新的',val);
					this.dfFormConfig=val.formConfigs;
				}
			},
			async getBpmnStatuDatas(){
				//获取图形状态的数据，查看哪个节点已审核
				console.log('ssss----xxxxxx',this.definitionId,this.versionIdSpecial)
				if(!this.definitionId&&!this.versionIdSpecial) return;
				let rqData={
					definitionId:this.definitionId,
					versionId:this.versionIdSpecial,
//					nodeId:this.bpmnXmlId,
//					definitionId:'9296e8cc03d94c1aa9187ca1ae7f3ee2',
				}
				const moniData={
				    "node": [
				    	{"nodeName": "起始","reviewer": [],"nodeStatus": 2,"nodeId": "Event_0hwrdaz"},
				    	{"nodeName": "组长审批", "reviewer": [],"nodeStatus": 2,"nodeId": "Activity_12rka6o"},
				        {"nodeName": "部门经理审批","reviewer": [],"nodeStatus": 2,"nodeId": "Activity_086btlv"},
				        {"nodeName": "总监审批","reviewer": [],"nodeStatus": 1, "nodeId": "Activity_0j0r09o"},
				        {"nodeName": "结束","reviewer": [],"nodeStatus": 0, "nodeId": "Event_022pu7q"},
				    ],
				    "line": [
				        {
				            "connectionStatus": 2,
				            "connectionId": "Flow_1gsb1hn"
				        },
				        {
				            "connectionStatus": 2,
				            "connectionId": "Flow_18qzb7f"
				        },
				        {
				            "connectionStatus": 2,
				            "connectionId": "Flow_00q8ypn"
				        },
				        {
				            "connectionStatus": 0,
				            "connectionId": "Flow_11derls",
				        }
				    ]
				}
				
				
				let res=await this.ApiHttp('/workflow/workflowNode/getWorkFlowTrack',rqData);
//				console.log('图形状态的数据',JSON.parse(JSON.stringify(res)));
				if(res){
					const elementRegistry = this.bpmnModeler.get('elementRegistry');
					
					const {node,line}=res;
					
//					console.log('elementRegistry',JSON.parse(JSON.stringify(this.flowTrackDatas)));
					node.map(it=>{
						it.webType='node';
						const colorByStatus=it.nodeStatus;
						if(it.nodeStatus>0){
//							console.log(888888888,it.nodeName,it.nodeStatus)
							let findEl=elementRegistry._elements[it.frontendNodeId];
							if(findEl){
								let elSetColor = findEl.element;//找到需要设置颜色的节点
								this.$refs.rtPanel.setNodeColorByStatus(elSetColor,it.nodeStatus);//将节点跟状态传入子组件进行颜色变换
							}
						}
					})
					line.map(it=>{
						it.webType='line';
						const colorByStatus=it.connectionStatus;
						if(colorByStatus>0){
							console.log(888888888,it.nodeName,it.nodeStatus)
							let findEl=elementRegistry._elements[it.lineId];
							if(findEl){
								let elSetColor = findEl.element;//找到需要设置颜色的节点
								this.$refs.rtPanel.setNodeColorByStatus(elSetColor,colorByStatus);//将节点跟状态传入子组件进行颜色变换
							}
						}
					})
					this.flowTrackDatas=[...node,...line];//记录存储流程图审批动态数据，后续鼠标浮框要用
//					console.log('666666666666666ssss,',JSON.parse(JSON.stringify(this.flowTrackDatas)))
//					this.currentElement = elementRegistry._elements[findId].element;
//					console.log('----it.node',findId,this.currentElement)
//					this.$refs.rtPanel.toNodeFromValidate(this.currentElement);//调用子组件中 对不同节点的表单校验方法
				
					
				}
				
				
			},
			async initDataById(){
				let rqData={
					modeId:this.bpmnXmlId,
				}
				if(this.isCompent) rqData.isloading=false;//如果是组件用的话  不要loading
				
				let res;
				if(this.bpmnInitData){
					res={...this.bpmnInitData};
				}else{
					let getDataUrl='/workflow/mode/preFindOne';
					if(this.versionId){
						getDataUrl='/workflow/mode/recordVersion';
						rqData.versionId=this.versionId;
					}
					res=await this.ApiHttp('/workflow/mode/preFindOne',rqData);
				}
				if(!res) return;
				this.dfFormConfig=res.formConfig||[];
				this.publisModalName=res.processName;
				this.publisNodeNamePaths=res.nodeNamePaths?res.nodeNamePaths.join('>'):'';
				if(res.enableStatus) this.enableStatus=res.enableStatus;
				console.log('后台返回数据',JSON.parse(JSON.stringify(res)));
				if(!res.processConfig){
					//若初次加载没有数据的话  走下清空
					this.clearBpmnAll();
					return;
				}
		      	const handData=JSON.parse(res.processConfig);
		      	const {flowCofigs,xmlDatas,nodes}=handData.webFlowDatas;
		      	this.httpAllNodes=JSON.parse(JSON.stringify(nodes));
		      	await this.createNewDiagram(xmlDatas);
      			console.log('+++++++++----initDetaiById',handData);
      			this.$refs.rtPanel.initHtmlCompent(handData.webFlowDatas);
			},
			async createNewDiagram(lcXml) {// 创建新流程
				// 加载xml字符串转换成图显示出来;
//				if(!lcXml) lcXml = localStorage.getItem('xmlDatas');
				let bpmn;
				if(lcXml){
					bpmn=lcXml;
				}else{
					bpmn=defaultXmlStr;
				}
				this.bpmnModeler.importXML(bpmn, err => {
					if(err) {
						this.$message.error('打开模型出错,请确认该模型符合Bpmn2.0规范')
					} else {
						console.log('成功导入模型')
//					 	bpmnModeler.getJSON({ format: true }, function(err, json) {});//无效方法 
					}
				})
				this.$nextTick(()=>{
//					this.bpmnModeler.get('canvas').zoom(0.1,'auto');
					setTimeout(()=>{
//						this.bpmnModeler.get('canvas').zoom(0.5,'auto');
						this.bpmnModeler.get('canvas').zoom("fit-viewport",'auto');//渲染成功的话 视图剧中
					},200)
					
					
				})
				this.initNodesEvents();
				
			},
			initNodesEvents(){
				const eventBus = this.bpmnModeler.get('eventBus');
//				console.log('eventBus~',eventBus);
				// 注册节点事件，eventTypes中可以写多个事件
				const eventTypes = ['element.click', 'element.hover','element.out'];
				let that=this;
				eventTypes.forEach((eventType) => {
				  eventBus.on(eventType, (e,val2) => {
				    const {element,gfx} = e;
				    if (!element.parent) return;
				    if (!e || element.type === 'bpmn:Process') {
				      return false;
				    } else {
				      if (eventType === 'element.click') {
				        // 节点点击后想要做的处理
				        // 此时想要点击节点后，拿到节点实例，通过外部输入更新节点名称
				        this.currentElement = element;
//				        console.log('鼠标d点击啦~',element);
//       	 			this.splitBusiness2Json(element.businessObject || element);
				      } else if (eventType === 'element.hover') {
//				      	console.log('~~~~~111~~~~~~~~',e,val2);
				        // 鼠标滑过节点后想要做的处理
				        if(this.isLookBpmn){
				        	//查看模式下  鼠标移动 显示浮窗
			        	 	const rectInfo= gfx.getBoundingClientRect();
//				    		console.log('~~~~222~~~~~~~~~',rectInfo);
				    		const {x,y,width}=rectInfo;
				        	const {id,type}=element;
				        	
				        	const notShowArr=['label','bpmn:StartEvent','bpmn:EndEvent'];
				        	const showArrs=['bpmn:UserTask','bpmn:ReceiveTask'];
//				        	if(notShowArr.indexOf(type)<0){//执行不允许的数组
			        		if(showArrs.indexOf(type)>-1){//执行允许的数组
//			        		 	console.log('鼠标经过节点啦~',x,y);
								const valKeyTypes={//通过节点类型取对应id所在的key 线的话  id就是lineId 
					        		'line':'lineId',
					        		'node':'frontendNodeId',
					        	}
								
								const [findNode]=that.flowTrackDatas.filter(it=>{
									let kId=valKeyTypes[it.webType];//如果是线的话  id就是lineId 
									return it[kId]==id
								})
								if(!findNode) return;
//								console.log('findNode',findNode,that.flowTrackDatas)
//								console.log('findNode,',JSON.parse(JSON.stringify(findNode)))
								if(findNode.webType=='line'&&!findNode.isExpreline){
									return;
								}
								if(!findNode.reviewer||findNode.reviewer.length<1){
									that.countloading=true;
									setTimeout(()=>{
										that.countloading=false;
									},2000)
									
								}
								that.toolMesTip.data={...findNode};
				        		that.toolMesTip.x=x+width/2;
//				        		that.toolMesTip.y=y;
				        		that.showNodeTip=true;
				        		if(findNode){
				        			this.$nextTick(()=>{
//				        				setTimeout(()=>{
					        				const tipRect=that.$refs.diyTooltip.getBoundingClientRect();
					        				that.toolMesTip.y=y-20-tipRect.height;
//					        				console.log('findNode~',findNode.nodeName,tipRect);
//					        			},100)
				        			})
				        		}
				        	}
				        }
				      }else if(eventType === 'element.out'){
				      	if(this.isLookBpmn){
				        	//查看模式下  鼠标移出 隐藏浮窗
//				        	console.log('鼠标离开节点啦~');
				        	that.showNodeTip=false;
				        }
				      }
				    }
				  });
				});
				
			},
			
			editNodeInfo(){
				//通过 modeling 的 updateProperties 方法可以修改节点属性，例如这里修改节点 name 为 ops-coffee.cn
//				const modeling = this.bpmnModeler.get("modeling");
//				const element =this.bpmnModeler.get("elementRegistry").get("Activity_lwlvj9r");
//				modeling.updateProperties(element，{name:"ops-coffee.cn"});
			},
			
			
			
			
        getExtensionElement(element, type) {
		  if (!element.extensionElements) {
		    return;
		  }
		  return element.extensionElements.values.filter((extensionElement) => {
		    return extensionElement.$instanceOf(type);
		  })[0];
		},
        splitBusiness2Json(businessObject) {
		  let formData = {};
		  let params = this.getExtensionElement(businessObject, 'bpmn:UserTask');
		  if (params && params.inputParameters) {
		    params.inputParameters.forEach((item) => {
		      let definition = item.definition;
		      if (definition) {
		        if (definition.$type === 'camunda:List') {
		          let arr = [];
		          definition.items.forEach((itemsItem) => {
		            arr.push(itemsItem.value);
		          });
		          formData[item.name] = arr;
		        } else if (definition.$type === 'camunda:Map') {
		          let obj = {};
		          if (definition.entries) {
		            definition.entries.forEach((entriesItem) => {
		              obj[entriesItem.key] = entriesItem.value;
		            });
		            formData[item.name] = obj;
		          }
		        }
		      } else {
		        formData[item.name] = item.value;
		      }
		    });
		  }
//		  console.log('formData.formData', formData);
		},
			

			clearBpmnAll(){
				let nullXml='<?xml version="1.0" encoding="UTF-8"?><definitions xmlns="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:flowable="http://flowable.org/bpmn" targetNamespace="http://flowable.org/bpmn"><process id="xxx" name="xxxx" isExecutable="true" /><bpmndi:BPMNDiagram id="BPMNDiagram_xxx"><bpmndi:BPMNPlane id="BPMNPlane_xxx" bpmnElement="xxx" /></bpmndi:BPMNDiagram></definitions>';
				this.bpmnModeler.importXML(nullXml, err => {})
				this.bpmnModeler.get('canvas').zoom("fit-viewport", "auto");//渲染成功的话 视图剧中
				
				this.$refs.rtPanel.initHtmlCompent();
			},
			beforeUpload(file) {
			},
			
			nowBpmnNodeToDatas(){
				//获取当前视图中所有节点并且转成数据
				return new Promise((resolve, reject) => {
					this.bpmnModeler.saveXML({ format: true }, function(err, xml) {
						const xotree = new XmlToJson.ObjTree();
			      		const jsonData = xotree.parseXML(xml);
				      	if(jsonData&&jsonData.definitions){
				      		const {process}=jsonData.definitions;
				      		const {startEvent,endEvent,sequenceFlow}=process;
				      		let nodeArrs=[];
				      		
				      		if(startEvent){
				      			let len1=startEvent.length;
				      			if(len1){
				      				return;
				      			}else{
				      				nodeArrs.push({...startEvent,nodeType:'startNode'});//添加开始节点
				      			}
				      			
				      		}
				      		let notAlow=['-id','-name','-isExecutable','startEvent','endEvent','sequenceFlow'];//需要过滤的数据
				      		for(let k in process){
				      			if(notAlow.indexOf(k)<0){//进行比对过滤
				      				let kvals=process[k];//通过key取对应的值
					      			if(kvals){
					      				let isMore=Array.isArray(kvals); //判断对应(如task)类型节点 是否为多个
						      			if(isMore){
						      				kvals.map(it=>{
						      					it.nodeType=k
						      				});
						      				nodeArrs=nodeArrs.concat(kvals);//如果是多个的话 直接相加
						      			}else{
						      				kvals.nodeType=k;
						      				nodeArrs.push(kvals);
						      			}
				      				}
				      			}
				      		}
				      		
				      		if(endEvent){
				      			let len1=endEvent.length;
				      			if(len1){
				      				return;
				      			}else{
				      				nodeArrs.push({...endEvent,nodeType:'endNode'});//添加结束节点
				      			}
				      			
				      		}
				      		//处理出后台需要的数据格式
				      		nodeArrs.map(it=>{//处理单一流程节点的数据
				      			it.name=it['-name'];
				      			it.nodeId=it['-id'];
				      		})
				      		if(Array.isArray(sequenceFlow)){
				      			sequenceFlow.map(it=>{
					      			it.lineId=it['-id'];
					      			it.fromNodeId=it['-sourceRef'];//起始节点ID 对应 sourceRef
					      			it.toNodeId=it['-targetRef'];//目标节点ID 对应 targetRef
					      		})
				      		}
				      		
				      		resolve({
				      			nodes:nodeArrs,//节点
				      			lines:sequenceFlow,//线
				      		});
				      	}
			      	})
				});
			},
			
			toFormSetHtml(){
				//点击跳转表单配置页面
				let params = {
			        path: "/workflow/detail",
			        query: {
			          	layout: 'hide',
			          	nodeId:this.bpmnXmlId,
			        },
		      	};
		      	this.$router.push(params);
			},
			//发布按钮相关start
			tohandlePublish(){
				this.publishModalConfig={
			        visible: true,
			        data: {
			        	form:{
			        		name:this.publisModalName,
			        	},
			        	path:null,
			        	nodeId:this.bpmnXmlId,
			        	nodeType:'mode',
			        }
		      	};
//		      	console.log('666666666666666ssss,',JSON.parse(JSON.stringify(this.publishModalConfig)))
			},
			async onOkPublishModal(){
				//发布弹窗确认按钮事件
				const data = await this.$refs.publishModalRef.onOk();
				if(!data) return;
				const {name,remark}=data;
				this.publisModalRemark=remark||'';
				this.publishModalConfig.data.form.name=name;
				this.saveBpmn('publish')
			},
			onCancelPublishModal() {
				//发布弹窗关闭取消
		      	this.publishModalConfig = {
			        visible: false,
			        data: {},
		      	};
		    },
		    //发布按钮相关end
		    
			async saveBpmn(saveType) {
//			 	this.getEleNodesTypes()
				this.bpmnNodesSetColor();//为了确保之前点过
			 	const that=this;
				this.bpmnModeler.saveXML({ format: true }, function(err, xml) {
			       	//无效方法1
//			       	const BpmnModdle = require('bpmn-js/lib/Modeler');
//					const xmlJsonModdle = new BpmnModdle();
//					xmlJsonModdle.fromXML(xml, 'definitions.json', (err, definitions, context) => {
//					  if (err) {
//					    console.error('解析错误:', err);
//					  } else {
//					    // 转换为JSON
//					    const json = definitions.$model.toJSON(definitions);
//					    console.log('JSON:', json);
//					  }
//					});
			       
			      	const xotree = new XmlToJson.ObjTree()
		      		const jsonData = xotree.parseXML(xml) 
			      	console.log('jsonData',jsonData)
			      	if(jsonData&&jsonData.definitions){
			      		//task任务节点  userTask审核节点 startEvent开始节点 sequenceFlow连接线
			      		const {process}=jsonData.definitions;
			      		// sequenceFlow 中  -sourceRef=线起点id -targetRef线终点id 
			      		//节点类型有[ 任务节点task， userTask,数据dataStoreReferenc,文件dataObjectReference,subProcess]
			      		//网关类型有5种  [X互斥eventBasedGateway,O相容inclusiveGateway，+并行parallelGateway,*复杂网关complexGateway，事件网关eventBasedGateway]
			      		//任务类型有  [任务节点Task,用户审核userask，手工manualTask，接收receiveTask，sendTask发送，业务规范businessRuleTask，serviceTask服务，scriptTask脚本，callActivity调用，子流程subProcess]
			      		//开始事件类型（格式startEvent.types）在有[messageEventDefinition消息开始，timerEventDefinition定时开始，conditionalEventDefinition条件开始，signalEventDefinition信号开始]
			      		//中间事件类[intermediateCatchEvent消息，timerEventDefinition定时，intermediateCatchEvent条件，intermediateCatchEvent信号]
			      		const {startEvent,endEvent,sequenceFlow}=process;
			      		let nodeArrs=[];
			      		if(!startEvent){
			      			that.$message.warning('开始节点不能为空！');
			      			return;
			      		}
			      		if(!endEvent){
			      			that.$message.warning('结束节点不能为空！');
			      			return;
			      		}
			      		if(startEvent&&endEvent){
			      			let len1=startEvent.length;
			      			let len2=endEvent.length;
			      			if(len1||len2){
			      				that.$message.warning('存在多个开始或结束节点！');
			      				return;
			      			}else{
			      				nodeArrs.push({...startEvent,nodeType:'startNode'});//添加开始节点
			      			}
			      			
			      		}else{
			      			that.$message.warning('起始结束节点不可为空！');
			      			return;
			      		}
			      		localStorage.setItem('xmlDatas',xml.toString());
			      		let notAlow=['-id','-name','-isExecutable','startEvent','endEvent','sequenceFlow'];//需要过滤的数据
			      		for(let k in process){
			      			if(notAlow.indexOf(k)<0){//进行比对过滤
			      				let kvals=process[k];//通过key取对应的值
				      			if(kvals){
				      				let isMore=Array.isArray(kvals); //判断对应(如task)类型节点 是否为多个
					      			if(isMore){
					      				kvals.map(it=>{
					      					it.nodeType=k
					      				});
					      				nodeArrs=nodeArrs.concat(kvals);//如果是多个的话 直接相加
					      			}else{
					      				kvals.nodeType=k;
					      				nodeArrs.push(kvals);
					      			}
//				      				console.log('isMore',JSON.parse(JSON.stringify(kvals)))
			      				}
			      			}
			      		}
			      		
			      		let NodeTypeObject={
			      			startNode:'开始节点',
			      			endNode:'结束节点',
			      			userTask:'审核节点',
//							inclusiveGateway:'相容网关',
							exclusiveGateway:'并行网关',
							intermediateCatchEvent:'定时节点',
							receiveTask:'抄送',
							exclusiveGateway:'条件网关',
			      		}
			      		nodeArrs.push({...endEvent,nodeType:'endNode'});//添加结束节点
			      		
			      		//处理出后台需要的数据格式
			      		let reqData={
							"nodeType": "mode",
							"modeVO":{
								'modeStatus':'DRAFT',
								"modeId":that.bpmnXmlId,// 流程模型唯一ID，新增无需此参数
								"processConfig": "", // 流程配置JSON字符串
            					'publishDescription': '',
            					'formConfig':that.dfFormConfig,//记录全局的表单配置
							}
						}
			      		let flowCofigs=that.$refs.rtPanel.flowCofigs;
			      		sequenceFlow.map(it=>{
			      			let itlineInfo={};
			      			if(it['-webDatas']){
			      				itlineInfo=JSON.parse(it['-webDatas']);
//			      				console.log('---------itlineInfo --',itlineInfo);
			      			}
			      			it.lineId=it['-id'];
			      			it.nodeName=it['-name'];
			      			it.nodeCode=itlineInfo.nodeCode?itlineInfo.nodeCode:null;
			      			it.fromNodeId=it['-sourceRef'];//起始节点ID 对应 sourceRef
			      			it.toNodeId=it['-targetRef'];//目标节点ID 对应 targetRef
			      			
			      			const {logic,conditions}=itlineInfo;//对条件网关的出线做单独处理
			      			if(logic&&conditions){
			      				let gxObj={
			      					logic:logic,
			      					conditions:conditions,
			      				}
			      				it.lineRelation=JSON.stringify(gxObj) //后台需要的又是字符串，转一下
			      			}
//			      			console.log('---------itlineInfo --',it);
								      			
			      		})
			      		let isAllow=true;//是否允许提交到后台
			      		nodeArrs.map(it=>{//处理单一流程节点的数据
			      			let itWebObj={};
			      			if(it['-webDatas']){
			      				itWebObj=JSON.parse(it['-webDatas']);
//			      				console.log('-----------',backendObj.nodeName,itWebObj)
			      				if(itWebObj.webDatas){
			      					const nodeInfo=JSON.parse(itWebObj.webDatas)
//			      					console.log('nodeInfo',nodeInfo)
			      					itWebObj.listenConfigs=nodeInfo.listenConfigs;

			      				}
			      			}
//			      			console.log('--xxxxxx---',JSON.parse(JSON.stringify(it)))
			      			let backendObj={//后台需要的对象格式，数据重组
								"nodeId" : it['-id'], // 节点ID
								"nodeName": it['-name'], // 节点名称
								"nodeCode": itWebObj.nodeCode?itWebObj.nodeCode:'', // 节点编码
								"nodeType": it.nodeType?it.nodeType:'', // 节点类型 （开始节点、结束节点、条件网关、并行网关、定时节点、审批节点）
								"nodeComment": itWebObj.description?itWebObj.description:null, // 节点备注
								"listen": itWebObj.listenConfigs||[], // 节点监听配置
			      			}
			      			
			      			if(it.nodeType=='startNode'){
//			      				backendObj.nodeComment=itWebObj.description;
//			      				backendObj.processInitiator=itWebObj.processInitiator;
			      			}
			      			if(it.nodeType=='receiveTask'){
			      				//抄送节点特有
			      				backendObj.copyNode=itWebObj.copyItems||[];
			      			}
			      			if(it.nodeType=='task'||it.nodeType=='userTask'){	// 
			      				// 审批节点特有
//			      				console.log('--xxxxxx receiveTask---',JSON.parse(JSON.stringify(itWebObj)))
								backendObj.auditNode= {
									// 基本配置
									"base": {
										"dueOpen": itWebObj.dueOpen||'N', 
										"dueType": itWebObj.dueType, // 到期时间类型（PH:小时，PD:天，PW:周，PM: 月）
										"dueTime": itWebObj.dueTime, // 到期时间值
										"notifyMethod": itWebObj.noticeMethod?itWebObj.noticeMethod.join(','):null, // 通知方式（0:待办、1：消息、2：短信、3邮件)
//										"operate": "", // 操作（通知：NOTIFY、转派：REASSIGN）
//										"transferPersonType": "", // 转派人类型（PEOPLE：指定人、DEPT:部门、POST:职位、LEADER:上下级）
//										"transferPerson": "", // 转派人标识（人员 id、部门 id、职位 id）
									},
									// 会签设置
									"countersign": {
										"agreeInstanceType": itWebObj.agreeInfo?itWebObj.agreeInfo.selectype:'', // 同意实例类型（0：百分比，1：数值）
										"agreeInstanceVal": itWebObj.agreeInfo?itWebObj.agreeInfo.nums:'', // 同意实例值
										"rejectInstanceType": itWebObj.rejectInfo?itWebObj.rejectInfo.selectype:'', // 同意实例类型（0：百分比，1：数值）
										"rejectInstanceCount": itWebObj.rejectInfo?itWebObj.rejectInfo.nums:'', // 拒绝实例值
									},
									// 表单字段配置JSON字符串
									"formConfig": itWebObj.keyWordDatas?JSON.stringify(itWebObj.keyWordDatas):'',
									// 审核人设置
									"auditor": {
										"handlingWhenEmpty": itWebObj.handlingWhenEmpty||'AUTO_PASS', // 处理人为空时（AUTO_PASS:自动通过（默认）、AUTO_REJECT:自动拒绝、ASSIGNER:指定处理人）
										"handlingUserId": itWebObj.handlingUserId?itWebObj.handlingUserId:null, //指定处理人用户ID
										'auditors':itWebObj.auditorItems||[],
									}
								}
								
								if(!itWebObj.handlingWhenEmpty) isAllow=false;
								
			      			}
			      			console.log('-------------------it.nodeType',it.nodeType)
			      			if(it.nodeType=='intermediateCatchEvent'){	// 定时节点特有
								backendObj.timer= {
									"timerType": itWebObj.timerType, // 定时方式(DATE:指定日期、DURATION:等待时长、EXPRESSION:表达式)
//									"specifiedDate": "", // 指定日期
									"waitingDuration": itWebObj.waitingDuration, // 等待时长(分钟)
									"expression": itWebObj.expression, // 表达式
									"nodeComment":itWebObj.nodeComment,//备注
								}
			      			}
			      			
			      			for(let keys in backendObj){
			      				//将转换的值赋给节点上去
			      				it[keys]=backendObj[keys]
			      			}
			      		})
			      		
//			      		if(!isAllow){
//			      			that.$message('审核人设置有误。')
//			      			return;
//			      		}
			      		let webFlowDatas={
			      			flowCofigs,
			      			nodes:nodeArrs,
			      			lines:sequenceFlow,
			      			'xmlDatas':xml.toString(),
			      		}
			      		console.log('webFlowDatas',JSON.parse(JSON.stringify(webFlowDatas)))
			      		let processConfig={//流程图 节点配置
			      			webFlowDatas:webFlowDatas,//前端数据，渲染用  后台不能改变 要原封不动返回
			      			nodes:nodeArrs,//节点-
			      			lines:sequenceFlow,//线
			      		}
			      		const {basics,listen}=flowCofigs;
			      		if(basics){//流程基本设置
			      			reqData.modeVO={
		      					...basics,
		      					...reqData.modeVO,
		      				}
//			      			if(basics.dueOpen=='Y'){
//			      				reqData.modeVO={
//			      					...basics,
//			      					...reqData.modeVO,
//			      				}
//			      			}
			      		}
			      		if(listen&&listen.webDatas){//流程监听设置
			      			const {listenConfigs}=JSON.parse(listen.webDatas);
			      			if(listenConfigs){
//			      				reqData.modeVO.listen=listenConfigs;
								processConfig.globalListen=listenConfigs;
			      			}
			      		}
			      		reqData.modeVO.processConfig=JSON.stringify(processConfig);
			      		if(reqData.modeVO.notifyMethod) reqData.modeVO.notifyMethod=reqData.modeVO.notifyMethod.join(',');
//			      		localStorage.setItem('flowDatas',JSON.stringify(webFlowDatas));
			      		
//			      		console.log('reqData',JSON.parse(JSON.stringify(reqData)))
			      		if(saveType&&saveType=='publish'){
			      			//发布的时候也调用该方法
			      			reqData.modeVO.modeStatus="PUBLISH"; // 模型状态 发布/草稿（PUBLISH/DRAFT） 节点类型mode专用
			      			reqData.modeVO.publishDescription=that.publisModalRemark;
			      			reqData.modeVO.processName=that.publishModalConfig.data.form.name;
			      		}
			      		that.sendHttpDatas(reqData);
			      		console.log('reqData',JSON.parse(JSON.stringify(reqData)))
//			      		let allowHttp=that.validatorAllNodes(nodeArrs,sequenceFlow);//校验所有节点的比填字段
//			      		if(allowHttp){
//			      			that.sendHttpDatas(reqData);
//			      		}
//			      		that.ApiHttp('/workflow/mode/saveNode', reqData,'POST').then(res=>{
//			      			console.log('reqData',res)
//			      			if(res){
//			      				that.$message.success('操作成功。');
//			      			}
//			      			
//			      		});
			      		
//			      		console.log('webDatas',JSON.stringify(webDatas))
			      	}
//			      	let josnXml=JsonToBpmnXml(jsonData);//该方法实验过也无效
//			      	console.log('JsonToBpmnXml',JSON.parse(JSON.stringify(josnXml)))
			     
			      })
			},
      goBack(){
        const query = this.$route.query
        this.$router.push({
          path: '/workflow/list',
          query: { folderNodeId: query.folderNodeId }
        })
      },
			validatorAllNodes(Arrs,lines=[]){
				let isAllow=true;
				let findId='';
				let tipStr='';
				let toNodesLine=[];
				lines.map(it=>toNodesLine.push(it.toNodeId));//将所有的线目标节点记录起来。
				for(let itNode of Arrs){
					const {nodeType,nodeId,outgoing}=itNode;
					let itWebObj={};
	      			if(itNode['-webDatas']){
	      				itWebObj=JSON.parse(itNode['-webDatas']);
	      				if(itWebObj.webDatas){
	      					const nodeInfo=JSON.parse(itWebObj.webDatas)
	      					itWebObj.listenConfigs=nodeInfo.listenConfigs;
	      				}
	      			}
					findId=nodeId;
					console.log('----it.node------',itNode,itWebObj)
					if(!itNode.nodeCode){
						isAllow=false;
						tipStr='节点编码不能为空。';
					}
					if(!itNode.nodeName){
						isAllow=false;
						tipStr='节点名称不能为空。';
					}
					
					let findTodex=toNodesLine.indexOf(nodeId);
					if(findTodex<0&&nodeType!=='startNode'){//没有入线
						console.log('----没有链接线的节点------',itNode,toNodesLine)
						isAllow=false;
						tipStr='检查节点连接入线。';
					}
					if(!outgoing&&!nodeType=='endNode'){//没有出线
						isAllow=false;
						tipStr='检查节点连接出线。';
					}
					if(!isAllow){
						this.$message.warning(tipStr);
						break;
					}
					
//					if(nodeType=='startNode'){
//						if(!itNode.processInitiator){
//							isAllow=false;
//							break;
//						}
//					}
				}
				if(!isAllow){
					//校验不通过的时候
					const elementRegistry = this.bpmnModeler.get('elementRegistry');
					this.currentElement = elementRegistry._elements[findId].element;
					console.log('----it.node',findId,this.currentElement)
					this.$refs.rtPanel.toNodeFromValidate(this.currentElement);//调用子组件中 对不同节点的表单校验方法
				}
				console.log('----isAllow',findId,isAllow)
				return isAllow;
			},
			
			sendHttpDatas(reqData){
				const { modeVO}=reqData;
				let params={...reqData};
				let httpUrl='/workflow/mode/saveNode';
				if(modeVO&&modeVO.modeStatus=='PUBLISH'){
					this.btnLoading=true;
					httpUrl='/workflow/mode/publishMode';
					params=reqData.modeVO;
					this.ApiHttp(httpUrl, params,'POST').then(res=>{
		      			console.log('reqData',res)
		      			if(res){
		      				this.btnLoading=false;
		      				const {formVerify,processVerify}=res;
		      				if(formVerify&&formVerify.length>0){
		      					let formTip='';
		      					formVerify.map(it=>{
		      						formTip+=it.errorInfo+';';
		      					})
		      					if(formTip) this.$message.warning(formTip);
		      					return;
		      				}
		      				if(!processVerify){
		      					this.$message.success('操作成功。');
		      					this.onCancelPublishModal();
		      					return;
		      				}
		      				let tipMes='';
		      				if(processVerify&&processVerify.length>0){
		      					processVerify.map(it=>{
		      						tipMes+=it.errorInfo+';';
		      					})
		      				}
		      				if(tipMes) this.$message.warning(tipMes);
		      				this.bpmnNodesSetColor(processVerify);
		      				this.onCancelPublishModal();
		      			}
		      			
		      		});
		      		
				}else{
					this.ApiHttp(httpUrl, params,'POST').then(res=>{
		      			if(res){
		      				this.$message.success('保存成功。');
		      				this.onCancelPublishModal();
		      			}
		      			
		      		});
					
				}
			},
			bpmnNodesSetColor(idArrs=[]){
//				const idArrs=[{nodeId:'Activity_0rsk4er'},{nodeId:'Activity_1o0ksaw'}];
				let findeIds=[];//整理出需要去查找匹配的节点id
				idArrs.map(it=>findeIds.push(it.nodeCode));
				const elementRegistry = this.bpmnModeler.get('elementRegistry');//获取到所有的节点
				const {_elements}=elementRegistry;
				for(let keys in _elements){
					const nodeEl=_elements[keys].element;
					const {type}=nodeEl;//节点类型
					if(type=='label'||type=='bpmn:Process'){
					}else{
						
						let colorType='default';
						if(findeIds.indexOf(keys)>-1){
							colorType='errTip';
						}
						
//						console.log('--------type',colorType,keys,findeIds.indexOf(keys))
						this.$refs.rtPanel.setNodeColorByStatus(nodeEl,colorType);
					}
				}
				console.log('bpmnNodesSetColor',elementRegistry)
//				for(let it of idArrs){
//					let findEl=elementRegistry._elements[it.nodeId];
//					if(findEl){
//						let elSetColor = findEl.element;//找到需要设置颜色的节点
//						this.$refs.rtPanel.setNodeColorByStatus(elSetColor,'errTip');//将节点跟状态传入子组件进行颜色变换  子组件中 
//					}
//					
//					
//				}
				
				
			},
			getEleNodesTypes(){
				//获取流程图内所有指定类型的节点
				const elementRegistry = this.bpmnModeler.get('elementRegistry');
				console.log('所有的节点ele',elementRegistry)
				console.log('所有的节点',JSON.parse(JSON.stringify(elementRegistry)))
			},
		},
		beforeDestroy() {
		    this.toDsetroyBpmn();
	  	},
	}
</script>

<style lang="scss" scoped>

.flowHtml{
	.header-line {
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #e3e8ee;
		background: #ffffff;
		padding: 0 40px;
		.header-left {
			display: flex;
			align-items: cneter;
			.back-div {
				cursor: pointer;
				display: flex;
				align-items: center;
				font-weight: 600;
				font-size: 18px;
				color: #2a61ff;
				img {
					margin-right: 4px;
					position: relative;
					top: -1px;
				}
				span {
					font-weight: 600;
				}
			}
			.border-line {
				width: 1px;
				height: 17px;
				background: #cbdbe9;
				margin: 0 10px;
			}
			.name-div {
				font-weight: 600;
				font-size: 18px;
				color: #252d3d;
			}
		}
		.header-right {
			display: flex;
			align-items: center;
			.look-json {
				display: flex;
				flex-direction: column;
				align-items: center;
				cursor: pointer;
				margin-right: 32px;
				.label-line {
					font-weight: 500;
					font-size: 12px;
					color: #252d3d;
					margin-top: 6px;
				}
				img {
					width: 18px;
					height: 18px;
				}
			}
		}
	}
}

.Y-bpmn{
	// 左边工具栏以及编辑节点的样式
	@import '~bpmn-js/dist/assets/diagram-js.css';
	@import '~bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
	@import '~bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css';
	@import '~bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
	padding: 0px;
	:deep(.m-body){
		padding: 0 !important;
	}
	.lfBox,.rtBox{
		height: 100%;
		display: inline-block;
	    vertical-align: top;
	}
	.lfBox{
		position: relative;
		width: calc(100% - 283px);
		.notMoveBox{
			position: absolute;
			top: 0px;
			width: 100%;
			height: 100%;
			left: 0;
			background: #E3E8EE;
			opacity: 0.2;
			z-index: 999;
		    cursor: no-drop;
		}
	}
	.rtBox{
		padding: 0;
		width: 280px;
		position: relative;
		.disableBox{
			position: absolute;
			top: 50px;
			width: 100%;
			height:calc(100% - 50px);
			background: #E3E8EE;
			opacity: 0.2;
			z-index: 999;
		    cursor: no-drop;

		}
		
	}
	.containers {
		background-color: #red;
		width: 100%;
		height: 100%;
		background: url('../FlowBpmn/images/backg.png') no-repeat;
		background-size: 100% 100%;
	}
	.bpmnCanvas {
		width: 100%;
		height: 100%;
	}
	.panel {
		position: absolute;
		right: 0;
		top: 0;
		width: 300px;
	}
}
.Y-bpmn.compBox{
	.lfBox{
		width: 100%;
	}
	.rtBox{
		display: none;
	}
}
.diyTooltip{
	padding: 12px;
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	//折线图点标记
	width: 160px;
	line-height: 24px;
	text-align: left;
	color: #152C5B;
	font-weight: bold;
	border-radius: 4px;
	font-size: 12px;
	padding: 6px 12px;
	background-color: white;
	margin-left: -80px;
	/*margin-top: -48px;*/
	box-shadow: 1px 1px 8px 1px #CCCCCC;
	._round{
		width: 8px;
		margin-right: 6px;
		display: inline-block;
		height: 8px;
		border-radius: 50%;
		background: #2a61ff;
	}
}
</style>
<style lang="scss">
	.lookHmtl{
		/*查看模式下。隐藏可操作部分*/
		.djs-palette-entries{
			/*左侧节点栏*/
			display: none;
		}
		.djs-context-pad{
			display: none;
		}
		.header-right{
			/*上右侧操作按钮*/
			display: none !important;
		}
	}
	.Y-bpmn {
		height: calc(100vh - 70px);
		.djs-palette.open{
			width: 48px !important;
			background: white;
			border-radius: 8px;
			border: none;
			transform: scale(1.2);
		    position: absolute;
		    top: 80px;
		    left: 38px;
			.separator{
				/*display: none;*/
				border-bottom:1px solid #eee;
				margin-bottom: 10px;
			}
			/*左侧节点栏 调节可控制换行*/
		}
		.bjs-powered-by{
			display: none;
			/*隐藏bpmn logo*/
		}
		.bpmn-icon-start-event-none,.bpmn-icon-end-event-none,.bpmn-icon-space-tool{
			display: none !important;
		}
		/*.bpmn-icon-hand-tool:before{
			content:'-' !important;
			background-image: url('./images/start.png') !important;
		}*/
		.bpmn-icon-startNode{
			background-image: url('./images/start.png') !important;
		}
		.bpmn-icon-endNode{
			background-image: url('./images/end.png') !important;
		}
		.bpmn-icon-cake {
		  background-image: url('./images/wg.svg') !important;
		}
		.bpmn-icon-userTask{
		 	 background-image: url('./images/utask.png') !important;
		}
		.bpmn-icon-copy{
		 	background-image: url('./images/ucopy.png') !important;
		}
		.bpmn-icon-tjwg{
		 	background-image: url('./images/tjwg.png') !important;
		}
		.bpmn-icon-bxwg{
		 	background-image: url('./images/bxwg.png') !important;
		}
		.bpmn-icon-timing{
		 	background-image: url('./images/timing2.png') !important;
		}
		.icon-custom {
		  cursor: move;
		  background-size: 70%;
	      margin-bottom: 16px;
		  background-repeat: no-repeat;
		  background-position: center center;
		}
		.djs-context-pad {
			.bpmn-icon-text-annotation,.bpmn-icon-end-event-none,.bpmn-icon-intermediate-event-none{
				display: none !important;
			}
			.bpmn-icon-task{
				/*隐藏普通task*/
				display: none;
			}
		}
		.djs-popup-header{
			display: none;
		}
		.djs-popup-body{
			.bpmn-icon-gateway-or{
				/*相容网关隐藏*/
				display: none;
			}
			.bpmn-icon-gateway-complex{
				/*隐藏复杂网关*/
				display: none;
			}
			.bpmn-icon-gateway-eventbased{
				/*隐藏事件网关*/
				display: none;
			}
			.bpmn-icon-task,.bpmn-icon-send,.bpmn-icon-manual,.bpmn-icon-business-rule,.bpmn-icon-service,.bpmn-icon-script,
			.bpmn-icon-call-activity,.bpmn-icon-subprocess-collapsed,.bpmn-icon-subprocess-expanded
			{
				display: none;
			}
			
			.bpmn-icon-start-event-none,.bpmn-icon-end-event-none,.bpmn-icon-start-event-message,.bpmn-icon-start-event-timer,
			.bpmn-icon-start-event-condition,.bpmn-icon-start-event-signal{
				display: none !important;
			}
		}
		.djs-palette-entries{
			
			.bpmn-icon-data-object{
				/*文件图标*/
				display: none !important;
			}
			.bpmn-icon-task{
				/*普通任务节点*/
				display: none !important;
			}
			.bpmn-icon-data-store{
				/*数据圆柱 数据存储引用*/
				display: none !important;
			}
			.bpmn-icon-participant{
				/*创建池/参与者*/
				display: none !important;
			}
			.bpmn-icon-group{
				/*创建组*/
				display: none !important;
			}
			.bpmn-icon-subprocess-expanded{
				display: none !important;
			}
			.bpmn-icon-intermediate-event-none{
				/*创建中间/边界事件*/
				display: none !important;
			}
			.entry.bpmn-icon-gateway-none{
				display: none !important;
			}
		}
		.el-form-item__label{
			color:#252D3D ;
		}
		.djs-shape.selected{
			background: red;
		}
		.el-checkbox__input.is-checked+.el-checkbox__label,.el-radio__input.is-checked+.el-radio__label{
			    color: #2a61ff;
		}
		.el-input__inner,.el-checkbox__inner,.el-radio__inner{
			background: #F7F8FA;
		}
		.el-input__inner{
			border: none;
		}
		.el-dialog__body{
			.el-input__inner{
				background: #FFFFFF;
				border: 1px solid #D1D5DD;
			}
			
		}
		.el-checkbox__input.is-checked .el-checkbox__inner, 
		.el-checkbox__input.is-indeterminate .el-checkbox__inner,
		.el-radio__input.is-checked .el-radio__inner,
		.el-switch.is-checked .el-switch__core{
			background-color: #2a61ff;
   		 	border-color: #2a61ff;
		}
	}
	
	/*.djs-connection {
	    stroke: yellow !important; 
	    stroke-width: 2px !important;
  	}*/
</style>