var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "workflow-dynamic-form" },
    [
      _c(
        "el-form",
        {
          ref: "dynamicFormIns",
          attrs: {
            model: _vm.dynamicForm,
            rules: _vm.dynamicFormRules,
            "label-width": "120px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.activeItemList, function (item) {
              return _c(
                "el-col",
                { key: item.id, attrs: { span: _vm.colCalc(item) } },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: _vm.labelRender(item, "code", null),
                      refInFor: true,
                      staticClass: "single-item",
                      attrs: {
                        label: _vm.labelRender(item, "label", "未命名"),
                        prop: _vm.labelRender(item, "code", null),
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "component-container" },
                        [
                          _vm.editState === "flowConfig" &&
                          item.itemRenderMap &&
                          item.itemRenderMap[_vm.flowNodeId] &&
                          item.itemRenderMap[_vm.flowNodeId] === 3
                            ? _c("div", { staticClass: "mask-container" })
                            : _vm._e(),
                          _c(_vm.propsCompRequire(item.key), {
                            key: item.id,
                            tag: "component",
                            attrs: {
                              dataset: item,
                              disabled: _vm.disableRender(item),
                            },
                            on: { update: _vm.updateHandler },
                          }),
                        ],
                        1
                      ),
                      _vm.editState === "flowConfig"
                        ? _c(
                            "el-radio-group",
                            {
                              model: {
                                value: item.itemRenderMap[_vm.flowNodeId],
                                callback: function ($$v) {
                                  _vm.$set(
                                    item.itemRenderMap,
                                    _vm.flowNodeId,
                                    $$v
                                  )
                                },
                                expression: "item.itemRenderMap[flowNodeId]",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("可见不可编辑"),
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("可编辑"),
                              ]),
                              _c("el-radio", { attrs: { label: 3 } }, [
                                _vm._v("不可见"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }