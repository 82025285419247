<template>
  <div class="dynamic-textarea-component">
    <el-input
      type="textarea"
      v-model="value"
      :disabled="disabled"
      :rows="3"
      :placeholder="placeHolder"
      :maxlength="maxlength"
      show-word-limit
      @input="updateHandler"
    ></el-input>
  </div>
</template>

<script>
import { labelRender, updateSource } from "../../../detail/utils.js";

export default {
  name: "dynamic-textarea-component",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        this.value = labelRender(datas, "default", null);
        this.placeHolder = labelRender(datas, "placeHolder", "请输入");
        this.maxlength = labelRender(datas, "maxLength", 20);
        this.code = labelRender(datas, "code", null);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      value: null,
      placeHolder: "请输入",
      maxlength: 20,
      code: null,
    };
  },
  methods: {
    updateHandler() {
      updateSource(this.dataset, 'default', this.value)
      this.$emit("update", {
        key: this.code,
        value: this.value,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic-textarea-component {
}
</style>