var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rtConfig" }, [
    _c(
      "div",
      { staticClass: "tabs_warp" },
      _vm._l(_vm.tabsArr, function (it, dex) {
        return !it.hide
          ? _c(
              "div",
              {
                key: dex,
                staticClass: "it_tap",
                class: { actived: _vm.activeTabs == it.val },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "it_n",
                    on: {
                      click: function ($event) {
                        return _vm.setTabChange(it)
                      },
                    },
                  },
                  [
                    _vm._v(_vm._s(it.name) + " "),
                    _c("div", { staticClass: "xbtm" }),
                  ]
                ),
              ]
            )
          : _vm._e()
      }),
      0
    ),
    _c("div", { ref: "propertyPanel", staticClass: "formWarp" }, [
      !_vm.isClickNode
        ? _c("div", [
            _vm.activeTabs == 1
              ? _c(
                  "div",
                  [
                    _c("flowWhole", {
                      attrs: { model: _vm.flowCofigs.basics },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("listenSet", {
                      attrs: { formInfo: _vm.flowCofigs.listen },
                      on: { upvals: _vm.fromValsChange },
                    }),
                  ],
                  1
                ),
          ])
        : _vm._e(),
      _vm.isClickNode
        ? _c("div", { class: _vm.getNodeYype }, [
            _vm.activeTabs == 1
              ? _c(
                  "div",
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          model: _vm.webForms,
                          "label-width": "0",
                          size: "small",
                          "label-position": "left",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-right": "0" },
                            attrs: { label: "" },
                          },
                          [
                            _c("div", { staticClass: "itFormlabel" }, [
                              _vm._v(" \n\t\t\t\t\t\t\t节点名称"),
                            ]),
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: { maxlength: _vm.nameMaxNum },
                              on: { input: _vm.nameChange },
                              model: {
                                value: _vm.webForms.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.webForms, "name", $$v)
                                },
                                expression: "webForms.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { key: _vm.webForms.id },
                      [
                        _c(_vm.formName, {
                          ref: "classNodes",
                          tag: "component",
                          attrs: { formInfo: _vm.webForms },
                          on: { upvals: _vm.fromValsChange },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.activeTabs == 2
              ? _c(
                  "div",
                  [
                    _c("listenSet", {
                      attrs: { formInfo: _vm.webForms },
                      on: { upvals: _vm.fromValsChange },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.activeTabs == 3
              ? _c(
                  "div",
                  [
                    _c("userTaskExamine", {
                      attrs: { formInfo: _vm.webForms },
                      on: { upvals: _vm.fromValsChange },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.activeTabs == 4
              ? _c(
                  "div",
                  [
                    _c("lineExpression", {
                      attrs: { formInfo: _vm.webForms },
                      on: { upvals: _vm.fromValsChange },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }