<template>
  <div class="graph-component-radio single-graph-component">
    <div :class="['item-label', datas.require && 'item-label-required']">
      {{ datas.label }}
    </div>
    <el-radio-group
      style="pointer-events: none"
      :class="[datas.direction === 'column' && 'radio-group-column']"
      v-model="datas.value"
    >
      <el-radio
        v-for="item in datas.optionList"
        :key="item.value"
        :label="item.value"
        >{{ item.label }}</el-radio
      >
    </el-radio-group>
  </div>
</template>
<script>
import { labelRender } from "../utils.js";
import request from "@/utils/request";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "graph-component-radio",
  props: {
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(item) {
        this.datas.label = labelRender(item, "label", "未命名");
        this.datas.direction = labelRender(item, "direction", "row");
        this.datas.require = labelRender(item, "require", false);
        this.optionsRender(item);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      labelRender,
      datas: {
        label: null,
        value: null,
        direction: "row",
        require: false,
        optionList: [],
      },
    };
  },
  methods: {
    optionsRender(item) {
      this.optionList = [];
      const { propsList } = item;
      const optionsItem = propsList.filter((item) => item.key === "options")[0];
      // static | inner | classify
      const { type, optionList, serviceUrl, valueKey, labelKey, classifyCode } =
        optionsItem.config;
      if (type === "static") {
        this.datas.optionList = optionList;
        this.datas.value = optionList.filter(
          (single) => single.selected
        )[0].value;
        console.log("this.datas.value", this.datas.value);
      } else if (type === "inner" && serviceUrl && valueKey && labelKey) {
        request({
          url: serviceUrl,
          method: "get",
        }).then((res) => {
          this.datas.optionList = res.map((singleItem, singleIndex) => {
            if (singleIndex === 0) {
              this.datas.value = singleItem[valueKey];
            }
            return {
              value: singleItem[valueKey],
              label: singleItem[labelKey],
            };
          });
          console.log("this.optionList", this.optionList);
        });
      } else if (type === "classify" && classifyCode) {
        request({
          url:
            envInfo.bgApp.lookupPath +
            `/tenant/lookup/item/find/list?classifyCode=${classifyCode}`,
          method: "get",
        }).then((res) => {
          this.datas.optionList = res.map((singleItem, singleIndex) => {
            if (singleIndex === 0) {
              this.datas.value = singleItem["itemCode"];
            }
            return {
              value: singleItem["itemCode"],
              label: singleItem["itemName"],
            };
          });
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.graph-component-radio {
  .radio-group-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
}
</style>