import axios from 'axios';
import { Message, Loading } from 'element-ui';
import router from '@/router';
import { debounce } from 'lodash';

// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 120000, // 请求超时时间
  // 跨域携带cookie
  withCredentials: true,
});
let loading;
const loadingOptions = {
  //遮罩层设置
  fullscreen: true,
  background: 'rgba(0,0,0,0)',
  text: '拼命加载中...',
  // spinner:"el-icon-loading"
};
// 请求拦截器 - 处理参数前后空格
service.interceptors.request.use(
  (config) => {
    // 如果是POST或PUT请求，且data是对象
    if (config.method === 'post' || config.method === 'put') {
      if (config.data) {
        // 对所有参数进行trim操作
        for (let key in config.data) {
          if (typeof config.data[key] === 'string') {
            config.data[key] = config.data[key].trim();
          }
        }
      }
    }
    // 如果是GET请求，且params是对象
    if (config.method === 'get' && config.params) {
      // 对所有参数进行trim操作
      for (let key in config.params) {
        if (typeof config.params[key] === 'string') {
          // config.params[key] = encodeURIComponent(config.params[key].trim());
          config.params[key] = config.params[key].trim();
        }
      }
    }
    return config;
  },
  (error) => {
    // 处理请求错误
    console.log(error); // 为了看到错误，在控制台输出错误
    return Promise.reject(error);
  }
);
service.interceptors.request.use(
  (config) => {
    if (config.isloading) {
      loading = Loading.service(loadingOptions);
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// response 拦截器
service.interceptors.response.use(
  (response) => {
    if (loading) loading.close();
    let res = response.data;
    if ((res == null || res === '') && response.status == 200) {
      res = true;
    }
    return res;
  },
  (error) => {
    console.log('service.interceptors.response error', error)
    if (loading) {
      loading.close();
      if (axios.isCancel(error)) {
        return  new Promise(() => {});
      }
    }
    if (axios.isCancel(error)) {
      return  new Promise(() => {});
    }
    const response = error.response;
    if (!response) {
      //调不通，返回null
      return;
    }
    const res = response.data;
    // 401:未登录;
    if (response.status === 401) {
      const { path, fullPath } = router.currentRoute;
      // 如果当前不在登录页面，则跳转登录页面
      if (path !== '/login') {
        // 跳转登录页面
        router.replace({
          path: '/login',
          query: { redirect: location.href }, //登录成功后跳入浏览的当前页面
        });
        localStorage.removeItem('application:ctn');
      }
    } else if (response.status === 403) {
      // 403:无权限访问
      //router.replace({path: '/403'})
      // res.errorMsg || response.status
      // Message({
      //   message: '操作被禁止，如有需要，请联系管理员',
      //   type: 'warning',
      //   duration: 2 * 1000,
      // });
      warningHanlder(res, response, '操作被禁止，如有需要，请联系管理员');
    } else if (response.status === 405) {
      // 405:密码到期，需要强制修改密码;
      router.replace({ path: '/' });
      Message({
        message: res.errorMsg || response.status,
        type: 'warning',
        duration: 2 * 1000,
      });
    } else if (response.status === 404 || response.status === 503) {
      // router.replace({path: '/404'})
      // Message({
      //     message: "对不起，系统部分服务维护中，请稍后重试" || response.status,
      //     type: 'warning',
      //     duration: 5 * 1000
      // })
    } else if (response.status === 500 || response.status === 518) {
      // 服务端异常
      //router.replace({path: '/error'})
      errorHandler(res, response);
    } else if (response.status === 417) {
      //登录发生异常
      Message({
        message: '登录异常',
        type: 'error',
        duration: 5 * 1000,
      });
    }
    return Promise.reject(error);
  }
);
var warningHanlder = debounce((res, response, message) => {
  Message({
    message: message || res.errorMsg || response.status,
    type: 'warning',
    duration: 5 * 1000,
  });
}, 1000);
var errorHandler = debounce((res, response) => {
  Message({
    message: res.errorMsg || response.status,
    type: 'error',
    duration: 5 * 1000,
  });
}, 1000);
export default service;
