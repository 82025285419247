var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "take-back-person",
    },
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: { multiple: "", placeholder: "请选择收回人" },
          on: { change: _vm.updateHandler },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        _vm._l(_vm.userList, function (item) {
          return _c("el-option", {
            key: item.userId,
            attrs: { value: item.userId, label: item.userName },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }