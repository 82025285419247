import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 *  分页查询
 * @param params
 */
export function getRulesList(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/warehouse/getWarehousePageList',
    method: 'get',
    params,
  });
}


