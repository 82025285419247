var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "graph-component-imgUploader single-graph-component" },
    [
      _c(
        "div",
        { class: ["item-label", _vm.datas.require && "item-label-required"] },
        [_vm._v("\n    " + _vm._s(_vm.datas.label) + "\n  ")]
      ),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "uploader-example" }, [
      _c("i", { staticClass: "el-icon-plus" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }