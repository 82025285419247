<script>
import { mapGetters } from "vuex";
import Pagination from "@/components/Pagination/index.vue";
import { processTableTabs } from "../constant";
import ProcessTable from "../components/process-table.vue";
import CloseTransformFormModal from "@/views/ruge/approvalProcess/components/close-transform-form-modal.vue";
import FlowModal from "@/views/ruge/workflow/list/components/flow-modal.vue";

import {
  apiGetQueryAdminWorkflowList,
  apiPostCloseOrder,
  apiPostTransferOrder,
} from "@/api/ruge/workflow/approvalProcess";
import { omit } from "lodash";
import { format } from "date-fns";
import { getMessageError } from "../../workflow/list/utils";
const tabList = processTableTabs.filter((item) =>
  ["all", "preTrial", "end"].includes(item.key)
);
export default {
  name: "Management",
  components: {
    Pagination,
    ProcessTable,
    CloseTransformFormModal,
    FlowModal,
  },
  data() {
    return {
      loading: false,
      tabList: tabList,
      closeDialogConfig: {
        visible: false,
        /**
         * definitionId
         */
        data: {},
      },
      transformDDialogConfig: {
        visible: false,
        /**
         * definitionId
         */
        data: {},
      },
    };
  },
  methods: {
    /**
     * 关单
     */
    async onOkCloseDialog() {
      const formValues = this.$refs.closeFormModalRef.onOk();
      const params = {
        definitionId: this.closeDialogConfig.data.definitionId,
        urgencyLevel: formValues.status,
        auditComment: formValues.auditComment,
      };
      try {
        this.loading = true;
        const res = await apiPostCloseOrder(params);
        this.loading = false;
        if (res.code !== 200) {
          this.$message.error("关单失败");
          return;
        }
        this.onCancelCloseModal();
        this.$message.success("关单成功");
        this.$refs.processTableRef.refresh();
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    },
    onCancelCloseModal() {
      this.closeDialogConfig = {
        visible: false,
        data: {},
      };
    },
    openCloseDialog(row) {
      this.closeDialogConfig = {
        visible: true,
        data: {
          definitionId: row.definitionId,
        },
      };
    },
    /**
     * 转办
     */
    openTransformDialog(row) {
      this.transformDDialogConfig = {
        visible: true,
        data: {
          definitionId: row.definitionId,
        },
      };
    },
    async onOkTransformDialog() {
      let formValues = {};
      try {
        formValues = this.$refs.transformFormModalRef.onOk();
      } catch (error) {
        this.$message.error(getMessageError(error));
        return;
      }
      const params = {
        definitionId: this.transformDDialogConfig.data.definitionId,
        taskId: formValues.transferredNode,
        recipient: formValues.transferredPersonnel,
        isEndNotify: formValues.isEndNotify,
        urgencyLevel: formValues.status,
        auditComment: formValues.auditComment,
      };
      try {
        this.loading = true;
        const res = await apiPostTransferOrder(params);
        this.loading = false;
        if (res.code !== 200) {
          this.$message.error("转办失败");
          return;
        }
        this.onCancelTransformModal();
        this.$message.success("转办成功");
        this.$refs.processTableRef.refresh();
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    onCancelTransformModal() {
      this.transformDDialogConfig = {
        visible: false,
        data: {},
      };
    },
    fetchList: async (query, cancelToken) => {
      try {
        const params = omit(query, ["queryType"]);
        const res = await apiGetQueryAdminWorkflowList(params, {
          cancelToken,
        });
        const data = res.rows.map((item) => {
          return {
            ...item,
            modeName: item.modeName,
            processInitiatorName: item.processInitiatorName,
            startTime: item.startTime
              ? format(new Date(item.startTime), "yyyy-MM-dd HH:mm:ss")
              : "-",
            endTime: item.endTime
              ? format(new Date(item.endTime), "yyyy-MM-dd HH:mm:ss")
              : "-",
          };
        });
        return {
          success: true,
          total: res.total,
          data,
        };
      } catch (error) {
        return {
          success: false,
          total: 0,
          data: [],
        };
      }
    },
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
};
</script>

<template>
  <div
    class="page"
    :style="{
      width: sidebar.opened ? 'calc(100vw - 330px)' : 'calc(100vw - 140px)',
    }"
  >
    <ProcessTable
      class="table-area"
      ref="processTableRef"
      queryType="management"
      :tabList="tabList"
      :fetchList="fetchList"
    >
      <template v-slot:operate="scope">
        <el-button
          v-if="[1].includes(scope.row.processStatus)"
          type="text"
          class="view-btn"
          @click="openTransformDialog(scope.row)"
          >转办</el-button
        >
        <el-button
          v-if="[1, 2, 3].includes(scope.row.processStatus)"
          type="text"
          class="view-btn danger-btn"
          @click="openCloseDialog(scope.row)"
          >关单</el-button
        >
      </template>
    </ProcessTable>
    <FlowModal
      width="600px"
      title="关单"
      :visible="closeDialogConfig.visible"
      @onOk="onOkCloseDialog"
      :loading="loading"
      @onCancel="onCancelCloseModal"
    >
      <CloseTransformFormModal
        v-if="closeDialogConfig.visible"
        ref="closeFormModalRef"
        :definitionId="closeDialogConfig.data.definitionId"
      />
    </FlowModal>
    <FlowModal
      width="600px"
      title="转办"
      :visible="transformDDialogConfig.visible"
      @onOk="onOkTransformDialog"
      @onCancel="onCancelTransformModal"
      :loading="loading"
    >
      <CloseTransformFormModal
        v-if="transformDDialogConfig.visible"
        ref="transformFormModalRef"
        :definitionId="transformDDialogConfig.data.definitionId"
        :show-transferred-field="true"
      />
    </FlowModal>
  </div>
</template>

<style scoped lang="less">
.page {
  display: flex;
  width: 100%;
  height: calc(100vh - 90px);
  box-sizing: border-box;
  //padding: 20px;
  border-radius: 10px;
  margin: 10px 40px 20px;
  font-size: 14px;
  font-family: "PingFang SC", "Microsoft YaHei", sans-serif;
  overflow: auto;
  flex-direction: column;

  .table-area {
    padding-top: 9px;
    margin-top: 0 !important;
  }
  div {
    box-sizing: border-box;
  }

  .view-btn {
    color: #2a61ff;
  }
  .danger-btn {
    color: #f35555;
  }
}
</style>
