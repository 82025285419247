<!--
 * @LastEditors: luo
 * @LastEditTime: 2024-11-29 14:23:17
 * @Description: 
-->
<template>
  <div v-loading="loading">
    <flow-tree
      :default-expanded-keys="expandedKeys"
      class="warp"
      :data="treeData"
      :show-checkbox="true"
      :checked-keys="checkedKeys"
      @checkbox-change="checkboxChange"
      @toggle-expanded="toggleExpanded"
      labelTextClass="label-text-custom"
    >
    </flow-tree>
  </div>
</template>
<script>
import { apiGetPreNodeTree } from "../../../../../api/ruge/workflow/workflow";
import { arrayToTree, formatTreeData, getTreeData, removeNode } from "../utils";
import FlowTree from "./flow-tree.vue";
import warningIcon from "@/assets/images/workflow/warning.svg";
export default {
  name: "move-file-modal",
  components: {
    FlowTree,
  },
  props: {
    nodeId: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.fetchTreeData();
  },
  data() {
    return {
      expandedKeys: [],
      warningIcon,
      loading: true,
      checkedKeys: [],
      treeData: [],
    };
  },
  methods: {
    toggleExpanded(node, data) {
      console.log("data", data, node);
      if (node.expanded) {
        this.expandedKeys = this.expandedKeys.filter(
          (item) => item !== node.id
        );
      } else {
        this.expandedKeys = [...this.expandedKeys, node.id];
      }
    },
    fetchTreeData() {
      this.loading = true;
      apiGetPreNodeTree()
        .then((res) => {
          const data = res.filter((item) => item.nodeType !== "mode");
          this.treeData = formatTreeData(arrayToTree(getTreeData(data)));
          this.expandedKeys = this.treeData.map((item) => item.id);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkboxChange(data) {
      this.checkedKeys = [data.currentKey];
    },
    onOk() {
      if (!this.checkedKeys.length) {
        this.$message.error("请选择文件夹");
        return;
      }
      return this.checkedKeys;
    },
  },
  expose: ["onOk"],
};
</script>
<style lang="less" scoped>
.warp {
  padding: 20px 18px;
  border-radius: 10px;
  background: #fcfcfc;
  border: 1px solid #e4e7eb;
  height: 480px;
  box-sizing: border-box;
  overflow-x: auto;
}

.tree-node {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  padding-right: 62px;
}

.checkbox {
  height: 14px;
}

::v-deep {
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #2a61ff;
    border-color: #2a61ff;
  }
  .label-text-custom {
    width: 320px !important;
  }
}
</style>
