<template>
  <div class="dynamic-editor-component">
    <div style="border: 1px solid #ccc">
      <!-- 工具栏 -->
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        ref="toolBar"
      />
      <!-- 编辑器 -->
      <Editor
        style="height: 200px; overflow-y: hidden"
        :defaultConfig="editorConfig"
        v-model="editorValue"
        @onCreated="onCreated"
        @change="updateHandler"
      />
    </div>
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { labelRender, updateSource } from "../../../detail/utils.js";

export default {
  name: "dynamic-editor-component",
  components: { Editor, Toolbar },
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    disabled: {
      handler(flag) {
        this.setEditState(!flag);
      },
      immediate: true,
    },
    dataset: {
      handler(datas) {
        this.editorValue = labelRender(datas, "default", null);
        this.editorConfig.placeholder = labelRender(
          datas,
          "placeHolder",
          "请输入"
        );
        this.code = labelRender(datas, "code", null);
        this.updateHandler();
      },
      deep: true,
      immediate: true,
    },
    editorValue: {
      handler() {
        this.updateHandler();
      },
      immediate: true,
    },
  },
  data() {
    return {
      editor: null,
      editorValue: null,
      toolbarConfig: {
        excludeKeys: [
          /* 隐藏哪些菜单 */ "fullScreen",
          "group-image",
          "group-video",
          "group-indent",
          "emotion",
          "insertLink",
          "insertTable",
          "codeBlock",
          "lineHeight",
          "fontFamily",
          "divider",
          "blockquote",
          "color",
          "bgColor",
          "todo",
        ],
        MENU_CONF: {},
      },
      editorConfig: {
        placeholder: "请输入",
        MENU_CONF: {},
      },
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
      // this.setEditState(false);
    },
    setEditState(flag) {
      console.log("xxx", flag);
      if (!this.editor) return;
      if (flag) {
        this.editor.enable();
      } else {
        this.editor.disable();
      }
    },
    /**
     * 判断editor富文本域是否为空
     * str返回的值为"" 代表输入框里面有值 成功
     * str返回！="" 代表里面有空格 回车 失败
     * */
    getWangEditorText(str) {
      return str
        .replace(/<[^<p>]+>/g, "") // 将所有<p>标签 replace ''
        .replace(/<[</p>$]+>/g, "") // 将所有</p>标签 replace ''
        .replace(/&nbsp;/gi, "") // 将所有 空格 replace ''
        .replace(/<[^<br/>]+>/g, ""); // 将所有 换行符 replace ''
    },
    updateHandler() {
      updateSource(
        this.dataset,
        "default",
        this.getWangEditorText(this.editorValue)
      );
      this.$emit("update", {
        key: this.code,
        value: this.getWangEditorText(this.editorValue),
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic-editor-component {
}
</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>