var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dynamic-timePicker-component" },
    [
      _c("el-time-picker", {
        staticStyle: { width: "100%" },
        attrs: {
          disabled: _vm.disabled,
          "is-range": _vm.isRange,
          editable: _vm.editable,
          clearable: _vm.clearable,
          "range-separator": "-",
          "value-format": "HH:mm:ss",
          "start-placeholder": _vm.startPlaceHolder,
          "end-placeholder": _vm.endPlaceHolder,
          placeholder: _vm.placeHolder2,
        },
        on: { change: _vm.updateHandler },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }