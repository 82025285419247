<template>
	<!--结束节点配置项-->
	<div>
		<TForm ref="refFrom1" lableRow  :autoCheck='true' :model="htmlDatas" :formlist="formlist" :rowGutter='16' labelPosition='left'>
			<template slot="setFormkeys" slot-scope="scope">
				<!--hellow  自定义内容块-->
				<el-button type="primary" size='mini' @click='openFormKeyWords'>前往设置</el-button>
			</template>
		</TForm>
		<fromWordsSet v-if='openWordsSet' :isOpen='openWordsSet' :dataId='formInfo.id' :httpConfigs='httpConfigs' :nodeArrs='bpmnNodes' :datas='htmlDatas.keyWordDatas' @close='closeDialog'  />
	
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	import fromWordsSet from './fromWordsSet.vue';//表单字段设置弹窗
	export default {
		components: { TForm,fromWordsSet },
		props: {
			type: {
				type: String,
				default: '1',
			},
			formInfo:{
				type: Object,
				default:()=>{
					return {}
				}
			},
		},
		watch:{
			htmlDatas: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$nextTick(()=>{
						this.$emit('upvals',this.htmlDatas)
						
					})
					
				}
			},
			
		},
		computed: {
			httpConfigs() {
                return this.$parent.getDefaultFormDatas();
            },
		},
		data() {
			return {
				htmlDatas: {},
				formlist: [
//					{name: '节点名称',keys: 'nodeName',value: '',type: 'input',flex:24,isMust:true},
					{name: '节点key',keys: 'nodeCode',value: '',type: 'input',flex:24,isMust:true},
					{name: '表单字段设置',keys: 'keyWordDatas',value:{
	  					checkedNode:'',
	  					isDefault:false,
	  				},type:'slot',slotName:"setFormkeys"},
//	  				{name: '抄送对象',keys: 'positionId',value: null,type:'slot',slotName:"copyTarget",flex:12,isMust:true},
				],
//				httpConfigs:'',
				openWordsSet:false,
				bpmnNodes:[],
			};
		},
		created() {
//			this.httpConfigs=this.$parent.getDefaultFormDatas();
			console.log('接收.formInfo',JSON.parse(JSON.stringify(this.formInfo)))
			this.resetTFrom();
		},
		mounted() {},
		methods: {
			async openFormKeyWords(){
				let res =await this.$parent.allowGetAllNodes();
				if(res){
					this.bpmnNodes=res.nodes;
					this.openWordsSet=true;
				}
			},
			closeDialog(val){
				this.openWordsSet=false;
				if(val){
					this.htmlDatas.keyWordDatas=val;
					
					this.$parent.updateFormConfig(val);//更新全局最新的from
				}
			},
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
	  				let fval=this.formInfo[item.keys]
	  				if(fval) item.value=fval;
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
		}
		
	};
</script>

<style scoped>
	
</style>