<template>
	<!--审批节点- 审核人设置  新增审核人处理对象弹窗-->
	<div class="Y-tips">
		<el-dialog class='diyModel' title="添加处理对象" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="30%" :before-close="closeMypup">
			<div :key='resetNum'>
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
					
				</TForm>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 确定</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import pinyin from "js-pinyin";
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		props: {
			dataId: {
				type: String|Number,
				default: '',
			},
			datas: {
				type: Object,
				default: ()=>{
					return {}
				},
			},
		},
		components: {TForm},
		data() {
			return {
				htmlDatas:{},
	  			isHttping:false,
	  			formlist:[
	  				
	  				{name: '审批类型',keys: 'reviewerType',value:'',type: 'select',notClear:true,isMust:true,options:[
	  				 // 审核人类型（PEOPLE：指定人、DEPT:部门、POST:职位、LEADER:部门主管）
//	  					{name:'请选择',code:''},
	  					{name:'指定人',code:'PEOPLE'},
	  					{name:'指定部门负责人',code:'DEPT'},
	  					{name:'指定岗位负责人',code:'POST'},
	  					{name:'发起人部门负责人',code:'LEADER'},
	  					{name:'流程发起人',code:'INITIATORS'},
	  				],changMethod:this.changeSetName},
	  				{name: '指定人',keys: 'toPerson',value: '',type: 'employeAll',notClear:true,options:[],isHide:true,changMethod:this.changeSetName},
//	  				{name: '指定人',keys: 'toPerson',value:'',type: 'select',notClear:true,options:[],isHide:true,changMethod:this.changeSetName},
	  				{name: '指定部门负责人',keys: 'toDept',value:'',type: 'lvAllDepts',notClear:true,options:[],isHide:true,changMethod:this.changeSetName},
	  				{name: '指定岗位负责人',keys: 'setDuty',value:'',type: 'select',notClear:true,options:[],isHide:true,changMethod:this.changeSetName},
	  			],
	  			resetNum:1,
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$nextTick(() => {
						this.$refs.htmlFrom.resetFields();
					})
				}
			},
			'htmlDatas.reviewerType': {
				deep: true, // 深度监听
				handler(val, oval) {
					this.hideOrShowItems();
					
				}
			},
			
		},
		created() {
		},
		mounted() {
			this.$nextTick(()=>{
				this.initPersomList();
				this.initDeptList();
			})
			
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			hideOrShowItems(){
				//通过定时方式判断当前列是否显示
				let keyArr1=['toPerson'],keyArr2=['toDept'];
				let showIts=[],hideIts=[];
				
				//指定人 toPerson  抄送部门toDept 选择岗位setDuty
				if(this.htmlDatas.reviewerType=='PEOPLE'){
					//选择指定人的时候 隐藏部门+岗位
					showIts=['toPerson'];
					hideIts=['toDept','setDuty'];
				}
				
				if(this.htmlDatas.reviewerType=='DEPT'){
					//选择部门的时候 隐藏指定人+岗位
					showIts=['toDept'];
					hideIts=['toPerson','setDuty'];
				}
				
				if(this.htmlDatas.reviewerType=='POST'){
					//选择岗位的时候 隐藏指定人
					showIts=['toDept','setDuty'];
					hideIts=['toPerson'];
				}
				
				if(this.htmlDatas.reviewerType=='LEADER'){
					//选择上下级的时候 隐藏指定人+部门+岗位
					showIts=[];
					hideIts=['toDept','toPerson','setDuty'];
				}
				if(this.htmlDatas.reviewerType=='INITIATORS'){
					//选择流程发起人的时候 隐藏指定人+部门+岗位
					showIts=[];
					hideIts=['toDept','toPerson','setDuty'];
				}
				
				
				for(let it of this.formlist){
					if(hideIts.indexOf(it.keys)>-1){
						it.isHide=true;
						it.isMust=false;
						this.htmlDatas[it.keys]='';//清空已选的值
					}
					if(showIts.indexOf(it.keys)>-1){
						it.isMust=true;
						it.isHide=false;
					}
				}
			},
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
	  		changeSetName(val,it){
				//因为select选择的时候前端需要用到对应的name属性
				let _key=it.keys+'Str';
				let _val;
				it.options.map(its=>{
					if(val==its.code) _val=its.name;
				})
				
				if(it.keys=='toPerson'||it.keys=='toDept'){
					//因为邹狗给的2个数据结构不同  又要写屎山代码  进行不同类型通过判断 递归找到数据并取出值
					const findId=it.keys=='toPerson'?'userId':'deptId';
					const findName=it.keys=='toPerson'?'userName':'name';
					const fileNodes=function(Arr){
						Arr.map(item=>{
							if(_val) return;
							if(val==item[findId]){
								_val=item[findName];
							}
							if(!_val&&item.children){
								fileNodes(item.children);
							}
						})
					}
					fileNodes([{children:it.options}]);
				}
				if(!_val) return;
//				console.log('切换类型是',it.keys,'值是',val,'对应的名称是',_val)
				this.$set(this.htmlDatas, _key, _val);
				if(it.keys=='reviewerType'&&val=='POST'&&this.htmlDatas.toDept){
	  				//当切换审批类型 选择职位 并且原先部门已选值的话  需要做清空
	  				this.htmlDatas.toDept='';
	  			}
				if(it.keys=='toDept'){
					if(this.htmlDatas.setDuty) this.htmlDatas.setDuty='';
					if(this.htmlDatas.reviewerType=='POST'){
						//如果部门变化并且类型是选的指定岗位
						this.initDutyListByDetpId();// 则获取岗位数据
					}
				}
//				this.resetNum++;
			},
			
			async initPersomList(){//指定人数据
//				let res=await this.ApiHttp('/organization/tenant/employee/empList');
				let res=this.$store.state.app.employesLvAll; 
				if(res){
//					res.map(it=>{
//						it.name = it.userName;
//						it.code = it.userId;
//					})
					this.common.insertOptions(this.formlist,'toPerson',res|| []);
				}
			},
			
			async initDeptList(projectId=1){//部门数据
				//await this.ApiHttp('/base/tenant/project/findDeptListByProject?projectId='+projectId);
				let res=this.$store.state.app.deptslvAll; 
				if(res){
//					res.map(it=>{
//						it.name = it.deptName;
//						it.code = it.deptId;
//					})
					this.common.insertOptions(this.formlist,'toDept',res|| []);
				}
			},
			
			async initDutyListByDetpId(){
				const detpId=this.htmlDatas.toDept;
				if(detpId){
		  			let res = await this.ApiHttp('/organization/tenant/duty/deptDutyListAll?deptId='+detpId);
					if(res){
						res.map(it=>{
							it.name = it.dutyName;
							it.code = it.dutyId;
							if(it.dutyPersonName){
								it.name=it.dutyName+'('+it.dutyPersonName+')';
							}
						})
						this.common.insertOptions(this.formlist,'setDuty',res||[]);
//						if(res.dutyList.length<1){
//							this.addDutyItems();
//						}
					}
				}else{
					//没有ID 代表新增，重置输入框
					this.resetTFrom()	
				}
			},
				
			async getDatasById(){},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let params={...this.htmlDatas,}
				
				const dataByType={
					'PEOPLE':{valKey:'toPerson'},
					'DEPT':{valKey:'toDept'},
					'POST':{valKey:'setDuty'},
					'LEADER':{valKey:null,dfVal:'发起人部门负责人'},
					'INITIATORS':{valKey:null,dfVal:'流程发起人'},
					
				}
				const {valKey,dfVal}=dataByType[params.reviewerType];
//				if(params.reviewerType=='LEADER')
				if(!valKey){
					params.name=dfVal;
					params.reviewerIdentifier='';
					
				}else{
					let nameKey=valKey+'Str';
					params.name=params[nameKey];
					params.reviewerIdentifier=params[valKey];
				}
//				if(params.reviewerType=='PEOPLE'){
//					params.name=params.toPersonStr;
//					params.reviewerIdentifier=params.toPerson;
//				}
//				if(params.reviewerType=='DEPT'){
//					params.name=params.toDeptStr;
//					params.reviewerIdentifier=params.toDept;
//				}
//				if(params.reviewerType=='POST'){
//					params.name=params.setDutyStr;
//					params.reviewerIdentifier=params.setDuty;
//				}
//				if(params.reviewerType=='LEADER'){
//					params.name='上下级';
//					params.reviewerIdentifier='';
//				}
				this.$emit('close',params)
				this.$refs.htmlFrom.resetFields();
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}

</style>