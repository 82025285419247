var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "graph-component-department single-graph-component" },
    [
      _c(
        "div",
        { class: ["item-label", _vm.datas.require && "item-label-required"] },
        [_vm._v("\n    " + _vm._s(_vm.datas.label) + "\n  ")]
      ),
      _c(
        "div",
        { staticClass: "selector-container" },
        [
          _c("el-select", {
            staticClass: "department-selector",
            attrs: {
              disabled: "",
              readonly: _vm.readOnly,
              multiple: "",
              placeholder: "请选择",
            },
            model: {
              value: _vm.datas.displayValue,
              callback: function ($$v) {
                _vm.$set(_vm.datas, "displayValue", $$v)
              },
              expression: "datas.displayValue",
            },
          }),
          _c("img", {
            staticClass: "input-icon",
            attrs: {
              src: require("@/assets/images/workflow/department_icon.png"),
              alt: "",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }