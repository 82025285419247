var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "graph-component-numberInput single-graph-component" },
    [
      _c(
        "div",
        { class: ["item-label", _vm.datas.require && "item-label-required"] },
        [_vm._v("\n    " + _vm._s(_vm.datas.label) + "\n  ")]
      ),
      _c(
        "el-input",
        {
          attrs: { readonly: _vm.readOnly, placeholder: _vm.datas.placeHolder },
          model: {
            value: _vm.datas.value,
            callback: function ($$v) {
              _vm.$set(_vm.datas, "value", $$v)
            },
            expression: "datas.value",
          },
        },
        [
          _vm.datas.unitString
            ? _c("template", { slot: _vm.datas.unitType }, [
                _vm._v(_vm._s(_vm.datas.unitString)),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }