<template>
  <div class="single-form-item">
    <component
      :is="propsCompRequire(dataset)"
      :key="dataset.id"
      :dataset="dataset"
      :readOnly="readOnly"
    ></component>
    <!-- el-select在只读状态下点击事件没办法修改，点击到组件无法激活当前卡片，所以用input实现 -->
    <!-- <template v-else-if="dataset.key === 'companySelector'">
        <el-input
          :suffix-icon="
            clearable ? 'el-icon-circle-close' : 'el-icon-arrow-down'
          "
          :readonly="readOnly"
          :placeholder="placeHolder"
        >
        </el-input>
      </template> -->
  </div>
</template>

<script>
export default {
  name: "single-form-item",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    propsCompRequire({ key }) {
      return require(`../graphComponents/${key}.vue`).default;
    },
  },
};
</script>

<style lang="less" scoped>
.single-form-item {
  height: 100%;
  .single-graph-component {
    display: flex;
    gap: 10px;
    flex-direction: column;
    height: 100%;
    ::v-deep .item-label {
      font-weight: 600;
      font-size: 14px;
      color: #252d3d;
      cursor: default;
    }
    ::v-deep .item-label-required {
      &::before {
        content: "*";
        color: #f56c6c;
      }
    }
  }
  ::v-deep .el-input__inner {
    cursor: default;
  }
  ::v-deep .el-textarea__inner {
    cursor: default;
  }
  ::v-deep .el-input.is-disabled .el-input__inner {
    background: #fff;
    cursor: default;
  }
}
</style>