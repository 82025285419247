var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dynamic-user-component" },
    [
      _c(
        "div",
        { staticClass: "tags-container" },
        [
          _vm._l(_vm.value, function (item) {
            return _c("span", { key: item.value, staticClass: "single-tag" }, [
              _vm._v("\n      " + _vm._s(item.userName) + "\n      "),
              !_vm.disabled
                ? _c("i", {
                    staticClass: "el-icon-error",
                    on: {
                      click: function ($event) {
                        return _vm.staffRemove(item)
                      },
                    },
                  })
                : _vm._e(),
            ])
          }),
          !_vm.disabled
            ? _c(
                "span",
                {
                  staticClass: "add-tag single-tag",
                  on: { click: _vm.attendanceStaffFocus },
                },
                [
                  _c("i", { staticClass: "el-icon-plus" }),
                  _vm._v("\n      添加\n    "),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.employeeDialog.dialogVisible
        ? _c("EmployeeSelection", {
            attrs: {
              dataset: _vm.employeeDialog,
              noCheck: true,
              multiple: _vm.multiple,
            },
            on: { close: _vm.employeeClose, submit: _vm.employeeSubmit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }