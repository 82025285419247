<script>
import { mapGetters } from "vuex";
import ProcessTable from "@/views/ruge/approvalProcess/components/process-table.vue";
import Statistic from "../components/statistic.vue";
import { statisticTabs, showSearchField } from "../constant";
const showSearchFieldList = showSearchField.filter((item) =>
  ["modeName", "createDate", "processStatus"].includes(item.key)
);
const tabs = statisticTabs.map((item) => {
  if (item.key === "draftCount") {
    return {
      ...item,
      routerName: "",
    };
  }
  return { ...item };
});
export default {
  name: "approvalProcessDraft",
  components: {
    ProcessTable,
    Statistic,
  },
  data() {
    return {
      tabs,
      showSearchFieldList,
      activeName: "all",
      formSearch: {
        subject: "",
        createDate: null,
        endDate: null,
        applicant: "",
      },
      tableData: [],
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
};
</script>

<template>
  <div
    class="page"
    :style="{
      width: sidebar.opened ? 'calc(100vw - 330px)' : 'calc(100vw - 140px)',
    }"
  >
    <statistic :tabList="tabs" />
    <ProcessTable
        class="table-area"
      query-type="draftedByMe"
      :showTabs="false"
      :showSearchField="showSearchFieldList"
    />
  </div>
</template>

<style scoped lang="less">
.page {
  display: flex;
  width: 100%;
  height: calc(100vh - 90px);
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0 40px 20px;
  padding-top: 10px;
  font-size: 14px;
  font-family: "PingFang SC", "Microsoft YaHei", sans-serif;
  overflow: auto;
  flex-direction: column;
  .table-area {
    padding-top: 9px;
    margin-top: 20px;
  }
  div {
    box-sizing: border-box;
  }
}
</style>
