var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.itemShowRender()
    ? _c(
        "div",
        { staticClass: "single-props-item" },
        [
          ["label", "code", "placeHolder", "placeHolder2"].includes(
            _vm.dataset.key
          )
            ? [
                _c(
                  "el-form-item",
                  {
                    class: [
                      "single-form-item",
                      `single-form-${_vm.dataset.key}`,
                    ],
                    attrs: { label: _vm.dataset.label, prop: _vm.dataset.key },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: _vm.dataset.value || "请输入",
                        maxlength: _vm.dataset.key === "default" ? 999 : 20,
                        "show-word-limit": _vm.dataset.key !== "default",
                      },
                      on: { input: _vm.inputChange },
                      model: {
                        value: _vm.dataset.value,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataset, "value", $$v)
                        },
                        expression: "dataset.value",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : ["hideValue"].includes(_vm.dataset.key)
            ? void 0
            : _c(_vm.propsCompRequire(_vm.dataset.key), {
                key: _vm.dataset.id,
                tag: "component",
                attrs: {
                  itemType: _vm.itemType,
                  propsList: _vm.propsList,
                  dataset: _vm.dataset,
                },
              }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }