<script>
import folderUrl from "@/assets/images/workflow/folder.svg";
import fileUrl from "@/assets/images/workflow/file-icon.svg";
import { FileType } from "@/views/ruge/workflow/list/utils";

export default {
  name: "FileCard",
  methods: {},
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      FileType,
      folderUrl,
      fileUrl,
    };
  },
};
</script>

<template>
  <div class="card-box" v-on="$listeners" v-bind="$attrs">
    <img
      :src="data.type === FileType ? fileUrl : folderUrl"
      alt="folder"
      class="card-icon"
    />
    <el-tooltip
      class="text-overflow tooltip"
      effect="dark"
      :content="data.nodeName"
      placement="top-start"
      v-tooltip-auto-show
    >
      <span class="card-name text-overflow">{{ data.nodeName }}</span>
    </el-tooltip>
  </div>
</template>

<style scoped lang="less">
.tooltip {
  flex: 1;
}
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-box {
  width: 250px;
  height: 58px;
  border-radius: 10px;
  border: 1px solid #e3e8ee;
  padding: 18px 16px 20px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .card-icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
  .card-name {
    font-weight: 500;
    font-size: 14px;
    color: #252d3d;
    line-height: 16px;
  }
}
</style>
