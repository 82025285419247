<template>
  <div class="single-props-item" v-if="itemShowRender()">
    <!-- label', 'code', 'placeHolder', 'placeHolder2'三种类型默认，不单独写文件 -->
    <template
      v-if="
        ['label', 'code', 'placeHolder', 'placeHolder2'].includes(dataset.key)
      "
    >
      <el-form-item
        :label="dataset.label"
        :prop="dataset.key"
        :class="['single-form-item', `single-form-${dataset.key}`]"
      >
        <el-input
          v-model="dataset.value"
          :placeholder="dataset.value || '请输入'"
          @input="inputChange"
          :maxlength="dataset.key === 'default' ? 999 : 20"
          :show-word-limit="dataset.key !== 'default'"
        ></el-input>
      </el-form-item>
    </template>
    <!-- hideValue类型用来记录radio/checkbox/fileUploader/imgUploader这种没有默认值的组件的值 -->
    <template v-else-if="['hideValue'].includes(dataset.key)"></template>
    <component
      v-else
      :is="propsCompRequire(dataset.key)"
      :key="dataset.id"
      :itemType="itemType"
      :propsList="propsList"
      :dataset="dataset"
    ></component>
  </div>
</template>

<script>
import { labelRender } from "../utils.js";

export default {
  name: "single-props-item",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    propsList: {
      type: Array,
      default() {
        return [];
      },
    },
    itemType: {
      type: String,
      default() {
        return "";
      },
    },
  },
  methods: {
    itemShowRender() {
      // 公司下拉框不需要展示
      if (this.dataset.key === "companyOptions") {
        return false;
      }
      // 时间选择器开始占位&结束占位 && isRange = false
      if (this.itemType === "timePicker") {
        const isRange = labelRender(
          { propsList: this.propsList },
          "isRange",
          false
        );
        if (
          ["startPlaceHolder", "endPlaceHolder"].includes(this.dataset.key) &&
          !isRange
        ) {
          return false;
        }
        if (this.dataset.key === "placeHolder2" && isRange) {
          return false;
        }
      }
      return true;
    },
    propsCompRequire(compName) {
      return require(`../propsComponents/${compName}.vue`).default;
    },
    inputChange() {
      if (this.dataset.key !== "code") return;
      this.dataset.value = this.dataset.value.replace(/[^a-zA-Z0-9-_]/g, "");
    },
  },
};
</script>

<style lang="less" scoped>
.single-props-item {
  padding: 0 20px;
  border-bottom: 1px solid #e3e8ee;
  ::v-deep .single-form-item {
    padding-bottom: 10px;
    margin-top: 14px;
    position: relative;
    .require-switch {
      position: absolute;
      right: 0;
      top: -28px;
    }
    .number-input-dom {
      width: 100%;
    }
  }
  ::v-deep .single-form-require,
  .single-form-multiple,
  .single-form-filterable {
    padding-bottom: 0 !important;
  }
  ::v-deep .el-input-number.is-without-controls .el-input__inner {
    text-align: left !important;
  }
}
</style>