<template>
  <div class="props-component-maxValue">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <div class="maxValue-line">
        <el-input-number
          class="number-input"
          v-model="dataset.value.value"
          :disabled="dataset.value.noLimit"
        ></el-input-number>
        <el-checkbox v-model="dataset.value.noLimit">不限</el-checkbox>
      </div>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "props-component-maxValue",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style scoped lang="less">
.props-component-maxValue {
  .maxValue-line {
    display: flex;
    gap: 14px;
    .number-input {
      min-width: 180px;
    }
    ::v-deep .el-checkbox__label {
      padding-left: 4px !important;
    }
  }
}
</style>