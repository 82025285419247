<template>
  <div class="props-component-switchDefault">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <el-radio v-model="dataset.value" :label="true">默认打开</el-radio>
      <el-radio v-model="dataset.value" :label="false">默认关闭</el-radio>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "props-component-switchDefault",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>