<template>
  <div class="graph-component-numberInput single-graph-component">
    <div :class="['item-label', datas.require && 'item-label-required']">
      {{ datas.label }}
    </div>
    <el-input
      :readonly="readOnly"
      :placeholder="datas.placeHolder"
      v-model="datas.value"
    >
      <template v-if="datas.unitString" :slot="datas.unitType">{{
        datas.unitString
      }}</template>
    </el-input>
  </div>
</template>
<script>
import { labelRender } from "../utils.js";
export default {
  name: "graph-component-numberInput",
  props: {
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(item) {
        this.datas.label = labelRender(item, "label", "未命名");
        const currentValue = labelRender(item, "default", null);
        this.datas.value = currentValue === null ? "" : currentValue;
        this.datas.accuracy = labelRender(item, "accuracy", 0);
        if (
          this.datas.accuracy !== null &&
          this.datas.value !== undefined &&
          currentValue !== null
        ) {
          this.datas.value = Number(this.datas.value).toFixed(
            this.datas.accuracy
          );
        }
        this.datas.placeHolder = labelRender(item, "placeHolder", "请输入");
        this.datas.require = labelRender(item, "require", false);
        this.datas.unitType = labelRender(item, "unit", "prepend", "unitType");
        this.datas.unitString = labelRender(item, "unit", null, "unitString");
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      labelRender,
      datas: {
        label: null,
        value: null,
        placeHolder: "请输入",
        require: false,
        unitType: null, // prepend | append
        unitString: null,
        accuracy: 0,
      },
    };
  },
};
</script>
<style lang="less" scoped>
.graph-component-numberInput {
  ::v-deep .el-input-group__prepend {
    background: #fff;
    position: relative;
    right: -1px;
    padding: 0 0 0 12px;
  }
  ::v-deep .el-input-group__append {
    background: #fff;
    position: relative;
    left: -1px;
    padding: 0 12px 0;
  }
}
</style>