var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dynamic-calendar-component" },
    [
      _c("el-date-picker", {
        staticStyle: { width: "100%" },
        attrs: {
          disabled: _vm.disabled,
          clearable: _vm.clearable,
          editable: _vm.editable,
          type: _vm.valueType,
          "value-format":
            _vm.valueType === "date" ? "yyyy-MM-dd" : "yyyy-MM-dd HH:mm:ss",
          placeholder: _vm.placeHolder,
        },
        on: { change: _vm.updateHandler },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }