var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._g({ ref: "containerRef", staticClass: "content-area" }, _vm.$attrs),
    [
      _c(
        "div",
        { ref: "tabsRef", staticStyle: { position: "relative" } },
        [
          _vm.showTabs
            ? [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  _vm._l(_vm.tabList, function (tab) {
                    return _c("el-tab-pane", {
                      key: tab.key,
                      attrs: { label: tab.label, name: tab.key },
                    })
                  }),
                  1
                ),
                _c("div", { staticClass: "tabs-right-area" }, [
                  _c("div", { staticClass: "header-right" }, [
                    _c(
                      "div",
                      { staticClass: "btn", on: { click: _vm.handleRefresh } },
                      [
                        _c("img", { attrs: { src: _vm.refreshUrl } }),
                        _c("span", [_vm._v("刷新")]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "process-btn",
                        on: { click: _vm.startProcess },
                      },
                      [
                        _c("div", { staticClass: "process-btn-text" }, [
                          _vm._v("发起流程"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            : _c("div", { staticClass: "header-tabs-area" }, [
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.pageTitle)),
                ]),
                _c("div", { staticClass: "header-right" }, [
                  _c(
                    "div",
                    { staticClass: "btn", on: { click: _vm.handleRefresh } },
                    [
                      _c("img", { attrs: { src: _vm.refreshUrl } }),
                      _c("span", [_vm._v("刷新")]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "process-btn",
                      on: { click: _vm.startProcess },
                    },
                    [
                      _c("div", { staticClass: "process-btn-text" }, [
                        _vm._v("发起流程"),
                      ]),
                    ]
                  ),
                ]),
              ]),
        ],
        2
      ),
      _c(
        "div",
        { ref: "formSearchRef", staticClass: "form-search-wrap" },
        [
          _c(
            "el-form",
            {
              staticClass: "form-search",
              attrs: {
                inline: true,
                model: _vm.formSearch,
                "label-position": "right",
              },
            },
            [
              _vm.showSearchFieldMap.modeName
                ? _c(
                    "el-form-item",
                    { attrs: { label: "模板名称：" } },
                    [
                      _c("el-input", {
                        staticClass: "form-item",
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formSearch.modeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formSearch, "modeName", $$v)
                          },
                          expression: "formSearch.modeName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showSearchFieldMap.applicant
                ? _c(
                    "el-form-item",
                    { attrs: { label: "申请人：" } },
                    [
                      _c("el-input", {
                        staticClass: "form-item",
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formSearch.applicant,
                          callback: function ($$v) {
                            _vm.$set(_vm.formSearch, "applicant", $$v)
                          },
                          expression: "formSearch.applicant",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showSearchFieldMap.processStatus
                ? _c(
                    "el-form-item",
                    { attrs: { label: "状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form-item",
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.handleStatusChange },
                          model: {
                            value: _vm.formSearch.processStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.formSearch, "processStatus", $$v)
                            },
                            expression: "formSearch.processStatus",
                          },
                        },
                        _vm._l(_vm.statusList, function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.label, value: item.key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showSearchFieldMap.currentReviewer
                ? _c(
                    "el-form-item",
                    { attrs: { label: "当前处理人：" } },
                    [
                      _c("el-input", {
                        staticClass: "form-item",
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formSearch.currentReviewer,
                          callback: function ($$v) {
                            _vm.$set(_vm.formSearch, "currentReviewer", $$v)
                          },
                          expression: "formSearch.currentReviewer",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showSearchFieldMap.createDate
                ? _c(
                    "el-form-item",
                    { attrs: { label: "创建时间：" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "form-item-date",
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          "picker-options": _vm.pickerOptions,
                        },
                        model: {
                          value: _vm.formSearch.createDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.formSearch, "createDate", $$v)
                          },
                          expression: "formSearch.createDate",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, height: _vm.maxHeight },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "index", width: "100", label: "序号" },
          }),
          _c("el-table-column", {
            attrs: { prop: "modeName", label: "模板名称", "min-width": "180 " },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        directives: [
                          {
                            name: "tooltip-auto-show",
                            rawName: "v-tooltip-auto-show",
                          },
                        ],
                        staticClass: "text-overflow",
                        attrs: {
                          "open-delay": 500,
                          content: scope.row.modeName,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "text-overflow" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.modeName) +
                              "\n          "
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "processInitiatorName",
              label: "申请人",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "startTime", label: "创建时间", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { prop: "endTime", label: "结束时间", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { prop: "processStatusStr", label: "状态", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { class: scope.row.processStatusColor }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.processStatusStr) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm.queryType === "forMyApproval"
            ? _c("el-table-column", {
                attrs: { label: "紧急程度", width: "80" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { class: scope.row.urgentStatusColor }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(scope.row.urgencyLevelStr) +
                                "\n        "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2956134684
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "currentNode",
              label: "当前环节",
              "min-width": "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "currentReviewer",
              label: "当前处理人",
              "min-width": "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        directives: [
                          {
                            name: "tooltip-auto-show",
                            rawName: "v-tooltip-auto-show",
                          },
                        ],
                        staticClass: "text-overflow",
                        attrs: {
                          "open-delay": 500,
                          content: scope.row.currentReviewer,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "text-overflow" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.currentReviewer) +
                              "\n          "
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "140" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "operate-column" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "view-btn",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToDetail(scope.row)
                                },
                              },
                            },
                            [_vm._v("详情")]
                          ),
                          _vm._t("operate", null, { row: scope.row }),
                        ],
                        2
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c(
        "div",
        { ref: "paginationRef" },
        [
          _c("pagination", {
            ref: "paginationRef",
            staticStyle: { "padding-bottom": "0" },
            attrs: {
              layout: "sizes,total , prev, pager, next, jumper",
              background: false,
              total: _vm.pageInfo.total,
              page: _vm.pageInfo.current,
              limit: _vm.pageInfo.rowCount,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.pageInfo, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.pageInfo, "rowCount", $event)
              },
              pagination: _vm.currentChange,
            },
          }),
        ],
        1
      ),
      _vm.templateDialog.show
        ? _c("ProcessTemplateDialog", {
            attrs: { dataset: _vm.templateDialog },
            on: { close: _vm.templateDialogClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }