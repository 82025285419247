<template>
  <div class="mini-info-edit">
    <div v-if="mapShow" class="map-container-box">
      <div class="map-search-line">
        <el-input
          v-model="mapKeyword"
          style="width: 100%; margin-right: 20px"
          placeholder="请输入地点"
        />
      </div>
      <baidu-map
        class="map-container"
        :center="{ lng: mapSiteObj.longitude, lat: mapSiteObj.latitude }"
        :zoom="16"
        @moving="syncCenterAndZoom"
        @moveend="syncCenterAndZoom"
        @zoomend="syncCenterAndZoom"
      >
        <bm-local-search
          :keyword="mapKeyword"
          :auto-viewport="false"
          @infohtmlset="infoSelectHanlder"
          @markersset="showSuggestion"
        ></bm-local-search>
        <bm-marker
          :position="{ lng: mapSiteObj.longitude, lat: mapSiteObj.latitude }"
          :dragging="true"
          @dragend="markerDragOver"
        >
        </bm-marker>
      </baidu-map>
      <div class="map-buttons-container">
        <r-button
          type="cancel"
          v-show="showFooterButtons"
          @click="mapShow = false"
          >取消</r-button
        >
        <r-button v-show="showFooterButtons" plain @click="mapSaveAndClose"
          >保存并返回</r-button
        >
      </div>
    </div>
    <div v-show="!mapShow">
      <el-form
        label-position="top"
        :model="form"
        ref="formIns"
        :rules="formRule"
      >
        <el-form-item label="小程序名称" prop="miniprogramTitle">
          <el-input
            placeholder="请输入小程序名称"
            v-model="form.miniprogramTitle"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择区域" prop="areaId">
          <el-select
            style="width: 100%"
            v-model="form.areaId"
            placeholder="请选择区域"
          >
            <el-option
              v-for="item in areaOptions"
              :value="item.areaId"
              :label="item.areaName"
              :key="item.areaId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="园区地址" prop="miniprogramAddress">
          <el-input
            placeholder="请输入园区地址"
            v-model="form.miniprogramAddress"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="position-container"
          label="园区坐标"
          prop="miniprogramLocation"
        >
          <el-input
            placeholder="请输入园区坐标"
            v-model="form.miniprogramLocation"
          ></el-input>
          <i
            class="el-icon-location-outline location-icon"
            @click="openMap"
          ></i>
        </el-form-item>
        <el-form-item label="绑定项目" prop="projectIds">
          <el-select
            style="width: 100%"
            v-model="form.projectIds"
            placeholder="请选择区域"
            multiple
          >
            <el-option
              v-for="item in projectList"
              :value="item.projectId"
              :label="item.projectName"
              :key="item.projectId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商户号" prop="wechatIds">
          <el-select
            style="width: 100%"
            v-model="form.wechatIds"
            placeholder="请选择商户号"
            multiple
          >
            <el-option
              v-for="item in merchantList"
              :value="item.id"
              :label="item.mchName"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- <div style="text-align: right; margin: 30px 0">
        <r-button plain @click="submitHandler" :loading="submitLoading"
          >保存</r-button
        >
      </div> -->
    </div>
  </div>
</template>

<script>
import { getProjectList } from "@/api/business/base/tenant/map";
import { getMiniprogramList, getMerchantList } from "@/api/ruge/lego/config";

export default {
  name: "mini-info-edit",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        datas.miniprogramId && this.displayDatas(datas);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      mapShow: false,
      mapKeyword: "",
      showFooterButtons: true,
      mapSiteObj: {
        longitude: 114.079646,
        latitude: 22.714196,
      },
      submitLoading: false,
      form: {
        miniprogramStatus: "DRAFT",
        miniprogramTitle: null,
        areaId: null,
        miniprogramAddress: null,
        miniprogramLocation: null,
        projectIds: [],
        wechatIds: [],
      },
      formRule: {
        miniprogramTitle: {
          required: true,
          trigger: ["blur", "change"],
          message: this.$t("validate.required"),
        },
        areaId: {
          required: true,
          trigger: ["blur", "change"],
          message: this.$t("validate.required"),
        },
        miniprogramAddress: {
          required: true,
          trigger: ["blur", "change"],
          message: this.$t("validate.required"),
        },
        miniprogramLocation: {
          required: true,
          trigger: ["blur", "change"],
          message: this.$t("validate.required"),
        },
        projectIds: {
          required: true,
          trigger: ["blur", "change"],
          message: this.$t("validate.required"),
        },
      },
      areaOptions: [],
      projectList: [],
      merchantList: [],
    };
  },
  created() {
    getProjectList().then((res) => {
      this.projectList = res;
    });
    getMerchantList({
      current: 1,
    }).then((res) => {
      this.merchantList = res.data.rows;
    });
    getMiniprogramList().then((res) => {
      this.areaOptions = res.map((item) => {
        return {
          areaName: item.areaName,
          areaId: item.areaId,
        };
      });
    });
  },
  methods: {
    displayDatas(datas) {
      const {
        miniprogramTitle,
        areaId,
        miniprogramAddress,
        miniprogramLocation,
        projectList,
        wechatList,
      } = datas || {};
      const projectIds = projectList.map((item) => item.projectId);
      const wechatIds = wechatList.map((item) => item.id);
      this.form = {
        ...this.form,
        ...{
          miniprogramTitle,
          areaId,
          miniprogramAddress,
          miniprogramLocation,
          projectIds,
          wechatIds,
        },
      };
    },
    mapSaveAndClose() {
      const { longitude, latitude } = this.mapSiteObj;
      this.form.miniprogramLocation = `${longitude},${latitude}`;
      this.mapShow = false;
    },
    showSuggestion() {
      this.showFooterButtons = false;
    },
    infoSelectHanlder() {
      this.showFooterButtons = true;
      this.mapKeyword = "";
    },
    markerDragOver(e) {
      const { lng, lat } = e.point;
      this.mapSiteObj.longitude = lng;
      this.mapSiteObj.latitude = lat;
    },
    syncCenterAndZoom(e) {
      const { lng, lat } = e.target.getCenter();
      this.mapSiteObj.longitude = lng;
      this.mapSiteObj.latitude = lat;
    },
    openMap() {
      const { miniprogramLocation } = this.form;
      if (miniprogramLocation && miniprogramLocation.includes(",")) {
        const [longitude, latitude] = miniprogramLocation.split(",");
        this.mapSiteObj.longitude = longitude;
        this.mapSiteObj.latitude = latitude;
      } else {
        this.mapSiteObj.longitude = 114.079646;
        this.mapSiteObj.latitude = 22.714196;
      }
      this.mapShow = true;
    },
    getBaseInfoForm() {
      return new Promise((resolve) => {
        this.$refs.formIns.validate((valid) => {
          if (valid) {
            resolve(this.form);
          } else {
            this.$emit("baseInfoActive", {});
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.mini-info-edit {
  .position-container {
    position: relative;
    .location-icon {
      position: absolute;
      right: 10px;
      top: 9px;
      cursor: pointer;
      font-size: 18px;
      color: #b1afaf;
    }
  }
  //地图弹出框
  .map-container-box {
    width: 100%;
    height: 100%;
    .map-search-line {
      margin-bottom: 15px;
    }
    .map-container {
      width: 100%;
      height: 460px;
    }
    .map-buttons-container {
      text-align: right;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}
</style>