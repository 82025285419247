<script>
import { mapGetters } from "vuex";
import Pagination from "@/components/Pagination/index.vue";
import ProcessTemplateDialog from "../components/processTemplateDialog.vue";
import FlowModal from "@/views/ruge/workflow/list/components/flow-modal.vue";
import refreshUrl from "@/assets/images/workflow/refresh.svg";
import {
  apiAuditInspectorCount,
  apiGetWorkflowList,
} from "@/api/ruge/workflow/approvalProcess";
import { endOfDay, format, getUnixTime, startOfDay } from "date-fns";
import { debounce, omit } from "lodash";
import { processTableTabs, showSearchField } from "../constant";
import axios from "axios";
const statusMap = new Map([
  ["all", { colorClass: "status-all", label: "全部", key: -1 }],
  ["draft", { colorClass: "status-draft", label: "草稿", key: 0 }],
  ["preTrial", { colorClass: "status-preTrial", label: "待审", key: 1 }],
  ["reject", { colorClass: "status-reject", label: "驳回", key: 2 }],
  ["withdraw", { colorClass: "status-withdraw", label: "撤回", key: 3 }],
  ["end", { colorClass: "status-end", label: "结束", key: 4 }],
  // (0:草稿: 1:待审:2:驳回: 3:撤回 4∶结束)
]);
const statusColorMap = {};
const statusList = [];
statusMap.forEach((item, key) => {
  statusColorMap[item.key] = item.colorClass;
  statusList.push({ label: item.label, key: item.key });
});
const urgentStatusMap = {
  0: { key: 0, label: "一般", colorClass: "urgent-normal" },
  1: { key: 1, label: "急", colorClass: "urgent" },
  2: { key: 2, label: "紧急", colorClass: "urgent-very" },
};

export default {
  name: "ProcessTable",
  components: {
    Pagination,
    ProcessTemplateDialog,
    FlowModal,
  },
  props: {
    queryType: {
      // 查询类型（all :全部，draftedByMe:我起草的， forMyApproval:待我审批的， myApproved:我已审批的）
      type: String,
    },
    showTabs: {
      type: Boolean,
      default: false,
    },
    tabList: {
      type: Array,
      default: () => processTableTabs,
    },
    fetchList: {
      type: Function,
    },
    showSearchField: {
      type: Array,
      default: () => [...showSearchField],
    },
  },

  data() {
    return {
      statusColorMap: statusColorMap,
      statusList: statusList,
      refreshUrl: refreshUrl,
      freshStep: 30,
      cancelTokenSource: null, // 用于存储 CancelToken
      loading: true,
      maxHeight: 300,
      debouncedHandler: () => {},
      activeName: "-1",
      formSearch: {
        // 模板名称
        modeName: "",
        // 状态
        processStatus: -1,
        // 当前处理人
        currentReviewer: "",
        createDate: null,
        applicant: "",
        endDate: null,
      },
      pageInfo: {
        total: 0,
        current: 1,
        rowCount: 10,
      },
      tableData: [],
      templateDialog: {
        show: false,
      },
      timer: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "昨天",
            onClick: (picker) => {
              const yesterday = this.getDay(-1);
              const end = new Date(`${yesterday} 23:59:59`).getTime();
              const start = new Date(`${yesterday} 00:00:00`).getTime();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "今天",
            onClick: (picker) => {
              const today = this.getDay(0);
              const end = new Date(`${today} 23:59:59`).getTime();
              const start = new Date(`${today} 00:00:00`).getTime();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.debouncedHandler = debounce((v) => this.fetchListFn(v), 200);
  },
  mounted() {
    this.cancelTokenSource = axios.CancelToken.source();
    const fn = debounce(() => this.updateTableMaxHeight(), 200);
    window.addEventListener("resize", fn);
    fn();
    this.debouncedHandler(this.formSearch);
    this.updateInterval();
  },
  beforeDestroy() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel("请求已被取消");
    }
    window.removeEventListener("resize", this.debouncedHandler);
    // 清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    handleStatusChange(e) {
      const h = this.tabList.find((item) => item.key === `${e}`);
      if (h) {
        this.activeName = `${e}`;
      }
    },
    updateInterval() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        console.log("setInterval");
        this.debouncedHandler(this.formSearch);
      }, this.freshStep * 1000);
    },
    handleRefresh() {
      this.debouncedHandler(this.formSearch);
    },
    updateTableMaxHeight() {
      // 获取父容器的高度
      const containerHeight = this.getClientHeight(this.$refs.containerRef);
      const tabsRefHeight = Math.max(
        this.getClientHeight(this.$refs.tabsRef),
        54
      );
      const formSearchHeight = this.getClientHeight(this.$refs.formSearchRef);
      const paginationHeight = this.getClientHeight(this.$refs.paginationRef);

      this.maxHeight =
        containerHeight -
        tabsRefHeight -
        formSearchHeight -
        paginationHeight -
        40; // 减去一些 margin/padding 的高度
    },
    getClientHeight(refDom) {
      if (!refDom) return 0;
      return refDom.clientHeight;
    },
    currentChange(event) {
      this.debouncedHandler(this.formSearch);
      this.pageInfo = {
        ...this.pageInfo,
        current: event.page,
        rowCount: event.limit,
      };
    },

    startProcess() {
      this.templateDialog.show = true;
    },
    templateDialogClose() {
      this.templateDialog.show = false;
    },
    handleClick(tab, event) {
      this.formSearch.processStatus = Number(this.activeName);
      this.debouncedHandler(this.formSearch);
    },
    async fetchCount() {
      try {
        const res = await apiAuditInspectorCount();

        this.$store.dispatch("updateStatisticCounts", res);
      } catch (e) {}
    },
    jumpToDetail(datas) {
      const { taskId, definitionId } = datas;
      let url = `${location.origin}${location.pathname}#/approval-process/detail?definitionId=${definitionId}`;
      if (taskId) {
        url += `&taskId=${taskId}`;
      }
      window.open(url, "_blank");
    },

    refresh() {
      this.pageInfo = {
        ...this.pageInfo,
        current: 1,
        rowCount: 10,
      };
      if (this.debouncedHandler.cancel) {
        this.debouncedHandler.cancel();
      }
      this.debouncedHandler(this.formSearch);
    },

    getQuery(formValues) {
      if (!this.queryType) {
        throw new Error("缺少queryType参数");
      }

      console.log(formValues);
      const createData = Array.isArray(formValues.createDate)
        ? formValues.createDate
        : [];
      const createStartTime = createData[0]
        ? getUnixTime(startOfDay(createData[0]))
        : null;
      const createEndTime = createData[1]
        ? getUnixTime(endOfDay(createData[1]))
        : null;
      const allQuery = {
        current: this.pageInfo.current,
        rowCount: this.pageInfo.rowCount,
        queryType: this.queryType,
        // 查询条件
        modeName: this.formSearch.modeName,
        processInitiatorName: this.formSearch.applicant,
        createStartTime,
        createEndTime,
        processStatus: this.formSearch.processStatus,
        currentReviewer: this.formSearch.currentReviewer,
      };
      switch (this.queryType) {
        case "management":
          return allQuery;
        case "draftedByMe":
          return omit(allQuery, ["processInitiatorName", "currentReviewer"]);
        case "forMyApproval":
          return omit(allQuery, ["processStatus", "currentReviewer"]);
        case "myApproved":
          return omit(allQuery, ["currentReviewer"]);
        case "all":
          return omit(allQuery, ["currentReviewer"]);
        default:
          return allQuery;
      }
    },

    async _fetchListFn(formValues) {
      try {
        const query = this.getQuery(formValues);
        const res = await apiGetWorkflowList(query, {
          cancelToken: this.cancelTokenSource,
        });
        const tableData = res.rows.map((item) => {
          return {
            ...item,
            modeName: item.modeName,
            processInitiatorName: item.processInitiatorName,
            startTime: item.startTime
              ? format(new Date(item.startTime), "yyyy-MM-dd HH:mm:ss")
              : "",
            endTime: item.endTime
              ? format(new Date(item.endTime), "yyyy-MM-dd HH:mm:ss")
              : "",
            // editDate: format(item.creationDate, 'yyyy-MM-dd'),
          };
        });
        return {
          success: true,
          total: res.total,
          data: tableData,
        };
      } catch (e) {
        console.error(e);
        return {
          success: false,
          total: 0,
          data: [],
        };
      }
    },
    async fetchListFn(formValues) {
      try {
        this.loading = true;
        const query = this.getQuery(formValues);
        const currentIndex = (query.current - 1) * query.rowCount;
        let res = [];
        // 取消之前的请求
        if (this.cancelTokenSource) {
          this.cancelTokenSource.cancel("请求已被取消");
        }

        // 创建新的 CancelToken
        this.cancelTokenSource = axios.CancelToken.source();
        if (this.fetchList) {
          const list = await this.fetchList(query, this.cancelTokenSource);
          res = list;
          this.pageInfo.total = res.total;
        } else {
          const list = await this._fetchListFn(formValues);
          res = list;
          this.pageInfo.total = res.total;
        }
        this.tableData = res.data.map((item, index) => {
          const urgentStatus = urgentStatusMap[item.urgencyLevel];
          return {
            ...item,
            index: currentIndex + index + 1,
            processStatusColor:
              this.statusColorMap[item.processStatus] || "status-end",
            urgentStatusColor: urgentStatus ? urgentStatus.colorClass : "",
          };
        });
        this.fetchCount();
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      } finally {
        this.updateInterval();
      }
    },
    getDay(day) {
      let today = new Date();
      let targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      let tYear = today.getFullYear();
      let tMonth = today.getMonth();
      let tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "-" + tMonth + "-" + tDate;
    },
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
  },
  computed: {
    ...mapGetters(["sidebar"]),
    pageTitle() {
      // 查询类型（ all :全部  draftedByMe:我起草的 ， forMyApproval:待我审批的  myApproved:我已审批的 ）
      const titleMap = {
        all: "所有流程",
        draftedByMe: "我起草的",
        forMyApproval: "待我审的",
        myApproved: "我已审的",
        management: "流程管理",
      };
      console.log(this.queryType, "this.queryType");

      if (titleMap[this.queryType]) {
        return titleMap[this.queryType];
      }
      return "";
    },
    showSearchFieldMap() {
      const map = {};
      this.showSearchField.forEach((item) => {
        map[item.key] = item;
      });
      return map;
    },
  },
  watch: {
    formSearch: {
      handler(val) {
        if (this.debouncedHandler.cancel) {
          this.debouncedHandler.cancel();
        }
        this.pageInfo.current = 1;
        this.debouncedHandler(val);
      },
      immediate: true,
      deep: true,
    },
  },
  expose: ["refresh"],
};
</script>
<template>
  <div class="content-area" ref="containerRef" v-on="$attrs">
    <div style="position: relative" ref="tabsRef">
      <template v-if="showTabs">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            :label="tab.label"
            :name="tab.key"
            :key="tab.key"
            v-for="tab in tabList"
          ></el-tab-pane>
        </el-tabs>
        <div class="tabs-right-area">
          <div class="header-right">
            <div class="btn" @click="handleRefresh">
              <img :src="refreshUrl" />
              <span>刷新</span>
            </div>
            <div class="process-btn" @click="startProcess">
              <div class="process-btn-text">发起流程</div>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="header-tabs-area">
        <div class="text">{{ pageTitle }}</div>
        <div class="header-right">
          <div class="btn" @click="handleRefresh">
            <img :src="refreshUrl" />
            <span>刷新</span>
          </div>
          <div class="process-btn" @click="startProcess">
            <div class="process-btn-text">发起流程</div>
          </div>
        </div>
      </div>
    </div>

    <div ref="formSearchRef" class="form-search-wrap">
      <el-form
        class="form-search"
        :inline="true"
        :model="formSearch"
        label-position="right"
      >
        <!-- 模板名称 -->
        <el-form-item label="模板名称：" v-if="showSearchFieldMap.modeName">
          <el-input
            class="form-item"
            v-model="formSearch.modeName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请人：" v-if="showSearchFieldMap.applicant">
          <el-input
            class="form-item"
            v-model="formSearch.applicant"
            placeholder="请输入"
          ></el-input>
        </el-form-item>

        <el-form-item label="状态：" v-if="showSearchFieldMap.processStatus">
          <el-select
            class="form-item"
            v-model="formSearch.processStatus"
            placeholder="请选择"
            @change="handleStatusChange"
          >
            <el-option
              v-for="item in statusList"
              :key="item.key"
              :label="item.label"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="当前处理人："
          v-if="showSearchFieldMap.currentReviewer"
        >
          <el-input
            class="form-item"
            v-model="formSearch.currentReviewer"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="创建时间：" v-if="showSearchFieldMap.createDate">
          <el-date-picker
            class="form-item-date"
            v-model="formSearch.createDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
      :height="maxHeight"
    >
      <el-table-column prop="index" width="100" label="序号"> </el-table-column>

      <el-table-column prop="modeName" label="模板名称" min-width="180 ">
        <template slot-scope="scope">
          <el-tooltip
            :open-delay="500"
            v-tooltip-auto-show
            class="text-overflow"
            :content="scope.row.modeName"
            placement="top-start"
          >
            <div class="text-overflow">
              {{ scope.row.modeName }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="processInitiatorName" label="申请人" width="120">
      </el-table-column>
      <el-table-column prop="startTime" label="创建时间" width="200">
      </el-table-column>
      <el-table-column prop="endTime" label="结束时间" width="200">
      </el-table-column>
      <el-table-column prop="processStatusStr" label="状态" width="120">
        <template slot-scope="scope">
          <span :class="scope.row.processStatusColor">
            {{ scope.row.processStatusStr }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="queryType === 'forMyApproval'"
        label="紧急程度"
        width="80"
      >
        <template slot-scope="scope">
          <span :class="scope.row.urgentStatusColor">
            {{ scope.row.urgencyLevelStr }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="currentNode" label="当前环节" min-width="120">
      </el-table-column>
      <el-table-column
        prop="currentReviewer"
        label="当前处理人"
        min-width="120"
      >
        <template slot-scope="scope">
          <el-tooltip
            :open-delay="500"
            v-tooltip-auto-show
            class="text-overflow"
            :content="scope.row.currentReviewer"
            placement="top-start"
          >
            <div class="text-overflow">
              {{ scope.row.currentReviewer }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <div class="operate-column">
            <el-button
              type="text"
              class="view-btn"
              @click="jumpToDetail(scope.row)"
              >详情</el-button
            >
            <slot name="operate" :row="scope.row"></slot>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div ref="paginationRef">
      <pagination
        style="padding-bottom: 0"
        ref="paginationRef"
        layout="sizes,total , prev, pager, next, jumper"
        :background="false"
        :total="pageInfo.total"
        :page.sync="pageInfo.current"
        :limit.sync="pageInfo.rowCount"
        @pagination="currentChange"
      />
    </div>
    <ProcessTemplateDialog
      v-if="templateDialog.show"
      :dataset="templateDialog"
      @close="templateDialogClose"
    />
  </div>
</template>

<style scoped lang="less">
.content-area-draft {
  padding-top: 9px;
}
.content-area {
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 13px;
  padding: 20px;
  flex: 1;
  overflow: hidden;

  .workflow-href {
    color: #2a61ff !important;
  }

  .tabs-right-area {
    position: absolute;
    right: 0;
    top: -9px;
  }
  .header-tabs-area {
    display: flex;
    padding-bottom: 9px;
    border-bottom: 1px solid #f1f4f6;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .text {
      font-weight: 600;
      font-size: 14px;
      color: #252d3d;
      line-height: 16px;
    }
  }
  .header-right {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 16px;
    .btn {
      cursor: pointer;
      margin: 0;
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 2px;
      height: 36px;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #2a61ff;
      > span {
        font-weight: 600;
        font-size: 14px;
        color: #2a61ff;
        line-height: 16px;
      }
    }
    .process-btn {
      cursor: pointer;
      width: 80px;
      padding: 8px 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      background: #2a61ff;
      border-radius: 8px 8px 8px 8px;
      > div {
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
  .view-btn {
    color: #2a61ff;
  }
  .operate-column {
    display: flex;
  }
  .text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .form-search-wrap {
    display: flex;
    justify-content: space-between;
    .form-search {
      display: flex;
      flex-wrap: wrap;
      column-gap: 30px;
    }
    .search-btn {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-wrap: nowrap;
      width: 260px;
    }
    .refresh-btn {
      width: 86px;
    }
    .form-item {
      width: 160px;
    }
    .form-item-date {
      width: 280px;
    }
  }
}

::v-deep {
  .status-end {
    color: #252d3d;
  }
  .status-preTrial {
    color: #04d5a3;
  }
  .status-draft {
    color: #bebebe;
  }

  .status-reject {
    color: #ff8a00;
  }
  // 撤回
  .status-withdraw {
    color: #f055f3;
  }

  .urgent-normal {
    color: #2a61ff;
  }
  .urgent {
    color: #fec021;
  }
  .urgent-very {
    color: #f35555;
  }

  .el-tabs__item {
    padding: 0 40px 0;

    font-weight: 600;
    font-size: 14px;
    color: #252d3d;
    line-height: 16px;
    height: 34px;
  }
  .el-range-separator {
    min-width: 28px;
  }
  .el-tabs__nav-wrap {
    padding-left: 10px;
    &::after {
      background-color: #f1f4f6;
    }
  }

  .el-tabs__active-bar {
    background-color: #2a61ff;
  }

  .el-form--inline {
    .el-form-item {
      margin-right: 0px;
    }
    .el-form-item__label {
      padding-right: 0;
    }
  }

  .el-table {
    thead tr th {
      background: #f0f4fa;
    }

    th.el-table__cell > .cell {
      font-weight: 600;
      font-size: 14px;
      color: #5d687c;
      line-height: 16px;
    }

    td.el-table__cell div {
      font-weight: 400;
      font-size: 14px;
      color: #252d3d;
      line-height: 16px;
    }
  }
}

/deep/ .el-pagination__sizes {
  float: right;
  margin-right: 0;
}

/deep/ .el-pagination {
  padding-right: 0;
}

/deep/ .el-pagination__total {
  float: right;
}
</style>
