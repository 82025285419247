<template>
  <div class="graph-component-input single-graph-component">
    <div :class="['item-label', datas.require && 'item-label-required']">
      {{ datas.label }}
    </div>
    <el-date-picker
      :readonly="readOnly"
      style="width: 100%"
      v-model="datas.value"
      :clearable="datas.clearable"
      :editable="datas.editable"
      :type="datas.valueType"
      :placeholder="datas.placeHolder"
    >
    </el-date-picker>
  </div>
</template>
<script>
import { labelRender } from "../utils.js";
export default {
  name: "graph-component-input",
  props: {
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(item) {
        this.datas.label = labelRender(item, "label", "未命名");
        this.datas.value = labelRender(item, "calendarDefault", null, "value");
        this.datas.placeHolder = labelRender(item, "placeHolder2", "请选择");
        this.datas.clearable = labelRender(item, "clearable", true);
        this.datas.editable = labelRender(item, "editable", true);
        this.datas.maxlength = labelRender(item, "maxLength", 20);
        this.datas.require = labelRender(item, "require", false);
        this.datas.valueType = labelRender(
          item,
          "calendarDefault",
          "date",
          "valueType"
        );
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      labelRender,
      datas: {
        label: null,
        value: null,
        placeHolder: "请输入",
        maxlength: 20,
        require: false,
        clearable: true,
        editable: true,
        valueType: "date",
      },
    };
  },
};
</script>
<style lang="less" scoped>
.graph-component-input {
}
</style>