<!--
 * @LastEditors: luo
 * @LastEditTime: 2024-11-29 09:30:46
 * @Description: 
-->
<template>
  <el-form
    label-position="top"
    ref="flowFormRef"
    label-width="80px"
    :model="form"
    :rules="rules"
    :disabled="readonly"
    class="flow-form"
  >
    <el-form-item prop="name">
      <template #label>
        <div class="flow-form-item">
          <span class="flow-form-item-label">审批流名称</span>
          <div class="flow-form-item-path">
            <span>位置：</span>
            <TooltipText contentClass="text" :value="path" />
          </div>
        </div>
      </template>
      <el-input v-model="form.name" @change="nameChange"></el-input>
    </el-form-item>
    <el-form-item prop="code">
      <template #label>
        <div class="flow-form-item">
          <span class="flow-form-item-label">编码</span>
        </div>
      </template>
      <el-input v-model="form.code" placeholder="请输入编码"></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
import pinyin from "js-pinyin";
import TooltipText from "./tooltip-text.vue";

const validateFileName = (rule, value = "", callback) => {
  if (value.trim() === "") {
    callback(new Error("请输入文件夹名称"));
  } else {
    callback();
  }
};
const validateCode = (rule, value, callback) => {
  if (value.trim() === "") {
    callback(new Error("请输入编码"));
  } else {
    callback();
  }
};
export default {
  name: "create-folder-modal",
  components: {
    TooltipText,
  },
  props: {
    path: {
      type: String,
      default: "",
    },
    defaultForm: {
      type: Object,
      default: () => ({}),
    },
    autoCode: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    console.log("this.defaultForm", this.defaultForm);

    if (this.defaultForm) {
      this.form.name = this.defaultForm.name;
      this.form.code = this.defaultForm.code || ''
    }
    if (this.autoCode) {
      this.nameChange(this.form.name)
    }

  },
  data() {
    return {
      form: {
        name: "",
        code: "",
      },
      rules: {
        name: [
          { validator: validateFileName, trigger: "blur" },
          { max: 20, message: "长度不能超过20个字符", trigger: "blur" },
        ],
        code: [
          { validator: validateCode, trigger: "blur" },
          { max: 20, message: "长度不能超过20个字符", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    nameChange(value) {
      if (this.form.code || !value) return;
      const py = pinyin.getCamelChars(value);
      this.form.code = py.toUpperCase();
    },
    async onOk() {
      try {
        const valid = await this.$refs.flowFormRef.validate();
        if (!valid) {
          return false;
        }
        return { ...this.form };
      } catch (error) {
        return false;
      }
    },
  },
  expose: ["onOk"],
};
</script>
<style lang="less" scoped>
.flow-form {
  width: 540px;
}

.flow-form-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 540px;

  &-label {
    height: 16px;
    font-weight: 600;
    font-size: 16px;
    color: #252d3d;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  &-path {
    display: flex;
    height: 14px;
    font-weight: 400;
    font-size: 14px;
    color: #5d687c;
    line-height: 14px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    .text {
      max-width: 280px;
    }
  }
}
</style>
