<template>
	<!--定时节点配置项-->
	<div>
		<TForm ref="refFrom1" lableRow  :autoCheck='true' :model="htmlDatas" :formlist="formlist" :rowGutter='16' labelPosition='left'>
			<template slot="numExample" slot-scope="scope">
				<!--hellow  自定义内容块-->
				<div>
					<el-row>
					  	<el-col :span="12">
					  		<el-input v-model="htmlDatas[scope.current.keys].nums"  oninput="value=value.replace(/[^\d^\.]+/g,'')" />
					  	</el-col>
					  	<el-col :span="12">
					  		<el-select v-model="htmlDatas[scope.current.keys].selectype" placeholder="请选择">
							    <el-option v-for="item in scope.current.options" :key="item.code" :label="item.name" :value="item.code" />
						  	</el-select>
					  	</el-col>
					</el-row>
				</div>
			</template>
			<template slot="setFormkeys" slot-scope="scope">
				<!--hellow  自定义内容块-->
				<el-button type="primary" size=' mini' >前往设置</el-button>
			</template>
		</TForm>
		<div v-if="htmlDatas.timerType=='EXPRESSION'" class="bdsTips">
			表达式示例如：</br>
			1.'${form.createDate} >= ${now()}' </br>
			2. '${form.createDate}' == ${now()}' </br>
			3. '${form.createDate} - ${now()} > 1'
			
		</div>
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		components: { TForm },
		props: {
			formInfo:{
				type: Object,
				default:()=>{
					return {}
				}
			},
		},
		watch:{
			htmlDatas: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$nextTick(()=>{
						this.$emit('upvals',this.htmlDatas)
					})
					
				}
			},
			'htmlDatas.timerType': {
				deep: true, // 深度监听
				handler(val, oval) {
					this.hideOrShowItems();
					
				}
			},
		},
		computed: {
			
			
			
			
		},
		data() {
			return {
				htmlDatas: {
					timerType:'DURATION',
				},
				formlist: [
					{name: '节点key',keys: 'nodeCode',value: '',type: 'input',flex:24,isMust:true},
					{name: '节点描述',keys: 'nodeComment',value: '',type: 'textarea',flex:24,isHide:false},
					{name: '定时方式',keys: 'timerType',value: 'DURATION',type: 'select',flex:24,isMust:true,options:[
	  					{name:'等待时长',code:'DURATION'},
	  					{name:'表达式',code:'EXPRESSION'},
	  				]},
//	  				{name: '选择日期',keys: 'kkkk1',value:null,type: 'time',flex:24,isHide:false},
	  				{name: '等待时长',keys: 'waitingDuration',value: '15',type: 'select',flex:24,options:[
	  					{name:'15分钟',code:'15'},
	  					{name:'30分钟',code:'30'},
	  					{name:'60分钟',code:'60'},
						{name:'24小时',code:'1440'},
	  				],isHide:true},
	  				{name: '表达式',keys: 'expression',value: '',type: 'input',flex:24,isHide:true},
				],
			};
		},
		created() {
			console.log('接收.formInfo',JSON.parse(JSON.stringify(this.formInfo)))
			this.resetTFrom();
		},
		mounted() {},
		methods: {
			hideOrShowItems(){
				//通过定时方式判断当前列是否显示
				let keyArr1=['waitingDuration'],keyArr2=['expression'];
				let showIts=[],hideIts=[];
				if(this.htmlDatas.timerType=='DURATION'){
					showIts=keyArr1;
					hideIts=keyArr2;
				}else{
					showIts=keyArr2;
					hideIts=keyArr1;
				}
				for(let it of this.formlist){
					if(hideIts.indexOf(it.keys)>-1){
						it.isHide=true;
					}
					if(showIts.indexOf(it.keys)>-1){
						it.isHide=false;
					}
				}
				console.log('hideOrShowItems',JSON.parse(JSON.stringify(this.formlist)))
			},
			resetTFrom(){
//				let bascArr=JSON.parse(JSON.stringify(this.utilArrs));
//				console.log('sssssssss',bascArr)
//				if(this.htmlDatas.timerType=='1'){
//					this.formlist=bascArr.concat(JSON.parse(JSON.stringify(this.waitDate)));
//				}else{
//					this.formlist=bascArr.concat(JSON.parse(JSON.stringify(this.checkDate)));
//				}
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
	  				let fval=this.formInfo[item.keys]
	  				if(fval) item.value=fval;
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  			
	  			console.log('sssssssss',this.formlist)
	  		},
		}
		
	};
</script>

<style scoped>
	.bdsTips{
		color: #c0c4cc;
		line-height: 20px;
	}
</style>