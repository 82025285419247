<template>
  <div class="dynamic-email-component">
    <el-input
      v-model="value"
      clearable
      :disabled="disabled"
      :placeholder="placeHolder"
      prefix-icon="el-icon-message"
      @input="updateHandler"
      @blur="emailValid"
    >
    </el-input>
  </div>
</template>

<script>
import { labelRender, updateSource } from "../../../detail/utils.js";

export default {
  name: "dynamic-email-component",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        this.value = labelRender(datas, "emailDefault", null);
        this.placeHolder = labelRender(datas, "placeHolder", "请输入");
        this.code = labelRender(datas, "code", null);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      value: null,
      placeHolder: "请输入",
      code: null,
    };
  },
  methods: {
    updateHandler() {
      updateSource(this.dataset, 'emailDefault', this.value)
      this.$emit("update", {
        key: this.code,
        value: this.value,
      });
    },
    emailValid() {
      if (!this.value) return;
      const emailReg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (emailReg.test(this.value)) return;
      this.$message.warning("请输入正确的邮箱");
      this.value = null;
      this.updateHandler();
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic-email-component {
}
</style>