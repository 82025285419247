<template>
	<!--审核节点配置项-->
	<div class="uTaskHtml">
		<TForm ref="refFrom1" lableRow  :autoCheck='true' :model="htmlDatas" :formlist="formlist" :rowGutter='16' labelPosition='left'>
			<template slot="numExample" slot-scope="scope">
				<!--hellow  自定义内容块-->
				<div>
					<el-row :gutter="1">
					  	<el-col :span="14">
					  		<el-input v-model.number="htmlDatas[scope.current.keys].nums"  onkeyup="value=value.replace(/\D+/g, '')" />
					  		<!--<el-input v-model="htmlDatas[scope.current.keys].nums"  type="number" min="1" step="1" />-->
					  	</el-col>
					  	<el-col :span="10">
					  		<el-select v-model="htmlDatas[scope.current.keys].selectype" placeholder="请选择">
							    <el-option v-for="item in scope.current.options" :key="item.code" :label="item.name" :value="item.code" />
						  	</el-select>
					  	</el-col>
					</el-row>
				</div>
				
			</template>
			<template slot="timeoutSet" slot-scope="scope">
				<!--value=value.replace(/^\D*(\d*(?:\.\d{0,1})?).*$/g, '$1')" 一位小数的正则-->
				<el-input v-model="htmlDatas[scope.current.keys]" placeholder='请输入' onkeyup="value=value.replace(/\D+/g, '')" />
				<span class="tipTx">分钟</span>
			</template>
			<template slot="setFormkeys" slot-scope="scope">
				<!--hellow  自定义内容块-->
				<el-button type="primary" size='mini' @click='openFormKeyWords'>前往设置</el-button>
				
			</template>
			<template slot="btmBorder" slot-scope="scope">
				<div class="btmlineBox"></div>
			</template>
			
			
			
		</TForm>
		<fromWordsSet v-if='openWordsSet' :isOpen='openWordsSet' :dataId='formInfo.id' :httpConfigs='httpConfigs' :nodeArrs='bpmnNodes' :datas='htmlDatas.keyWordDatas' @close='closeDialog'  />
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	import fromWordsSet from './fromWordsSet.vue';//表单字段设置弹窗
	export default {
		components: { TForm ,fromWordsSet},
		props: {
			formInfo:{
				type: Object,
				default:()=>{
					return {
						id:''
					}
				}
			},
		},
		watch:{
			htmlDatas: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$nextTick(()=>{
//						let htObj={
//							//后台将 拒绝&同意示例数量 当作会签设置单独存储一个对象
//							'countersign':{
//								"agreeInstanceType": val.agreeInfo.selectype, // 同意实例类型（0：百分比，1：数值）
//								"agreeInstanceVal": val.agreeInfo.nums, // 同意实例值
//								"rejectInstanceType": val.rejectInfo.selectype, // 同意实例类型（0：百分比，1：数值）
//								"rejectInstanceCount": val.rejectInfo.nums, // 拒绝实例值
//							},
//							...this.htmlDatas,
//						}
						this.$emit('upvals',this.htmlDatas)
					})
					
				}
			},
			'htmlDatas.dueOpen': {
				deep: true, // 深度监听
				handler(val, oval) {
					this.hideOrShowItems();
				}
			},
		},
		computed: {
			httpConfigs() {
                return this.$parent.getDefaultFormDatas();
            },
			
			
		},
		data() {
			return {
//				httpConfigs:'',
				htmlDatas: {},
				formlist: [
//					{name: '节点名称',keys: 'nodeName',value: '',type: 'input',flex:24,isMust:true},
					{name: '节点key',keys: 'nodeCode',value: '',type: 'input',flex:24,isMust:true},
					{name: '节点描述',keys: 'description',value: '',type: 'textarea',flex:24},
					{name: '',keys: 'null1',value: null,type: 'slot',slotName:"btmBorder"},
					{name: '超时提醒',keys: 'dueOpen',value: 'N',type: 'switch',flex:24},
					{name: '时间类型-不显示',keys: 'dueType',value: 'PF',type: 'input',flex:24,isHide:true},
//					{name: '超时设置[小时]',keys: 'dueTime',value: '',type: 'input',flex:24,isMust:false},
					{name: '超时设置',keys: 'dueTime',value: '',type: 'slot',slotName:"timeoutSet",tips:'分钟',diyClass:'setTimeinp',isHide:false},
					{name: '提醒方式',keys: 'noticeMethod',value: ['0'],type: 'check',flex:24,isMust:false,options:[
	  					{name:'待办',code:'0',disabled:true},
//						{name:'消息',code:'1'},
						{name:'短信',code:'2'},
						{name:'邮件',code:'3'},
	  				]},
	  				{name: '',keys: 'null1',value: null,type: 'slot',slotName:"btmBorder"},
//	  				{name: '是否会签',keys: 'isHuiqian',value: 'N',type: 'switch',flex:24},
	  				{name: '拒绝数量',keys: 'rejectInfo',value:{nums:1,selectype:'1'},type:'slot',slotName:"numExample",options:[
	  					{name:'百分比',code:'0'},
	  					{name:'数值',code:'1'},
	  				]},
	  				{name: '同意数量',keys: 'agreeInfo',value:{nums:100,selectype:'0'},type:'slot',slotName:"numExample",options:[
	  					{name:'百分比',code:'0'},
	  					{name:'数值',code:'1'},
	  				]},
	  				{name: '',keys: 'null1',value: null,type: 'slot',slotName:"btmBorder"},
	  				{name: '表单字段设置',keys: 'keyWordDatas',value:{
	  					checkedNode:'',
	  					isDefault:false,
	  				},type:'slot',slotName:"setFormkeys"},
				],
				openWordsSet:false,
				bpmnNodes:[],
			};
		},
		created() {
//			this.httpConfigs=this.$parent.getDefaultFormDatas();
			console.log('接收.this.formInfo',this.formInfo)
			this.resetTFrom();
		},
		mounted() {},
		methods: {
			hideOrShowItems(){
				//通过定时方式判断当前列是否显示
				let keyArr1=['dueTime','noticeMethod'],keyArr2=[];
				let showIts=[],hideIts=[];
				if(this.htmlDatas.dueOpen=='Y'){
					showIts=keyArr1;
					hideIts=keyArr2;
				}else{
					showIts=keyArr2;
					hideIts=keyArr1;
					//恢复默认值
					this.htmlDatas.dueTime='';
					this.htmlDatas.noticeMethod=['0'];
				}
				for(let it of this.formlist){
					if(hideIts.indexOf(it.keys)>-1){
						it.isHide=true;
					}
					if(showIts.indexOf(it.keys)>-1){
						it.isHide=false;
					}
				}
				console.log('hideOrShowItems',JSON.parse(JSON.stringify(this.formlist)))
			},
			closeDialog(val){
				console.log('closeDialog',val)
				this.openWordsSet=false;
				if(val){
					this.htmlDatas.keyWordDatas=val;
					
					this.$parent.updateFormConfig(val);//更新全局最新的from
				}
			},
			async openFormKeyWords(){
				let res =await this.$parent.allowGetAllNodes();
				if(res){
					console.log('获取当前所有节点',res)
					this.bpmnNodes=res.nodes;
					this.openWordsSet=true;
					// 表单字段设置加载时传参
//					props: {
//						// 全量表单数据
//						dataset: {
//							type: String,
//							default() { return ''},
//						},
//						// 当前审批节点的key
//						flowNodeId: {
//							type: String,
//							default() { return ''},
//						},
//						// 是否可编辑，设置时-true, 审批时-false
//						editState: {
//							type: Boolean,
//							default() { return false},
//						},
//					}
					// 表单字段设置确定时调用
					// this.$refs.dynamicFormIns.getFormConfiguredDatas()
					// 将获取到的数据更新到流程配置的formConfig字段
					
				}
//				
			},
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
	  				let fval=this.formInfo[item.keys]
	  				if(fval) item.value=fval;
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  			console.log('赋值后',JSON.parse(JSON.stringify(this.htmlDatas)))
	  		},
		}
		
	};
</script>

<style lang="scss">
	.uTaskHtml  .el-form-item{
	    margin-bottom: 12px;
	    /*.el-textarea__inner,.el-input__count{
    	    background-color: #F7F8FA;
    	    border: none;
	    }*/
	}
	.setTimeinp{
		position: relative;
		.el-input__inner{
			width: calc(100% - 32px);
		}
		.tipTx{
			color: #252D3D;
	        position: absolute;
	        left: 90%;
	        top: 44px;
	        width: 32px;
		}
	}
	.btmlineBox{
		margin-top: 8px;
		border-bottom: 1px solid #E3E8EE;
	}
</style>