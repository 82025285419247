<template>
  <div class="props-component-direction">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <!-- row | column -->
      <el-radio-group v-model="dataset.value">
        <el-radio label="row">横向排列</el-radio>
        <el-radio label="column">纵向排列</el-radio>
      </el-radio-group>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "props-component-direction",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>