<template>
	<!--小程序-功能配置-->
	<mainBox mClass='lgMbox' >
		<div class="wapBox">
			<div >
				<div class="eBtns">
					<el-button class='b_it' @click='refreshTable' icon="el-icon-refresh-right">刷新</el-button>
					<el-button class='b_it' @click='toAddOrEdit()' type="primary" >新增</el-button>
					<!--<el-button class='b_it sxBtn' @click.stop='showRowQuery'>筛选<i class="el-icon-arrow-down" v-if="rowQuery"></i><i class="el-icon-arrow-right" v-else></i></el-button>-->
				</div>
				<div class="tbBox ">
					<TTable2 class='' ref="myTable" :tbloading='tbloading' :border='false' :showQuery='rowQuery' @rowSeach='rowSeach' :tableData="tableData" :tableTitle="tableTitle">
						<template slot='operating' slot-scope="s">
							<el-button type="text" @click='toAddOrEdit(s.row)'>编辑</el-button>
							<el-button type="text" @click='tbRowDel(s.row)'>删除</el-button>
						</template>
					</TTable2>
					<TPagination v-model="tableParams" @initTable="initTable"></TPagination><!-- 分页 -->
				</div>
			</div>
		</div>
		<AddPopup v-if='addInfo.open'  :isOpen='addInfo.open' @close='closeAddEditPup' :dataId='addInfo.dataId' :datas='addInfo.rowData'/>
	</mainBox>
</template>
<script>
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TTable2 from '@/components/YTable/TTable2.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	import AddPopup from './model/AddPopup.vue'
	const statusArrs=[]
	export default {
		mixins: [pagination, seachAndTable],
		components: {TTable2,TPagination,AddPopup},
		data() {
			return {
				tableTitle: [
					{
						name: '功能名称',
						prop: "functionName",
//						queryInfo: {type: 'input',value: "",},
					},
					{
						name: '创建人',
						prop: "creationUserName",
					},
					{
						name: '创建时间',
						prop: "creationDateStr",
					},
					{
						name: '修改人',
						prop: "updateUserName",
					},
					{
						name: '修改时间',
						prop: "updateDateStr",
					},
					
//					{
//						name: '状态',
//						prop: "materielStatus",
//						slotName:'endingStatusStr',
//						queryInfo: {
//							type:'select',
//							value:'',
//							options:[],
//						}
//					},
				
					{
						name: '操作',
						width: "180",
						fixed: 'right',
						slotName: "operating",
					},
				],
				tableData: [],
				addInfo: { //新增，编辑的弹框信息
					open: false,
					dataId: '',
					rowData:{},
				},
			};
			},
			watch: {},
				created() {
				},
				mounted() {
					this.showRowQuery();
				},
				computed: {},
				methods: {
					
					async initTable() {
						let params = {
							current: this.tableParams.current,
							rowCount: this.tableParams.rowCount,
							...this.rowFilterData,
						}
						for(let keys in params){
							if(!params[keys]) delete params[keys];
							
						}
						let res = await this.ApiHttp('/legoBuilder/miniprogramFunction/page', params);
						
						res.rows.map(it=>{
							it.iconsArr=[];
							it.creationDateStr=this.common.getTimeMmss(it.creationDate);
							it.updateDateStr=this.common.getTimeMmss(it.updateDate);
							if(it.functionIcon) it.iconsArr.push({fileId:it.functionIcon,fileType: "image/jpeg"});//拼装前端需要编辑的图片
						})
						this.tableData = res.rows||[];
						this.tableParams.total = res.total;
						this.$nextTick(() => {
							this.tbloading = false;
						})
					},
					toAddOrEdit(row){//点击打开弹窗
						let dataId='';
						if(row) dataId=row.functionId;
						this.addInfo={
				      		open:true,
				      		dataId,
				      		rowData:{...row}
				      	};
					},
					tbRowDel(row) {
						this.$confirm('是否确认删除？', '提示').then(() => {
							this.ApiHttp('/legoBuilder/miniprogramFunction/delete/'+row.functionId, {}, 'delete').then(res => {
								if(res) {
									this.initTable();
									this.$message({type:'success',message:'删除成功'})
								}
							});

						}).catch(() => {});
					},
					closeAddEditPup(isInit){//关闭添加编辑弹框
						this.addInfo={
				      		open:false,
				      		dataId:'',
				      		rowData:{},
				      	};
				      	if(isInit){
				      		this.tableParams.current=1;
				      		this.initTable();
				      	}
					},
					

				},
			};</script>

<style scoped lang="scss">
	.wapBox {
		height: 100%;
		.flexs {
			height: 100%;
		}

		.right {
			padding: 16px 24px;
			.eBtns {
				position: relative;
				.el-button {
					padding: 10px 18px;
				}
				.sxBtn{
					position: absolute;
					right: 0;
				}
			}
		}
		.txColor0{
	    padding: 0 6px;
	   	color: #4e78ff;
    	background: #f4f6fe;
		}
		.txColor4{
	    padding: 0 6px;
	   	color: #78d549;
    	background: #f2faee;
		}
		.txColor5{
	    padding: 0 6px;
	   	color: #e8331c;
  		background: #fef5f4;
		}
	}
</style>
