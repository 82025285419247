<script>
import statusBlue from "@/assets/images/approval/status-blue.svg";
import statusYellow from "@/assets/images/approval/status-yellow.svg";
import statusRed from "@/assets/images/approval/status-red.svg";
import {
  apiGetTransferNodeList,
  apiGetDefinitionBackData,
  apiGetEmployeeTreeAll,
} from "@/api/ruge/workflow/approvalProcess";
import { omit } from "lodash";

export default {
  name: "CloseTransformFormModal",
  components: {},
  props: {
    showTransferredField: {
      type: Boolean,
      default: false,
    },
    /**
     * 转办需要
     */
    definitionId: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerLoading: true,
      statusBlue,
      statusRed,
      statusYellow,
      transferredPersonnelList: [],
      transferredNodeList: [],
      radioList: [
        { key: 0, label: "一般", imgSrc: statusBlue },
        { key: 1, label: "急", imgSrc: statusYellow },
        { key: 2, label: "紧急", imgSrc: statusRed },
      ],
      form: {
        /** 转办节点 */
        transferredNode: "",
        /** 转办人 */
        transferredPersonnel: [],
        /** 紧急程度 */
        status: 0,
        /** 是否通知 */
        isEndNotify: true,
        /** 当前处理人 */
        currentUser: "",
        /** 上一处理人 */
        prevUsers: "",
        /** 意见 */
        auditComment: "",
      },
    };
  },
  mounted() {
    if (this.showTransferredField) {
      this.fetchTransferredPersonnelList();
      this.fetchTransferredNodeList();
    }
    this.fetchDefinitionBackData();
  },
  methods: {
    async fetchTransferredPersonnelList() {
      try {
        const res = await apiGetEmployeeTreeAll();
  
        const getTreeData = (res) => {
          return res.map((item, index) => {
            const option = {
              value: "",
              label: "",
              isLeaf: false,
            };

            if (item.userId) {
              option.value = item.userId;
              option.label = item.userName;
              option.isLeaf = true;
            } else if (item.deptId) {
              option.value = `deptId-${item.deptId}`;
              option.label = item.deptName;
              option.children = [];
            } else if (item.companyId) {
              option.value = `companyId-${item.companyId}`;
              option.label = item.companyName;
              option.children = [];
            }
            if (item.children && item.children.length) {
              option.children = getTreeData(item.children);
            }
            return option;
          });
        };
        this.transferredPersonnelList = getTreeData(res);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchTransferredNodeList() {
      try {
        if (!this.definitionId) {
          this.$message.error("缺少definitionId");
          return;
        }
        const res = await apiGetTransferNodeList({
          definitionId: this.definitionId,
        });
        this.transferredNodeList = res.map((item, index) => {
          return {
            value: item.taskId,
            label: item.nodeName,
          };
        });
        const firstNode = this.transferredNodeList.at(0);
        if (firstNode) {
          this.form.transferredNode = firstNode.value;
        }
        console.log(res, "this.transferredNodeList", this.transferredNodeList);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchDefinitionBackData() {
      try {
        if (!this.definitionId) {
          this.$message.error("缺少definitionId");
          return;
        }
        this.innerLoading = true;
        const res = await apiGetDefinitionBackData({
          definitionId: this.definitionId,
        });
        this.form.currentUser = res.currentProcessor;
        this.form.prevUsers = res.handleProcessor;
        console.log(res);
      } catch (error) {
        console.error(error);
      } finally {
        this.innerLoading = false;
      }
    },
    onOk() {
      const form = { ...this.form };
      form.isEndNotify = form.isEndNotify ? "Y" : "N";
      if (!this.showTransferredField) {
        return omit(form, [
          "transferredPersonnel",
          "transferredNode",
          "isEndNotify",
        ]);
      }
      const transferredPersonnel = this.form.transferredPersonnel
      const personnelLen = Array.isArray(transferredPersonnel)  ? transferredPersonnel.length : 0
      if (personnelLen < 1) {
        throw new Error('请选择转办人员')
      }
      const id = transferredPersonnel.at(personnelLen-1)
      if(!id) {
        throw new Error('查询转办人员ID失败')
      }
      form.transferredPersonnel = id
      return form;
    },
  },
  expose: ["onOk"],
};
</script>

<template>
  <el-form
    v-loading="innerLoading || loading"
    label-position="top"
    ref="flowFormRef"
    label-width="80px"
    :model="form"
    class="flow-form"
  >
    <template v-if="showTransferredField">
      <el-form-item>
        <template #label>
          <div class="flow-form-item">
            <span class="flow-form-item-label">转办节点</span>
          </div>
        </template>
        <el-select
          class="select"
          placeholder="请选择节点"
          v-model="form.transferredNode"
        >
          <el-option
            v-for="item in transferredNodeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div class="flow-form-item">
            <span class="flow-form-item-label">转办人员</span>
          </div>
        </template>
        <el-cascader
          filterable
          v-model="form.transferredPersonnel"
          placeholder="请选择转办人员"
          class="select"
          :options="transferredPersonnelList"
        ></el-cascader>
      </el-form-item>
    </template>

    <el-form-item>
      <template #label>
        <div class="flow-form-item">
          <span class="flow-form-item-label">通知紧急程度</span>
        </div>
      </template>
      <el-radio-group class="radio-group-border" v-model="form.status">
        <el-radio
          v-for="item in radioList"
          :key="item.key"
          class="radio-border"
          :label="item.key"
          border
          :class="{ 'radio-checked': form.status === item.key }"
        >
          <div class="radio-item">
            <img :src="item.imgSrc" :alt="item.label" />
            <span>{{ item.label }}</span>
          </div>
        </el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item v-if="showTransferredField">
      <template #label>
        <div class="flow-form-item">
          <span class="flow-form-item-label">通知选项</span>
        </div>
      </template>
      <el-checkbox class="checkbox" v-model="form.isEndNotify"
        >流程结束后通知我</el-checkbox
      >
    </el-form-item>

    <el-form-item>
      <template #label>
        <div class="flow-form-item">
          <span class="flow-form-item-label">当前处理人</span>
        </div>
      </template>
      <div class="text-user">{{ form.currentUser }}</div>
    </el-form-item>
    <el-form-item>
      <template #label>
        <div class="flow-form-item">
          <span class="flow-form-item-label">已经处理人</span>
        </div>
      </template>
      <div class="text-user">{{ form.prevUsers }}</div>
    </el-form-item>
    <el-form-item>
      <template #label>
        <div class="flow-form-item">
          <span class="flow-form-item-label">处理意见</span>
        </div>
      </template>
      <el-input
        class="textarea"
        type="textarea"
        placeholder="请输入内容"
        v-model="form.auditComment"
      >
      </el-input>
    </el-form-item>
  </el-form>
</template>

<style scoped lang="less">
.flow-form {
  width: 540px;
}

.flow-form-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 540px;

  &-label {
    height: 16px;
    font-weight: 600;
    font-size: 16px;
    color: #252d3d;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }

  &-path {
    height: 14px;
    font-weight: 400;
    font-size: 14px;
    color: #5d687c;
    line-height: 14px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}
.select {
  ::v-deep {
    input {
      width: 540px;
      height: 46px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #d1d5dd;
    }
  }
}
.radio-group-border {
  display: flex;
  column-gap: 30px;
  .radio-border {
    height: 42px;
    min-width: 160px;
    display: flex;
    flex-direction: row-reverse;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #d1d5dd;
    align-items: center;
    justify-content: space-between;
    padding: 11px 10px;
    margin: 0 !important;
  }
  .radio-item {
    display: flex;
    align-items: center;
    span {
      font-weight: 500;
      font-size: 14px;
      color: #252d3d;
      line-height: 16px;
      margin-left: 11px;
    }
  }
  .radio-checked {
    border-color: #2a61ff;
  }
}
.checkbox {
  ::v-deep {
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background: #2a61ff;
    }
    .el-checkbox__label {
      font-weight: 400;
      font-size: 14px;
      color: #252d3d;
      line-height: 16px;
      padding-left: 7px;
    }
  }
}
.text-user {
  font-weight: 400;
  font-size: 14px;
  color: #252d3d;
  line-height: 16px;
}
.textarea {
  ::v-deep {
    textarea {
      height: 80px;
      padding: 12px 16px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #d1d5dd;
    }
  }
}
::v-deep {
  .el-radio__inner::after {
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 4px;
    position: absolute;
    top: 1px;
    transform: rotate(45deg) scaleY(0);
    width: 3px;
    /* transition: transform .15s ease-in .05s; */
    transform-origin: center;
    border-radius: unset;
    background-color: unset;
    transition: unset;
  }
  .el-radio__input.is-checked .el-radio__inner::after {
    transform: rotate(45deg) scaleY(1);
  }
  .el-radio:hover .el-radio__inner {
    border-color: #2a61ff;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: #2a61ff !important;
    background: #2a61ff;
  }
  .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled)
    .el-radio__inner {
    box-shadow: 0 0 2px 2px #2a61ff;
  }
}
</style>
