<template>
  <div class="props-component-occupy">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <div>
        <el-switch
          class="require-switch"
          v-model="dataset.value.value"
          active-color="#2A61FF"
          inactive-color="#ccc"
          :active-value="true"
          :inactive-value="false"
        >
        </el-switch>
      </div>
      <template v-if="dataset.value.value">
        <div class="occupy-line">
          <span class="occupy-label"> 显示清除按钮 </span>
          <el-switch
            v-model="dataset.value.clearable"
            active-color="#2A61FF"
            inactive-color="#ccc"
            :active-value="true"
            :inactive-value="false"
          >
          </el-switch>
        </div>
        <div>
          <div class="occupy-label">占位内容</div>
          <el-input v-model="dataset.value.placeHolder" />
        </div>
      </template>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "props-component-occupy",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="less" scoped>
.props-component-occupy {
  .occupy-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }
  .occupy-label {
    font-weight: 400;
    font-size: 14px;
    color: #5d687c;
  }
}
</style>