<!--
 * @LastEditors: luo
 * @LastEditTime: 2024-12-02 11:40:26
 * @Description:  
 * @Link: https://sit.rlinkiot.com/saascloud/workflow/mode/publishRecord?modeId=xxx
-->
<template>
  <div class="box" v-on="$listeners">
    <div class="title">
      <span>{{ data.updateUserName }}</span>
      <span>发布了</span>
      <el-tooltip class="tooltip-node" placement="top-start" v-tooltip-auto-show :content=" data.nodeName">
        <span class="text-overflow">{{ data.nodeName }}</span>
      </el-tooltip>
    </div>
    <div class="date">{{ data.publishTime }}</div>
    <div class="remark">
      <span class="start-text">发布备注：</span>
      <span class="content">{{ data.publishDescription }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecordBox",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
  //min-height: 154px;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #eef0f7;
  padding: 16px 16px 20px 16px;

  .tooltip-node{
    flex: 1;
  }
  .text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .title {
    display: flex;
    column-gap: 6px;
    height: 18px;

    font-size: 14px;
    color: #252d3d;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    text-transform: none;

    > span {
      font-weight: 600;
    }

    margin-bottom: 10px;
  }

  .date {
    height: 12px;
    font-weight: 400;
    font-size: 12px;
    color: #b5bece;
    line-height: 12px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 12px;
  }

  .remark {
    .start-text {
      font-weight: 600;
      font-size: 12px;
      color: #252d3d;
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .content {
      font-size: 12px;
      color: #5d687c;
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      word-break: break-all;
    }
  }

  .mulLineTruncate {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}
</style>
