var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flowHtml", class: { lookHmtl: _vm.isLookBpmn } },
    [
      !_vm.isCompent
        ? _c("div", { staticClass: "header-line" }, [
            _c("div", { staticClass: "header-left" }, [
              _c(
                "div",
                { staticClass: "back-div", on: { click: _vm.goBack } },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/go_back_icon.png"),
                      alt: "",
                    },
                  }),
                  _c("span", [_vm._v("返回")]),
                ]
              ),
              _c("div", { staticClass: "border-line" }),
              _c("div", { staticClass: "name-div" }, [
                _vm._v("流程配置 - " + _vm._s(_vm.publisModalName)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "header-right" },
              [
                _c(
                  "r-button",
                  {
                    attrs: { size: "small" },
                    on: { click: _vm.toFormSetHtml },
                  },
                  [_vm._v("表单配置")]
                ),
                _c(
                  "r-button",
                  {
                    attrs: {
                      size: "small",
                      disabled: _vm.enableStatus === "DISABLE",
                    },
                    on: { click: _vm.tohandlePublish },
                  },
                  [_vm._v("发布")]
                ),
                _c(
                  "r-button",
                  { attrs: { plain: "" }, on: { click: _vm.saveBpmn } },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "mainBox",
        {
          staticClass: "Y-bpmn",
          class: { compBox: _vm.isCompent },
          style: _vm.difWidthHeight(),
        },
        [
          _c("div", { staticClass: "lfBox" }, [
            _c("div", { ref: "content", staticClass: "containers" }, [
              _c("div", {
                ref: "canvas",
                staticClass: "bpmnCanvas",
                attrs: { id: "canvas" },
              }),
              _vm.bpmnNotMove
                ? _c("div", { staticClass: "notMoveBox" })
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "rtBox" },
            [
              _vm.isLookBpmn
                ? _c("div", { staticClass: "disableBox" })
                : _vm._e(),
              _vm.bpmnModeler
                ? _c("flowConfig", {
                    ref: "rtPanel",
                    attrs: { modeler: _vm.bpmnModeler },
                    on: { saveWebData: _vm.saveBpmn },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "flow-modal",
        {
          attrs: {
            width: "600px",
            title: "发布",
            visible: _vm.publishModalConfig.visible,
            loading: _vm.btnLoading,
          },
          on: {
            onOk: _vm.onOkPublishModal,
            onCancel: _vm.onCancelPublishModal,
          },
        },
        [
          _vm.publishModalConfig.visible
            ? _c("publish-modal", {
                ref: "publishModalRef",
                attrs: {
                  path: _vm.publisNodeNamePaths,
                  "default-form": _vm.publishModalConfig.data.form,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.showNodeTip
        ? _c(
            "div",
            {
              ref: "diyTooltip",
              staticClass: "diyTooltip",
              style: _vm.difElementXy,
            },
            [
              _vm.toolMesTip.data.reviewer &&
              _vm.toolMesTip.data.reviewer.length > 0
                ? _c(
                    "div",
                    [
                      _c("div", [_vm._v("节点处理人:")]),
                      _vm._l(_vm.toolMesTip.data.reviewer, function (_tx, dex) {
                        return _c("div", { key: dex }, [
                          _c("div", { staticClass: "_peoMes" }, [
                            _c("span", { staticClass: "_round" }),
                            _tx.deptName && _tx.userName
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_tx.deptName) +
                                      " - " +
                                      _vm._s(_tx.userName)
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(_tx.userName || _tx.deptName)),
                                ]),
                            _vm._v(";\n\t\t\t\t\t\n\t\t\t\t"),
                          ]),
                        ])
                      }),
                    ],
                    2
                  )
                : _c("div", [
                    _vm.countloading
                      ? _c("i", { staticClass: "el-icon-loading" })
                      : _c("span", [_vm._v("暂无法计算处理人")]),
                  ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }