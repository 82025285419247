<template>
  <el-tooltip
      placement="top-start"
      v-tooltip-auto-show
      popper-class="path-popper"
      :content="value.all"
      style="width: 90%;"
  >
    <div>
      <div class="text-overflow" style="display: flex;">
        <div class="path-text text-overflow">{{ value.prev }}</div>
        <div class="path-text split-code">{{ value.splitCode }}</div>
        <div class="path-text path-text-current text-overflow">
          {{ value.current }}
        </div>
      </div>
    </div>
  </el-tooltip>
</template>
<script>
import "./path-tooltip.scss"
export default {
  name: "PathTooltip",
  props: {
    value: {
      type: Object,
      default: () => ({
        prev: ``,
        current: '',
        all: ``,
        splitCode: '',
      })
    }
  }
};
</script>
<style lang="less" scoped>
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.path-text {
  font-weight: 400;
  font-size: 14px;
  color: #5D687C;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.split-code{
  margin: 0 4px;
}
.path-text-current {
  flex: auto;
  color: #252d3d;
}

</style>
