var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "graph-component-fileUploader single-graph-component" },
    [
      _c(
        "div",
        { class: ["item-label", _vm.datas.require && "item-label-required"] },
        [_vm._v("\n    " + _vm._s(_vm.datas.label) + "\n  ")]
      ),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "uploader-example" }, [
      _c("img", {
        staticStyle: { "margin-bottom": "8px" },
        attrs: {
          src: require("@/assets/images/import_folder_icon.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "el-upload__text" }, [
        _vm._v("\n      将文件拖到此处，或"),
        _c("em", { staticStyle: { color: "#2a61ff" } }, [
          _vm._v("点击选择文件"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }