import { render, staticRenderFns } from "./default.vue?vue&type=template&id=36f10fd6"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\ruge-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36f10fd6')) {
      api.createRecord('36f10fd6', component.options)
    } else {
      api.reload('36f10fd6', component.options)
    }
    module.hot.accept("./default.vue?vue&type=template&id=36f10fd6", function () {
      api.rerender('36f10fd6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ruge/workflow/detail/propsComponents/default.vue"
export default component.exports