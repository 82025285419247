<template>
  <div class="guide-form-comp">
    <el-form :model="ruleForm" :rules="rules" label-position="top" ref="ruleForm" label-width="100px"
      class="demo-ruleForm">
      <el-row>
        <el-col>
          <el-form-item label="项目选择" prop="projectId">
            <el-select style="width: 100%" v-model="ruleForm.projectId" @change="subsetCompany">
              <el-option v-for="(item, index) in projectData" :value="item.companyId" :label="item.companyName"
                :key="index" @click.native="optionUnit(item.companyName)"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="所属部门" prop="companyId">
            <el-select style="width: 100%" v-model="ruleForm.companyId" filterable placeholder="请选择所属部门">
              <el-option v-for="(item, index) in companyList" :key="index" :value="item.deptId" :label="item.deptName"
                @click.native="changeCompany(item.deptName)"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="仓库管理员" prop="administratorList">
            <el-select style="width: 100%" v-model="ruleForm.administratorList" multiple>
              <el-option v-for="item in empData" :key="item.userId" :label="item.userName" :value="item.userId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="仓库名称" prop="warehouseName">
            <el-input placeholder="请输入仓库名称" v-model="ruleForm.warehouseName" maxlength="50" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="仓库编码" prop="warehouseCode">
            <el-input placeholder="请输入仓库编码" v-model="ruleForm.warehouseCode" maxlength="50" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="可使用公司" prop="availableCompanyList">
            <el-select style="width: 100%" v-model="ruleForm.availableCompanyList" multiple>
              <el-option v-for="item in companyListAll" :key="item.companyId" :label="item.companyName"
                :value="item.companyId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="可使用部门" prop="availableDepartmentList">
            <el-select style="width: 100%" v-model="ruleForm.availableDepartmentList" multiple>
              <el-option v-for="item in companyList" :key="item.deptId" :label="item.deptName" :value="item.deptId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="所属地区" prop="affiliatedArea">
            <el-select style="width: 100%" v-model="ruleForm.affiliatedArea" filterable placeholder="请选择所属地区">
              <el-option v-for="(item, index) in areaList" :key="index" :value="item.value"
                :label="item.label"></el-option></el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-row>
          <el-col>
            <el-form-item label="仓库地址" prop="warehouseAddress">
              <el-input placeholder="请输入仓库地址" v-model="ruleForm.warehouseAddress"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item align="right">
          <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick>确定</el-button>
          <el-button @click="resetForm">取消</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {
  getWarehouseDetail,
  getProjectList,
  getCompany,
  getEmpList,
  getCompanyListAll,
  addWarehouse
} from "@/api/ruge/ams/basicConfig/warehouse";
export default {
  props: {
    warehouseId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      ruleForm: {
        projectId: null,
        projectName: null,
        companyId: null,
        companyName: null,
        warehouseName: null,
        warehouseCode: null,
        administratorList: [],
        availableCompanyList: [],
        availableDepartmentList: [],
        affiliatedArea: null,
        warehouseAddress: null
      },
      rules: {
        projectId: [{ required: true, message: "项目选择为必填", trigger: "change" },],
        companyId: [{ required: true, message: "所属部门为必填", trigger: "change" },],
        warehouseName: [{ required: true, message: "仓库名称为必填", trigger: "blur" }],
        warehouseCode: [{ required: true, message: "仓库编码为必填", trigger: "blur" }],
        administratorList: [{ required: true, message: "仓库管理员为必填", trigger: "change" },],
        availableCompanyList: [{ required: true, message: "可使用公司为必填", trigger: "change" },],
        availableDepartmentList: [{ required: true, message: "可使用部门为必填", trigger: "change" },],
        affiliatedArea: [{ required: true, message: "所属地区为必填", trigger: "change" },],
        warehouseAddress: [{ required: true, message: "仓库名称为必填", trigger: "blur" }],
      },
      projectData: [],
      companyList: [],
      empData: [],
      companyListAll: [],
      areaList: [{ value: "深圳", label: "深圳" }, { value: "广州", label: "广州" }]

    };
  },
  created() {
    if (this.warehouseId) {
      this.findDetail()
    }
    this.getProject()
  },
  methods: {
    findDetail() {
      getWarehouseDetail({
        warehouseId: this.warehouseId
      }).then((res) => {
        if (res.code === 200) {
        this.ruleForm.projectId = res.data.affiliatedCompanyId
        this.ruleForm.projectName = res.data.affiliatedCompanyName
        this.ruleForm.companyId = res.data.affiliatedDepartmentId
        this.ruleForm.companyName = res.data.affiliatedDepartmentName
        this.ruleForm.warehouseName = res.data.warehouseName
        this.ruleForm.warehouseCode = res.data.warehouseCode
        this.ruleForm.affiliatedArea=res.data.affiliatedArea
        this.ruleForm.warehouseAddress = res.data.warehouseAddress
        this.ruleForm.administratorList = res.data.administratorList.map((item)=>{return item.correlationId})
        this.ruleForm.availableCompanyList = res.data.availableCompanyList.map((item)=>{return item.correlationId})
        this.ruleForm.availableDepartmentList = res.data.availableDepartmentList.map((item)=>{return item.correlationId})
        this.subsetCompany(res.data.affiliatedCompanyId)
        console.log("availableCompanyList", this.ruleForm)
        }
      });
    },
    handleChange(value) {
      console.log(value);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitHandler();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitHandler() {
      let adminList = [] //仓库管理员
      this.empData.forEach((item) => {
        this.ruleForm.administratorList.forEach((adItem) => {
          if (item.userId == adItem) {
            adminList.push({
              correlationId: item.userId,
              correlationValue: item.userName
            })
          }
        })
      })

      let comList = []
      this.companyListAll.forEach((item) => {
        this.ruleForm.availableCompanyList.forEach((adItem) => {
          if (item.companyId == adItem) {
            comList.push({
              correlationId: item.companyId,
              correlationValue: item.companyName
            })
          }
        })
      })

      let departList = []
      this.companyList.forEach((item) => {
        this.ruleForm.availableDepartmentList.forEach((adItem) => {
          if (item.deptId == adItem) {
            departList.push({
              correlationId: item.deptId,
              correlationValue: item.deptName
            })
          }
        })
      })
      addWarehouse({
        warehouseId: this.warehouseId,
        affiliatedCompanyId: this.ruleForm.projectId,
        affiliatedCompanyName: this.ruleForm.projectName,
        affiliatedDepartmentId: this.ruleForm.companyId,
        affiliatedDepartmentName: this.ruleForm.companyName,
        warehouseName: this.ruleForm.warehouseName,
        warehouseCode: this.ruleForm.warehouseCode,
        administratorList: adminList,
        availableCompanyList: comList,
        availableDepartmentList: departList,
        affiliatedArea: this.ruleForm.affiliatedArea,
        warehouseAddress: this.ruleForm.warehouseAddress,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("编辑成功！");
          this.$emit("close", true);
        } else {
          this.$message.warning("编辑失败！");
        }
      });


    },
    resetForm() {
      this.$emit("close", false);
    },
    getProject() {
      getProjectList().then((res) => {
        if (res) {
          this.projectData = res;
        }
      });
      getCompanyListAll().then((res) => {
        if (res) {
          this.companyListAll = res;
        }
      });
    },

    optionUnit(data) {
      this.ruleForm.projectName = data;
      this.ruleForm.companyId=null
      this.ruleForm.companyName=null
      this.ruleForm.administratorList=[]
      this.ruleForm.availableCompanyList=[]
      this.ruleForm.availableDepartmentList=[]
      console.log("optionUnit", data)

    },
    subsetCompany(id) {
      getCompany({
        companyId: id,
      }).then((res) => {
        if (res) {
          this.companyList = res;
        }
      });
      getEmpList({
        companyId: id,
      }).then((res) => {
        if (res) {
          this.empData = res;
        }
      });
      console.log("this.ruleForm", this.ruleForm)
    },
    changeCompany(data) {
      this.ruleForm.companyName = data;
      console.log("this.ruleForm", this.ruleForm)
    },
  },
};
</script>

<style scoped lang="less"></style>