import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 *  分页查询
 * @param params
 */
export function getCodeConfigList(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/encoding/getEncodingList',
    method: 'get',
    params,
  });
}



/**
 *  删除
 * @param params
 */
export function deleteEncode(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/encoding/delete',
    method: 'get',
    params,
  });
}
/**
 * 新增
 * @param params
 */
export function addEncod(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/encoding/add',
    method: 'post',
    data: params,
  });
}


/**
 * 编辑
 * @param params
 */
export function updateEncod(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/encoding/update',
    method: 'post',
    data: params,
  });
}
