import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 *
 * @param {modeId} params
 * @returns
 */
//获取流程分页列表
export function getWorkFlowDetails(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/mode/preFindOne',
    method: 'get',
    params,
  });
}
/**
 * 保存分类节点saveNode
 */
export function processSaveForm(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/mode/saveNode',
    method: 'post',
    data: params,
  });
}

/**
 * 通过nodeId获取流程详情
 * params: modeId
 */
export function getDetailsByNodeId(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/mode/findOne',
    method: 'get',
    params,
  });
}

/**
 * 通过versionId获取流程详情
 */
export function getDetailsByVersionId(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/mode/publishRecord',
    method: 'get',
    params,
  });
}

/**
 * 获取当前登录人姓名和部门
 */
export function getUserAndDeptName(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/tenant/employee/selectByUserDeptLeader',
    method: 'get',
  });
}

/**
 * 根据流程实例ID获取已审核节点
 * definitionId
 */
export function getApprovedNodes(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/workflowNode/queryApprovedNodes',
    method: 'get',
    params,
  });
}

/**
 * 发起流程
 */
export function createDefinition(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/definition/createDefinition',
    method: 'post',
    data: params,
  });
}

/**
 * 审核流程
 */
export function auditDefinition(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/definition/auditDefinition',
    method: 'post',
    data: params,
  });
}

/**
 * 暂存
 */
export function temporarilyDefinition(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/definition/temporarilyDefinition',
    method: 'post',
    data: params,
  });
}

/**
 * 根据流程实例ID获取已审核节点
 * definitionId
 */
 export function getDetailsByDefinitionId(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/definition/getDefinition',
    method: 'get',
    params,
  });
}

/**
 * 根据流程实例ID获取获取审核记录
 * definitionId
 */
 export function getOperationLogByDefinitionId(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/approvalLog/queryOperateLog',
    method: 'get',
    params,
  });
}

/**
 * 根据流程实例ID获取获取获取已审核记录
 * definitionId
 */
 export function getAuditeLogByDefinitionId(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/approvalLog/queryAuditedLog',
    method: 'get',
    params,
  });
}

/**
 * params:modeId versionId
 */
 export function getModeVersionDetail(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/recordVersion",
    method: "get",
    params,
  });
}

/**
 * 发布
 */
 export function publishMode(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/mode/publishMode",
    method: 'post',
    data: params,
  });
}

/**
 * 根据当前节点获取即将流向节点
 */
 export function queryToNode(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/workflowNode/queryToNode",
    method: 'post',
    data: params,
  });
}

/**
 * 通过nodeId查审核人
 */
 export function queryBasedOnReviewerType(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/workflowNode/queryBasedOnReviewerType",
    method: 'get',
    params,
  });
}

/**
 * 收回加签的人员列表
    taksId
 */
 export function getWithdrawUserId(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/definition/getWithdrawUserId",
    method: 'get',
    params,
  });
}

/**
 * 加签收回
 */
 export function withdrawSign(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/definition/withdrawSign",
    method: 'post',
    data: params,
  });
}

/**
 * 退回加签
 */
 export function rejectSign(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/definition/rejectSign",
    method: 'post',
    data: params,
  });
}

/**
 * 删除流程实例
 */
 export function deleteDefinition(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/definition/deleteDefinition",
    method: 'post',
    data: params,
  });
}

/**
 * 以起草人身份操作
 */
 export function initiatorOperation(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/definition/initiatorOperation",
    method: 'post',
    data: params,
  });
}

/**
 * 复制流程
 */
 export function copyDefinition(params) {
  return request({
    url: envInfo.bgApp.workflowPath + "/definition/copyDefinition",
    method: 'get',
    params,
  });
}