<template>
  <div class="dynamic-select-component">
    <el-select
      style="width: 100%"
      v-model="value"
      :disabled="disabled"
      :placeholder="placeHolder"
      :clearable="clearable"
      :multiple="multiple"
      :filterable="filterable"
      @change="updateHandler"
    >
      <el-option
        v-for="(item, index) in optionList"
        :value="item.value"
        :label="item.label"
        :key="index"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { labelRender, updateSource } from "../../../detail/utils.js";
import request from "@/utils/request";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "dynamic-select-component",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        this.multiple = labelRender(datas, "multiple", false);
        this.value = labelRender(datas, "default", this.multiple ? [] : "");
        if (this.multiple) {
          this.value =
            typeof this.value === "string" ? this.value.split(",") : this.value;
        }
        this.placeHolder = labelRender(datas, "placeHolder2", "请选择");
        this.code = labelRender(datas, "code", null);
        this.filterable = labelRender(datas, "filterable", false);
        this.clearable = labelRender(datas, "clearable", false);
        this.optionRender(datas);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      value: null,
      placeHolder: "请选择",
      code: null,
      multiple: false,
      filterable: false,
      clearable: false,
      optionList: [],
    };
  },
  methods: {
    updateHandler() {
      updateSource(this.dataset, 'default', this.value)
      this.$emit("update", {
        key: this.code,
        value: this.value,
      });
    },
    optionRender(data) {
      console.log("data.propsList", data.propsList);
      const { type, optionList, serviceUrl, valueKey, labelKey, classifyCode } =
        labelRender(data, "options", {}, "config");
      if (type === "static") {
        this.optionList = optionList;
        this.badValueFilter();
      } else if (type === "inner") {
        request({
          url: serviceUrl,
          method: "get",
        }).then((res) => {
          this.optionList = res.map((item) => {
            return {
              value: item[valueKey],
              label: item[labelKey],
            };
          });
          this.badValueFilter();
        });
      } else if (type === "classify") {
        request({
          url:
            envInfo.bgApp.lookupPath +
            `/tenant/lookup/item/find/list?classifyCode=${classifyCode}`,
          method: "get",
        }).then((res) => {
          this.optionList = res.map((item) => {
            return {
              value: item["itemCode"],
              label: item["itemName"],
            };
          });
          this.badValueFilter();
        });
      }
    },
    badValueFilter() {
      // key在options中没匹配到，但是下拉框组件把值回显上去的问题
      if (this.multiple) {
        this.value = this.value.filter((item) => {
          const filterTemp = this.optionList.filter(
            (single) => single["value"] == item
          );
          if (filterTemp && filterTemp.length) {
            return true;
          } else {
            // 如果没有匹配到，要把default的value值给清掉，否则影响必填
            this.dataset.propsList.forEach((prop) => {
              if (prop.key === "default") {
                prop.value = [];
              }
            });
            return false;
          }
        });
      } else {
        const filterTemp = this.optionList.filter(
          (single) => single["value"] == this.value
        );
        if (!filterTemp.length) {
          // 如果没有匹配到，要把default的value值给清掉，否则影响必填
          this.dataset.propsList.forEach((prop) => {
            if (prop.key === "default") {
              prop.value = "";
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic-select-component {
}
</style>