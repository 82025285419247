<!--
 * @LastEditors: luo
 * @LastEditTime: 2024-12-02 14:32:38
 * @Description: 
-->
<template>
  <div class="page-wrapper">
    <div class="header-line">
      <div class="left-part">
        <div class="back-div">
          <img src="@/assets/images/go_back_icon.png" alt="" />
          <router-link :to="{ path: '/workflow/list', query }"
            >返回</router-link
          >
        </div>
        <div class="border-line"></div>
        <div class="name-div">
          <span class="name-label"> 发布记录 - {{ title }} </span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <div class="title">发布记录</div>
        <!-- https://sit.rlinkiot.com/saascloud/workflow/mode/publishRecord?modeId=xxx -->
        <RecordBox
          v-for="item in publishRecordList"
          :key="item.versionId"
          :data="item"
          :class="{ selected: selectedRecord.versionId === item.versionId }"
          @click="handleSelectRecord(item)"
        />
      </div>
      <div class="right" ref="rightRef">
        <div ref="rightHeaderRef">
          <div class="right-header">
            <Segmented :options="segmentedOptions" v-model="activeKey" @change="changeSegmented" />
            <div style="display: inline-block">
              <div class="header-btns">
                <el-button class="default-btn" @click="handleRestore"
                  >还原</el-button
                >
                <el-button
                  type="primary"
                  class="default-btn primary-btn"
                  @click="handleCreateCopy"
                  >创建副本</el-button
                >
              </div>
            </div>
          </div>
          <el-divider></el-divider>
        </div>
        <div class="process-graph">
     <div v-if="showProcessGraph">
       <div v-if="processGraphHeight" >
         <ProcessGraph
             ref="processGraphRef"
             v-if="activeKey === 'workflowConfig'"
             :bpmnId="selectedRecord.modeId"
             :allowEdit="false"
             :versionIdSpecial="selectedRecord.versionId"
             :bHeight="processGraphHeight"
             :isCompent="true"
             :bpmnInitData="selectedRecord.versionDetail"
         />
       </div>
     </div>
        </div>
        <div class="dynamic-form" v-loading="formLoading">
          <DynamicForm
            v-if="activeKey === 'formConfig'"
            editState="readOnly"
            :dataset="selectedRecord.formConfig"
          />
        </div>
      </div>
    </div>
    <flow-modal
      width="600px"
      title="创建副本"
      :visible="copyModalConfig.visible"
      @onOk="onOkCopyModal"
      @onCancel="onCancelCopyModal"
      :loading="loading"
    >
      <create-file-modal
        v-if="copyModalConfig.visible"
        ref="copyModalRef"
        :path="copyModalConfig.data.path"
        :defaultForm="copyModalConfig.data.form"
      />
    </flow-modal>
  </div>
</template>

<script>
import { format } from "date-fns";
import {
  apiPublishRecord,
  apiPublishRecordDetail,
} from "@/api/ruge/workflow/workflow";
import RecordBox from "./components/recordBox.vue";
import Segmented from "./components/segmented.vue";
import ProcessGraph from "@/views/ruge/workflow/FlowBpmn";
import { apiRestore, apiCreateCopy } from "@/api/ruge/workflow/workflow";
import { debounce } from "lodash";
import FlowModal from "../list/components/flow-modal.vue";
import CreateFileModal from "../list/components/create-file-modal.vue";
import {
  getTreeData,
  findNode,
  formatTreeData,
  arrayToTree,
} from "../list/utils";
import { apiGetPreNodeTree } from "@/api/ruge/workflow/workflow";
import { getModeVersionDetail } from "@/api/ruge/workflow/detail";
import DynamicForm from "@/views/ruge/workflow/components/DynamicForm";

export default {
  name: "PublishRecord",
  components: {
    RecordBox,
    Segmented,
    ProcessGraph,
    FlowModal,
    CreateFileModal,
    DynamicForm,
  },
  data() {
    return {
      formLoading: false,
      parentNode: {},
      copyModalConfig: {
        visible: false,
        /**
         * Modal data containing:
         * @property {string} id - ID of workflow being copied
         * @property {string} path - Path where copy will be created
         * @property {Object} form - Default form values for copy
         */
        data: {},
      },
      debounceUpdateProcessGraphHeight: () => {},
      processGraphHeight: 0,
      loading: false,
      rightLoading: false,
      title: "",
      selectedRecord: {},
      publishRecordList: [],
      recordDetail: {},
      segmentedOptions: [
        {
          label: "表单配置",
          key: "formConfig",
        },
        {
          label: "流程配置",
          key: "workflowConfig",
        },
      ],
      activeKey: "formConfig",
      /**
       * 版本 id 发生改变，重新加载流程配置
       */
      showProcessGraph: 0,
    };
  },
  mounted() {

    this.fetchTreeData();
    const modeId = this.$route.query.nodeId;
    if (!modeId) {
      this.$message.error("流程模型ID不存在");
      return;
    }
    this.fetchPublishRecord(modeId);
    this.debounceUpdateProcessGraphHeight = debounce(
      this.updateProcessGraphHeight,
      100
    );
    window.addEventListener("resize", this.debounceUpdateProcessGraphHeight);
    this.debounceUpdateProcessGraphHeight();
  },
  methods: {
    changeSegmented(val){
      this.showProcessGraph = val === 'workflowConfig' ? 1 : 0
    },
    async handleRestore() {
      if (!this.selectedRecord.versionId) {
        this.$message.error("没有发布记录");
        return;
      }
      try {
        await apiRestore({
          modeId: this.nodeId,
          versionId: this.selectedRecord.versionId,
        });
        this.$message.success("还原成功");
        this.goBack();
      } catch (error) {
        this.$message.error("还原失败");
      }
    },
    handleCreateCopy() {
      if (!this.selectedRecord.versionId) {
        this.$message.error("没有发布记录");
        return;
      }
      const path = this.parentNode.parentNodes
        ? this.parentNode.parentNodes.map((item) => item.nodeName).join(" ＞ ")
        : "";
      const data = {
        id: this.nodeId,
        path,
        form: { name: `${this.selectedRecord.processName}-副本` },
        versionId: this.selectedRecord.versionId,
      };
      this.copyModalConfig = {
        visible: true,
        data,
      };
    },
    async onOkCopyModal() {
      try {
        const data = await this.$refs.copyModalRef.onOk();
        if (!data) return;
        const params = {
          modeId: this.nodeId,
          versionId: this.selectedRecord.versionId,
          processCode: data.code,
          processName: data.name,
        };
        const res = await apiCreateCopy(params);
        this.$message.success("创建副本成功");
        if (!res.modeId) {
          throw new Error("缺少节点信息，跳转表单配置页失败～");
        }

        this.onCancelCopyModal();
        this.$router.push({
          path: "/workflow/detail?layout=hide",
          query: {
            nodeId: res.modeId,
            folderNodeId: this.$route.query.folderNodeId,
          },
        });
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    onCancelCopyModal() {
      this.copyModalConfig = {
        visible: false,
        data: {},
      };
    },
    handleSelectRecord(item) {
      console.log(item);
      if (this.selectedRecord.versionId !== item.versionId) {
        this.selectedRecord = item;
        this.showProcessGraph = 0
        this.getFormConfig(this.nodeId, item.versionId, () => {
          if (this.activeKey === 'workflowConfig'){
            this.showProcessGraph = 1
          }
        });

        // this.fetchPublishDetail(item.versionId);
      }
    },
    getFormConfig(modeId, versionId, cb) {
      this.formLoading = true;
      getModeVersionDetail({ modeId, versionId })
        .then((res) => {
          this.selectedRecord.versionDetail  = res
          this.selectedRecord.formConfig = res.formConfig;
        })
        .finally(() => {
          if (cb){
            cb()
          }
          this.formLoading = false;
        });
    },
    fetchPublishRecord(modeId) {
      this.loading = true;
      apiPublishRecord({ modeId })
        .then((res) => {
          this.publishRecordList = res.map((item) => {
            return {
              ...item,
              nodeName: item.processName,
              publishTime: format(
                new Date(item.lastUpdateDate),
                "yyyy-MM-dd HH:mm:ss"
              ),
              remake: item.publishDescription,
            };
          });
          const record = this.publishRecordList.at(0) || {};
          this.title = record.processName;
          this.selectedRecord = record;
          this.getFormConfig(this.nodeId, record.versionId);
          // if (record.versionId) {
          //   this.fetchPublishDetail(record.versionId);
          // }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    fetchPublishDetail(versionId) {
      const modeId = this.nodeId;
      if (!modeId) {
        this.$message.error("流程模型ID不存在");
        return;
      }
      this.rightLoading = true;
      apiPublishRecordDetail({ modeId, versionId })
        .then((res) => {
          const data = Array.isArray(res) ? res.at(0) : {};
          this.recordDetail = data;
          this.rightLoading = false;
        })
        .catch((err) => {
          this.rightLoading = false;
          this.recordDetail = {};
        });
    },
    updateProcessGraphHeight() {
      const rightHeight = this.$refs.rightRef
        ? this.$refs.rightRef.clientHeight
        : 0;
      const rightHeaderHeight = this.$refs.rightHeaderRef
        ? this.$refs.rightHeaderRef.clientHeight
        : 0;
      this.processGraphHeight = rightHeight - rightHeaderHeight;
    },
    async fetchTreeData() {
      this.loading = true;
      try {
        const res = await apiGetPreNodeTree();
        const data = res.filter((item) => item.nodeType !== "mode");
        const treeData = formatTreeData(arrayToTree(getTreeData(data)));
        console.log(treeData, "treeData");
        const folderNodeId = this.$route.query.folderNodeId;
        const node = findNode(treeData, (node) => node.id === folderNodeId);
        console.log(node, "node");
        this.parentNode = node || {};
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    goBack() {
      const query = this.$route.query;
      this.$router.push({
        path: "/workflow/list",
        query: { folderNodeId: query.folderNodeId },
      });
    },
  },
  computed: {
    query() {
      const query = this.$route.query;
      return { folderNodeId: query.folderNodeId };
    },
    nodeId() {
      return this.$route.query.nodeId;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debounceUpdateProcessGraphHeight);
  },
};
</script>
<style lang="scss" scoped>
.page-wrapper {
  ::v-deep {
    .header-line {
      display: none;
    }
  }

  height: 100vh;
  display: flex;
  box-sizing: border-box;
  background: #f1f6fd;
  font-size: 14px;
  font-family: "PingFang SC", "Microsoft YaHei", sans-serif;
  overflow: auto;
  flex-direction: column;

  .selected {
    border-color: #2a61ff !important;
  }

  .header-line {
    width: 100%;
    height: 70px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    border-bottom: 1px solid #e3e8ee;

    .left-part {
      display: flex;
      align-items: center;

      .back-div {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        color: #2a61ff;

        img {
          margin-right: 4px;
          position: relative;
          top: -1px;
        }

        span {
          font-weight: 600;
        }
      }

      .border-line {
        width: 1px;
        height: 12px;
        background: #cbdbe9;
        margin: 0 10px;
      }

      .name-div {
        display: flex;
        align-items: center;

        .name-label {
          display: inline-block;
          font-weight: 600;
          font-size: 18px;
          color: #252d3d;
          line-height: 21px;
        }

        .edit-icon {
          cursor: pointer;
          margin-left: 2px;
        }

        .name-input {
          width: 300px;
        }
      }

      .update-time {
        margin-left: 25px;
        font-weight: 400;
        font-size: 14px;
        color: #5d687c;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 19px 20px;
    flex: 1;
    column-gap: 20px;

    .left {
      width: 340px;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #e4e7eb;
      padding: 20px;
      display: flex;
      flex-direction: column;
      overflow: auto;
      row-gap: 12px;

      .title {
        font-weight: 600;
        font-size: 16px;
        color: #252d3d;
        line-height: 18px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 1040px;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #e4e7eb;

      .right-header {
        padding: 12px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header-btns {
          display: flex;
          column-gap: 20px;

          .default-btn {
            border-radius: 8px;
            border: 1px solid #2a61ff;
            background: #ffffff;
            color: #2a61ff;
            padding: 9px 12px;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            min-width: 80px;
            margin-left: 0;
          }

          .primary-btn {
            background: #2a61ff;
            color: #ffffff;
            padding: 9px 11px;
          }
        }
      }

      .dynamic-form {
        flex: 1;
        padding: 30px;
        overflow: auto;
      }
    }
  }

  ::v-deep {
    .el-divider {
      margin: 0;
    }
  }
}
</style>
