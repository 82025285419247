<template>
  <div class="department-selection">
    <el-dialog
      :visible.sync="dataset.dialogVisible"
      :before-close="dialogCloseHandler"
      width="500px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      append-to-body
    >
      <template slot="title">
        <span>{{ dataset.mainTitle }}</span>
        <span class="second-title" style="font-size: 12px; color: #666">
          {{ dataset.secondTitle }}
        </span>
      </template>
      <div class="department-choose-part">
        <div class="operation-line">
          <span> 已选：{{ selectList.length }}个 </span>
          <span class="clear-span" @click="clearAll"> 全部清除 </span>
        </div>
        <div class="detail-container" v-loading="treeLoading">
          <el-tree
            :data="organizationDatas"
            node-key="id"
            :auto-expand-parent="true"
            :highlight-current="true"
            show-checkbox
            :check-strictly="!multiple"
            @check-change="handleCheckChange"
            @check="handlerNodeClick"
            default-expand-all
            ref="organizationTree"
          >
          </el-tree>
        </div>
      </div>
      <div class="department-footer-container">
        <el-button @click="$emit('close', false)">取消</el-button>
        <el-button type="primary" @click="submitHandler">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getEmployeeTree } from "@/api/ruge/organization/tenant/employee.js";
import { GenNonDuplicateID } from "@/utils/utils.js";

export default {
  name: "department-selection",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    // 是否多选，默认单选
    multiple: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      selectList: [],
      treeLoading: false,
      organizationDatas: [],
    };
  },
  watch: {
    dataset: {
      handler(value) {
        value.selectList.forEach((item) => {
          item.id = item.userName
            ? item.userName + item.deptId
            : item.deptId || -8;
        });
        this.selectList = value.selectList;
        this.setSelectByIds();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.initDatas();
  },
  methods: {
    clearAll() {
      this.selectList = [];
      this.$refs.organizationTree.setCheckedKeys([]);
    },
    submitHandler() {
      console.log("submit", this.selectList);
      this.$emit("submit", this.selectList);
    },
    handlerNodeClick(data, node, self) {
      // 多选时不需要处理
      if (this.multiple) return;
      // 如果已经选中了一个节点，则取消选择
      this.$refs.organizationTree.setCheckedKeys([]);
      // 更新当前选中节点的 key
      this.$refs.organizationTree.setCheckedNodes([data]);
      // 更新当前选中节点的 key
      this.selectList = [data];
    },
    handleCheckChange() {
      const currentAllNodes = this.$refs.organizationTree.getCheckedNodes();
      this.selectList = currentAllNodes;
    },
    dialogCloseHandler() {
      this.$emit("close");
    },
    initDatas() {
      this.treeLoading = true;
      getEmployeeTree()
        .then((res) => {
          // 过滤掉员工，只保留部门
          this.filterUser(res);
          this.dealTreeDatas(res);
        })
        .finally(() => {
          this.treeLoading = false;
          if (this.selectList && this.selectList.length) {
            this.setSelectByIds();
          }
        });
    },
    filterUser(datas) {
      for (let i = datas.length - 1; i >= 0; i--) {
        if (datas[i].children) {
          this.filterUser(datas[i].children);
        }
        if (datas[i].userId) {
          datas.splice(i, 1);
        }
      }
    },
    dealTreeDatas(datas) {
      let resultList = datas.map((item) => {
        item.label = item.userName || item.deptName || item.companyName;
        item.id = item.userName
          ? item.userName + item.deptId
          : item.deptId || GenNonDuplicateID();
        if (item.children != undefined) {
          this.dealTreeDatas(item.children);
        }
        return item;
      });
      this.organizationDatas = resultList;
    },
    setSelectByIds() {
      this.$nextTick(() => {
        const currentNodeIds = this.selectList.map((item) => item.id);
        this.$refs.organizationTree &&
          this.$refs.organizationTree.setCheckedKeys(currentNodeIds);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.department-selection {
}
::v-deep .department-choose-part {
  width: 440px;
  height: 485px;
  overflow: auto;
  border-radius: 10px;
  border: 1px solid #e3e8ee;
  .operation-line {
    height: 48px;
    padding: 0 20px;
    background: #f7f8fa;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .clear-span {
      font-weight: 400;
      font-size: 14px;
      color: #2a61ff;
      cursor: pointer;
    }
  }
  .detail-container {
    padding: 0 20px;
  }
}
::v-deep .department-footer-container {
  margin: 20px 0;
  text-align: right;
}
</style>