var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rx-select" }, [
    _c("div", { staticClass: "select-selector" }, [_c("el-input")], 1),
    _c("div", { staticClass: "select-arrow" }, [
      _c("img", { attrs: { src: _vm.url, alt: "fold" } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }