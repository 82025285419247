<template>
  <div class="process-radio-component">
    <div class="radio-container">
      <div
        :class="['single-radio', item.active && 'single-radio-active']"
        v-for="item in dataset"
        :key="item.key"
        @click="radioChange(item.key)"
      >
        <div class="left-part">
          <img v-if="item.icon" class="item-icon" :src="item.icon" alt="" />
          <span class="label-span">
            {{ item.label }}
          </span>
        </div>
        <div class="right-part">
          <div v-if="!item.active" class="no-check"></div>
          <img
            v-else
            class="checked-icon"
            src="@/assets/images/workflow/detail/checked-icon.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "process-radio-component",
  props: {
    dataset: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    radioChange(key) {
      this.dataset.forEach((item) => {
        item.active = item.key === key;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.process-radio-component {
  .radio-container {
    display: flex;
    gap: 0 40px;
    .single-radio {
      width: 160px;
      height: 42px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d1d5dd;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      cursor: pointer;
      .left-part {
        display: flex;
        align-items: center;
        .item-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        .label-span {
          font-weight: 500;
          font-size: 14px;
          color: #252d3d;
        }
      }
      .right-part {
        .no-check {
          height: 10px;
          width: 10px;
          border: 1px solid #b5bece;
          border-radius: 50%;
        }
        .checked-icon {
          height: 10px;
          width: 10px;
        }
      }
    }
    .single-radio-active {
      border: 1px solid #2a61ff;
    }
  }
}
</style>