<template>
  <div class="graph-component-user single-graph-component">
    <div :class="['item-label', datas.require && 'item-label-required']">
      {{ datas.label }}
    </div>
    <div class="tags-container">
      <span class="single-tag" v-for="item in datas.value" :key="item.value">
        {{ item.userName }}
        <i class="el-icon-error"></i>
      </span>
      <span class="add-tag single-tag">
        <i class="el-icon-plus"></i>
        添加
      </span>
    </div>
  </div>
</template>
<script>
import { labelRender } from "../utils.js";
export default {
  name: "graph-component-user",
  props: {
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(item) {
        this.datas.label = labelRender(item, "label", "未命名");
        this.datas.value = labelRender(item, "userDefault", []);
        this.datas.require = labelRender(item, "require", false);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      labelRender,
      datas: {
        label: null,
        value: [],
        require: false,
      },
    };
  },
};
</script>
<style lang="less" scoped>
.graph-component-user {
  .tags-container {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    .single-tag {
      padding: 0 8px;
      height: 40px;
      display: flex;
      align-items: center;
      background: #f7f8fa;
      border-radius: 10px;
      color: #5d687c;
      .el-icon-error {
        color: #7d8799;
        margin-left: 4px;
      }
    }
    .add-tag {
      width: 76px;
      border: 1px dotted #7fa0ff;
      font-weight: 600;
      font-size: 14px;
      color: #2a61ff;
      .el-icon-plus {
        margin-right: 4px;
      }
    }
  }
}
</style>