<template>
  <page class="wrapper">
    <div class="tree-box">
      <flow-tree
        :selected-node="selectedNode"
        :data="treeData"
        @node-click="nodeClick"
        :default-expanded-keys="expandedKeys"
      />
    </div>

    <div class="right">
      <div class="right-header"><PathTooltip :value="path" /></div>
      <div v-if="!workflowList.length" class="empty-box">
        <img :src="emptyUrl" alt="empty" />
      </div>
      <div class="cards">
        <card-file
          class="card-item"
          @click="handleSelectWorkflow(item)"
          v-for="item in workflowList"
          :data="item"
          :key="item.id"
        ></card-file>
      </div>
    </div>
  </page>
</template>
<script>
import Page from "@/components/Page";
import FlowTree from "@/views/ruge/workflow/list/components/flow-tree.vue";
import {
  arrayToTree,
  FileType,
  findNode,
  FolderType,
  formatTreeData,
  getTreeData,
} from "@/views/ruge/workflow/list/utils";
import {
  apiGetNodeTree,
  apiGetCategoryNodeList,
} from "@/api/ruge/workflow/workflow";
import { format } from "date-fns";
import CardFile from "./file-card.vue";
import emptyUrl from "@/assets/images/workflow/empty.svg";
import { deepClone } from "@/utils/utils";
import TooltipText from "../../workflow/list/components/tooltip-text.vue";
import PathTooltip from "./path-tooltip.vue"
export default {
  name: "ApprovalProcessOverview",
  components: { FlowTree, Page, CardFile, TooltipText, PathTooltip },
  data() {
    return {
      emptyUrl,
      selectedNode: null,
      treeData: [],
      allTreeData: [],
      expandedKeys: [],
      workflowList: [],
    };
  },
  mounted() {
    const query = this.$route.query;
    const folderNodeId = query.folderNodeId;
    this.fetchTreeData(folderNodeId);
  },
  methods: {
    nodeClick(data) {
      const { children, ...rest } = data;
      const id = this.selectedNode ? this.selectedNode.id : null;
      if (id && id === data.id) {
        return;
      }
      const node = findNode(
        this.allTreeData,
        (node) => node.nodeId === data.nodeId
      );
      if (!node) {
        this.workflowList = [];
        return;
      }
      const { children: list = [] } = node;
      this.workflowList = list.map((item) => {
        return {
          ...item,
          type: item.nodeType === "category" ? FolderType : FileType,
        };
      });
      this.expandedKeys = [data.id];
      this.selectedNode = { ...rest };
    },
    fetchTreeData(folderNodeId) {
      this.loading = true;
      apiGetNodeTree()
        .then((res) => {
          this.allTreeData = formatTreeData(
            arrayToTree(getTreeData(deepClone(res)))
          );
          const data = res.filter((item) => item.nodeType !== "mode");
          this.treeData = formatTreeData(arrayToTree(getTreeData(data)));

          if (this.selectedNode) {
            const { children, ...rest } = this.treeData[0] || {};
            this.nodeClick(rest);
          } else if (this.treeData.length) {
            const node = folderNodeId
              ? findNode(this.treeData, (node) => node.id === folderNodeId)
              : undefined;
            const currentNode = node ? node : this.treeData[0];
            this.nodeClick(currentNode);
            this.nodeClick(this.treeData[0]);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchNodeData(parentId) {
      apiGetCategoryNodeList({ categoryId: parentId })
        .then((res) => {
          this.workflowList = res.map((item) => ({
            ...item,
            type: item.nodeType === "category" ? FolderType : FileType,
            lastUpdateDate: format(
              new Date(item.lastUpdateDate),
              "yyyy-MM-dd HH:mm"
            ),
          }));
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    handleSelectWorkflow(item) {
      if (item.type === FolderType) {
        const node = findNode(this.treeData, (node) => node.id === item.nodeId);
        if (node) {
          const { children, ...rest } = node;
          this.nodeClick({ ...rest });
        }
      } else {
        this.jumpToDetail(item);
      }
    },
    jumpToDetail(data) {
      const { nodeId, versionId } = data;
      console.log("data", data);
      const url = `${location.origin}${location.pathname}#/approval-process/detail?nodeId=${nodeId}&versionId=${versionId}`;
      window.open(url, "_blank");

    },
  },
  computed: {
    path() {
      if (!this.selectedNode) {
        return { prev: "", current: "" };
      }
      const { parentNodes } = this.selectedNode;
      if (!parentNodes || !parentNodes.length) return { prev: "", current: "" };

      const all = parentNodes.map((item) => item.nodeName).join(` > `)
      // return {
      //   prev: parentNodes.map((item) => item.nodeName).join(` > `),
      //   current: "",
      // };

      const last = parentNodes.splice(-1, 1).at(0).nodeName;
      const prev = parentNodes.map((item) => item.nodeName).join(` > `);
      console.log("[ parentNodes ] >", parentNodes);
      const splitCode = parentNodes.length >= 1 ? ` > ` : "";
      return {
        prev: `${prev}`,
        current: last,
        all,
        splitCode,
      };
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  .text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  background-color: #fff;
  flex-direction: row;
  padding: 27px 20px;
  margin: 10px 40px 20px;

  .tree-box {
    width: 250px;
    background: #fcfcfc;
    border-radius: 10px;
    border: 1px solid #e4e7eb;
    height: 100%;
    padding: 12px 10px;
    margin-right: 20px;
    overflow: auto;
  }
  .right {
    flex: 1;
    overflow: hidden;

    .right-header {
      margin-bottom: 20px;
    }
    .path-text {
      height: 20px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #5D687C;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
    .split-code{
      padding: 0 2px;
    }
    .path-text-current {
      flex: auto;
      color: #252d3d;
    }
    .cards {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 8px;
    }
  }
  .empty-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-item {
    &:hover,
    &:active {
      border-color: #2a61ff;
    }
  }
}
</style>
