var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", _vm._g({ staticClass: "box" }, _vm.$listeners), [
    _c(
      "div",
      { staticClass: "title" },
      [
        _c("span", [_vm._v(_vm._s(_vm.data.updateUserName))]),
        _c("span", [_vm._v("发布了")]),
        _c(
          "el-tooltip",
          {
            directives: [
              { name: "tooltip-auto-show", rawName: "v-tooltip-auto-show" },
            ],
            staticClass: "tooltip-node",
            attrs: { placement: "top-start", content: _vm.data.nodeName },
          },
          [
            _c("span", { staticClass: "text-overflow" }, [
              _vm._v(_vm._s(_vm.data.nodeName)),
            ]),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "date" }, [_vm._v(_vm._s(_vm.data.publishTime))]),
    _c("div", { staticClass: "remark" }, [
      _c("span", { staticClass: "start-text" }, [_vm._v("发布备注：")]),
      _c("span", { staticClass: "content" }, [
        _vm._v(_vm._s(_vm.data.publishDescription)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }