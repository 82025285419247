<template>
  <div class="detail-basic-info">
    <el-row>
      <el-col :span="12" v-for="item in colList" :key="item.value">
        <div class="single-item">
          <span class="label-span">
            {{ item.label }}
          </span>
          :
          <span class="value-span">
            {{ item.value }}
          </span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: "detail-basic-info",
  props: {
    dataset: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    dataset: {
      handler(list) {
        this.displayDatas(list)
      },
      immediate: true,
    },
  },
  data() {
    return {
      colList: [
        {
          label: "模板名称",
          value: "-",
          key: "processName",
        },
        {
          label: "创建时间",
          value: "-",
          key: "createTime",
        },
        {
          label: "申请人",
          value: "-",
          key: "userName",
        },
        {
          label: "部门",
          value: "-",
          key: "deptNames",
        },
      ],
    };
  },
  methods: {
    displayDatas(datas) {
      this.colList.forEach((item, index) => {
        item.value = datas[index]
      })
    }
  },
};
</script>

<style lang="less" scoped>
.detail-basic-info {
  .single-item {
    margin-bottom: 30px;
    .label-span {
      font-weight: 400;
      font-size: 14px;
      color: #5d687c;
    }
    .value-span {
      font-weight: 400;
      font-size: 14px;
      color: #252d3d;
    }
  }
}
</style>