<template>
  <div class="filter-select" v-bind="$attrs">
    <el-select
      class="filter-select-inner"
      :class="{ 'filter-select-inner-active': visible }"
      :style="{ width, minWidth: '42px' }"
      popper-class="filter-select-popper"
      :popper-append-to-body="false"
      :value="value"
      @change="change"
      @visible-change="visibleChange"
    >
      <slot></slot>
      <template #prefix>
        <img :src="fold" alt="fold" />
        <!-- <div class="prefix">
      
        </div> -->
      </template>
    </el-select>
    <span ref="inputInner" class="input-inner">{{ labelMap[value] }}</span>
    <!-- <span class="suffix">
      <img :src="fold" alt="fold" />
    </span> -->
  </div>
</template>
<script>
import fold from "@/assets/images/workflow/fold.svg";
import "./filter-select.scss";
export default {
  name: "FilterSelect",
  data() {
    return {
      fold,
      visible: false,
      width: "100%",
    };
  },
  props: {
    value: {
      type: [String, Number], // 根据需要调整类型
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    visibleChange(val) {
      this.visible = val;
      this.$emit("visible-change", val);
    },
    change(val) {
      this.$emit("input", val);
      this.$emit("change", val);

      this.$nextTick(() => {
        this.getWidth();
      });
    },
    getWidth() {
      setTimeout(() => {
        const { width } = this.$refs.inputInner.getBoundingClientRect();
        this.width = width + 10 + 4 + "px";
      }, 10);
    },
  },
  computed: {
    labelMap() {
      return this.options.reduce((acc, item) => {
        acc[item.value] = item.label;
        return acc;
      }, {});
    },
  },
  watch: {
    labelMap: {
      handler(val) {
        this.getWidth();
      },
      immediate: true,
    },
  },
};
</script>
<style scoped lang="scss">
.filter-select {
  position: relative;
  .suffix {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .prefix {
    width: 25px;
    height: 100%;
  }
}
.input-inner {
  font-weight: 400;
  font-size: 14px;
  color: #3a3c47;
  line-height: 16px;
  height: 16px;
  position: absolute;
  display: flex;
  visibility: hidden;
  z-index: -10;
}
.filter-select-inner {
  width: 100%;

  ::v-deep {
    .filter-select-popper {
      min-width: 0% !important;
    }
    .el-input__suffix {
      display: none;
    }

    .el-input__inner {
      border: none;
      font-weight: 400;
      font-size: 14px;
      color: #3a3c47;
      line-height: 16px;
      height: 16px;
    }
    .el-input__prefix {
      right: 4px;
      left: unset;
      display: flex;
      justify-items: center;
      align-items: center;
      transition: transform 0.3s;
    }
    .el-input--suffix .el-input__inner {
      padding-left: 0px;
      padding-right: 10px;
    }
  }

  ::v-deep {
    .popper__arrow {
      display: none;
    }

    .el-select-dropdown__list {
      padding: 12px 0 !important;
      min-width: 120px;
    }
    .el-select-dropdown__item {
      font-weight: 500;
      font-size: 14px;
      color: #252d3d;
      height: 30px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      padding: 0 16px;
      &:hover {
        color: #2a61ff;
        background: #f4f4f5;
      }
    }
  }
}
.filter-select-inner-active {
  ::v-deep {
    .el-input__prefix {
      transform: rotate(180deg);
    }
  }
}
</style>
