var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dynamic-calendar-component" },
    [
      _c("el-switch", {
        attrs: {
          disabled: _vm.disabled,
          "active-value": _vm.activeValue,
          "inactive-value": _vm.inactiveValue,
        },
        on: { change: _vm.updateHandler },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }