<template>
  <div class="props-component-departmentDefault">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <div class="selector-container">
        <el-select
          v-model="displayValue"
          multiple
          filterable
          remote
          reserve-keyword
          @remove-tag="staffRemove"
          placeholder="请选择"
          @focus="attendanceStaffFocus"
          style="width: 100%; cursor: pointer !important"
          class="department-selector"
        >
        </el-select>
        <img
          class="input-icon"
          src="@/assets/images/workflow/department_icon.png"
          alt=""
        />
      </div>
    </el-form-item>
    <DepartmentSelection
      v-if="departmentDialog.dialogVisible"
      :dataset="departmentDialog"
      :multiple="multiple"
      @close="dialogCloseHandler"
      @submit="departmentSubmit"
    />
  </div>
</template>

<script>
import { labelRender } from "../utils.js";
import DepartmentSelection from "@/components/DepartmentSelection";

export default {
  name: "props-component-departmentDefault",
  components: {
    DepartmentSelection,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    propsList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      multiple: undefined,
      departmentDialog: {
        selectList: [],
        dialogVisible: false,
        mainTitle: "选择部门",
      },
    };
  },
  watch: {
    propsList: {
      handler(item) {
        const curMultiple = labelRender({ propsList: item }, "multiple", false);
        this.multipleChange(curMultiple);
      },
      deep: true,
    },
  },
  computed: {
    displayValue() {
      return this.dataset.value.map((item) => item.deptName);
    },
  },
  methods: {
    dialogCloseHandler() {
      this.departmentDialog.dialogVisible = false;
    },
    departmentSubmit(userList) {
      this.dataset.value = userList;
      this.departmentDialog.dialogVisible = false;
    },
    multipleChange(curMultiple) {
      if (this.multiple === curMultiple) return;
      this.multiple = curMultiple;
      this.dataset.value = [];
    },
    staffRemove(value) {
      this.dataset.value = this.dataset.value.filter(
        (item) => item.deptName !== value
      );
    },
    attendanceStaffFocus() {
      this.departmentDialog.selectList = this.dataset.value;
      this.departmentDialog.dialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.props-component-departmentDefault {
  .selector-container {
    position: relative;
    .input-icon {
      position: absolute;
      left: 12px;
      top: 10px;
    }
    .department-selector {
      position: relative;
      ::v-deep .el-input__inner {
        padding-left: 40px;
      }
      ::v-deep .el-select__input {
        padding-left: 25px;
      }
    }
  }
}
</style>