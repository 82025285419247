<template>
  <div class="graph-component-input single-graph-component">
    <div :class="['item-label', datas.require && 'item-label-required']">
      {{ datas.label }}
    </div>
    <div style="border: 1px solid #ccc">
      <!-- 工具栏 -->
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        ref="toolBar"
      />
      <!-- 编辑器 -->
      <Editor
        style="height: 200px; overflow-y: hidden"
        :defaultConfig="editorConfig"
        v-model="editorValue"
        @onCreated="onCreated"
      />
    </div>
  </div>
</template>
<script>
import { labelRender } from "../utils.js";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";

export default {
  name: "graph-component-input",
  components: { Editor, Toolbar },
  props: {
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(item) {
        this.datas.label = labelRender(item, "label", "未命名");
        // this.datas.value = labelRender(item, "default", "");
        this.datas.require = labelRender(item, "require", false);
        this.editorConfig.placeholder = labelRender(
          item,
          "placeHolder",
          "请输入"
        );
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      labelRender,
      editorValue: null,
      datas: {
        label: null,
        value: null,
        placeHolder: "请输入",
        require: false,
      },
      editor: null,
      // html: "<p>hello&nbsp;world</p>",
      toolbarConfig: {
        // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
        excludeKeys: [
          /* 隐藏哪些菜单 */ "fullScreen",
          "group-image",
          "group-video",
          "group-indent",
          "emotion",
          "insertLink",
          "insertTable",
          "codeBlock",
          "lineHeight",
          "fontFamily",
          "divider",
          "blockquote",
          "color",
          "bgColor",
          "todo",
        ],
        MENU_CONF: {},
        // toolbarKeys: [
        //   // 菜单 key
        //   "headerSelect",
        //   // 分割线
        //   "|",
        //   "undo",
        //   "redo",
        //   "|",
        //   "fontSize",
        //   // 菜单 key
        //   // 'bold', 'italic',
        //   "bold",
        //   "italic",
        //   "through", //没找到删除线配置的key
        //   "underline",
        //   "|",
        //   // "left",
        //   // "center",
        //   // "right",
        //   // "justify",
        //   // 菜单组，包含多个菜单
        //   // {
        //   //   key: 'group-more-style', // 必填，要以 group 开头
        //   //   title: '更多样式', // 必填
        //   //   iconSvg: '<svg>....</svg>', // 可选
        //   //   menuKeys: ["through", "code", "clearStyle"] // 下级菜单 key ，必填
        //   // },
        //   // 继续配置其他菜单...
        // ],
      },
      editorConfig: {
        placeholder: "请输入",
        // autoFocus: false,

        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {},
      },
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
      this.editor.disable();
    },
  },
};
</script>
<style lang="less" scoped>
.graph-component-input {
}
</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>