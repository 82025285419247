<template>
  <div class="employee-selection-componnet">
    <el-dialog
      :visible.sync="dataset.dialogVisible"
      :before-close="dialogCloseHandler"
      width="1000px"
      append-to-body
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <span>{{ dataset.mainTitle }}</span>
        <span class="second-title" style="font-size: 12px; color: #666">
          {{ dataset.secondTitle }}
        </span>
      </template>
      <div class="employee-main-container">
        <div class="choose-part">
          <div>选择：</div>
          <div class="detail-container" v-loading="treeLoading">
            <el-tree
              :data="organizationDatas"
              node-key="id"
              :auto-expand-parent="true"
              :highlight-current="true"
              show-checkbox
              @check-change="handleCheckChange"
              @check="handlerNodeClick"
              default-expand-all
              ref="organizationTree"
            >
            </el-tree>
          </div>
        </div>
        <div class="selected-part">
          <div>已选：</div>
          <div class="detail-container">
            <div
              v-for="(user, index) in this.selectList"
              :key="index"
              class="single-user"
            >
              <span class="left-part">
                <span class="icon-span">
                  {{ user.userName.slice(user.userName.length - 1) }}
                </span>
                <span>
                  <div class="name-span">{{ user.userName }}</div>
                  <div class="dept-span">{{ user.deptName }}</div>
                </span>
              </span>
              <span class="right-part">
                <i class="el-icon-close" @click="deleteSingleUser(user)"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="employee-footer-container">
        <el-button @click="$emit('close', false)">取消</el-button>
        <el-button
          type="primary"
          @click="submitHandler"
          v-loading="checkLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getEmployeeTree } from "@/api/ruge/organization/tenant/employee.js";
import { employeeCheck } from "@/api/business/shifts/attendanceGroup.js";
import { GenNonDuplicateID } from "@/utils/utils.js";

export default {
  name: "employeeSelectionComponnet",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    /**
     * 确认时是否需要检查员工已存在其他考勤组中
     * workfolow: 不需要检查
     * rwork考勤组设置: 需要检查
     */
    noCheck: {
      type: Boolean,
      default() {
        return false;
      },
    },
    multiple: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      checkLoading: false,
      treeLoading: false,
      organizationDatas: [],
      selectList: [],
    };
  },
  watch: {
    dataset: {
      handler(value) {
        value.selectList.forEach((item) => {
          item.id = item.userName
            ? item.userName + item.deptId
            : item.deptId || -8;
        });
        this.selectList = value.selectList;
        this.setSelectByIds();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.initDatas();
  },
  methods: {
    initDatas() {
      this.treeLoading = true;
      getEmployeeTree()
        .then((res) => {
          this.dealTreeDatas(res);
        })
        .finally(() => {
          this.treeLoading = false;
          if (this.selectList && this.selectList.length) {
            this.setSelectByIds();
          }
        });
    },
    dealTreeDatas(datas) {
      let resultList = datas.map((item) => {
        item.label = item.userName || item.deptName || item.companyName;
        // 单选时禁用公司部门层级勾选框
        if (!this.multiple) {
          item.disabled = !item.userId && !item.userName;
        }
        item.id = item.userName
          ? item.userName + item.deptId
          : item.deptId || GenNonDuplicateID();
        if (item.children != undefined) {
          this.dealTreeDatas(item.children);
        }
        return item;
      });
      this.organizationDatas = resultList;
    },
    handlerNodeClick(data, node, self) {
      // 多选时不需要处理
      if (this.multiple) return;
      // 如果已经选中了一个节点，则取消选择
      this.$refs.organizationTree.setCheckedKeys([]);
      // 更新当前选中节点的 key
      this.$refs.organizationTree.setCheckedNodes([data]);
      // 更新当前选中节点的 key
      this.selectList = [data];
    },
    handleCheckChange() {
      const currentAllNodes = this.$refs.organizationTree.getCheckedNodes();
      this.selectList = currentAllNodes.filter((item) => item.userName);
    },
    dialogCloseHandler() {
      this.$emit("close");
    },
    deleteSingleUser(user) {
      this.selectList = this.selectList.filter(
        (item) => item.userId !== user.userId
      );
      this.setSelectByIds();
    },
    setSelectByIds() {
      this.$nextTick(() => {
        const currentNodeIds = this.selectList.map((item) => item.id);
        this.$refs.organizationTree &&
          this.$refs.organizationTree.setCheckedKeys(currentNodeIds);
      });
    },
    submitHandler() {
      // noCheck-不需要检查数量和是否在其他考勤组中
      if (this.noCheck) {
        this.$emit("submit", this.selectList);
        this.checkLoading = false;
        return;
      }
      if (!this.selectList.length) {
        this.$message.warning("请至少选择一个考勤人员！");
        return;
      }
      this.checkLoading = true;
      employeeCheck({
        addIds: this.selectList.map((item) => item.empId).join(","),
        attendanceGroupId: this.dataset.attendanceGroupId || null,
      })
        .then((res) => {
          const existList =
            res
              .map((item) => {
                return item.userName;
              })
              .join(",") || "";
          if (existList) {
            this.$confirm(
              `以下考勤人员【${existList}】已存在其他考勤组中，当考勤组创建后，以下人员将自动添加至当前考勤组内`,
              this.$t("commons.warning"),
              {
                confirmButtonText: this.$t("commons.confirm"),
                cancelButtonText: this.$t("commons.cancel"),
                type: "warning",
              }
            )
              .then(() => {
                this.$emit("submit", this.selectList);
              })
              .catch((error) => {
                console.log(`未删除，原因 => ${error}`);
              });
          } else {
            this.$emit("submit", this.selectList);
          }
        })
        .finally(() => {
          this.checkLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.employee-selection-componnet {
}
::v-deep .employee-main-container {
  display: flex;
  justify-content: space-between;
  & > div {
    width: 460px;
  }
  .detail-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 415px;
    margin-top: 15px;
    padding: 5px;
    overflow: auto;
    .el-tree {
      max-height: 400px;
      overflow: auto;
    }
    .single-user {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      height: 36px;
      .left-part {
        display: flex;
        align-items: center;
        .icon-span {
          background: #409eff;
          color: #ffffff;
          display: inline-block;
          height: 28px;
          line-height: 28px;
          width: 28px;
          border-radius: 50%;
          text-align: center;
          font-size: 12px;
          margin-right: 10px;
        }
        .name-span {
          font-size: 14px;
          line-height: 16px;
        }
        .dept-span {
          font-size: 12px;
        }
      }
      &:hover {
        background: #e4e4e4;
        // .el-icon-close {
        //   display: block;
        // }
      }
      .el-icon-close {
        cursor: pointer;
        font-size: 16px;
        // display: none;
      }
    }
  }
}
::v-deep .employee-footer-container {
  margin: 20px 0;
  text-align: right;
}
</style>