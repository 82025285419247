var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "graph-component-radio single-graph-component" },
    [
      _c(
        "div",
        { class: ["item-label", _vm.datas.require && "item-label-required"] },
        [_vm._v("\n    " + _vm._s(_vm.datas.label) + "\n  ")]
      ),
      _c(
        "el-radio-group",
        {
          class: [_vm.datas.direction === "column" && "radio-group-column"],
          staticStyle: { "pointer-events": "none" },
          model: {
            value: _vm.datas.value,
            callback: function ($$v) {
              _vm.$set(_vm.datas, "value", $$v)
            },
            expression: "datas.value",
          },
        },
        _vm._l(_vm.datas.optionList, function (item) {
          return _c(
            "el-radio",
            { key: item.value, attrs: { label: item.value } },
            [_vm._v(_vm._s(item.label))]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }