<template>
	<!--并行条件网关配置项-->
	<div>
		<TForm ref="refFrom1" lableRow  :autoCheck='true' :model="htmlDatas" :formlist="formlist" :rowGutter='16' labelPosition='left'>
			
		</TForm>
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		components: { TForm },
		props: {
			type: {
				type: String,
				default: '1',
			},
			formInfo:{
				type: Object,
				default:()=>{
					return {}
				}
			},
		},
		watch:{
			htmlDatas: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$nextTick(()=>{
						this.$emit('upvals',this.htmlDatas)
						
					})
					
				}
			},
			
		},
		computed: {},
		data() {
			return {
				htmlDatas: {},
				formlist: [
					{name: '节点key',keys: 'nodeCode',value: '',type: 'input',flex:24,isMust:true},
				],
			};
		},
		created() {
			console.log('线节点接收.formInfo',JSON.parse(JSON.stringify(this.formInfo)))
			this.resetTFrom();
		},
		mounted() {},
		methods: {
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
	  				let fval=this.formInfo[item.keys]
	  				if(fval) item.value=fval;
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
		}
		
	};
</script>

<style scoped>
	
</style>