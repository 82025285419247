var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      directives: [
        { name: "tooltip-auto-show", rawName: "v-tooltip-auto-show" },
      ],
      staticStyle: { width: "90%" },
      attrs: {
        placement: "top-start",
        "popper-class": "path-popper",
        content: _vm.value.all,
      },
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "text-overflow", staticStyle: { display: "flex" } },
          [
            _c("div", { staticClass: "path-text text-overflow" }, [
              _vm._v(_vm._s(_vm.value.prev)),
            ]),
            _c("div", { staticClass: "path-text split-code" }, [
              _vm._v(_vm._s(_vm.value.splitCode)),
            ]),
            _c(
              "div",
              { staticClass: "path-text path-text-current text-overflow" },
              [_vm._v("\n        " + _vm._s(_vm.value.current) + "\n      ")]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }