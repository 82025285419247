<template>
  <div class="props-component-phoneDefault">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <el-input
        prefix-icon="el-icon-mobile-phone"
        v-model="dataset.value"
        @blur="numberValid"
      >
      </el-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "props-component-phoneDefault",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    numberValid() {
      if (!this.dataset.value) return;
      const phoneReg = /^1[3-9]\d{9}$/;
      const telReg = /^((\(\d{3,4}\))|(\d{3,4}))[ -]?\d{7,8}(#\d{3,4})?$/;
      if (phoneReg.test(this.dataset.value) || telReg.test(this.dataset.value))
        return;
      this.$message.warning("请输入正确的手机号/座机号");
      this.dataset.value = null;
    },
  },
};
</script>