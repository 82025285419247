<template>
  <div class="graph-component-timePicker single-graph-component">
    <div :class="['item-label', datas.require && 'item-label-required']">
      {{ datas.label }}
    </div>
    <el-time-picker
      v-if="pickerShow"
      v-model="datas.value"
      :readonly="readOnly"
      style="width: 100%"
      :is-range="datas.isRange"
      :editable="datas.editable"
      :clearable="datas.clearable"
      range-separator="-"
      value-format="HH:mm:ss"
      :start-placeholder="datas.startPlaceHolder"
      :end-placeholder="datas.endPlaceHolder"
      :placeholder="datas.placeHolder2"
    >
    </el-time-picker>
  </div>
</template>
<script>
import { labelRender } from "../utils.js";
export default {
  name: "graph-component-timePicker",
  props: {
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(item) {
        this.datas.label = labelRender(item, "label", "未命名");
        this.datas.clearable = labelRender(item, "clearable", false);
        this.datas.require = labelRender(item, "require", false);
        this.datas.placeHolder2 = labelRender(item, "placeHolder2", "请选择");
        this.datas.editable = labelRender(item, "editable", true);
        this.datas.startPlaceHolder = labelRender(
          item,
          "startPlaceHolder",
          "请选择"
        );
        this.datas.endPlaceHolder = labelRender(
          item,
          "endPlaceHolder",
          "请选择"
        );
        this.datas.value = labelRender(item, "timeDefault", null);
        // 动态切换isRange会导致popover漂移至屏幕左上角，所以用pickerShow让组件重新加载一遍
        const current = labelRender(item, "isRange", false);
        if (current !== this.datas.isRange) {
          this.pickerShow = false;
          setTimeout(() => {
            this.datas.isRange = current;
            this.datas.value = null;
            this.pickerShow = true;
          }, 100);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      labelRender,
      pickerShow: true,
      datas: {
        require: false,
        label: null,
        clearable: false,
        isRange: false,
        startPlaceHolder: "请选择",
        endPlaceHolder: "请选择",
        placeHolder2: "请选择",
        editable: true,
        value: null,
      },
    };
  },
};
</script>
<style lang="less" scoped>
.graph-component-timePicker {
}
</style>