<template>
  <div class="props-component-calendarDefault">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <!-- dataset.valueType  date | datetime -->
      <div class="tabs-line">
        <span
          :class="[
            'single-tab',
            item.key === dataset.valueType && 'single-tab-active',
          ]"
          v-for="item in tabs"
          :key="item.key"
          @click="tabChange(item)"
        >
          {{ item.label }}
        </span>
      </div>
      <el-date-picker
        style="width: 100%"
        v-model="dataset.value"
        :type="dataset.valueType"
        :value-format="
          dataset.valueType === 'date' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss'
        "
        :placeholder="
          dataset.valueType === 'date' ? '选择日期' : '选择日期时间'
        "
      >
      </el-date-picker>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "props-component-calendarDefault",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    "dataset.valueType": {
      handler() {
        this.pickerShow = false;
        setTimeout(() => {
          this.pickerShow = true;
        }, 100);
      },
      immediate: true,
    },
  },
  data() {
    return {
      pickerShow: true,
      tabs: [
        {
          label: "日期",
          key: "date",
        },
        {
          label: "日期时间",
          key: "datetime",
        },
      ],
    };
  },
  methods: {
    tabChange({ key }) {
      this.dataset.valueType = key;
    },
  },
};
</script>
<style scoped lang="less">
.props-component-calendarDefault {
  .tabs-line {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #e9eff4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
    margin: 12px 0;
    .single-tab {
      width: 108px;
      height: 28px;
      line-height: 28px;
      border-radius: 8px;
      font-weight: 400;
      font-size: 14px;
      color: #5d687c;
      text-align: center;
      cursor: pointer;
    }
    .single-tab-active {
      background: #e9eff4;
      font-weight: 500;
      font-size: 14px;
      color: #252d3d;
    }
  }
}
</style>
