<template>
  <div class="graph-component-input single-graph-component">
    <div :class="['item-label']">
      {{ datas.label }}
    </div>

    <el-switch
      :disabled="readOnly"
      v-model="datas.value"
      active-value="true"
      inactive-value="false"
    >
    </el-switch>
  </div>
</template>
<script>
import { labelRender } from "../utils.js";
export default {
  name: "graph-component-input",
  props: {
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(item) {
        this.datas.label = labelRender(item, "label", "未命名");
        const activeValue = labelRender(item, "activeValue", null);
        const inactiveValue = labelRender(item, "inactiveValue", null);
        const switchDefault = labelRender(item, "switchDefault", true);
        this.datas.value = switchDefault ? activeValue : inactiveValue;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      labelRender,
      datas: {
        label: null,
        value: null,
      },
    };
  },
};
</script>
<style lang="less" scoped>
.graph-component-input {
}
</style>