<template>
  <div class="graph-component-fileUploader single-graph-component">
    <div :class="['item-label', datas.require && 'item-label-required']">
      {{ datas.label }}
    </div>
    <div class="uploader-example">
      <img
        src="@/assets/images/import_folder_icon.png"
        style="margin-bottom: 8px"
        alt=""
      />
      <div class="el-upload__text">
        将文件拖到此处，或<em style="color: #2a61ff">点击选择文件</em>
      </div>
    </div>
  </div>
</template>
<script>
import { labelRender } from "../utils.js";
export default {
  name: "graph-component-fileUploader",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(item) {
        this.datas.label = labelRender(item, "label", "未命名");
        this.datas.require = labelRender(item, "require", false);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      labelRender,
      datas: {
        label: null,
        require: false,
      },
    };
  },
};
</script>
<style lang="less" scoped>
.graph-component-fileUploader {
  .uploader-example {
    width: 100%;
    height: 148px;
    border-radius: 4px;
    border: 1px solid #d1d5dd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    background: #fcfdff;
    .el-upload__text {
      color: #b5bece;
      font-size: 14px;
    }
  }
}
</style>