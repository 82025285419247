<template>
	<!--审核节点 表单字段设置-->
	<div class="Y-tips">
		<el-dialog class='diyModel' title="表单字段设置" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="1000px" :before-close="closeMypup">
			<div >
				<div class="topPart">
					
					<!--<div class="flxdiv btndiv"  :class="{actived:htmlDatas.isDefault}">默认1（可见不可编辑）</div>-->
					<div class="flxdiv" >
						<el-select @change="tipConfirmChange" v-model="checkedNode" placeholder="请选择">
						    <el-option v-for="item in nodeOptionArr" 
						    	:label="item.name?item.name:item.nodeCode" 
						    	:key="item.nodeId" 
						    	:value="item.nodeId" 
						    	:disabled="item.disabled"/>
					  	</el-select>
					</div>
					<el-button type="primary" @click='initDfFormDatas("tip")'>恢复默认(可见不可编辑)</el-button>
				</div>
				<div class="keyWordsFrom">
					<DynamicForm
				        ref="dynamicFormIns"
				        :dataset="testObj.dataset"
				        :editState="testObj.editState"
				        :flowNodeId="testObj.flowNodeId"
				      />
					
				</div>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='getFromDatas' :disabled='isHttping'> 确定</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import pinyin from "js-pinyin";
	import TForm from '@/components/YTable/TForm.vue';
	import DynamicForm from "@/views/ruge/workflow/components/DynamicForm";
	export default {
		components: {TForm,DynamicForm},
		props: {
			dataId: {
				type: String|Number,
				default: '',
			},
			nodeArrs:{
				type: Array,
				default: [],
			},
			httpConfigs:{
				type: String,
				default: '',
			},
//			model: {
//				type: Object,
//				default: ()=>{
//					return {
//						wordType:'1',
//						isDefault:false,
//						checkedNode:'',
//					}
//				},
//			},
			datas: {
				type: Object,
				default: ()=>{
					return {}
				},
			},
		},
		data() {
			return {
				htmlDatas:{
//					isDefault:true,
				},
				checkedNode:'',
	  			isHttping:false,
	  			testObj:{
	  				show: false,
			        flowNodeId: "",
			        dataset: null,
			        editState: "flowConfig",
	  			},
	  			nodeOptionArr:[],
	  			upNums:1,
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.$nextTick(() => {
						this.initModalHtml();
					})
				}
			},
			
		},
		created() {
//			console.log('+++++++++nodeArrs',JSON.parse(JSON.stringify(this.nodeArrs)))
			this.initModalHtml();
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			
			async initModalHtml(){
				if(!this.dataId) return;
				this.testObj.flowNodeId=this.dataId;
//				this.nodeOptionArr=this.nodeArrs.filter((item) => {
//					return item.nodeId !== this.dataId&&item.nodeType=='userTask'
//				});
				this.nodeOptionArr=[];
				this.nodeArrs.map(its=>{
					let item=JSON.parse(JSON.stringify(its));
					item.disabled=true;
					if(item.nodeType=='userTask'&&item.nodeId !== this.dataId){
						let itWebObj={};
		      			if(item['-webDatas']){
		      				itWebObj=JSON.parse(item['-webDatas']);
		      				item.nodeCode=itWebObj.nodeCode;
//		      				if(itWebObj.webDatas){
//		      					const nodeInfo=JSON.parse(itWebObj.webDatas)
//		      					itWebObj.listenConfigs=nodeInfo.listenConfigs;
//		      				}
		      			}
		      			if(itWebObj.keyWordDatas){
		      				const {formConfigs}=itWebObj.keyWordDatas;
							if(formConfigs){
//								item.configsDatas=formConfigs;
								item.disabled=false;
							}
		      			}
		      			this.nodeOptionArr.push(item);
					}
					
				})
//				console.log('-------+++++++++++----nodeArrs',this.dataId,JSON.parse(JSON.stringify(this.nodeOptionArr)))
				if(this.datas.webId){
					this.htmlDatas=JSON.parse(JSON.stringify(this.datas));
				}
				this.testObj.dataset=String(this.httpConfigs);
//				if(this.datas.formConfigs){
//					this.testObj.dataset=this.datas.formConfigs;
//				}else{
//					this.initDfFormDatas();//加载默认DynamicForm
//				}
				console.log('---------初始弹窗的数据',JSON.parse(JSON.stringify(this.testObj)))
			},
			initDfFormDatas(isTip=''){
				//加载默认DynamicForm组件的表单字段数据 可见不可编辑
				
				this.checkedNode='';
				if(isTip){
					this.tipConfirmChange();
				}else{
					this.changeDyFormDatas();
				}
			},
			tipConfirmChange(){
				this.$confirm('该操作将会覆盖原有操作,是否继续', '提示').then(() => {
					this.changeDyFormDatas();
				}).catch(() => {
					this.checkedNode='';
				});
			},
			changeDyFormDatas(){
				//选择节点 切换DynamicForm组件的数据
				this.upNums++;
				// 没有的时候 代表恢复默认 id没有的时候就是恢复默认
				//有id的时候就拿对应id的数据覆盖当前节点
				this.$refs.dynamicFormIns.setCurrentItemById(this.checkedNode);
//				let  result;
//				if(!this.checkedNode){
//					//没有的时候 代表恢复默认
//					// id没有的时候就是恢复默认
//					//有id的时候就拿对应id的数据覆盖当前节点
//					this.$refs.dynamicFormIns.setCurrentItemById('sdsd');
//				}else{
//					const [it]=this.nodeOptionArr.filter((item) => item.nodeId == this.checkedNode);
//					result=it.configsDatas;
//	      			
//				}
//				if(result) this.testObj.dataset=result;
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				console.log(this.datas)
				let mId=this.datas.webId;
				if(!mId){
					mId=this.common.creadWebId();
				}
				let params={
					webId:mId,
					...this.htmlDatas,
					formConfigs:this.$refs.dynamicFormIns.getFormConfiguredDatas()
				}
				this.$emit('close',params)
			},
			closeMypup(){
				this.$emit('close')
			},
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	margin-top: 20px;
	text-align: right;
}
.topPart{
	margin-bottom: 16px;
	display: flex;
	.flxdiv{
		margin-right: 12px;
		line-height: 38px;
		/*border: 1px solid #D1D5DD;*/
		.el-input__inner{
			background:white;
		}
	}
	.btndiv{
		width: 172px;
		background: #FFFFFF;
		border-radius: 4px 4px 4px 4px;
		text-align: center;
		cursor: pointer;
		margin-right: 16px;
	}
	.btndiv.actived{
		color: #2A61FF;
		border: 1px solid #2A61FF;
	}
}
.keyWordsFrom{
	border: 1px solid #E3E8EE;
	height: 364px;
	overflow-y: auto;
	padding: 20px;
}
</style>