import { Base64 } from "js-base64";
const getters = {
  sidebar: (state) => state.app.sidebar,
  language: (state) => state.app.language,
  displayLayout: (state) => state.app.displayLayout,
  theme: (state) => state.app.theme,
  size: (state) => state.app.size,
  selectMenuId: (state) => state.app.selectMenuId,
  device: (state) => state.app.device,
  constomLogo: (state) => state.app.tenantAvatar,
  constomLogoOpen: (state) => state.app.tenantAvatarOpen,
  userAccount: (state) => state.user.userAccount,
  tenantList: (state) => state.user.tenantList,
  currentTenant: (state) => state.user.currentTenant,
  currentProductKey: (state) => state.user.currentProductKey,
  isGuest: (state) => state.user.isGuest,
  avatar: (state) => state.user.avatar,
  roleList: (state) => state.user.roleList,
  isSitemapInit: (state) => state.user.isSitemapInit,
  sitemapList: (state) => state.user.sitemapList,
  isHomeInit: (state) => state.user.isHomeInit,
  baseAuthToken: (state) => state.user.baseAuthToken,
  base64Token: (state) => Base64.encode(state.user.baseAuthToken),
  havePermissions: (state) => state.user.havePermissions,
  addRouters: (state) => state.router.addRouters,
  wstate: (state) => state.message, //ws暴露的相关信息
  orientationMessage: (state) => state.message.wsMessage.orientationMessage, //暴露定向消息
  propertyMessage: (state) => state.message.wsMessage.propertyMessage,
  statusMessage: (state) => state.message.wsMessage.statusMessage,
  alarmMessage: (state) => state.message.wsMessage.alarmMessage,
  exportNoticeList: (state) => state.message.exportNoticeList, //导出任务消息
  alarmNoticeList: (state) => state.message.alarmNoticeList, //导出任务消息
  flowVariable: (state) => state.workflow.flowVariable,
  statisticCounts: (state) => state.workflow.statisticCounts,
  stationSelected: (state) => state.rule.stationSelected,
  tenantHomePage: (state) => state.user.tenantHome,
  exportTaskList: (state) => state.notify.exportTaskList, //导出任务消息
  legoFuncList: (state) => state.lego.functionList,
  legoBannerList: (state) => state.lego.bannerList,
  legonNotifyList: (state) => state.lego.notifyList,
  legonNewsList: (state) => state.lego.newsList,
};
export default getters;
