<template>
  <div class="process-template-dialog">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="dialogCloseHandler"
      width="1140px"
      title="选择模板"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <div class="dialog-main" v-loading="loading">
        <div class="tree-box">
          <FlowTree
            :selected-node="selectedNode"
            :data="treeData"
            @node-click="nodeClick"
            :default-expanded-keys="expandedKeys"
          />
        </div>
        <div class="right-part">
          <div class="right-bread">
            {{ breadCrumb }}
          </div>
          <div class="workflow-list">
            <div v-if="!workflowList.length" class="empty-box">
              <img :src="emptyUrl" alt="empty" />
            </div>
            <div
              v-for="item in workflowList"
              :key="item.nodeId"
              :class="[
                'workflow-card',
                item.nodeId === activeNodeId && 'workflow-card-active',
              ]"
              @click="handleSelectWorkflow(item)"
            >
              <img
                :src="item.type === FileType ? fileUrl : folderUrl"
                alt="folder"
                class="workflow-icon"
              />
              <el-tooltip class="text-overflow tooltip" :offset="50" effect="dark" :content="item.nodeName" placement="top-start" v-tooltip-auto-show>
                <span class="card-name text-overflow"> {{ item.nodeName }}</span>
              </el-tooltip>

            </div>
          </div>
          <div class="button-line">
            <r-button @click="dialogCloseHandler">取消</r-button>
            <r-button plain @click="submitHandler" :disabled="!activeNodeId"
              >确定</r-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import folderUrl from "@/assets/images/workflow/folder.svg";
import fileUrl from "@/assets/images/workflow/file-icon.svg";
import emptyUrl from "@/assets/images/workflow/empty.svg";
import FlowTree from "@/views/ruge/workflow/list/components/flow-tree.vue";
import { format } from "date-fns";
import { getPublishedNodeTree } from "@/api/ruge/workflow/workflow";
import {
  FolderType,
  FileType,
  formatTreeData,
  arrayToTree,
  getTreeData,
  RootType,
  findNode,
} from "@/views/ruge/workflow/list/utils.js";
import { apiCategoryNodeList } from "@/api/ruge/workflow/workflow";
import { deepClone } from "@/utils/utils";

export default {
  name: "process-template-dialog",
  components: {
    FlowTree,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
  },
  data() {
    return {
      folderUrl,
      fileUrl,
      FileType,
      RootType,
      emptyUrl,
      selectedNode: null,
      treeData: [],
      allTreeData: [],
      loading: false,
      expandedKeys: [],
      workflowList: [],
      breadCrumb: "/",
      activeNodeId: null,
      activeVersionId: null,
    };
  },
  created() {
    this.fetchTreeData();
  },
  methods: {
    submitHandler() {
      console.log("选中的nodeId: ", this.activeNodeId);
      if (this.activeNodeId) {
      const { activeNodeId, activeVersionId } = this;
      const url = `${location.origin}${location.pathname}#/approval-process/detail?nodeId=${activeNodeId}&versionId=${activeVersionId}`;
      window.open(url, "_blank");
      this.$emit("close");
      // this.$router.push({
      //   path: "/approval-process/detail",
      //   query: {
      //     nodeId: this.activeNodeId,
      //     versionId: this.activeVersionId,
      //   },
      // });
      }
    },
    breadCrumbRender(parentNodes) {
      this.breadCrumb = parentNodes.map((item) => item.label).join(" > ");
    },
    dialogCloseHandler() {
      this.$emit("close");
    },
    handleSelectWorkflow(item) {
      console.log("item", item);
      if (item.type === FolderType) {
        const node = findNode(this.treeData, (node) => node.id === item.nodeId);
        if (node) {
          const { children, ...rest } = node;
          this.nodeClick({ ...rest });
        }
      }
      this.activeNodeId = item.type === FolderType ? null : item.nodeId;
      this.activeVersionId = item.type === FolderType ? null : item.versionId;
    },
    async fetchTreeData() {
      this.loading = true;
      try {
        const res = await getPublishedNodeTree();
        this.allTreeData = formatTreeData(
          arrayToTree(getTreeData(deepClone(res)))
        );
        const data = res.filter((item) => item.nodeType !== "mode");
        this.treeData = formatTreeData(arrayToTree(getTreeData(data)));
        if (this.selectedNode) {
          // const nodeId =
          //   this.selectedNode.id === RootType ? "" : this.selectedNode.id;
          const { children, ...rest } = this.treeData[0] || {};
          this.nodeClick(rest);
          this.nodeClick(rest);
        } else if (this.treeData.length) {
          this.nodeClick(this.treeData[0]);
        }
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    nodeClick(data) {
      const { children, ...rest } = data;
      const id = this.selectedNode ? this.selectedNode.id : null;
      if (id && id === data.id) {
        return;
      }
      const node = findNode(
        this.allTreeData,
        (node) => node.nodeId === data.nodeId
      );
      if (!node) {
        this.workflowList = [];
        return;
      }
      const { children: list = [] } = node;
      this.workflowList = list.map((item) => {
        return {
          ...item,
          type: item.nodeType === "category" ? FolderType : FileType,
        };
      });
      this.expandedKeys = [data.id];
      this.selectedNode = { ...rest };
      this.breadCrumbRender(data.parentNodes);
    },
    fetchNodeData(parentId) {
      apiCategoryNodeList({ categoryId: parentId })
        .then((res) => {
          this.workflowList = res.map((item) => ({
            ...item,
            type: item.nodeType === "category" ? FolderType : FileType,
          }));
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.process-template-dialog {
  .dialog-main {
    display: flex;
    height: calc(90vh - 108px);
    .tree-box {
      width: 250px;
      background: #fcfcfc;
      border-radius: 10px;
      border: 1px solid #e4e7eb;
      padding: 12px 10px;
      margin-right: 20px;
      overflow: auto;
      height: calc(100% - 30px);
    }
    .right-part {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: calc(100% - 30px);
      .right-bread {
        height: 20px;
        line-height: 20px;
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 14px;
        color: #5d687c;
      }
      .right-top {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
      }

      .workflow-list {
        flex: 1;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        align-content: flex-start;
        .empty-box {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .workflow-card-active {
          border: 1px solid #2a61ff !important;
        }
        .text-overflow {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 16px;
        }
        .workflow-card {
          width: 250px;
          height: 58px;
          padding: 0 18px;
          display: flex;
          border-radius: 10px;
          border: 1px solid #e3e8ee;
          box-sizing: border-box;
          justify-content: start;
          align-items: center;
          cursor: pointer;
          .workflow-icon {
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }
          &:hover {
            border-color: #2a61ff70;
          }
        }
      }

      .button-line {
        text-align: right;
      }
    }
  }
}
</style>