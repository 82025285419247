import Layout from '@/views/ruge/layout/Layout';
const amsRouter = [
    {
        path: '/ams',
        component: Layout,
        redirect: '/ams/basicConfig/codeConfig',
        children:[
            {
                path: 'basicConfig/codeConfig',
                name: 'AMSCodeConfig',
                component: () =>
                  import(
                    '@/views/ruge/ams/basicConfig/codeConfig'
                  ),
              },
              {
                path: 'basicConfig/warehouse',
                name: 'AMSWarehouse',
                component: () =>
                  import(
                    '@/views/ruge/ams/basicConfig/warehouse'
                  ),
              },
              {
                path: 'basicConfig/warnRules',
                name: 'AMSWarehouse',
                component: () =>
                  import(
                    '@/views/ruge/ams/basicConfig/warnRules'
                  ),
              },
              {
                path: 'basicConfig/depreciationRules',
                name: 'AMSWarehouse',
                component: () =>
                  import(
                    '@/views/ruge/ams/basicConfig/depreciationRules'
                  ),
              },
        ]
    }
]
export default amsRouter;