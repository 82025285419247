var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "props-component-departmentDefault" },
    [
      _c(
        "el-form-item",
        {
          class: ["single-form-item", `single-form-${_vm.dataset.key}`],
          attrs: { label: _vm.dataset.label, prop: _vm.dataset.key },
        },
        [
          _c(
            "div",
            { staticClass: "selector-container" },
            [
              _c("el-select", {
                staticClass: "department-selector",
                staticStyle: { width: "100%", cursor: "pointer !important" },
                attrs: {
                  multiple: "",
                  filterable: "",
                  remote: "",
                  "reserve-keyword": "",
                  placeholder: "请选择",
                },
                on: {
                  "remove-tag": _vm.staffRemove,
                  focus: _vm.attendanceStaffFocus,
                },
                model: {
                  value: _vm.displayValue,
                  callback: function ($$v) {
                    _vm.displayValue = $$v
                  },
                  expression: "displayValue",
                },
              }),
              _c("img", {
                staticClass: "input-icon",
                attrs: {
                  src: require("@/assets/images/workflow/department_icon.png"),
                  alt: "",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.departmentDialog.dialogVisible
        ? _c("DepartmentSelection", {
            attrs: { dataset: _vm.departmentDialog, multiple: _vm.multiple },
            on: { close: _vm.dialogCloseHandler, submit: _vm.departmentSubmit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }