<template>
  <div class="process-graph-component">
    <ProcessGraph
      v-if="nodeId"
      :isCompent="true"
      :definitionId="definitionId"
      :bpmnId="nodeId"
      :allowEdit="false"
      :versionIdSpecial="versionIdSpecial"
      bHeight="600"
    />
  </div>
</template>

<script>
import ProcessGraph from "@/views/ruge/workflow/FlowBpmn";
export default {
  name: "process-graph-component",
  components: {
    ProcessGraph,
  },
  props: {
    nodeId: {
      type: String,
      default() {
        null;
      },
    },
  },
  data() {
    return {
      definitionId: null,
      versionIdSpecial: null,
    };
  },
  created() {
    const { definitionId, versionId } = this.$route.query;
    this.definitionId = definitionId;
    this.versionIdSpecial = versionId;
  },
};
</script>

<style lang="less" scoped>
.process-graph-component {
  width: 100%;
  height: 600px;
}
</style>