var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "props-component-direction" },
    [
      _c(
        "el-form-item",
        {
          class: ["single-form-item", `single-form-${_vm.dataset.key}`],
          attrs: { label: _vm.dataset.label, prop: _vm.dataset.key },
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.dataset.value,
                callback: function ($$v) {
                  _vm.$set(_vm.dataset, "value", $$v)
                },
                expression: "dataset.value",
              },
            },
            [
              _c("el-radio", { attrs: { label: "row" } }, [_vm._v("横向排列")]),
              _c("el-radio", { attrs: { label: "column" } }, [
                _vm._v("纵向排列"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }