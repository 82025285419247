var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dynamic-radio-component" },
    [
      _c(
        "el-radio-group",
        {
          class: [_vm.direction === "column" && "radio-group-column"],
          attrs: { disabled: _vm.disabled },
          on: { change: _vm.updateHandler },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        _vm._l(_vm.optionList, function (item) {
          return _c(
            "el-radio",
            { key: item.value, attrs: { label: item.value } },
            [_vm._v(_vm._s(item.label))]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }