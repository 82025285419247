var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dynamic-imgUpload-component" },
    [
      _c(
        "el-upload",
        {
          attrs: {
            accept: ".jpg,.png",
            action: _vm.uploadPathBuild(),
            multiple: _vm.multiple,
            limit: _vm.limit,
            "list-type": "picture-card",
            "file-list": _vm.fileList,
            disabled: _vm.disabled,
            "on-success": _vm.successHandler,
            "on-exceed": _vm.exceedHandler,
            "auto-upload": true,
          },
          scopedSlots: _vm._u([
            {
              key: "file",
              fn: function ({ file }) {
                return _c("div", {}, [
                  _c("img", {
                    staticClass: "el-upload-list__item-thumbnail",
                    attrs: { src: file.url, alt: "" },
                  }),
                  _c("span", { staticClass: "el-upload-list__item-actions" }, [
                    _c(
                      "span",
                      {
                        staticClass: "el-upload-list__item-preview",
                        on: {
                          click: function ($event) {
                            return _vm.handlePictureCardPreview(file)
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-zoom-in" })]
                    ),
                    !_vm.disabled
                      ? _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-delete",
                            on: {
                              click: function ($event) {
                                return _vm.handleRemove(file)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-delete" })]
                        )
                      : _vm._e(),
                  ]),
                ])
              },
            },
          ]),
        },
        [
          _c("i", {
            staticClass: "el-icon-plus",
            attrs: { slot: "default" },
            slot: "default",
          }),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { "append-to-body": "", visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }