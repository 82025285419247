<!--
 * @LastEditors: luo
 * @LastEditTime: 2024-12-02 14:22:34
 * @Description: 
-->
<template>
  <div
    v-loading="loading"
    :style="{
      width: sidebar.opened ? 'calc(100vw - 330px)' : 'calc(100vw - 140px)',
    }"
    class="workflow-list-page"
  >
    <div class="tree-box">
      <InputPanel
        class="tree-box-search"
        popup-class="workflow-popup"
        :fetchSuggestions="querySearch"
        v-model="searchValue"
        @change="handleInputPanelChange"
      >
        <template slot="panel-list" slot-scope="scope">
          <div class="input-panel-wrapper">
            <ul
              v-for="groupItem in scope.data"
              :key="groupItem.value"
              class="panel-list"
            >
              <template v-if="groupItem.children.length">
                <div class="panel-list">
                  <span class="group-item-title"
                    >{{ groupItem.label }}（{{
                      groupItem.children.length
                    }}）</span
                  >
                  <ul
                    v-for="item in groupItem.children"
                    :key="item.value"
                    class="panel-list"
                  >
                    <li @click="handleSelect(item.data)" class="option">
                      <img
                        :src="
                          item.data.nodeType === 'category'
                            ? folderUrl
                            : fileUrl
                        "
                      />
                      <div>
                        <div class="option-title">{{ item.label }}</div>
                        <div class="option-path">{{ item.path }}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </template>
            </ul>
          </div>
        </template>
        <template slot="suffix">
          <img :src="inputSearchUrl" alt="inputSearchUrl" />
        </template>
      </InputPanel>

      <flow-tree
        class="tree"
        :selected-node="selectedNode"
        :data="treeData"
        @node-click="nodeClick"
        :default-expanded-keys="expandedKeys"
      >
        <template slot="suffix" slot-scope="data">
          <div class="more" :class="{show: treeShowDropdown === data.data.id}" @click.stop="() => {}">
            <div class="more-content">
              <div class="dropdown-part">
                <el-dropdown @visible-change="(v) =>treeDropdownVisibleChange(v, data.data)" @command="(key) => handleCommandTree(key, data.data)">
                  <div class="ellipsis-btn-wrap">
                    <div class="ellipsis-btn">
                      <div class="dot"></div>
                      <div class="dot"></div>
                      <div class="dot"></div>
                    </div>
                  </div>
                  <el-dropdown-menu
                      slot="dropdown"
                      class="dropdown-menu shadow"
                      trigger="click"
                      :hide-on-click="false"
                  >

                    <el-dropdown-item command="createFolder"
                    >新建文件夹</el-dropdown-item
                    >
                    <template v-if="data.data.type !== 'ROOT' ">
                      <el-dropdown-item command="createFile"
                      >新建审批流</el-dropdown-item
                      >
                      <el-dropdown-item command="rename">重命名</el-dropdown-item>
                      <el-dropdown-item command="showCode"
                      >查看编码</el-dropdown-item
                      >

                      <el-dropdown-item command="move">
                        移动至文件夹
                      </el-dropdown-item>
                      <el-dropdown-item command="delete"> 删除 </el-dropdown-item>
                    </template>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </template>
      </flow-tree>
    </div>
    <div class="right" ref="cardWrapperRef">
      <div class="right-top">
        <create-button
          class="list-create-btn"
          @click="createFolderClick"
          :icon-url="folderUrl"
        >
          <div>新建文件夹</div>
        </create-button>
        <el-tooltip
          popper-class="workflow-tips"
          placement="top"
          content="根节点下不能新建审批流"
          effect="light"
          :disabled="selectedNode && selectedNode.id !== RootType"
        >
          <create-button
            class="list-create-btn"
            :disabled="selectedNode && selectedNode.id === RootType"
            @click="createFileClick"
            :icon-url="fileUrl"
          >
            <div>新建审批流</div>
          </create-button>
        </el-tooltip>
      </div>
      <div class="filter-box">
        <FilterSelect
          class="list-filter-select"
          v-model="filterFileTypeValue"
          :options="filterFileTypeList"
          placeholder="请选择"
        >
          <el-option
            v-for="item in filterFileTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </FilterSelect>
        <FilterSelect
          class="list-filter-select-sort"
          v-model="filterSortValue"
          placeholder="请选择"
          :options="sortOptionsList"
          @change="handleSortChange"
        >
          <el-option
            v-for="item in sortOptionsList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </FilterSelect>
      </div>
      <div v-if="!workflowListFilter.length" class="empty-area">
        <div class="empty-box">
          <img :src="emptyUrl" alt="empty" />
          <div class="empty-text">暂无内容</div>
        </div>
      </div>
      <div class="workflow-list">
        <div
          v-for="item in workflowListFilter"
          :key="item.nodeId"
          class="workflow-card"
        >
          <div class="workflow-card-content">
            <div class="dropdown-part" @click.stop="() => {}">
              <el-dropdown @command="(key) => handleCommand(key, item)">
               <div class="ellipsis-btn-wrap">
                 <div class="ellipsis-btn">
                   <div class="dot"></div>
                   <div class="dot"></div>
                   <div class="dot"></div>
                 </div>
               </div>
                <el-dropdown-menu
                  slot="dropdown"
                  class="dropdown-menu shadow"
                  trigger="click"
                  :hide-on-click="false"
                >
                  <template v-if="item.type === FileType">
                    <el-dropdown-item
                      v-if="item.enableStatus === 'ENABLE'"
                      command="publish"
                      >发布</el-dropdown-item
                    >
                    <el-dropdown-item command="formConfig"
                      >表单配置</el-dropdown-item
                    >
                    <el-dropdown-item
                      :disabled="item.isProcess !== '1'"
                      command="processConfiguration"
                      >流程配置</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="item.enableStatus === 'ENABLE'"
                      command="disable"
                      >禁用</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="item.enableStatus === 'DISABLE'"
                      command="enable"
                      >取消禁用</el-dropdown-item
                    >
                    <el-dropdown-item command="copy"
                      >复制审批流</el-dropdown-item
                    >
                    <el-dropdown-item command="publishRecord"
                      >发布记录</el-dropdown-item
                    >
                    <el-dropdown-item
                      command="createFolder"
                      v-if="item.type === FolderType"
                      >新建文件夹</el-dropdown-item
                    >
                    <el-dropdown-item
                      command="createFile"
                      v-if="item.type === FolderType"
                      >新建审批流</el-dropdown-item
                    >
                    <el-dropdown-item command="rename">重命名</el-dropdown-item>
                    <el-dropdown-item command="showCode"
                      >查看编码</el-dropdown-item
                    >
                    <el-dropdown-item command="move">
                      移动至文件夹
                    </el-dropdown-item>
                    <el-dropdown-item command="delete">
                      删除
                      <!--                      <warning-modal-->
                      <!--                        :on-ok="() => handleDelete(item)"-->
                      <!--                        class-name="error-text dropdown-menu-item"-->
                      <!--                        >删除</warning-modal-->
                      <!--                      >-->
                    </el-dropdown-item>
                  </template>
                  <template v-if="item.type === FolderType">
                    <el-dropdown-item command="createFolder"
                      >新建文件夹</el-dropdown-item
                    >
                    <el-dropdown-item command="createFile"
                      >新建审批流</el-dropdown-item
                    >
                    <el-dropdown-item command="rename">重命名</el-dropdown-item>
                    <el-dropdown-item command="showCode"
                      >查看编码</el-dropdown-item
                    >

                    <el-dropdown-item command="move">
                      移动至文件夹
                    </el-dropdown-item>
                    <el-dropdown-item command="delete"> 删除 </el-dropdown-item>
                  </template>
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <div class="workflow-icon-box" @click="handleSelectWorkflow(item)">
              <img
                :src="item.type === FileType ? fileUrl : folderUrl"
                alt="folder"
                class="workflow-icon"
              />
            </div>
          </div>
          <div class="workflow-card-footer">
            <div class="workflow-card-name">
              <img
                class="name-icon"
                :src="item.type === FileType ? fileUrl : folderUrl"
                alt="folder"
              />
              <el-tooltip
                :open-delay="500"
                v-tooltip-auto-show
                class="text-overflow tooltip"
                effect="dark"
                :content="item.nodeName"
                placement="top-start"
              >
                <div class="text-overflow h-20">{{ item.nodeName }}</div>
              </el-tooltip>

              <div class="workflow-tag-box" v-if="item.type === FileType">
                <span
                  v-if="item.modeStatus === 'PUBLISH'"
                  class="workflow-tag workflow-tag-publish"
                  >已发布</span
                >
                <span
                  v-if="item.modeStatus === 'DRAFT'"
                  class="workflow-tag workflow-tag-draft"
                  >草稿</span
                >
                <span
                  v-if="item.enableStatus === 'DISABLE'"
                  class="workflow-tag-disabled"
                  >禁用</span
                >
              </div>
            </div>
            <div class="workflow-name-date">
              <span>{{ item.updateUserName }}</span>
              <span>修改于：{{ item.lastUpdateDate }}</span>
            </div>
          </div>
        </div>
      </div>
      <flow-modal
        width="600px"
        title="新建文件夹"
        :visible="createFolderModalConfig.visible"
        @onOk="onOkCreateFolder"
        @onCancel="handleCancelCreateFolder"
        :loading="btnLoading"
      >
        <create-folder-modal
          v-if="createFolderModalConfig.visible"
          ref="createFolderModalRef"
          :path="createFolderModalConfig.data.path"
        />
      </flow-modal>
      <flow-modal
        width="600px"
        title="新建审批流"
        :visible="createFileModalConfig.visible"
        @onOk="onOkCreateFile"
        @onCancel="handleCancelCreateFile"
        :loading="btnLoading"
      >
        <create-file-modal
          v-if="createFileModalConfig.visible"
          ref="createFileModalRef"
          :path="createFileModalConfig.data.path"
        />
      </flow-modal>
      <flow-modal
        width="400px"
        title="重命名"
        :visible="renameModalConfig.visible"
        :data="renameModalConfig.data"
        @onOk="onOkRename"
        @onCancel="handleCancelRename"
        :loading="btnLoading"
      >
        <rename-modal
          ref="renameModalRef"
          v-if="renameModalConfig.visible"
          :old-name="renameModalConfig.data.oldName"
        />
        <template
          #footer-middle
          v-if="
            renameModalConfig.data.nodeType === 'mode' &&
            renameModalConfig.data.enableStatus === 'ENABLE'
          "
        >
          <el-button
            style="border-radius: 10px; padding: 9px 25px"
            type="primary"
            plain
            @click="() => onOkSaveRename('PUBLISH')"
            >保存并发布</el-button
          >
        </template>
      </flow-modal>
      <flow-modal
        width="600px"
        title="复制审批流"
        :visible="copyModalConfig.visible"
        @onOk="onOkCopyModal"
        @onCancel="onCancelCopyModal"
        :loading="btnLoading"
      >
        <create-file-modal
          :auto-code="true"
          v-if="copyModalConfig.visible"
          ref="copyModalRef"
          :path="copyModalConfig.data.path"
          :defaultForm="copyModalConfig.data.form"
        />
      </flow-modal>
      <flow-modal
        width="720px"
        title="移动至文件夹"
        :visible="moveFileModalConfig.visible"
        @onOk="onOkMoveFile"
        @onCancel="onCancelMoveFile"
        :loading="btnLoading"
      >
        <move-file-modal
          v-if="moveFileModalConfig.visible"
          ref="moveFileModalRef"
          :node-id="moveFileModalConfig.data.nodeId"
        />
      </flow-modal>
      <flow-modal
        width="600px"
        title="发布"
        :visible="publishModalConfig.visible"
        @onOk="onOkPublishModal"
        @onCancel="onCancelPublishModal"
        :loading="btnLoading"
      >
        <publish-modal
          v-if="publishModalConfig.visible"
          ref="publishModalRef"
          :path="publishModalConfig.data.path"
          :default-form="publishModalConfig.data.form"
        />
      </flow-modal>
      <flow-modal
        width="600px"
        title="查看编码"
        @onCancel="onCancelShowCodeModal"
        :visible="showCodeModalConfig.visible"
      >
        <ShowCodeModal
          v-if="showCodeModalConfig.visible"
          :default-form="showCodeModalConfig.data"
          :readonly="true"
        />
        <template v-slot:footer>
          <el-button
            class="flow-button flow-button-primary"
            type="primary"
            @click="onCancelShowCodeModal"
            >关闭</el-button
          >
        </template>
      </flow-modal>
    </div>
  </div>
</template>

<script>
import folderUrl from "@/assets/images/workflow/folder.svg";
import fileUrl from "@/assets/images/workflow/file-icon.svg";
import expandedUrl from "@/assets/images/workflow/expanded.svg";
import foldUrl from "@/assets/images/workflow/fold.svg";
import fileDisabledUrl from "@/assets/images/workflow/file-disabled.svg";
import { mapGetters } from "vuex";
import CreateButton from "./components/create-button.vue";
import FlowModal from "./components/flow-modal.vue";
import CreateFolderModal from "./components/create-folder-modal.vue";
import CreateFileModal from "./components/create-file-modal.vue";
import WarningModal from "@/views/ruge/workflow/list/components/warning-modal.vue";
import FlowTree from "@/views/ruge/workflow/list/components/flow-tree.vue";
import MoveFileModal from "@/views/ruge/workflow/list/components/move-file-modal.vue";
import { apiGetPreNodeTree } from "@/api/ruge/workflow/workflow";
import FilterSelect from "./components/filter-select.vue";
import inputSearchUrl from "@/assets/images/workflow/input-search.svg";
import {
  FolderType,
  FileType,
  formatTreeData,
  arrayToTree,
  getTreeData,
  RootType,
  findNode,
  treeToList,
} from "./utils";
import {
  apiPreCategoryNodeList,
  apiDeleteNode,
  apiNodeRename,
  apiPublish,
  apiCopyMode,
  apiPostSaveFolderNode,
  apiPostSaveFileNode,
  apiEnableDisable,
  apiMoveNode,
  apiNodeSaveRename,
} from "../../../../api/ruge/workflow/workflow";
import RenameModal from "./components/rename-modal.vue";
import emptyUrl from "@/assets/images/workflow/empty.svg";
import { format, getUnixTime } from "date-fns";
import PublishModal from "./components/publish-modal.vue";
import ShowCodeModal from "./components/show-code-modal.vue";
import "./index.css";
import { debounce } from "lodash";
import { isPlainObject } from "lodash";
import Select from "./components/select.vue";
import InputPanel from "./components/input-panel.vue";
import Template from "@/views/ruge/modulx/template.vue";
import { deepClone } from "@/utils/utils";
import axios from "axios";
export default {
  name: "workflow-list-page",
  components: {
    Template,
    MoveFileModal,
    CreateFolderModal,
    CreateButton,
    FlowModal,
    CreateFileModal,
    WarningModal,
    FlowTree,
    RenameModal,
    PublishModal,
    FilterSelect,
    Select,
    InputPanel,
    ShowCodeModal,
  },
  data() {
    return {
      inputSearchUrl,
      FolderType,
      FileType,
      RootType,
      folderUrl,
      fileUrl,
      expandedUrl,
      foldUrl,
      fileDisabledUrl,
      emptyUrl,
      expandedKeys: [],
      btnLoading: false,
      loading: true,
      cardAreaLoading: false,
      searchValue: "",
      filterFileTypeValue: "all",
      treeShowDropdown: '',
      /**
       * 默认
       * 修改时间
       * 创建时间
       * 名称
       */
      filterSortValue: "sort",
      sortOptionsList: [
        { label: "默认", value: "sort" },
        { label: "修改时间", value: "updateDate" },
        { label: "创建时间", value: "createDate" },
        { label: "名称", value: "name" },
      ],
      filterFileTypeList: [
        { label: "全部", value: "all" },
        { label: "文件夹", value: "folder" },
        { label: "审批流", value: "mode" },
      ],
      /** Config for create file modal */
      createFileModalConfig: {
        visible: false,
        /**
         * Modal data containing:
         * @property {string} id - Parent folder ID
         * @property {string} path - Full path to current location
         */
        data: {},
      },

      /** Config for create folder modal */
      createFolderModalConfig: {
        visible: false,
        /**
         * Modal data containing:
         * @property {string} id - Parent folder ID
         * @property {string} path - Full path to current location
         */
        data: {},
      },

      /** Flag for rename modal visibility */
      visibleRename: false,

      /** Config for rename modal */
      renameModalConfig: {
        visible: false,
        /**
         * Modal data containing:
         * @property {string} oldName - Original node name
         * @property {string} nodeId - ID of node being renamed
         * @property {string} nodeType - Type of node being renamed
         */
        data: {},
      },

      /** Config for copy workflow modal */
      copyModalConfig: {
        visible: false,
        /**
         * Modal data containing:
         * @property {string} id - ID of workflow being copied
         * @property {string} path - Path where copy will be created
         * @property {Object} form - Default form values for copy
         */
        data: {},
      },

      /** Config for move file modal */
      moveFileModalConfig: {
        visible: false,
        /**
         * Modal data containing:
         * @property {string} nodeId - ID of node being moved
         * @property {string} nodeType - Type of node being moved
         * @property {string} path - Current path of node
         */
        data: {},
      },
      publishModalConfig: {
        visible: false,
        /**
         * Modal data containing:
         * @property {string} path - Current path in workflow tree
         * @property {string} name - Name of workflow being published
         * @property {string} nodeId - ID of node being published
         * @property {string} nodeType - Type of node being published
         */
        data: {},
      },
      selectedNode: null,
      btns: [
        { label: "新建文件夹", imgUrl: folderUrl },
        { label: "新建审批流", imgUrl: fileUrl },
      ],
      workflowList: [],
      workflowListFilter: [],
      treeData: [],
      treeDataFlat: [],
      debounceCalcCardWidth: () => {},
      showCodeModalConfig: {
        visible: false,
        data: {},
      },
      cancelTokenSource: null,
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
  mounted() {
    const query = this.$route.query;
    const folderNodeId = query.folderNodeId;
    this.fetchTreeData(folderNodeId);
    this.debounceCalcCardWidth = debounce(() => this.calcCardWidth(), 100);
    window.addEventListener("resize", this.debounceCalcCardWidth);
    this.debounceCalcCardWidth();
    this.cancelTokenSource = axios.CancelToken.source();
  },
  methods: {
    handleSortChange(val) {
      this.workflowListFilter = this.sortWorkList(val, this.workflowListFilter);
    },
    filterWorkList(fileType, workflowList) {
      const list =
        fileType === "all"
          ? workflowList
          : workflowList.filter(
              (item) => item.type === fileType || item.nodeType === fileType
            );
      return list;
    },
    /**
     *
     * @param sortType
     * @param list []
     */
    sortWorkList(sortType, list) {
      const data = [...list];
      switch (sortType) {
        case "sort":
          data.sort((a, b) => a.sort - b.sort);
          return data;
        case "updateDate":
          data.sort((a, b) => b.updateDate - a.updateDate);
          return data;
        case "createDate":
          data.sort((a, b) => b.createDate - a.createDate);
          return data;
        case "name":
          data.sort((a, b) => a.nodeName.localeCompare(b.nodeName));
          return data;
        default:
          return data;
      }
    },
    handleInputPanelChange(v) {
      console.log(v);
      if (!v) {
        this.workflowListFilter = deepClone(this.workflowList);
      }
    },
    querySearch(queryString, cb) {
      const treeDataFlat = this.treeDataFlat.filter((item) =>
        item.nodeName.includes(queryString)
      );

      const res = treeDataFlat.map((item) => ({
        value: item.nodeId,
        label: item.nodeName,
        path: this.getPath(item),
        data: item,
      }));
      console.log("res", res);
      const files = {
        value: "folder",
        label: "文件夹",
        children: res.filter((item) => item.data.nodeType === "category"),
      };
      const modes = {
        value: "mode",
        label: "审批流",
        children: res.filter((item) => item.data.nodeType === "mode"),
      };
      const groupRes = [];
      if (files.children.length) {
        groupRes.push(files);
      }
      if (modes.children.length) {
        groupRes.push(modes);
      }

      cb(groupRes);
    },
    async handleSelect(item) {
      this.filterFileTypeValue = "all";
      if (item.nodeType === "category") {
        const res = await this.nodeClick(item);
        console.log("handleSelecthandleSelect", res);
        // this.workflowListFilter = res.filter(node => node.id === item.id)
      } else if (item.nodeType === "mode") {
        const parentNode = this.treeDataFlat.find(
          (a) => a.id === item.parentNodeId
        );
        if (!parentNode) {
          this.$message.error("打开失败");
          return;
        }
        const res = await this.nodeClick(parentNode);
        this.workflowListFilter = res.filter((node) => node.nodeId === item.id);
        console.log("handleSelecthandleSelect", res);
      }
    },
    handleSelectWorkflow(item) {
      if (item.type === FolderType) {
        const node = findNode(this.treeData, (node) => node.id === item.nodeId);
        if (node) {
          const { children, ...rest } = node;
          this.nodeClick({ ...rest });
        }
      }
    },
    async onOkPublishModal() {
      const data = await this.$refs.publishModalRef.onOk();
      if (!data) return;
      const { nodeId, nodeType } = this.publishModalConfig.data;
      try {
        this.btnLoading = true;
        const res = await apiPublish({
          modeId: nodeId,
          modeStatus: "PUBLISH",
          processName: data.name,
          publishDescription: data.remark,
        });
        this.btnLoading = false;
        const { success } = this.checkPublishedSuccess(res);
        if (!success) {
          return;
        }
        this.onCancelPublishModal();
        this.fetchNodeData(this.selectedNode.id);
      } catch (error) {
        console.error(error);
        this.btnLoading = false;
      }
    },
    onCancelPublishModal() {
      this.publishModalConfig = {
        visible: false,
        data: {},
      };
    },
    async onOkMoveFile() {
      const checkedKeys = await this.$refs.moveFileModalRef.onOk();
      if (!checkedKeys) return;
      const { data } = this.moveFileModalConfig;
      if (data.nodeType === "mode" && checkedKeys[0] === RootType) {
        this.$message.error("审批流不能移动到根节点");
        return;
      }
      try {
        this.btnLoading = true;
        await apiMoveNode({
          nodeId: data.nodeId,
          nodeType: data.nodeType,
          parentNodeId: checkedKeys[0] === RootType ? "" : checkedKeys[0],
        });
        this.btnLoading = false;
        this.onCancelMoveFile();
        this.$message.success("移动成功");
        this.fetchTreeData();
      } catch (error) {
        this.btnLoading = false;
      }
    },
    onCancelMoveFile() {
      this.moveFileModalConfig = {
        visible: false,
        data: {},
      };
    },
    async onOkRename(modeStatus = "DRAFT") {
      try {
        const data = await this.$refs.renameModalRef.onOk();
        if (!data) return;
        this.btnLoading = true;
        const res = await apiNodeRename({
          nodeId: this.renameModalConfig.data.nodeId,
          nodeType: this.renameModalConfig.data.nodeType,
          nodeName: data.name,
          /**
           * 保存并发布传 PUBLISH
           */
          modeStatus,
        });
        this.btnLoading = false;
        this.$message.success("重命名成功");
        this.handleCancelRename();
        this.fetchTreeData();
      } catch (error) {
        this.btnLoading = false;
      }
      this.handleCancelRename();
    },
    async onOkSaveRename(modeStatus = "DRAFT") {
      try {
        const data = await this.$refs.renameModalRef.onOk();
        if (!data) return;
        this.btnLoading = true;
        const res = await apiNodeSaveRename({
          modeId: this.renameModalConfig.data.nodeId,
          processName: data.name,
          /**
           * 保存并发布传 PUBLISH
           */
          modeStatus,
        });
        this.btnLoading = false;
        const { success } = this.checkPublishedSuccess(res);
        if (!success) {
          return;
        }
        this.handleCancelRename();
        this.fetchTreeData();
      } catch (error) {
        console.error(error);
        this.btnLoading = false;
      }
      this.handleCancelRename();
    },
    handleCancelRename() {
      this.renameModalConfig = {
        visible: false,
        data: {},
      };
    },
    createFolderClick() {
      console.log("this.selectedNode", this.selectedNode);
      if (this.selectedNode.parentNodes.length >= 8) {
        this.$message.error("最多创建8级文件夹");
        return;
      }
      const data = {
        id: this.selectedNode.id,
        path: this.getPath(this.selectedNode),
      };
      this.createFolderModalConfig = {
        visible: true,
        data,
      };
    },
    createFileClick() {
      const id = this.selectedNode.id;
      if (id === RootType) {
        this.$message.error("根节点下不能新建审批流");
        return;
      }
      const data = {
        id: this.selectedNode.id,
        path: this.getPath(this.selectedNode),
        parentId: this.selectedNode.id,
      };
      this.createFileModalConfig = {
        visible: true,
        data,
      };
    },
    async onOkCopyModal() {
      try {
        const data = await this.$refs.copyModalRef.onOk();
        if (!data) return;
        this.btnLoading = true;
        await apiCopyMode({
          modeId: this.copyModalConfig.data.id,
          processCode: data.code,
          processName: data.name,
        });
        this.btnLoading = false;
        this.$message.success("复制成功");
        this.onCancelCopyModal();
        this.fetchTreeData();
      } catch (error) {
        this.btnLoading = false;
      }
    },
    onCancelCopyModal() {
      this.copyModalConfig = {
        visible: false,
        data: {},
      };
    },
    onCancelShowCodeModal() {
      this.showCodeModalConfig = {
        visible: false,
        data: {},
      };
    },
    openShowCodeModal(item) {
      console.log("item", item);
      this.showCodeModalConfig = {
        visible: true,
        data: {
          name: item.nodeName,
          code: item.processCode || item.categoryCode,
        },
      };
    },
    async onOkCreateFolder() {
      try {
        const curNode = this.createFolderModalConfig.data;
        const data = await this.$refs.createFolderModalRef.onOk();
        if (!data) return;
        const parentId = curNode ? curNode.id : "";
        this.btnLoading = true;
        await apiPostSaveFolderNode({
          nodeType: "category",
          categoryVO: {
            categoryName: data.name,
            categoryCode: data.categoryCode,
            parentId: parentId === RootType ? "" : parentId,
          },
        });
        this.btnLoading = false;
        this.$message.success("新建文件夹成功");
        this.fetchTreeData();
      } catch (error) {
        this.btnLoading = false;
      }
      this.handleCancelCreateFolder();
    },
    async onOkCreateFile() {
      try {
        const curNode = this.createFileModalConfig.data;
        const data = await this.$refs.createFileModalRef.onOk();
        if (!data) return;
        const parentId = curNode ? curNode.id : "";
        this.btnLoading = true;
        await apiPostSaveFileNode({
          nodeType: "mode",
          modeVO: {
            processName: data.name,
            processCode: data.code,
            categoryId: parentId,
            modeStatus: "DRAFT",
          },
        });
        this.btnLoading = false;
        this.$message.success("新建审批流成功");
        this.fetchTreeData();
        // if (curNode) {
        //   this.fetchNodeData(curNode.parentId);
        // } else {
        //
        // }
        this.handleCancelCreateFile();
      } catch (error) {
        this.btnLoading = false;
      }
    },

    handleCancelCreateFolder() {
      this.createFolderModalConfig = {
        visible: false,
        data: {},
      };
    },
    handleCancelCreateFile() {
      this.createFileModalConfig = {
        visible: false,
        data: {},
      };
    },
    async fetchTreeData(folderNodeId) {
      this.loading = true;
      try {
        const res = await apiGetPreNodeTree();
        const allTree = formatTreeData(
          arrayToTree(getTreeData(deepClone(res)))
        );
        this.treeDataFlat = treeToList(allTree)
          .map((item) => {
            const { children, ...rest } = item;
            if (rest.type === RootType) return null;
            return { ...rest };
          })
          .filter((item) => item);
        console.log(" this.treeDataFlat ", this.treeDataFlat);
        const data = res.filter((item) => item.nodeType !== "mode");
        this.treeData = formatTreeData(arrayToTree(getTreeData(data)));

        if (this.selectedNode) {
          const nodeId =
            this.selectedNode.id === RootType ? "" : this.selectedNode.id;
          this.fetchNodeData(nodeId);
        } else if (this.treeData.length) {
          const node = folderNodeId
            ? findNode(this.treeData, (node) => node.id === folderNodeId)
            : undefined;
          const currentNode = node ? node : this.treeData[0];
          this.nodeClick(currentNode);
        }
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    fetchNodeData(parentId) {
      return new Promise((resolve, reject) => {
        if (this.cancelTokenSource) {
          this.cancelTokenSource.cancel("请求已被取消");
        }
        // 创建新的 CancelToken
        this.cancelTokenSource = axios.CancelToken.source();
        const debounceLoadingFn = debounce(() => {
          this.cardAreaLoading = true;
        }, 400);
        debounceLoadingFn();
        apiPreCategoryNodeList({ categoryId: parentId }, this.cancelTokenSource)
          .then((res) => {
            this.cardAreaLoading = false;
            debounceLoadingFn.cancel();
            this.workflowList = res.map((item) => ({
              ...item,
              type: item.nodeType === "category" ? FolderType : FileType,
              lastUpdateDate: format(
                new Date(item.lastUpdateDate),
                "yyyy-MM-dd HH:mm"
              ),
              creationDate: format(
                new Date(item.creationDate),
                "yyyy-MM-dd HH:mm"
              ),
              updateDate: getUnixTime(new Date(item.lastUpdateDate)),
              createDate: getUnixTime(new Date(item.creationDate)),
            }));
            this.workflowListFilter = deepClone(this.workflowList);
            console.log(
              "deepClone(this.workflowList)",
              deepClone(this.workflowList)
            );
            resolve(deepClone(this.workflowList));
          })
          .catch((err) => {
            debounceLoadingFn.cancel();
            this.cardAreaLoading = false;
            resolve([]);
          });
      });
    },

    nodeClick(data) {
      const { children, ...rest } = data;
      const id = this.selectedNode ? this.selectedNode.id : null;
      if (id && id === data.id) {
        return deepClone(this.workflowList);
      }
      this.selectedNode = { ...rest };
      if (data.type === FolderType) {
        this.expandedKeys = [data.id];
        return this.fetchNodeData(data.id);
      } else if (data.type === RootType) {
        this.expandedKeys = [RootType];
        return this.fetchNodeData("");
      }
    },
    calcCardWidth() {
      const cardWrapper = this.$refs.cardWrapperRef;
      const width = cardWrapper.clientWidth;
      const num = Math.floor((width + 20) / 270);
      const w = num * 250 + (num - 1) * 20;
      const restWidth = width - w - num;
      if (restWidth <= 10) {
        cardWrapper.style.setProperty("--width", `250px`);
        return;
      }
      const r = (restWidth - num) / num;

      const cardWidth = 250 + Math.floor(r);
      cardWrapper.style.setProperty("--width", `${cardWidth}px`);
    },
    treeDropdownVisibleChange(visible, item){
      this.treeShowDropdown = visible ? item.id : ''
    },
    handleCommandTree(command, item) {
      switch (command) {
        case "publish":
          this.handlePublish(item);
          break;
        case "formConfig":
          this.handleFormConfig(item);
          break;
        case "processConfiguration":
          this.handleProcessConfiguration(item);
          break;
        case "disable":
          this.handleDisable(item);
          break;
        case "enable":
          this.handleEnable(item);
          break;
        case "copy":
          this.handleCopy(item);
          break;
        case "publishRecord":
          this.handlePublishRecord(item);
          break;
        case "createFolder":
          const path = this.getPath(item);
          this.handleCreateFolder(item, path);
          break;
        case "createFile":
          this.handleCreateFile(item, this.getPath(item));
          break;
        case "rename":
          this.handleRename(item);
          break;
        case "move":
          this.handleMove(item);
          break;
        case "delete":
          this.handleDelete(item);
          break;
        case "showCode":
          this.openShowCodeModal(item);
          break;
        default:
          break;
      }
    },
    handleCommand(command, item) {
      switch (command) {
        case "publish":
          this.handlePublish(item);
          break;
        case "formConfig":
          this.handleFormConfig(item);
          break;
        case "processConfiguration":
          this.handleProcessConfiguration(item);
          break;
        case "disable":
          this.handleDisable(item);
          break;
        case "enable":
          this.handleEnable(item);
          break;
        case "copy":
          this.handleCopy(item);
          break;
        case "publishRecord":
          this.handlePublishRecord(item);
          break;
        case "createFolder":
          const path = this.getPath(this.selectedNode);
          this.handleCreateFolder(item, `${path} ＞ ${item.nodeName}`);
          break;
        case "createFile":
          const data = {
            id: item.nodeId,
            path: this.getPath(item),
            parentId: item.id,
          };
          this.createFileModalConfig = {
            visible: true,
            data,
          };
          break;
        case "rename":
          this.handleRename(item);
          break;
        case "move":
          this.handleMove(item);
          break;
        case "delete":
          this.handleDelete(item);
          break;
        case "showCode":
          this.openShowCodeModal(item);
          break;
        default:
          break;
      }
    },
    async handlePublish(item) {
      const path = this.getPath(this.selectedNode);
      const data = {
        path,
        form: {
          name: item.nodeName,
        },
        nodeId: item.nodeId,
        nodeType: item.nodeType,
      };
      this.publishModalConfig = {
        visible: true,
        data,
      };
    },
    handleFormConfig({ nodeId }) {
      this.$router.push({
        path: "/workflow/detail?layout=hide",
        query: {
          nodeId,
          folderNodeId: this.selectedNode.nodeId,
        },
      });
    },
    handleProcessConfiguration({ nodeId }) {
      this.$router.push({
        path: "/workflow/flowBpnm?layout=hide",
        query: {
          nodeId,
          folderNodeId: this.selectedNode.nodeId,
        },
      });
    },
    handleDisable(item) {
      this.$confirm(`确认禁用该审批流吗？`, this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      }).then(async () => {
        const res = await this.handleEnableDisable({
          nodeType: item.nodeType,
          modeId: item.nodeId,
          enableStatus: "DISABLE",
          modeStatus: "DRAFT",
        });
        if (res) {
          this.$message.success("禁用成功");
        }
      });
    },
    handleEnable(item) {
      this.$confirm(`确认取消禁用该审批流吗？`, this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      }).then(async () => {
        const res = await this.handleEnableDisable({
          nodeType: item.nodeType,
          modeId: item.nodeId,
          enableStatus: "ENABLE",
          modeStatus: "DRAFT",
        });
        if (res) {
          this.$message.success("取消禁用成功");
        }
      });
    },
    async handleEnableDisable(item) {
      try {
        this.btnLoading = true;
        await apiEnableDisable({
          nodeType: item.nodeType,
          modeVO: {
            modeId: item.modeId,
            enableStatus: item.enableStatus,
            modeStatus: item.modeStatus,
          },
        });

        this.btnLoading = false;
        this.fetchNodeData(this.selectedNode.id);
        return true;
      } catch (error) {
        this.btnLoading = false;
        return false;
      }
    },
    handleCopy(item) {
      const path = this.getPath(this.selectedNode);
      const data = {
        id: item.nodeId,
        path,
        form: { name: `${item.nodeName}-副本` },
      };
      console.log("data", data);
      this.copyModalConfig = {
        visible: true,
        data,
      };
    },
    handlePublishRecord(item) {
      const routeData = {
        path: "/workflow/publishRecord",
        query: {
          nodeId: item.nodeId,
          folderNodeId: this.selectedNode.nodeId,
        },
      };
      // Open in new tab
      const { href } = this.$router.resolve(routeData);
      window.open(href, "_blank");
    },
    handleCreateFolder(item, path) {
      const data = { id: item.nodeId, path };
      this.createFolderModalConfig = {
        visible: true,
        data,
      };
    },
    handleCreateFile(item) {
      const path = this.getPath(this.selectedNode);
      const data = {
        id: item.nodeId,
        path: `${path} ＞ ${item.nodeName}`,
        parentId: this.selectedNode.id,
      };
      console.log("data", data);
      this.createFileModalConfig = {
        visible: true,
        data,
      };
    },
    getPath(item) {
      return item && Array.isArray(item.parentNodes)
        ? item.parentNodes.map((item) => item.nodeName).join(" ＞ ")
        : "";
    },
    handleRename(item) {
      const data = {
        oldName: item.nodeName,
        nodeId: item.nodeId,
        nodeType: item.nodeType,
        enableStatus: item.enableStatus,
      };
      this.renameModalConfig = {
        visible: true,
        data,
      };
    },
    handleMove(item) {
      const data = {
        nodeId: item.nodeId,
        nodeType: item.nodeType,
        path: this.getPath(this.selectedNode),
      };
      this.moveFileModalConfig = {
        visible: true,
        data,
      };
    },
    async handleDelete(item) {
      const fileTypeMap = {
        mode: "审批流",
        category: "文件夹",
      };
      const type = fileTypeMap[item.nodeType];
      this.$confirm(`确认删除该${type}吗？`, this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      }).then(async (res) => {
        this.loading = true;
        try {
          await apiDeleteNode({
            nodeId: item.nodeId,
            nodeType: item.nodeType,
          });
          this.$message.success("删除成功");
          this.loading = false;
          this.fetchTreeData();
        } catch (error) {
          this.loading = false;
        }
      });
    },
    checkPublishedSuccess(res) {
      if (isPlainObject(res) && res.processVerify) {
        const msgs = res.processVerify;
        this.$message.error({
          dangerouslyUseHTMLString: true,
          message: msgs
            .map((item) => `<div>${item.errorInfo}</div>`)
            .join("<br>"),
        });
        return {
          success: false,
          msg: "",
          msgs,
        };
      }
      const msg = "发布成功";
      this.$message.success(msg);
      return { success: true, msg, msgs: [] };
    },
  },
  watch: {
    workflowList(val) {
      const list = this.filterWorkList(this.filterFileTypeValue, val);
      this.workflowListFilter = this.sortWorkList(this.filterSortValue, list);
    },
    filterFileTypeValue(val) {
      const list = this.filterWorkList(val, this.workflowList);
      this.workflowListFilter = this.sortWorkList(this.filterSortValue, list);
    },
  },
  // 销毁
  beforeDestroy() {
    window.removeEventListener("resize", this.debounceCalcCardWidth);
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel("请求已被取消");
    }
  },
};
</script>

<style lang="less" scoped>
.tooltip {
  flex: 1;
}
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.workflow-list-page {
  display: flex;
  width: 100%;
  height: calc(100vh - 90px);
  box-sizing: border-box;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 10px 20px 20px 40px;
  font-size: 14px;
  font-family: "PingFang SC", "Microsoft YaHei", sans-serif;
  overflow: auto;
  .dropdown-part {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 12px;
  }
  .ellipsis-btn-wrap {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    &:hover, &:active{
      background: #EBEBEC;
      border-radius: 4px;
    }

  }
  .ellipsis-btn {
    width: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    .dot {
      width: 3px;
      height: 3px;
      background: #5d687c;
      margin-right: 3px;
      border-radius: 100%;
    }
    .dot:last-child {
      margin-right: 0;
    }

  }
  .shadow {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  }

  div {
    box-sizing: border-box;
  }

  .empty-area {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .empty-box {
      width: 100%;
      height: 196px;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 5px;
      .empty-text {
        font-weight: 400;
        font-size: 10px;
        color: #b5bece;
        line-height: 16px;
        text-align: left;
        font-style: normal;
      }
    }
  }

  .tree-box {
    width: 280px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    background: #fcfcfc;
    border-radius: 10px;
    border: 1px solid #e4e7eb;
    height: 100%;
    padding: 12px 10px;
    margin-right: 20px;
    overflow: hidden;
    .tree {
      overflow: auto;
    }
    .tree-box-search {
      height: 36px;
      border-radius: 10px 10px 10px 10px;
      ::v-deep {
        .tree-box-search-icon {
          height: 100%;
          display: flex;
          justify-content: center; /* 水平居中 */
          align-items: center; /* 垂直居中 */
        }
        .el-input__inner {
          background: #f4f4f5;
          border-radius: 10px 10px 10px 10px;
          &:active,
          &:focus {
            border-color: #2a61ff;
          }
        }
        .el-input__suffix {
          display: flex;
          justify-content: center;
          align-items: center;
          right: 10px;
        }
      }
    }
  }

  .workflow-tag-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 4px;
    background: #ffefef;
    border-radius: 2px 4px;
    font-weight: 400;
    font-size: 10px;
    color: #f35555;
    font-style: normal;
    text-transform: none;
  }

  .workflow-tag-box {
    display: flex;
    gap: 4px;
    margin-left: 6px;
  }

  .workflow-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 4px;
    font-weight: 400;
    font-size: 10px;
    color: #2a61ff;
    background: rgba(42, 97, 255, 0.15);
    border-radius: 2px 4px;
    line-height: 10px;

    &-publish {
      background: rgba(42, 97, 255, 0.15);
    }

    &-draft {
      color: #5d687c;
      background: #eaeaea;
    }
  }

  .right {
    --width: 250px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .list-create-btn {
      width: var(--width);
    }
    .filter-box {
      display: flex;
      column-gap: 40px;
    }
    .list-filter-select {
      width: 56px;
    }
    .list-filter-select-sort {
      width: 72px;
    }
    .right-top {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
    }

    .workflow-list {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 20px;

      .workflow-card {
        width: var(--width);
        height: 195px;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        transition: width 0.5s ease-in-out;
        border: 1px solid #e3e8ee;

        &:hover {
          border-color: #2a61ff;
        }

        &-content {
          height: 130px;
          display: flex;
          box-sizing: border-box;
          flex-direction: column;
          align-items: center;
          background: #f6f6f6;
          border-radius: 10px 10px 0px 0px;
          padding-top: 10px;

          .workflow-icon-box {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }

          .workflow-icon {
            width: 80px;
            height: 80px;
          }




        }
        .h-20 {
          font-size: 14px;
          color: #252d3d;
          line-height: 16px;
        }
        &-footer {
          display: flex;
          flex-direction: column;
          padding: 12px 12px 0 16px;
          box-sizing: border-box;

          .workflow-card-name {
            display: flex;
            align-items: center;
            margin-bottom: 9px;
            font-weight: 500;
            font-size: 14px;
            color: #252d3d;

            .name-icon {
              width: 20px;
              height: 20px;
              margin-right: 4px;
            }
          }

          .workflow-name-date {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: #b5bece;
          }
        }
      }
    }
  }
}

.dropdown-menu {
  width: 120px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px 0px;
  border-radius: 10px;
  padding: 12px 0px;
  //margin-top: 0px;
  font-weight: 500;
  font-size: 14px;
  color: #252d3d;

  .el-popper .popper__arrow {
    background-color: red;
  }

  .el-dropdown-menu__item {
    padding: 8px 16px;
    line-height: 14px;

    &:hover {
      background: #f4f4f5 !important;
      color: #2a61ff;
    }
  }
}

.workflow-tips {
  border-color: #ffffff !important;
}

.flow-button {
  border-radius: 8px;
  border: 1px solid #2a61ff;
  cursor: pointer;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 14px;
  color: #2a61ff;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-left: 0 !important;
  padding: 9px 25px;

  &-primary {
    background: #2a61ff;
    color: #fff;
  }
}

::v-deep {
  .custom-tree-node {
    flex: 1;
    :hover, .show  {
      .more-content {
        display: block !important;
      }
    }
  }
  .more{
    .more-content {
      display: none;
    }

  }

  .error-text {
    color: #f13636 !important;
  }

  .dropdown-menu-item {
    padding: 8px 16px;
    line-height: 14px;
  }

  .el-button + .el-button {
    margin-left: 0;
  }

  .input-panel-wrapper {
    padding: 16px 20px;
  }
  .panel-list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    //padding:16px 20px;
    //background: #FFFFFF;
    //box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1);
    //border-radius: 10px 10px 10px 10px;
    //border: 1px solid #F4F4F5;
    .group-item-title {
      font-weight: 400;
      font-size: 12px;
      color: #5d687c;
      line-height: 12px;
    }
    .option {
      display: flex;
      align-items: center;
      padding: 8px 10px;
      cursor: pointer;
      &:hover {
        background: #f4f4f5;
        border-radius: 6px 6px 6px 6px;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
      .option-title {
        font-weight: 500;
        height: 20px;
        font-size: 14px;
        color: #252d3d;
        margin-bottom: 4px;
        display: flex;
      }
      .option-path {
        font-weight: 400;
        font-size: 12px;
        color: #b5bece;
        line-height: 12px;
      }
    }
  }
}
</style>
