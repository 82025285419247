var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "process-detail-page" },
    [
      _c("div", { staticClass: "process-header" }, [
        _c("span", { staticClass: "logo-and-title" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/login/small_logo.png"),
              alt: "",
            },
          }),
          _c("span", { staticClass: "header-title" }, [
            _vm._v(
              "\n        " + _vm._s(this.processBasicInfo[0]) + "\n      "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "process-detail-container" }, [
        _c("div", { staticClass: "process-name" }, [
          _vm._v("\n      " + _vm._s(_vm.processName) + "\n    "),
        ]),
        _c(
          "div",
          { staticClass: "card-container" },
          [
            _c("DetailTitle", { attrs: { title: "基本信息" } }),
            _c("DetailBasicInfo", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.initLoading,
                  expression: "initLoading",
                },
              ],
              ref: "basicInfoIns",
              attrs: { dataset: _vm.processBasicInfo },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "card-container" },
          [
            _c("DetailTitle", { attrs: { title: "审批内容" } }),
            _c(
              "div",
              { staticClass: "form-container" },
              [
                _c("DynamicForm", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.initLoading,
                      expression: "initLoading",
                    },
                  ],
                  ref: "dynamicFormIns",
                  attrs: {
                    editState:
                      _vm.state === "readOnly" ? "readOnly" : "process",
                    flowNodeId: _vm.flowDetails.frontendNodeId,
                    dataset: _vm.formConfig,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "card-container" },
          [
            _c("DetailTitle", { attrs: { title: "流程处理" } }),
            _c("div", { staticClass: "tabs-and-button" }, [
              _c(
                "div",
                { staticClass: "tabs-line" },
                _vm._l(_vm.filteredTabs, function (item) {
                  return _c(
                    "span",
                    {
                      key: item.key,
                      class: ["single-tab", item.active && "single-tab-active"],
                      on: {
                        click: function ($event) {
                          return _vm.setTabActive(item.key)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.label) + "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "special-buttons" }, [
                _vm.specialButtonRender("admin")
                  ? _c("span", { staticClass: "special-buton" }, [
                      _vm._v("\n            以管理员身份操作\n          "),
                    ])
                  : _vm._e(),
                _vm.specialButtonRender("start")
                  ? _c(
                      "span",
                      {
                        staticClass: "special-buton",
                        on: { click: _vm.drafterHandler },
                      },
                      [_vm._v("\n            以起草人身份操作\n          ")]
                    )
                  : _vm._e(),
              ]),
            ]),
            _c(
              "keep-alive",
              [
                _c(_vm.propsCompRequire(_vm.activeTab), {
                  ref: "processHandlerIns",
                  tag: "components",
                  attrs: {
                    processInfo: _vm.processInfo,
                    nodeId: _vm.graphNodeId,
                    queryNodeParams: _vm.queryNodeParams,
                    flowDetails: _vm.flowDetails,
                    buttonList: _vm.buttonList,
                    diaplayDatas: _vm.diaplayDatas,
                    state: _vm.state,
                    processStatus: _vm.processStatus,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.initLoading,
              expression: "initLoading",
            },
          ],
          staticClass: "process-buttons",
        },
        [
          _c(
            "r-button",
            { attrs: { type: "danger" }, on: { click: _vm.closePage } },
            [_vm._v("关闭")]
          ),
          _vm.buttonRender("copy")
            ? _c("r-button", { on: { click: _vm.copyHandler } }, [
                _vm._v("复制流程"),
              ])
            : _vm._e(),
          _vm.buttonRender("draft")
            ? _c("r-button", { on: { click: _vm.saveAsDraft } }, [
                _vm._v("暂存"),
              ])
            : _vm._e(),
          _vm.buttonRender("delete")
            ? _c(
                "r-button",
                { attrs: { type: "danger" }, on: { click: _vm.deleteHandler } },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _vm.buttonRender("submit")
            ? _c(
                "r-button",
                {
                  attrs: { plain: "", loading: _vm.submitloading },
                  on: { click: _vm.submitHandler },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("DrafterDialog", {
        attrs: { dataset: _vm.drafterDialog },
        on: { close: _vm.drafterDialogClose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }