<template>
  <div class="dynamic-calendar-component">
    <el-date-picker
      :disabled="disabled"
      style="width: 100%"
      v-model="value"
      :clearable="clearable"
      :editable="editable"
      :type="valueType"
      :value-format="
        valueType === 'date' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss'
      "
      :placeholder="placeHolder"
      @change="updateHandler"
    >
    </el-date-picker>
  </div>
</template>

<script>
import { labelRender, updateSource } from "../../../detail/utils.js";

export default {
  name: "dynamic-calendar-component",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        this.label = labelRender(datas, "label", "未命名");
        this.value = labelRender(datas, "calendarDefault", null, "value");
        this.code = labelRender(datas, "code", null);
        this.placeHolder = labelRender(datas, "placeHolder2", "请选择");
        this.clearable = labelRender(datas, "clearable", false);
        this.editable = labelRender(datas, "editable", false);
        this.valueType = labelRender(
          datas,
          "calendarDefault",
          "date",
          "valueType"
        );
        if (this.value) this.updateHandler();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      label: null,
      code: null,
      value: null,
      placeHolder: "请输入",
      maxlength: 20,
      require: false,
      clearable: false,
      editable: false,
      valueType: "date",
    };
  },
  methods: {
    updateHandler() {
      updateSource(this.dataset, 'calendarDefault', this.value)
      this.$emit("update", {
        key: this.code,
        value: this.value,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic-calendar-component {
}
</style>