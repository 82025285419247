<template>
  <div class="reject-node-selector" v-loading="loading">
    <el-select v-model="value" placeholder="" style="width: 100%" @change="updateHandler">
      <el-option
        v-for="(item, index) in nodeList"
        :value="item.value"
        :label="item.label"
        :key="index"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import { getApprovedNodes } from "@/api/ruge/workflow/detail";

export default {
  name: "reject-node-selector",
  props: {
    flowDetails: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      loading: false,
      value: null,
      nodeList: [],
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    initDatas() {
      this.loading = true;
      const { definitionId } = this.$route.query;
      getApprovedNodes({
        definitionId,
        nodeId: this.flowDetails.nodeId
      }).then((res) => {
        this.nodeList = res.map(item => {
          return {
            value: item.nodeId,
            label: item.nodeName
          }
        })
        // 默认选中第一个，并update
        this.value = res[0].nodeId;
        this.updateHandler()
      }).finally(() => {
        this.loading = false;
      })
    },
    updateHandler() {
      this.$emit('update', this.value)
    },
  },
};
</script>

<style lang="less" scoped>
.reject-node-selector {
}
</style>