<template>
    <div class="guide-form-comp">
        <el-form :model="ruleForm" :rules="rules" label-position="top" ref="ruleForm" label-width="100px"
            class="demo-ruleForm">
            <el-row>
                <el-col>
                    <el-form-item label="固定资产类别" prop="categoryAssetVOList">
                        <el-cascader :options="ruleForm.categoryAssetVOList" :props="{ multiple: true }" collapse-tags
                            clearable></el-cascader></el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col>
                    <el-form-item label="折旧年限" prop="depreciationLife">
                        <el-input placeholder="请输入折旧年限" v-model="ruleForm.depreciationLife" show-word-limit><template
                                slot="append">年</template></el-input></el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="预计净残值率" prop="surplusRate">
                        <el-input placeholder="请输入预计净残值率" v-model="ruleForm.surplusRate" show-word-limit><template
                                slot="append">%</template></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item align="right">
                    <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick>确定</el-button>
                    <el-button @click="resetForm">取消</el-button>
                </el-form-item>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import {
    addDepreciation, getPropertyList
} from "@/api/ruge/ams/basicConfig/depreciationRules";
import { listToTree } from "@/utils/tree";
export default {
    props: {
        dataObj: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            depreciationId: "",
            ruleForm: {
                categoryAssetVOList: [],
                depreciationLife: null,
                surplusRate: null,
            },
            rules: {
                categoryAssetVOList: [{ required: true, message: "固定资产类别", trigger: "change" },],
                depreciationLife: [
                    { required: true, message: "折旧年限为必填", trigger: "blur" },
                ],
                surplusRate: [
                    { required: true, message: "预计净残值率为必填", trigger: "blur" },
                ],

            },
        };
    },
    created() {
        if (this.dataObj) {
            this.depreciationId = this.dataObj.depreciationId
            this.ruleForm.depreciationLife = this.dataObj.depreciationLife
            this.ruleForm.surplusRate = this.dataObj.surplusRate
        }
        this.findPropertyList()
    },
    methods: {
        findPropertyList() {
            getPropertyList({
                parentId:'ROOT'
            }).then((res) => {
                if (res) {
                    let data = listToTree(res, "assetId", "parentId");
                    this.setTree(data)
                    console.log("data", data)
                }
            })
        },
        setTree(row) {
            let data = row.map((v) => {
                v.value = v.assetId;
                v.label = v.assetName;
                if (v.children != undefined) {
                    this.setTree(v.children);
                }
                return v;
            })
            this.ruleForm.categoryAssetVOList = data;
            console.log("v", this.ruleForm.categoryAssetVOList)
        },

        handleChange(value) {
            console.log(value);
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitHandler();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        submitHandler() {
            addDepreciation({
                depreciationId: this.depreciationId,
                depreciationLife: this.ruleForm.depreciationLife,
                surplusRate: this.ruleForm.surplusRate,
            }).then((res) => {
                if (res.code === 200) {
                    this.$message.success("编辑成功！");
                    this.$emit("close", true);
                } else {
                    this.$message.warning("编辑失败！");
                }
            });
        },
        resetForm() {
            this.$emit("close", false);
        },

    },
};
</script>

<style scoped lang="less"></style>