var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "guide-form-comp" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "固定资产类别",
                        prop: "categoryAssetVOList",
                      },
                    },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.ruleForm.categoryAssetVOList,
                          props: { multiple: true },
                          "collapse-tags": "",
                          clearable: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "折旧年限", prop: "depreciationLife" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: "请输入折旧年限",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.ruleForm.depreciationLife,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "depreciationLife", $$v)
                            },
                            expression: "ruleForm.depreciationLife",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("年")])],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "预计净残值率", prop: "surplusRate" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: "请输入预计净残值率",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.ruleForm.surplusRate,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "surplusRate", $$v)
                            },
                            expression: "ruleForm.surplusRate",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("%")])],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { align: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _c("el-button", { on: { click: _vm.resetForm } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }