<template>
  <div class="rx-select">
    <div class="select-selector">
      <el-input/>
    </div>
    <div class="select-arrow">
      <img :src="url" alt="fold" />
    </div>
    <!-- <el-input >
         <slot name="suffix">
      <div class="rx-select-suffix">
        <img src="" alt="" />
      </div>
    </slot>
    </el-input> -->
  </div>
</template>
<script>
import url from "@/assets/images/workflow/fold.svg";
export default {
  name: "Select",
  data(){
    return {
      url
    }
  }
};
</script>
<style lang="scss">
.rx-select {
  position: relative;
  .rx-select-suffix {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
</style>
