<template>
  <div class="rx-input-panel" @click="handleOutsideClick">
    <div class="input" ref="inputWrapRef">
      <el-input
        ref="inputRef"
        @focus="handleFocus"
        v-on="$listeners"
        v-bind="[$props, $attrs]"
        @input="handleInput"
      >
        <template v-slot:suffix>
          <div @click="suffixClick">
            <slot name="suffix"></slot>
          </div>
        </template>
      </el-input>
    </div>
    <div ref="popupRef" class="popup" @click="handleClickPopup" :class="popupClass" :style="style">
      <transition name="fade">
        <template v-if="visible">
          <slot>
            <div class="panel">
              <slot name="panel-list" :data="suggestions">
                <div class="input-panel-wrapper ">
                  <ul v-for="groupItem in suggestions" :key="groupItem.value" class="panel-list">
                    <template v-if="groupItem.children.length">
                      <div class="panel-list">
                        <span class="group-item-title">{{ groupItem.label }}（{{groupItem.children.length}}）</span>
                        <ul v-for="item in groupItem.children" :key="item.value" class="panel-list" >
                          <li @click="handleClickPanelItem(item.data)" class="option">
                            <img :src="item.data.nodeType === 'category'? folderUrl :  fileUrl">
                            <div>
                              <div class="option-title">{{ item.label }}</div>
                              <div class="option-path">{{ item.label }}</div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </template>
                  </ul>

                </div>
              </slot>
            </div>
          </slot>
        </template>
      </transition>
    </div>
  </div>
</template>
<script>
import url from "@/assets/images/workflow/fold.svg";
import { debounce } from "lodash";
import folderUrl from "@/assets/images/workflow/folder.svg";
import fileUrl from "@/assets/images/workflow/file-icon.svg";
export default {
  name: "InputPanel",
  // mounted() {
  //   document.body.appendChild(this.$el);
  // },
  // beforeDestroy() {
  //   this.$el.remove(); // 组件销毁时移除
  // },
  data() {
    return {
      folderUrl,
      fileUrl,
      debouncedGetData: (queryString, cb) => {
        cb([]);
      },
      url,
      visible: false,
      style: {},
      loading: false,
      suggestions: [],
    };
  },
  methods: {
    handleOutsideClick(event) {
      // 判断点击位置是否在面板外部，若是则关闭面板
      if (!this.$refs.inputWrapRef.contains(event.target)) {
        this.visible = false;
        this.style = {}
      }
    },
    suffixClick() {
      console.log("this.$refs.inputWrapRef", this.$refs.inputWrapRef);
      this.$refs.inputRef.focus();
    },
    handleInput(value) {
      console.log("handleInput", value);
      this.$emit("input", value);
      this.debouncedGetData(value);
    },
    getData(queryString) {
      // if (this.suggestionDisabled) {
      //   return;
      // }
      this.loading = true;
      this.fetchSuggestions(queryString, (suggestions) => {
        this.loading = false;
        // if (this.suggestionDisabled) {
        //   return;
        // }
        if (Array.isArray(suggestions)) {
          this.suggestions = suggestions;
          console.log(this.suggestions, " this.suggestions");
          // this.highlightedIndex = this.highlightFirstItem ? 0 : -1;
        } else {
          console.error(
            "[Element Error][Autocomplete]autocomplete suggestions must be an array"
          );
        }
      });
    },
    handleClickPanelItem(item) {
      this.$emit("select", item);
    },
    handleFocus() {
      const el = this.$refs.inputWrapRef;
      const { height, left, top } = el.getBoundingClientRect();
      this.style = {
        top: `${top + height}px`,
        left: `${left}px`,
        position: "absolute",
        display: "block",
      };
      this.visible = true;
      this.debouncedGetData(this.value);
    },
    handleBlur() {
     this.$nextTick(()=>{
       this.visible = false;
       this.style = {};
     })
    },
    handleClickPopup(e) {
      setTimeout(() => {
        this.handleBlur()
      }, 60)
    }
  },
  props: {
    value: String,
    fetchSuggestions: Function,
    itemKey: {
      type: String,
      default: "value",
    },
    popupClass: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.debouncedGetData = debounce((...arg) => this.getData(...arg));
    window.addEventListener('click', this.handleOutsideClick)
    document.body.appendChild(this.$refs.popupRef);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleOutsideClick)
    document.body.removeChild(this.$refs.popupRef);
  },
};
</script>
<style lang="scss">
.rx-input-panel {
  position: relative;
}
.panel {
  position: absolute;
  width: 400px;
  height: 394px;
  background: #ffffff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid #f4f4f5;
}
.popup {
  display: none;
}
</style>
