var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "transfer-user-selector",
    },
    [
      _c("el-cascader", {
        staticStyle: { width: "100%" },
        attrs: {
          "show-all-levels": false,
          filterable: "",
          options: _vm.$store.state.app.employesLvAll,
          props: {
            multiple: false,
            value: "userId",
            label: "userName",
            children: "children",
            emitPath: false,
          },
          "collapse-tags": true,
        },
        on: { change: _vm.updateHandler },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ node, data }) {
              return [
                !node.isLeaf
                  ? _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            data.deptName ? data.deptName : data.companyName
                          ) +
                          "\n      "
                      ),
                    ])
                  : _c("span", [
                      _vm._v("\n        " + _vm._s(data.userName) + "\n      "),
                    ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }