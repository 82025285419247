var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c("flow-tree", {
        staticClass: "warp",
        attrs: {
          "default-expanded-keys": _vm.expandedKeys,
          data: _vm.treeData,
          "show-checkbox": true,
          "checked-keys": _vm.checkedKeys,
          labelTextClass: "label-text-custom",
        },
        on: {
          "checkbox-change": _vm.checkboxChange,
          "toggle-expanded": _vm.toggleExpanded,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }