var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "debugger-dialog-component" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset.show,
            "before-close": _vm.dialogCloseHandler,
            width: "1000px",
            title: "调试效果",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "show", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "debugger-result" }, [
            _c("div", { staticClass: "result-container" }, [
              _c(
                "div",
                {
                  class: [
                    "result-label",
                    _vm.required ? "result-label-required" : "",
                  ],
                },
                [_vm._v("\n          " + _vm._s(_vm.label) + "\n        ")]
              ),
              _c(
                "div",
                { staticClass: "result-selector" },
                [
                  _vm.itemType === "selector"
                    ? _c(
                        "el-select",
                        {
                          ref: "selectorIns",
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.placeHolder,
                            clearable: "",
                            multiple: _vm.multiple,
                            filterable: _vm.filterable,
                          },
                          model: {
                            value: _vm.value,
                            callback: function ($$v) {
                              _vm.value = $$v
                            },
                            expression: "value",
                          },
                        },
                        _vm._l(_vm.optionList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      )
                    : _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.value,
                            callback: function ($$v) {
                              _vm.value = $$v
                            },
                            expression: "value",
                          },
                        },
                        _vm._l(_vm.optionList, function (item) {
                          return _c(
                            "el-radio",
                            { key: item.value, attrs: { label: item.value } },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }),
                        1
                      ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "json-container" },
            [
              _c("div", { staticClass: "label-line" }, [_vm._v("json代码")]),
              _c("ace-editor", {
                staticStyle: {
                  border: "1px solid #ebecec",
                  "margin-top": "15px",
                },
                attrs: {
                  lang: "json",
                  theme: "chrome",
                  height: "340px",
                  width: "100%",
                },
                on: { init: _vm.editorInit },
                model: {
                  value: _vm.jsonResource,
                  callback: function ($$v) {
                    _vm.jsonResource = $$v
                  },
                  expression: "jsonResource",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "button-line" },
            [
              _c(
                "r-button",
                {
                  attrs: { type: "cancel" },
                  on: { click: _vm.dialogCloseHandler },
                },
                [_vm._v("关闭")]
              ),
              _c(
                "r-button",
                {
                  attrs: { loading: _vm.debugLoading },
                  on: { click: _vm.debuggerHandler },
                },
                [_vm._v("调试")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }