<template>
  <div class="submit-role-selector">
    <el-select
        v-model="value"
        placeholder=""
        style="width: 100%"
      >
        <el-option
          v-for="(item, index) in roleList"
          :value="item.value"
          :label="item.label"
          :key="index"
        ></el-option>
      </el-select>
  </div>
</template>

<script>
import { getUserAndDeptName } from '@/api/ruge/workflow/detail'

export default {
  name: 'submit-role-selector',
  data() {
    return {
      value: 'liu',
      roleList: []
    }
  },
  created() {
    this.initDatas()
  },
  methods: {
    initDatas() {
      getUserAndDeptName().then(res => {
        const {userId, userName} = res;
        this.roleList = [
          {
            label: userName,
            value: userId
          }
        ]
        this.value = userId;
      })
    },
  }
}
</script>

<style lang="less" scoped>
.submit-role-selector {

}
</style>