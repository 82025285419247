<template>
  <div class="props-component-editable">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <el-switch
        class="require-switch"
        v-model="dataset.value"
        active-color="#2A61FF"
        inactive-color="#ccc"
        :active-value="true"
        :inactive-value="false"
      >
      </el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "props-component-editable",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>