var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dynamic-department-component" },
    [
      _c(
        "div",
        { staticClass: "selector-container" },
        [
          _c("el-select", {
            ref: "selectIns",
            staticClass: "department-selector",
            attrs: {
              disabled: _vm.disabled,
              multiple: _vm.multiple,
              placeholder: "请选择",
            },
            on: {
              "remove-tag": _vm.staffRemove,
              focus: _vm.attendanceStaffFocus,
            },
            model: {
              value: _vm.displayValue,
              callback: function ($$v) {
                _vm.displayValue = $$v
              },
              expression: "displayValue",
            },
          }),
          _c("img", {
            staticClass: "input-icon",
            attrs: {
              src: require("@/assets/images/workflow/department_icon.png"),
              alt: "",
            },
          }),
        ],
        1
      ),
      _vm.departmentDialog.dialogVisible
        ? _c("DepartmentSelection", {
            attrs: { dataset: _vm.departmentDialog, multiple: _vm.multiple },
            on: { close: _vm.dialogCloseHandler, submit: _vm.departmentSubmit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }