<template>
  <div class="drafter-dialog">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="dialogCloseHandler"
      width="600px"
      title="以起草人身份操作"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        label-position="top"
        ref="flowFormRef"
        label-width="80px"
        :model="form"
        class="flow-form"
      >
        <el-form-item>
          <template #label>
            <div class="flow-form-item">
              <span class="flow-form-item-label">操作</span>
            </div>
          </template>
          <el-radio-group
            class="radio-group-border"
            v-model="form.auditOperation"
          >
            <el-radio
              v-for="item in operationListActive"
              :key="item.key"
              class="radio-border"
              :label="item.key"
              border
              :class="{ 'radio-checked': form.auditOperation === item.key }"
            >
              <div class="radio-item">
                <span>{{ item.label }}</span>
              </div>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.auditOperation === 8">
          <el-form-item>
            <template #label>
              <div class="flow-form-item">
                <span class="flow-form-item-label">通知紧急程度</span>
              </div>
            </template>
            <el-radio-group
              class="radio-group-border"
              v-model="form.urgencyLevel"
            >
              <el-radio
                v-for="item in radioList"
                :key="item.key"
                class="radio-border"
                :label="item.key"
                border
                :class="{ 'radio-checked': form.urgencyLevel === item.key }"
              >
                <div class="radio-item">
                  <img :src="item.imgSrc" :alt="item.label" />
                  <span>{{ item.label }}</span>
                </div>
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <template #label>
              <div class="flow-form-item">
                <span class="flow-form-item-label">通知选项</span>
              </div>
            </template>
            <el-checkbox class="checkbox" v-model="form.isEndNotify"
              >流程结束后通知我</el-checkbox
            >
          </el-form-item>
        </template>
        <el-form-item>
          <template #label>
            <div class="flow-form-item">
              <span class="flow-form-item-label">当前处理人</span>
            </div>
          </template>
          <div class="text-user">{{ form.currentUser || "-" }}</div>
        </el-form-item>
        <el-form-item>
          <template #label>
            <div class="flow-form-item">
              <span class="flow-form-item-label">已经处理人</span>
            </div>
          </template>
          <div class="text-user">{{ form.prevUsers || "-" }}</div>
        </el-form-item>
        <el-form-item>
          <template #label>
            <div class="flow-form-item">
              <span class="flow-form-item-label">处理意见</span>
            </div>
          </template>
          <el-input
            class="textarea"
            type="textarea"
            placeholder="请输入内容"
            v-model="form.auditComment"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="buttons-line">
        <r-button @click="dialogCloseHandler">取消</r-button>
        <r-button plain @click="submitHandler" :loading="submitLoading"
          >确定</r-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import statusBlue from "@/assets/images/approval/status-blue.svg";
import statusYellow from "@/assets/images/approval/status-yellow.svg";
import statusRed from "@/assets/images/approval/status-red.svg";
import { initiatorOperation } from "@/api/ruge/workflow/detail.js";
export default {
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
  },
  data() {
    return {
      submitLoading: false,
      statusBlue,
      statusRed,
      statusYellow,
      radioList: [
        { key: 0, label: "一般", imgSrc: statusBlue },
        { key: 1, label: "急", imgSrc: statusYellow },
        { key: 2, label: "紧急", imgSrc: statusRed },
      ],
      operationList: [
        { key: 8, label: "催办" },
        { key: 9, label: "撤回" },
        { key: 10, label: "废弃" },
      ],
      form: {
        /** 操作 */
        auditOperation: 8,
        /** 转派节点 */
        transferredNode: "",
        /** 转派人 */
        transferredPersonnel: "",
        /** 紧急程度 */
        urgencyLevel: 0,
        /** 是否通知 */
        isEndNotify: true,
        /** 当前处理人 */
        currentUser: "",
        /** 上一处理人 */
        prevUsers: "",
        /** 意见 */
        auditComment: "",
      },
    };
  },
  computed: {
    operationListActive() {
      // 如果是驳回状态|撤回状态，隐藏“催办”和“撤回”
      if ([2, 3].includes(this.dataset.datas.processStatus)) {
        this.form.auditOperation = 10;
        return this.operationList.filter(
          (item) => item.key !== 9 && item.key !== 8
        );
      }
      return this.operationList;
    },
  },
  watch: {
    dataset: {
      handler(value) {
        if (!value.datas) return;
        const { currentUser, prevUsers } = value.datas;
        this.form.currentUser = currentUser;
        this.form.prevUsers = prevUsers;
        console.log("this.form", value);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    submitHandler() {
      this.submitLoading = true;
      initiatorOperation(this.buildParams())
        .then((res) => {
          this.$message.success("操作成功");
          this.$emit("close", true);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    buildParams() {
      const { isEndNotify, auditOperation, auditComment, urgencyLevel } =
        this.form;
      const param = {
        definitionId: this.dataset.datas.definitionId,
        auditOperation,
        auditComment,
      };
      if (auditOperation === 8) {
        param.urgencyLevel = urgencyLevel;
        param.isEndNotify = isEndNotify ? "Y" : "N";
      }
      return param;
    },
    dialogCloseHandler() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.drafter-dialog {
  .flow-form {
    width: 540px;
  }

  .flow-form-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 540px;

    &-label {
      height: 16px;
      font-weight: 600;
      font-size: 14px;
      color: #252d3d;
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    &-path {
      height: 14px;
      font-weight: 400;
      font-size: 14px;
      color: #5d687c;
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }
  .select {
    ::v-deep {
      input {
        width: 540px;
        height: 46px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #d1d5dd;
      }
    }
  }
  .radio-group-border {
    display: flex;
    column-gap: 30px;
    .radio-border {
      height: 42px;
      min-width: 160px;
      display: flex;
      flex-direction: row-reverse;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #d1d5dd;
      align-items: center;
      justify-content: space-between;
      padding: 11px 10px;
      margin: 0 !important;
    }
    .radio-item {
      display: flex;
      align-items: center;
      span {
        font-weight: 500;
        font-size: 14px;
        color: #252d3d;
        line-height: 16px;
        margin-left: 11px;
      }
    }
    .radio-checked {
      border-color: #2a61ff;
    }
  }
  .checkbox {
    ::v-deep {
      .el-checkbox__input.is-checked .el-checkbox__inner {
        background: #2a61ff;
      }
      .el-checkbox__label {
        font-weight: 400;
        font-size: 14px;
        color: #252d3d;
        line-height: 16px;
        padding-left: 7px;
      }
    }
  }
  .text-user {
    font-weight: 400;
    font-size: 14px;
    color: #252d3d;
    line-height: 16px;
  }
  .textarea {
    ::v-deep {
      textarea {
        height: 80px;
        padding: 12px 16px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #d1d5dd;
      }
    }
  }
  ::v-deep {
    .el-radio__inner::after {
      box-sizing: content-box;
      content: "";
      border: 1px solid #fff;
      border-left: 0;
      border-top: 0;
      height: 7px;
      left: 4px;
      position: absolute;
      top: 1px;
      transform: rotate(45deg) scaleY(0);
      width: 3px;
      /* transition: transform .15s ease-in .05s; */
      transform-origin: center;
      border-radius: unset;
      background-color: unset;
      transition: unset;
    }
    .el-radio__input.is-checked .el-radio__inner::after {
      transform: rotate(45deg) scaleY(1);
    }
    .el-radio:hover .el-radio__inner {
      border-color: #2a61ff;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #2a61ff !important;
      background: #2a61ff;
    }
    .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled)
      .el-radio__inner {
      box-shadow: 0 0 2px 2px #2a61ff;
    }
  }
  .buttons-line {
    text-align: right;
    margin: 20px 0 10px;
  }
}
</style>