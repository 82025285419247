<template>
  <div class="dynamic-imgUpload-component">
    <el-upload
      accept=".jpg,.png"
      :action="uploadPathBuild()"
      :multiple="multiple"
      :limit="limit"
      list-type="picture-card"
      :file-list="fileList"
      :disabled="disabled"
      :on-success="successHandler"
      :on-exceed="exceedHandler"
      :auto-upload="true"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <!-- <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleDownload(file)"
            >
              <i class="el-icon-download"></i>
            </span> -->
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog append-to-body :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { labelRender, updateSource } from "../../../detail/utils.js";
import { envInfo } from "@/constants/envInfo";
import { deepClone } from "@/utils/utils.js";

export default {
  name: "dynamic-imgUpload-component",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        this.limit = labelRender(datas, "limit", 1);
        this.code = labelRender(datas, "code", null);
        this.multiple = labelRender(datas, "uploadMultiple", true);
        const hideValue = labelRender(datas, "hideValue", null);
        this.fileList = hideValue || [];
          // ? hideValue.map((item) => {
          //     item.url = item.url
          //       ? item.url
          //       : `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${item.response[0].fileId}`;
          //     return item;
          //   })
          // : [];
        console.log('this.fileList',this.fileList)
        // this.updateHandler();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      fileList: [],
      limit: 1,
      multiple: true,
      code: null,
      dialogImageUrl: null,
      dialogVisible: false,
    };
  },
  methods: {
    successHandler(response, file, fileList) {
      this.fileList = fileList;
      this.updateHandler();
    },
    exceedHandler() {
      this.$message.warning(`当前限制选择 ${this.limit} 个文件`);
    },
    uploadPathBuild() {
      const rootPath = envInfo.bgApp.archivePath;
      return `${rootPath}/param/archive/upload?ulType=DefaultUpload&scopeType=ALL`;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.fileList = this.fileList.filter(
        (item) => item.response[0].fileId !== file.response[0].fileId
      );
      this.updateHandler();
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    updateHandler() {
      updateSource(this.dataset, "hideValue", this.fileList.map(item => {
        item.url = `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${item.response[0].fileId}`
        return item
      }));
      this.$emit("update", {
        key: this.code,
        value: this.fileList.length
          ? this.fileList.map((item) => item.response[0].fileId)
          : null,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic-imgUpload-component {
}
</style>