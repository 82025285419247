var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Y-tips" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "diyModel",
          attrs: {
            title: "表单字段设置",
            visible: _vm.$attrs.isOpen,
            "close-on-click-modal": false,
            width: "1000px",
            "before-close": _vm.closeMypup,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.$attrs, "isOpen", $event)
            },
          },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "topPart" },
              [
                _c(
                  "div",
                  { staticClass: "flxdiv" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.tipConfirmChange },
                        model: {
                          value: _vm.checkedNode,
                          callback: function ($$v) {
                            _vm.checkedNode = $$v
                          },
                          expression: "checkedNode",
                        },
                      },
                      _vm._l(_vm.nodeOptionArr, function (item) {
                        return _c("el-option", {
                          key: item.nodeId,
                          attrs: {
                            label: item.name ? item.name : item.nodeCode,
                            value: item.nodeId,
                            disabled: item.disabled,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.initDfFormDatas("tip")
                      },
                    },
                  },
                  [_vm._v("恢复默认(可见不可编辑)")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "keyWordsFrom" },
              [
                _c("DynamicForm", {
                  ref: "dynamicFormIns",
                  attrs: {
                    dataset: _vm.testObj.dataset,
                    editState: _vm.testObj.editState,
                    flowNodeId: _vm.testObj.flowNodeId,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "btmBtn" },
              [
                _c(
                  "el-button",
                  { staticClass: "searchbtn", on: { click: _vm.closeMypup } },
                  [_vm._v(" 取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", disabled: _vm.isHttping },
                    on: { click: _vm.getFromDatas },
                  },
                  [_vm._v(" 确定")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }