<template>
  <div class="dynamic-calendar-component">
    <el-switch
      :disabled="disabled"
      v-model="value"
      :active-value="activeValue"
      :inactive-value="inactiveValue"
      @change="updateHandler"
    >
    </el-switch>
  </div>
</template>

<script>
import { labelRender, updateSource } from "../../../detail/utils.js";

export default {
  name: "dynamic-calendar-component",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    dataset: {
      handler(datas) {
        this.activeValue = labelRender(datas, "activeValue", null);
        this.inactiveValue = labelRender(datas, "inactiveValue", null);
        this.code = labelRender(datas, "code", null);
        const switchDefault = labelRender(datas, "switchDefault", false);
        this.value = switchDefault ? this.activeValue : this.inactiveValue;
        this.updateHandler();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      value: null,
      code: null,
      activeValue: null,
      inactiveValue: null,
    };
  },
  methods: {
    updateHandler() {
      updateSource(this.dataset, 'switchDefault', this.value)
      this.$emit("update", {
        key: this.code,
        value: this.value,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic-calendar-component {
}
</style>