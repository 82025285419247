<template>
  <div class="countersign-user-selector" v-loading="loading">
    <el-cascader
      v-model="currentValue"
      :show-all-levels="false"
      filterable
      @change="updateHandler"
      :options="userList"
      ref="cascaderIns"
      :props="{
        multiple: true,
        value: 'userId',
        label: 'userName',
        children: 'children',
        emitPath: false,
      }"
      style="width: 100%"
      :collapse-tags="false"
    >
      <template slot-scope="{ node, data }">
        <span v-if="!node.isLeaf">
          {{ data.deptName ? data.deptName : data.companyName }}
        </span>
        <span v-else>
          {{ data.userName }}
        </span>
      </template>
    </el-cascader>
  </div>
</template>

<script>
import { GenNonDuplicateID } from "@/utils/utils.js";

export default {
  name: "countersign-user-selector",
  data() {
    return {
      currentValue: [],
      userList: [],
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    this.$store
      .dispatch("getEmployeelvAll")
      .then((res) => {
        const nowUserId = localStorage.getItem("userId");
        this.userList = this.currentUserFilter(res, nowUserId);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    currentUserFilter(list, nowUserId) {
      return list.map((item) => {
        // 给没有userId的节点添加key，不然初始化的时候所有父级节点标蓝
        if (!item.userId) {
          item.userId = GenNonDuplicateID();
        }
        // 过滤掉当前用户
        if (item.children && item.children.length) {
          item.children = item.children.filter(
            (child) => child.userId != nowUserId
          );
          this.currentUserFilter(item.children, nowUserId);
        }
        return item;
      });
    },
    updateHandler() {
      this.$nextTick(() => {
        this.$refs.cascaderIns.inputValue = "";
        this.$refs.cascaderIns.filtering = false;
      });
      this.$emit("update", this.currentValue);
    },
  },
};
</script>

<style lang="less" scoped>
.countersign-user-selector {
}
</style>