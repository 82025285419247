var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "guide-form-comp" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "项目选择", prop: "projectId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          on: { change: _vm.subsetCompany },
                          model: {
                            value: _vm.ruleForm.projectId,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "projectId", $$v)
                            },
                            expression: "ruleForm.projectId",
                          },
                        },
                        _vm._l(_vm.projectData, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              value: item.companyId,
                              label: item.companyName,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.optionUnit(item.companyName)
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属部门", prop: "companyId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择所属部门",
                          },
                          model: {
                            value: _vm.ruleForm.companyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "companyId", $$v)
                            },
                            expression: "ruleForm.companyId",
                          },
                        },
                        _vm._l(_vm.companyList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.deptId, label: item.deptName },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.changeCompany(item.deptName)
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "仓库管理员", prop: "administratorList" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { multiple: "" },
                          model: {
                            value: _vm.ruleForm.administratorList,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "administratorList", $$v)
                            },
                            expression: "ruleForm.administratorList",
                          },
                        },
                        _vm._l(_vm.empData, function (item) {
                          return _c("el-option", {
                            key: item.userId,
                            attrs: { label: item.userName, value: item.userId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "仓库名称", prop: "warehouseName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入仓库名称",
                          maxlength: "50",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.ruleForm.warehouseName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "warehouseName", $$v)
                          },
                          expression: "ruleForm.warehouseName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "仓库编码", prop: "warehouseCode" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入仓库编码",
                          maxlength: "50",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.ruleForm.warehouseCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "warehouseCode", $$v)
                          },
                          expression: "ruleForm.warehouseCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "可使用公司",
                        prop: "availableCompanyList",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { multiple: "" },
                          model: {
                            value: _vm.ruleForm.availableCompanyList,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "availableCompanyList",
                                $$v
                              )
                            },
                            expression: "ruleForm.availableCompanyList",
                          },
                        },
                        _vm._l(_vm.companyListAll, function (item) {
                          return _c("el-option", {
                            key: item.companyId,
                            attrs: {
                              label: item.companyName,
                              value: item.companyId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "可使用部门",
                        prop: "availableDepartmentList",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { multiple: "" },
                          model: {
                            value: _vm.ruleForm.availableDepartmentList,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "availableDepartmentList",
                                $$v
                              )
                            },
                            expression: "ruleForm.availableDepartmentList",
                          },
                        },
                        _vm._l(_vm.companyList, function (item) {
                          return _c("el-option", {
                            key: item.deptId,
                            attrs: { label: item.deptName, value: item.deptId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属地区", prop: "affiliatedArea" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择所属地区",
                          },
                          model: {
                            value: _vm.ruleForm.affiliatedArea,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "affiliatedArea", $$v)
                            },
                            expression: "ruleForm.affiliatedArea",
                          },
                        },
                        _vm._l(_vm.areaList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "仓库地址",
                            prop: "warehouseAddress",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入仓库地址" },
                            model: {
                              value: _vm.ruleForm.warehouseAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "warehouseAddress", $$v)
                              },
                              expression: "ruleForm.warehouseAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { align: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _c("el-button", { on: { click: _vm.resetForm } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }