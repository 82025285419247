import Vue from 'vue';
import store from './store';
import { permissions } from '@/constants/permissions';
import { on, off, getStyle } from 'element-ui/src/utils/dom';

/**
 * 操作权限鉴权指令
 * @type {DirectiveOptions}
 */
Vue.directive('rugePermission', {
  inserted: function (el, binding) {
    // 获取页面按钮权限
    let permissionCode = binding.value;
    if (!Vue.prototype.$rugePermission(permissionCode)) {
      el.parentNode.removeChild(el);
    }
  },
});

Vue.directive('InputNumber', {
    bind (el) {
        el.oninput = () => {
        		let val=el.children[0].value.replace(/[^\d.]/g, '');
        		if(Number.isNaN(Number(val))){
        			console.log('不是数字',val)
        			el.children[0].value=0;
        		}else{
        			el.children[0].value=val;
        		}
           
          }
      }
 });
/**
 * 操作权限鉴权方法
 * @param permissionCode 待鉴权的权限编码
 * @returns {boolean} true: 有权限；false: 无权限
 */
Vue.prototype.$rugePermission = function (permissionCode) {
  // 如果待鉴权的权限编码为空，则表示无需鉴权，返回true
  if (!permissionCode) {
    return true;
  }
  // 权限检测配置,前端按钮做权限检测时，如果配置为"IgnoreAll"，则放开所有权限检测
  // if ("IgnoreAll" == checkPermission) {
  //     return true;
  // }
  let havePermissions = store.getters.havePermissions;
  // 鉴权
  let _valid = function (p) {
    for (let i = 0; i < havePermissions.length; i++) {
      if (p == havePermissions[i]) {
        return true;
      }
    }
    return false;
  };
  return _valid(permissionCode);
};

/**
 * 当前系统所有操作权限编码集合
 */
Vue.prototype.$ALLPERMISSIONS = permissions;

Vue.directive('drawerDrag', {
  bind(el, binding, vnode, oldVnode) {
    const minWidth = 446;
    const dragDom = el.querySelector('.el-drawer');
    dragDom.style.overflow = 'auto';

    const resizeElL = document.createElement('div');
    const img = new Image(24, 38);
    img.src = require('@/assets/images/stretch.png');
    dragDom.appendChild(img);
    dragDom.appendChild(resizeElL);
    resizeElL.style.cursor = 'n-resize';
    resizeElL.style.position = 'absolute';
    resizeElL.style.height = '30px';
    resizeElL.style.width = '95%';
    resizeElL.style.left = '0px';
    resizeElL.style.top = '0px';
    img.style.position = 'absolute';
    img.style.left = 'calc(50% - 12px)';
    img.style.top = '5px';
    img.style.height = '24px';
    img.style.width = '24px';
    img.style.opacity = '0.5';
    img.style.cursor = 'n-resize';

    resizeElL.onmousedown = (e) => {
      const elW = dragDom.clientWidth;
      const elH = dragDom.clientHeight;
      const EloffsetLeft = dragDom.offsetLeft;
      const EloffsetTop = dragDom.offsetTop;
      const clientX = e.clientX;
      const clientY = e.clientY;
      const leftInput = el.querySelector('.left-part .el-textarea');
      const leftInputHeight = leftInput.clientHeight;
      const rightInput = el.querySelector('.right-part .el-textarea');
      const rightInputHeight = rightInput.clientHeight;
      const dividerDom = el.querySelector('.el-divider');
      const dividerHeight = dividerDom.clientHeight;
      document.onmousemove = function (e) {
        e.preventDefault();
        // clientY > e.clientY 向上拖动，并且离边框的距离预留20px
        if (clientY > e.clientY && e.clientY > 20) {
          dragDom.style.height = elH + (clientY - e.clientY) + 'px';
          leftInput.style.height =
            leftInputHeight + (clientY - e.clientY) + 'px';
          rightInput.style.height =
            rightInputHeight + (clientY - e.clientY) + 'px';
          dividerDom.style.height =
            dividerHeight + (clientY - e.clientY) + 'px';
        }
        // clientY < e.clientY 向下拖动，并且不小于最小高度
        if (clientY < e.clientY && dragDom.clientHeight >= minWidth) {
          dragDom.style.height = elH - (e.clientY - clientY) + 'px';
          leftInput.style.height =
            leftInputHeight - (e.clientY - clientY) + 'px';
          rightInput.style.height =
            rightInputHeight - (e.clientY - clientY) + 'px';
          dividerDom.style.height =
            dividerHeight - (e.clientY - clientY) + 'px';
        }
      };
      // 拉伸结束
      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  },
});

/**
 * 按钮重复点击
 */
Vue.directive('preventReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true;
        el.style.cursur = 'not-allowed';
        setTimeout(() => {
          el.disabled = false;
          el.style.cursor = 'pointer';
        }, binding.value || 2000);
      }
    });
  },
});

/**
 * tooltip-auto-show
 * 当text没有被折叠时，不显示tooltip
 */
 Vue.directive('tooltip-auto-show', {
  inserted: function (el, binding, vnode) {
    el.addEventListener('mouseenter', function (e) {
      let defalutSilent = !!Vue.config.silent;
      Vue.config.silent = true;
      vnode.componentInstance.disabled = true;
      const range = document.createRange();
      range.setStart(el, 0);
      range.setEnd(el, el.childNodes.length);
      const rangeWidth = Math.ceil(range.getBoundingClientRect().width);
      const padding = (parseInt(getStyle(el, 'paddingLeft'), 10) || 0) + (parseInt(getStyle(el, 'paddingRight'), 10) || 0);
      if (rangeWidth + padding > el.offsetWidth || el.scrollWidth > el.offsetWidth) {
        vnode.componentInstance.disabled = false;
      }
      Vue.config.silent = defalutSilent;
    });
  }
});