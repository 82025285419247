var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._g(
      _vm._b({ staticClass: "card-box" }, "div", _vm.$attrs, false),
      _vm.$listeners
    ),
    [
      _c("img", {
        staticClass: "card-icon",
        attrs: {
          src: _vm.data.type === _vm.FileType ? _vm.fileUrl : _vm.folderUrl,
          alt: "folder",
        },
      }),
      _c(
        "el-tooltip",
        {
          directives: [
            { name: "tooltip-auto-show", rawName: "v-tooltip-auto-show" },
          ],
          staticClass: "text-overflow tooltip",
          attrs: {
            effect: "dark",
            content: _vm.data.nodeName,
            placement: "top-start",
          },
        },
        [
          _c("span", { staticClass: "card-name text-overflow" }, [
            _vm._v(_vm._s(_vm.data.nodeName)),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }