var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "page",
      style: {
        width: _vm.sidebar.opened
          ? "calc(100vw - 330px)"
          : "calc(100vw - 140px)",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }