var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    _vm._b(
      {
        directives: [
          { name: "tooltip-auto-show", rawName: "v-tooltip-auto-show" },
        ],
        attrs: { placement: "top-start", offset: 100, content: _vm.value },
      },
      "el-tooltip",
      _vm.$attrs,
      false
    ),
    [
      _c("div", { staticClass: "text-overflow", class: _vm.contentClass }, [
        _vm._v(_vm._s(_vm.value)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }