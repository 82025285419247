<template>
  <div class="graph-component-department single-graph-component">
    <div :class="['item-label', datas.require && 'item-label-required']">
      {{ datas.label }}
    </div>
    <div class="selector-container">
      <el-select
        disabled
        :readonly="readOnly"
        class="department-selector"
        v-model="datas.displayValue"
        multiple
        placeholder="请选择"
      >
      </el-select>
      <img
        class="input-icon"
        src="@/assets/images/workflow/department_icon.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import { labelRender } from "../utils.js";
export default {
  name: "graph-component-department",
  props: {
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(item) {
        this.datas.label = labelRender(item, "label", "未命名");
        this.datas.require = labelRender(item, "require", false);
        const value = labelRender(item, "departmentDefault", []);
        this.datas.displayValue = value.map((item) => item.deptName);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      labelRender,
      options: [],
      datas: {
        label: null,
        displayValue: [],
        require: false,
      },
    };
  },
};
</script>
<style lang="less" scoped>
.graph-component-department {
  .selector-container {
    position: relative;
    .input-icon {
      position: absolute;
      left: 12px;
      top: 10px;
    }
    .department-selector {
      width: 100%;
      position: relative;
      ::v-deep .el-input__inner {
        padding-left: 40px;
      }
    }
  }
}
</style>