<template>
  <div class="props-component-unit">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <div class="unit-line">
        <el-select v-model="dataset.unitType" class="unit-selector">
          <el-option
            v-for="item in unitList"
            :value="item.value"
            :label="item.label"
            :key="item.value"
          ></el-option>
        </el-select>
        <el-input v-model="dataset.unitString"></el-input>
      </div>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "props-component-unit",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      unitList: [
        {
          value: "prepend",
          label: "前缀",
        },
        {
          value: "append",
          label: "后缀",
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.props-component-unit {
  .unit-line {
    display: flex;
    gap: 4px;
    .unit-selector {
      min-width: 88px;
    }
  }
}
</style>