<template>
  <div class="props-component-layout">
    <el-form-item
      :label="dataset.label"
      :prop="dataset.key"
      :class="['single-form-item', `single-form-${dataset.key}`]"
    >
      <div class="layout-container">
        <div
          v-if="showHalf"
          :class="[
            'layout-item layout-half',
            dataset.value === 'half' && 'layout-active',
          ]"
          @click="itemClickHandler('half')"
        >
          <div class="inner-item"></div>
          <div class="inner-item"></div>
          <img
            class="active-corner"
            src="@/assets/images/dynamicForm/layout-active-corner.png"
            v-if="dataset.value === 'half'"
            alt=""
          />
        </div>
        <div
          :class="[
            'layout-item layout-all',
            dataset.value === 'all' && 'layout-active',
          ]"
          @click="itemClickHandler('all')"
        >
          <div class="inner-item"></div>
          <img
            class="active-corner"
            src="@/assets/images/dynamicForm/layout-active-corner.png"
            v-if="dataset.value === 'all'"
            alt=""
          />
        </div>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import { labelRender } from "../utils.js";
export default {
  name: "props-component-layout",
  props: {
    itemType: {
      type: String,
      default() {
        return "";
      },
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    propsList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    showHalf() {
      // radio【单选按钮】checkbox【多选按钮】纵向排列的时候，只能是整行
      if (["radio", "checkbox"].includes(this.itemType)) {
        const curDirection = labelRender(
          { propsList: this.propsList },
          "direction",
          "row"
        );
        this.dataset.value = curDirection === "column" ? "all" : "half";
        return curDirection == "row";
      }
      // "textarea", "editor", "imgUploader", "fileUploader"四种类型只能选整行
      return !this.disableList.includes(this.itemType);
    },
  },
  data() {
    return {
      disableList: ["textarea", "editor", "imgUploader", "fileUploader"],
    };
  },
  methods: {
    itemClickHandler(type) {
      if (this.disableList.includes(this.itemType)) return;
      this.dataset.value = type;
    },
  },
};
</script>

<style lang="less" scoped>
.props-component-layout {
  .layout-container {
    display: flex;
    flex-direction: column;
    .disable-item {
      cursor: not-allowed !important;
    }
    .layout-item {
      width: 240px;
      height: 58px;
      border-radius: 6px;
      border: 1px solid #e4e7eb;
      cursor: pointer;
      position: relative;
      .active-corner {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .layout-active {
      border-color: #2a61ff;
    }
    .layout-half {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .inner-item {
        width: 100px;
        height: 36px;
        background: #b5bece;
        border-radius: 4px;
      }
    }
    .layout-all {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 10px;
      .inner-item {
        width: 210px;
        height: 36px;
        background: #b5bece;
        border-radius: 4px;
      }
    }
  }
}
</style>