var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "props-component-default" },
    [
      _c(
        "el-form-item",
        {
          class: ["single-form-item", `single-form-${_vm.dataset.key}`],
          attrs: { label: _vm.dataset.label, prop: _vm.dataset.key },
        },
        [
          ["numberInput"].includes(_vm.itemType)
            ? _c("el-input-number", {
                staticClass: "number-input-dom",
                attrs: {
                  controls: false,
                  min: _vm.minValue,
                  max: _vm.maxValue,
                },
                model: {
                  value: _vm.dataset.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataset, "value", $$v)
                  },
                  expression: "dataset.value",
                },
              })
            : _c("el-input", {
                attrs: {
                  placeholder: _vm.dataset.value || "请输入",
                  maxlength: _vm.dataset.key === "default" ? 999 : 20,
                  "show-word-limit": _vm.dataset.key !== "default",
                },
                model: {
                  value: _vm.dataset.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataset, "value", $$v)
                  },
                  expression: "dataset.value",
                },
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }