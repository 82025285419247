var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "props-component-switchDefault" },
    [
      _c(
        "el-form-item",
        {
          class: ["single-form-item", `single-form-${_vm.dataset.key}`],
          attrs: { label: _vm.dataset.label, prop: _vm.dataset.key },
        },
        [
          _c(
            "el-radio",
            {
              attrs: { label: true },
              model: {
                value: _vm.dataset.value,
                callback: function ($$v) {
                  _vm.$set(_vm.dataset, "value", $$v)
                },
                expression: "dataset.value",
              },
            },
            [_vm._v("默认打开")]
          ),
          _c(
            "el-radio",
            {
              attrs: { label: false },
              model: {
                value: _vm.dataset.value,
                callback: function ($$v) {
                  _vm.$set(_vm.dataset, "value", $$v)
                },
                expression: "dataset.value",
              },
            },
            [_vm._v("默认关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }