/*
 * @LastEditors: luo
 * @LastEditTime: 2024-12-02 14:14:14
 * @Description: 
 */
export const FolderType = 'folder'
export const FileType = 'file'
export const RootType = 'ROOT'

export function getMessageError(err) {
    if (err instanceof Error) {
        return err.message
    }
    return err
}
export function getTreeData(res) {
    return res.map(item => {
        if (item.parentNodeId === RootType) {
            item.parentNodeId = null
            item.nodeId = RootType
        } else if (!item.parentNodeId) {
            item.parentNodeId = RootType
        }
        return item
    })
}
export function arrayToTree(data) {
    const dataMap = {}
    const nodes = []

    data.forEach(item => {
        dataMap[item.nodeId] = {
            ...item,
            children: [],
            id: item.nodeId,
            label: item.nodeName,
            disabled: item.enableStatus === 'DISABLE'
        }
    })
    for (const item of data) {
        const node = dataMap[item.nodeId]
        if (item.parentNodeId && dataMap[item.parentNodeId]) {
            dataMap[item.parentNodeId].children.push(node)
        } else {
            nodes.push(node)
        }
    }
    console.log('nodes', nodes)
    return nodes
}
/**
 * 将扁平数据格式化为树形结构数据
 * @param {Array} data - 需要格式化的原始数据数组
 * @param {Object} props - 数据字段映射配置
 * @param {string} props.id - ID字段名称，默认为'id'
 * @param {string} props.label - 标签字段名称，默认为'label'
 * @param {string} props.children - 子节点字段名称，默认为'children'
 * @param {Object} options - 格式化选项
 * @param {number} options.level - 当前节点层级，默认为0
 * @param {string|null} options.parentId - 父节点ID，默认为null
 * @param {Array} options.parentIds - 父节点ID数组，用于记录节点路径，默认为空数组
 * @param {Array} options.parentNodes - 父节点对象数组，用于记录完整的父节点对象，默认为空数组
 * @returns {Array<Object>} 格式化后的树形结构数据
 *   @returns {number} .level - 当前节点的层级深度
 *   @returns {Array} .parentIds - 从根节点到当前节点的所有父节点ID路径
 *   @returns {Array} .parentNodes - 从根节点到当前节点的所有父节点对象
 *   @returns {Array} [.children] - 子节点数组，如果存在子节点的话
 */
export function formatTreeData(data, props = {}, options = {}) {
    const { id: idKey = 'id', label: labelKey = 'label', children: childrenKey = 'children' } = props
    const { level = 0, parentIds = [], parentNodes = [] } = options
    return data.map(item => {
        const id = item[idKey]
        const curParentIds = [...parentIds, id]
        const curParentNodes = [...parentNodes, item]
        const type = getNodeType(item.nodeType)
        if (item[childrenKey] && item[childrenKey].length) {
            return {
                ...item,
                level,
                type,
                parentIds: curParentIds,
                parentNodes: curParentNodes,
                [childrenKey]: formatTreeData(item[childrenKey], props, { level: level + 1, parentId: id, parentIds: curParentIds, parentNodes: curParentNodes })
            }
        } else {
            return {
                ...item,
                level,
                type,
                parentIds: curParentIds,
                parentNodes: curParentNodes,
            }
        }
    })
}
export function getNodeType(type) {
    if (type === 'category') {
        return FolderType
    }
    if (type === 'file') {
        return FileType
    }
    if (type === RootType) {
        return RootType
    }
    return null
}
const DEFAULT_CONFIG = {
    id: 'id',
    children: 'children',
    parentId: 'parentNodeId'
}
const getConfig = config => Object.assign({}, DEFAULT_CONFIG, config)
export function findNode(tree, func, config = {}) {
    config = getConfig(config)
    const { children } = config, list = [...tree]
    for (let node of list) {
        if (func(node)) return node
        node[children] && list.push(...node[children])
    }
    return null
}

export function  removeNode (tree, func, config = {}) {
    config = getConfig(config)
    const { children } = config, list = [tree]
    while (list.length) {
        const nodeList = list.shift()
        const delList = nodeList.reduce((r, n, idx) => (func(n) && r.push(idx), r), [])
        delList.reverse()
        delList.forEach(idx => nodeList.splice(idx, 1))
        const childrenList = nodeList.map(n => n[children]).filter(l => l && l.length)
        list.push(...childrenList)
    }
}

export function treeToList (tree, config = {}) {
    config = getConfig(config)
    const { children } = config, result = [...tree]
    for (let i = 0; i < result.length; i++) {
        if (!result[i][children]) continue
        result.splice(i + 1, 0, ...result[i][children])
    }
    return result
}