var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dynamic-email-component" },
    [
      _c("el-input", {
        attrs: {
          clearable: "",
          disabled: _vm.disabled,
          placeholder: _vm.placeHolder,
          "prefix-icon": "el-icon-message",
        },
        on: { input: _vm.updateHandler, blur: _vm.emailValid },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }