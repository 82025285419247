var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "process-graph-component" },
    [
      _vm.nodeId
        ? _c("ProcessGraph", {
            attrs: {
              isCompent: true,
              definitionId: _vm.definitionId,
              bpmnId: _vm.nodeId,
              allowEdit: false,
              versionIdSpecial: _vm.versionIdSpecial,
              bHeight: "600",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }